/* global axios */

import ApiClient from './ApiClient';

class InboxLabelAPI extends ApiClient {
  constructor() {
    super('inbox_labels');
  }
}


export default new InboxLabelAPI();
