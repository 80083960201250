<template>
  <div class="report-outer-div">
    <!-- Second Sidebar Section -->
    <div class="second-sidebar">
      <div class="qw-sub-menu-header-section">
        <div class="qw-sub-menu-header-title">Reports</div>
      </div>
      <div class="qw-sub-menu-body-section">
        <div
          v-for="(tab, index) in reportTabs"
          :key="tab.id"
          :index="index"
          @click="changeReportTab(tab)"
          v-bind:class="['report-sections-tab-title', 'l-h-24', { 'selected' : tab.id == currentTabSelected.id }]"
        >{{tab.name}}</div>
      </div>
    </div>
    <div class="qw-body-section">
      <div class="qw-body-header-section">
        <div class="qw-body-header-title">{{currentTabSelected.name}}</div>
      </div>
      <div class="report-main-section">
        <!-- DateTime/Inbox Selection -->
        <div style="display:flex;">
          <div>
            <div class="report-date-selector">
              <div class="report-date-section">
                <div style="position: relative">
                  <div
                    v-if="dateDropdownShow"
                    v-on-clickaway="onToggleDateDropdown"
                    class="qw-dropdown-menu-items"
                  >
                    <div
                      class="date-filter-element"
                      v-for="item in dateRange"
                      :key="item.id"
                      @click="changeDateSelection(item)"
                    >
                      <div class="title">{{item.name}}</div>
                    </div>
                  </div>
                </div>
                <div class="date-filter-element" @click="onToggleDateDropdown">
                  <img src="~/assets/images/Calendar_Icon.svg">
                  <div class="title">{{currentDateRangeSelection.name}}</div>
                  <i
                    class="fa fa-chevron-down"
                    aria-hidden="true"
                    style="font-size: 8px; color:#1A1C1D;"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-left: 5px;">
            <div class="report-date-selector">
              <div class="report-date-section">
                <div style="position: relative">
                  <div
                    v-if="inboxDropdownShow"
                    v-on-clickaway="onToggleInboxDropdown"
                    class="qw-dropdown-menu-items"
                  >
                    <div class="date-filter-element" @click="inboxChangeEvent(null)">
                      <div class="title">
                        <b>All Inboxes</b>
                      </div>
                    </div>
                    <div
                      class="date-filter-element"
                      v-for="item in inboxesList"
                      :key="item.id"
                      @click="inboxChangeEvent(item)"
                    >
                      <div class="title">{{item.name}}</div>
                    </div>
                  </div>
                </div>
                <div class="date-filter-element" @click="onToggleInboxDropdown">
                  <div class="title">{{currentInboxSelection.name}}</div>
                  <i
                    class="fa fa-chevron-down"
                    aria-hidden="true"
                    style="font-size: 8px; color:#1A1C1D;"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Reports section -->
        <div v-if="currentTabSelected.id == 'overview_report'">
          <overview-reports :to="to" :from="from" :currentInboxSelection="currentInboxSelection" />
        </div>

        <div v-if="currentTabSelected.id == 'team_performance_report'">
          <team-reports :to="to" :from="from" :currentInboxSelection="currentInboxSelection" />
        </div>

        <div v-if="currentTabSelected.id == 'label_report'">
          <label-reports :to="to" :from="from" :currentInboxSelection="currentInboxSelection" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import LabelReports from './LabelReports.vue';
import TeamReports from './TeamReports.vue';
import OverviewReports from './OverviewReports.vue';
import moment from 'moment';

export default {
  mixins: [clickaway],
  components: {
    LabelReports,
    TeamReports,
    OverviewReports
  },
  data() {
    return {
      dateDropdownShow: false,
      inboxDropdownShow: false,
      currentDateRangeSelection: this.$t('REPORT.DATE_RANGE_NEW')[2],
      currentInboxSelection: {
        id: null,
        name: 'All Inboxes',
      },
      currentTabSelected: this.$t('REPORT.REPORTS_TABS')[0],
      dateRange: this.$t('REPORT.DATE_RANGE_NEW'),
    };
  },
  computed: {
    ...mapGetters({
      inboxesList: 'inboxes/getInboxes',
    }),
    to() {
      const m = moment.utc();
      m.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
      return m.unix();
    },
    from() {
      var diff = null;
      if (this.currentDateRangeSelection.id == 0) {
        diff = 0;
      } else if (this.currentDateRangeSelection.id == 1) {
        diff = 1;
      } else if (this.currentDateRangeSelection.id == 2) {
        diff = 6;
      } else if (this.currentDateRangeSelection.id == 3) {
        diff = 29;
      } else if (this.currentDateRangeSelection.id == 4) {
        diff = 89;
      }
      // const diff = this.currentDateRangeSelection.id ? 29 : 6;
      const m = moment.utc().subtract(diff, 'days');
      m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      return m.unix();
    },
    reportTabs() {
      return this.$t('REPORT.REPORTS_TABS');
    },
  },
  mounted() {},
  methods: {
    onToggleDateDropdown() {
      this.dateDropdownShow = !this.dateDropdownShow;
    },
    onToggleInboxDropdown() {
      this.inboxDropdownShow = !this.inboxDropdownShow;
    },
    changeReportTab(tab) {
      this.currentTabSelected = tab;
    },
    changeDateSelection(selectedRange) {
      this.currentDateRangeSelection = selectedRange;
      this.onToggleDateDropdown();
    },
    inboxChangeEvent(selectedInbox) {
      if (selectedInbox == null) {
        this.currentInboxSelection = { id: null, name: 'All Inboxes' };
      } else {
        this.currentInboxSelection = selectedInbox;
      }
      this.onToggleInboxDropdown();
    },
  },
};
</script>


<style lang="scss">
.second-sidebar {
  flex: 0 0 19.58%;
  max-width: 19.58%;
  border-right: 1px solid #efeef0;
}

.report-outer-div {
  display: flex;
  flex: 1 1;
  background: var(--white) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.report-main-section {
  padding: 2rem;
  height: calc(100% - 67px);
  overflow-y: scroll;
  // padding-bottom: 15%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.report-sections {
}

.report-sections-tab-title {
  padding-left: 24px;
  padding-top: 15px;
  text-align: left;
  letter-spacing: 0px;
  color: #697181;
  font-size: 14px;
  opacity: 1;
  cursor: pointer;
}

.report-sections-tab-title.selected {
  color: #3970f1;
  font-weight: bold;
}

.report-sections-inbox-selection {
  padding: 15px;
  border-bottom: 1px solid #efeef0;
  text-align: left;
  letter-spacing: 0px;
  color: #1a1c1d;
  font-size: 14px;
  opacity: 1;

  .multiselect > .multiselect__tags {
    border: 0;
    // min-height: 0;
    color: #1a1c1d;
  }

  .multiselect__input,
  .multiselect__single {
    background: initial;
    color: #1a1c1d;
  }

  .multiselect > .multiselect__tags .multiselect__single {
    padding: 1rem 0;
  }

  .multiselect__option {
    padding: 10px 5px;
  }

  .multiselect__option--highlight {
    background: #f9fafc;
    color: #1a1c1d;
    padding: 10px 5px;
  }

  .multiselect > .multiselect__tags .multiselect__placeholder {
    color: #1a1c1d;
  }

  .multiselect--active > .multiselect__tags .multiselect__placeholder {
    color: #1a1c1d;
  }

  .multiselect__select:before {
    border-color: #969ba7 transparent transparent;
  }
}

.report-summery-export {
  display: flex;
  padding: 5px;
  justify-content: flex-end;
  width: 100%;
}

.broadcast-element {
  padding: 10px;
  margin: 2px 0px 5px 0px;
  display: flex;
  border: 1px solid #efeef0;
  border-radius: 8px;
  .rule {
    align-self: center;
  }
}
.broadcast-element:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0);
}
.broadcast-element-empty {
  justify-content: center;
  padding: 3rem;
  margin: 10px;
  border: 1px solid #efeef0;
  border-radius: 10px;
}

.top-class {
  color: black;
  background: white;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #dedddf;
}

.report_stats_main_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dedddf;
  border-radius: 8px;
  opacity: 1;
}

.report-bar-new {
  border: 1px solid #dedddf;
  border-radius: 8px;
  padding: 24px;
}

.report-title {
  text-align: left;
  letter-spacing: 0px;
  color: #1a1c1d;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
}

.qw-export-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dedddf;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 8px 12px;
}

.qw-export-button:hover {
  border-color: #3970f1;

  .export-botton-title {
    color: #3970f1;
  }
}

.export-botton-title {
  text-align: center;
  letter-spacing: 0px;
  color: #1a1c1d;
  opacity: 1;
  font-size: 14px;
  padding: 2px 10px;
}

.tooltip.top.report-top-hover {
  margin-bottom: 8px;
  border-radius: 4px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05);
  transition-property: opacity, transform;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #fff;
  color: #222;
  font-size: 14px;
  line-height: 20px;
  width: 260px;
  max-width: 260px;
}

.tooltip.top.report-top-hover::before {
  position: absolute;
  left: 0px;
  transform: translate3d(126px, 0px, 0px);
  bottom: -5px;
  border-color: #fff transparent transparent;
}

.team_report_tooltip {
  font-size: 14px !important;
  color: #485463 !important;
}

.report-data-table-teams {
  table {
    border: 1px solid #dedddf;
    border-radius: 8px;
    font-size: 12px;

    th,
    td {
      text-align: right;
      padding: 8px;
    }

    th:first-child,
    td:first-child {
      text-align: left;
      padding: 8px;
    }
  }

  th {
    text-transform: capitalize;
    background-color: #f9f9f9;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  th.active {
    color: #3970f1;
  }

  th.active .arrow.asc {
    opacity: 1;
    border-bottom: 4px solid #3970f1;
  }

  th.active .arrow.dsc {
    opacity: 1;
    border-top: 4px solid #3970f1;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

  .arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #c7c4c4;
  }

  .arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #c7c4c4;
  }
}

.report-data-table-labels {
  table {
    border: 1px solid #dedddf;
    border-radius: 8px;
    font-size: 13px;

    th,
    td {
      text-align: right;
      padding: 8px;
    }

    th:first-child,
    td:first-child {
      text-align: left;
      padding: 8px;
    }
  }

  th {
    text-transform: capitalize;
    background-color: #f9f9f9;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  th.active {
    color: #3970f1;
  }

  th.active .arrow.asc {
    opacity: 1;
    border-bottom: 4px solid #3970f1;
  }

  th.active .arrow.dsc {
    opacity: 1;
    border-top: 4px solid #3970f1;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

  .arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #c7c4c4;
  }

  .arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #c7c4c4;
  }
}

.search-option {
  border: 0;
  border-bottom: 1px solid #dedddf;
  border-radius: 0px !important;
  width: 50%;
  float: right;
  margin-bottom: 0;
  padding-right: 32px;
}

.search-option:focus {
  border: 0;
  border-bottom: 1px solid #dedddf;
  outline: none;
}

.qw-sub-menu-header-section {
  padding: 17px 24px;
  width: 100%;
  border-bottom: 1px solid #efeef0;
}

.qw-sub-menu-header-title {
  text-align: left;
  letter-spacing: 0px;
  color: #1a1c1d;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
}

.qw-body-header-section {
  padding: 17px 24px;
  width: 100%;
  border-bottom: 1px solid #efeef0;
}

.qw-body-header-title {
  text-align: left;
  letter-spacing: 0px;
  color: #3970f1;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
}

.qw-body-section {
  flex: 0 0 80.42%;
  max-width: 80.42%;
}

.report-response-time {
  margin: 24px 0px;
}

.report-date-selector {
  width: fit-content;

  .date-filter-element {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    line-height: 24px;
    border-radius: inherit;

    img {
      margin-right: 4px;
    }
    .title {
      color: inherit;
      font-size: 14px;
      font-weight: normal;
      margin-right: 8px;
    }
  }

  .date-filter-element:hover {
    background: #3970f1;
    .title {
      color: white;
    }
    img {
      filter: brightness(0) invert(1);
    }
  }

  .qw-dropdown-menu-items {
    position: absolute;
    top: 2.5rem;
    background: white;
    border: 1px solid #d4d8dc;
    z-index: 999;
    width: max-content;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;

    .date-filter-element {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      cursor: pointer;
      line-height: 24px;
      border-radius: 0;

      img {
        margin-right: 4px;
      }
      .title {
        color: inherit;
        font-size: 14px;
        font-weight: normal;
        margin-right: 8px;
      }
    }

    .date-filter-element:hover {
      background: #3970f1;
      .title {
        color: white;
      }
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .report-date-section {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #dedddf;
  }

  // .report-date-section img{
  //     margin-right: 4px;
  // }

  .report-date-section .title {
    color: #1a1c1d;
    font-size: 14px;
    margin-right: 16px;
  }
}
</style>