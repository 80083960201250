<template>
  <div class="contact-conversation-label-panel">
    <div class="form-input" style="max-width: 100%; ">
      <div style="padding-bottom: 8px;" v-if="!selectedlabels.length">
        <i class="information-data-pill">{{ $t('CONTACT_PANEL.LABELS.NO_RECORDS_FOUND') }}</i>
      </div>
      <div v-if="selectedlabels" style="display: flex; width: inherit; flex-flow: wrap;">
        <div
          class="conversation-agent-selected-filter"
          v-for="(item, index) in selectedlabels"
          :key="item.label_id"
        >
          <div
            style="display: flex; justify-content: space-between; width: inherit; max-width: inherit; line-height: 24px;"
          >
            <div class="title" v-html="prepareContent(item.name)"></div>
            <div @click="removeAgentFromSelected(item, index)">
              <i class="fa fa-times-circle"></i>
            </div>
          </div>
        </div>
      </div>

      <div style="position: relative">
        <div
          v-if="labelDropdownShow"
          v-on-clickaway="onToggleDropdown"
          class="qw-dropdown-menu-items"
        >
          <div
            v-if="selectedlabels.length == getLabels.length"
            class="conversation-agent-filter-no-element"
            @click="onToggleDropdown"
          >No label found!</div>
          <div v-else v-for="(agent, index) in getLabels" :key="agent.label_id">
            <div
              v-if="showIfNotSelected(agent, index)"
              class="conversation-agent-filter-element"
              @click="labelSelected(agent, index)"
            >
              <div class="title">{{agent.name}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class style="display: flex;">
        <div class="conversation-agent-filter" @click="onToggleDropdown">
          <div
            style="display: flex; justify-content: space-between; width: inherit; align-items: center;line-height: 24px;"
          >
            <div class="title">+ Add Label</div>
            <i class="fa fa-chevron-down qw-chevron" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="more-edit m-t-8">
      <div
        v-if="!disableSaveButton"
        class="see-more"
        :class="{active: !disableSaveButton}"
        @click="updateLabels()"
      >Save</div>
      <div v-if="!disableSaveButton" class="edit" @click="cancelLabelUpdate()">Cancel</div>
    </div>

    <!-- <div style="display: flex; justify-content: space-between;">
      <div>
        <div class="form-button blue">+ Add Label</div>
        <button
          @click="updateLabels()"
          :disabled="disableSaveButton"
          class="button nice resolve--button success"
          style="padding: 0.5rem 1rem !important; font-size: 1.3rem"
        >
          <i class="ion-checkmark"></i> Save
        </button>
      </div>
    </div>
    <div v-if="!uiFlags.isFetching">
      <div>
        <div class="contact-conversation--list">
          <i v-if="!selectedlabels.length">{{ $t('CONTACT_PANEL.LABELS.NO_RECORDS_FOUND') }}</i>

          <multiselect
            v-model="selectedlabels"
            :options="getLabels"
            track-by="label_id"
            label="name"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            placeholder="Add label"
            @input="onValueChange"
            @select="$v.selectedlabels.$touch"
          />
        </div>
      </div>
    </div>
    <spinner v-else></spinner>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import Spinner from 'shared/components/Spinner.vue';
import ContactDetailsItem from './ContactDetailsItem.vue';

export default {
  mixins: [clickaway],
  components: {
    ContactDetailsItem,
    Spinner,
  },
  props: {
    conversationId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      getLabels: [],
      current_conversation: {},
      selectedlabels: [],
      disableSaveButton: true,
      labelDropdownShow: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contactConversations/getUIFlags',
      activeLabel: 'getSelectedLabel',
      activeInbox: 'getSelectedInbox',
      searchObject: 'globalVariables/getSearchObject',
    }),
  },
  methods: {
    prepareContent(content = '') {
      if (this.searchObject.searchKey == 'label') {
        return content.replace(
          new RegExp(`(${this.searchObject.searchValue})`, 'ig'),
          '<span class="searchkey--highlight">$1</span>'
        );
      } else {
        return content;
      }
    },
    labelSelected(item, index) {
      this.selectedlabels.push(item);
      this.onValueChange();
    },
    showIfNotSelected(item, index) {
      const [present] = this.selectedlabels.filter(
        agent => item.label_id === agent.label_id
      );
      if (present) {
        return false;
      } else {
        return true;
      }
    },
    removeAgentFromSelected(item, index) {
      this.selectedlabels.splice(index, 1);
      this.onValueChange();
    },
    onToggleDropdown() {
      this.labelDropdownShow = !this.labelDropdownShow;
    },

    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    onValueChange() {
      this.disableSaveButton = false;
    },
    async fetchLabels() {
      this.selectedlabels = [];
      this.getLabels = [];

      try {
        var conversation = await this.$store.dispatch(
          'conversationLabels/getConversation',
          this.conversationId
        );
        const response = await this.$store.dispatch('inboxLabels/get', {
          inboxId: conversation.data.inbox_id,
        });
        const {
          data: { payload },
        } = response;
        this.getLabels = payload;

        var conversation_labels = await this.$store.getters[
          'conversationLabels/getConversationLabels'
        ](this.conversationId);
        conversation_labels.forEach(el => {
          const [item] = this.getLabels.filter(
            label => label.label_id === el.id
          );
          if (item) {
            this.selectedlabels.push(item);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    async updateLabels() {
      const labelList = this.selectedlabels.map(el => el.label_id);

      try {
        await this.$store.dispatch('conversationLabels/create', {
          conversationId: this.conversationId,
          labels: labelList,
        });
        this.disableSaveButton = true;
        this.showAlert(this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('LABEL_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    cancelLabelUpdate() {
      this.fetchLabels();
      this.disableSaveButton = true;
    },
  },
  watch: {
    conversationId(newConversationId, prevConversationId) {
      if (newConversationId == undefined || newConversationId == null) {
        this.selectedlabels = [];
        this.getLabels = [];
        this.disableSaveButton = true;
        return;
      }
      if (newConversationId && newConversationId !== prevConversationId) {
        this.$store.dispatch('conversationLabels/get', newConversationId);
        this.disableSaveButton = true;
        this.fetchLabels();
      }
    },
  },
  mounted() {
    this.$store.dispatch('conversationLabels/get', this.conversationId);
    this.disableSaveButton = true;
    this.fetchLabels();
  },
  validations: {
    selectedlabels: {
      isEmpty() {
        return !!this.selectedlabels.length;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-conversation-label-panel {
  .information-data-pill {
    font-style: normal;
    font-size: 14px;
    color: #697181;
  }

  .qw-dropdown-menu-items {
    position: absolute;
    top: 2.5rem;
    background: white;
    border: 1px solid #d4d8dc;
    z-index: 999;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    max-height: 150px;
    box-shadow: 0px 0px 8px #1a1c1d29;
    width: 100%;
    overflow-wrap: anywhere;
    overflow-y: scroll;
  }

  .conversation-agent-filter {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
    border: 1px solid #dedddf;
    border-radius: 4px;
    height: 24px;
    // color: #1a1c1d;
    width: 100px;
    font-weight: 500;
    line-height: 24px;

    img {
      margin-right: 4px;
    }

    .title {
      // color: #1a1c1d;
      font-size: 12px;
      margin-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .conversation-agent-selected-filter {
    margin: 0px 4px 8px 0px;
    display: flex;
    align-items: center;
    border: 1px solid #dedddf;
    border-radius: 4px;
    padding: 4px 8px;
    height: 24px;
    // color: #1a1c1d;
    font-weight: 500;
    width: fit-content;
    max-width: 100%;

    img {
      margin-right: 4px;
    }

    .title {
      // color: #1a1c1d;
      font-size: 14px;
      margin-right: 9px;
      overflow: hidden;
      white-space: nowrap;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    i {
      font-size: 14px;
      padding-top: 5px;
      cursor: pointer;
    }
  }

  .conversation-agent-selected-filter:hover {
    background: #fff8eb;
    border-color: #ffa600;

    i {
      color: #1a1c1d;
    }
  }

  .conversation-agent-filter:hover {
    border-color: #3970f1;
    color: #3970f1;

    .title {
      color: #3970f1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      filter: brightness(3.5) sepia(1) hue-rotate(190deg) saturate(6);
    }
  }

  .conversation-agent-filter-no-element {
    display: flex;
    padding: 6px 10px;
    .title {
      color: inherit;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .conversation-agent-filter-element {
    display: flex;
    // padding: 4px 8px;
    padding: 6px 10px;
    cursor: pointer;

    img {
      margin-right: 4px;
    }

    .title {
      color: inherit;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .conversation-agent-filter-element:hover {
    background: #3970f1;

    .title {
      color: white;
    }
  }
}
</style>
