/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */

// const chatType = 'all';
// initial state
const state = {};

// actions
const actions = {};

export default {
  state,
  actions,
};
