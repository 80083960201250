<template>
  <div v-if="currentChat.id" class="qw-contact-details contact-panel">
    <!-- <div class="details-header">
        Details
    </div>-->
    <div v-if="currentChat.id">
      <div class="details-card">
        <div class="title">Personal Information</div>
        <div class="details">
          <div class="detail">
            <div class="icon">
              <img src="~/assets/new_images/User_Icon.svg">
            </div>
            <div class="value">
              <div v-if="!personlDetailsEditable">
                <div v-if="tempContact.name" v-html="prepareContent(tempContact.name)"></div>
                <div v-else class="contact_placeholder_details" >Enter name</div>
              </div>
              <input
                v-else
                type="text"
                class="contact_input"
                v-model.trim="tempContact.name"
                placeholder="Enter name"
              >
            </div>
          </div>
        </div>
        <div class="details">
          <div class="detail">
            <div class="icon">
              <img src="~/assets/new_images/Email_Icon.svg">
            </div>
            <div class="value">
              <div v-if="!personlDetailsEditable">
                <div v-if="tempContact.email" v-html="prepareContent(tempContact.email)"></div>
                <div v-else class="contact_placeholder_details" >Enter email</div>
              </div>
              <input
                v-else
                type="text"
                class="contact_input"
                v-model.trim="tempContact.email"
                placeholder="Enter email"
              >
            </div>
          </div>
        </div>
        <div class="details">
          <div class="detail">
            <div class="icon">
              <img src="~/assets/new_images/Phone_Icon.svg">
            </div>
            <div class="value">
              <div v-if="!personlDetailsEditable" placeholder="Enter phone number">
                <div v-if="tempContact.phone_number" v-html="prepareContent(tempContact.phone_number)"></div>
                <div v-else class="contact_placeholder_details" >Enter phone number</div>
              </div>
              <input
                v-else
                type="text"
                class="contact_input"
                v-model.trim="tempContact.phone_number"
                placeholder="Enter phone number"
              >
            </div>
          </div>
        </div>
        <div class="more-edit" v-show="!personlDetailsEditable">
          <div class="see-more"></div>
          <div class="edit" @click="editPersonalDetails()">Edit</div>
        </div>
        <div class="more-edit" v-show="personlDetailsEditable">
          <div class="see-more" @click="savePersonalDetails()">Save</div>
          <div class="edit" @click="cancelPersonalDetails()">Cancel</div>
        </div>
      </div>

      <div class="details-card">
        <!-- <div class="title display-flex-space-between">
          <div>Properties</div>
          <div>
            <div @click="addElement()" class="add-property-button"> <i class="fa fa-plus-circle"></i> Add</div>
          </div>
        </div>-->
        <div class="details">
          <conversation-property :contact-detail="contact" :conversation-id="conversationId"/>
        </div>
      </div>

      <div class="details-card">
        <div class="title">Labels</div>
        <div class="details">
          <conversation-labels :conversation-id="conversationId"/>
        </div>
      </div>

      <div class="details-card">
        <div class="title">Technical Details</div>
        <!-- <div class="details">
            <div class="detail">
                <div class="icon">
                    <img src="~/assets/new_images//Browser_Icon.svg">
                </div>
                <div class="value">
                    {{user_location}}
                </div>
            </div>
        </div>-->
        <div class="details">
          <div class="detail">
            <div class="icon">
              <img src="~/assets/new_images//Browser_Icon.svg">
            </div>
            <div class="value">{{browserName || '-'}}</div>
          </div>
        </div>
        <div class="details">
          <div class="detail">
            <div class="icon">
              <img src="~/assets/new_images//Mouse_Icon.svg">
            </div>
            <div class="value">{{platformName}}</div>
          </div>
        </div>
        <div class="details">
          <div class="detail">
            <div class="icon">
              <img src="~/assets/new_images//Link_Icon.svg">
            </div>
            <div class="value">
              <a @click="openNewWindow(referer)" style="text-decoration: underline;">{{referer}}</a>
            </div>
          </div>
        </div>
        <div class="details" v-if="user_location">
          <div class="detail">
            <div class="icon">
              <img src="~/assets/new_images//Location_Icon.svg">
            </div>
            <div class="value">{{user_location}}</div>
          </div>
        </div>
        <div class="details">
          <div class="detail m-0">
            <div class="icon">
              <img src="~/assets/new_images//Time_Icon.svg">
            </div>
            <div class="value">{{this.toDateTimeWithDay(this.currentChat.created_at)}}</div>
          </div>
        </div>
      </div>

      <div class="details-card m-b-8">
        <div class="title">Previous Conversations</div>
        <div class="details">
          <div class="detail m-0">
            <contact-conversations
              v-if="contact.id"
              :contact-id="contact.id"
              :conversation-id="conversationId"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      style="height: 100%;height: 100%;display: flex;justify-content: center;align-items: center; padding: 10px;"
    >
      <div>Please select a conversation from left pane</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import timeMixin from '../../../mixins/time';
import { email } from 'vuelidate/lib/validators';
import ContactConversations from './ContactConversations.vue';
import ContactDetailsItem from './ContactDetailsItem.vue';
import ConversationLabels from './ConversationLabels.vue';
import ConversationProperty from './ConversationProperty.vue';

export default {
  components: {
    ContactConversations,
    ContactDetailsItem,
    ConversationLabels,
    ConversationProperty,
    Thumbnail,
  },
  data() {
    return {
      personlDetailsEditable: false,
      tempContact: {},
    };
  },
  mixins: [timeMixin],
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
    onToggle: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      searchObject: 'globalVariables/getSearchObject',
    }),
    currentConversationMetaData() {
      return this.$store.getters[
        'conversationMetadata/getConversationMetadata'
      ](this.conversationId);
    },
    additionalAttributes() {
      return this.currentConversationMetaData.additional_attributes || {};
    },
    browser() {
      return this.additionalAttributes.browser || {};
    },
    referer() {
      return this.additionalAttributes.referer;
    },
    initiatedAt() {
      return this.additionalAttributes.initiated_at;
    },
    multichannel_details() {
      return this.additionalAttributes.multichannel_details || {};
    },
    user_location() {
      var user_location = null;
      if (this.browser.location) {
        if (this.browser.location.city) {
          if (this.browser.location.country) {
            user_location =
              this.browser.location.city + ', ' + this.browser.location.country;
          } else {
            user_location = this.browser.location.city;
          }
        } else if (this.browser.location.region) {
          if (this.browser.location.country) {
            user_location =
              this.browser.location.region +
              ', ' +
              this.browser.location.country;
          } else {
            user_location = this.browser.location.region;
          }
        } else {
          if (this.browser.location.country) {
            user_location = this.browser.location.country;
          }
        }
      }
      return user_location;
    },
    browserName() {
      var output =
        this.browser.browser_name || '' + this.browser.browser_version || '';
      if (output == 'undefined') {
        return '-';
      }
      return output;
    },
    platformName() {
      const {
        platform_name: platformName,
        platform_version: platformVersion,
      } = this.browser;
      var output = platformName || '' + platformVersion || '';

      if (output == 'undefined') {
        return '-';
      }
      return output;
    },
    contactId() {
      return this.currentConversationMetaData.contact_id;
    },
    contact() {
      var contactDetails = this.$store.getters['contacts/getContact'](
        this.contactId
      );
      this.tempContact = Object.assign({}, contactDetails);
      return contactDetails;
    },
  },
  watch: {
    contactId(newContactId, prevContactId) {
      if (newContactId && newContactId !== prevContactId) {
        this.$store.dispatch('contacts/show', {
          id: this.currentConversationMetaData.contact_id,
        });
      }
    },
    currentChat(newCurrentChat, prevCurrentChat) {
      if (!newCurrentChat.id) {
        this.onPanelToggle();
      }
    },
  },
  mounted() {
    if (this.currentConversationMetaData.id) {
      this.$store.dispatch('contacts/show', {
        id: this.currentConversationMetaData.contact_id,
      });
    }
  },
  methods: {
    prepareContent(content = '') {
      if (this.searchObject.searchKey == 'user') {
        return content.replace(
          new RegExp(`(${this.searchObject.searchValue})`, 'ig'),
          '<span class="searchkey--highlight">$1</span>'
        );
      } else {
        return content;
      }
    },
    openNewWindow(referer) {
      window.open(referer, '_blank');
    },
    onPanelToggle() {
      this.onToggle();
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPhoneNumber(phone_number) {
      var re = /^\+?\d+$/;
      return re.test(phone_number);
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    editPersonalDetails() {
      this.personlDetailsEditable = true;
    },
    cancelPersonalDetails() {
      this.personlDetailsEditable = false;
      this.tempContact = this.contact;
    },
    async savePersonalDetails() {
      if (this.tempContact.email != null && this.tempContact.email != '') {
        if (!this.validEmail(this.tempContact.email)) {
          this.showAlert('Email is not valid');
          return;
        }
      }

      if (
        this.tempContact.phone_number != null &&
        this.tempContact.phone_number != ''
      ) {
        if (!this.validPhoneNumber(this.tempContact.phone_number)) {
          this.showAlert('Phone number is not valid');
          return;
        }
      }

      var response = await this.$store.dispatch('contacts/update', {
        id: this.currentConversationMetaData.contact_id,
        contact: {
          name:
            this.tempContact.name == ''
              ? this.contact.name
              : this.tempContact.name,
          email:
            this.tempContact.email == ''
              ? this.contact.email
              : this.tempContact.email,
          phone_number:
            this.tempContact.phone_number == ''
              ? this.contact.phone_number
              : this.tempContact.phone_number,
        },
      }).then((response) => {
        this.showAlert(this.$t('CONTACT_PANEL.UPDATE_DETAILS.SUCCESS_MESSAGE'));
        this.personlDetailsEditable = false;
        this.contact.name = this.tempContact.name;
      }).catch((error) => {
        // this.showAlert(this.$t('CONTACT_PANEL.UPDATE_DETAILS.ERROR_MESSAGE'));
        this.showAlert(error.response.data.error);
        this.personlDetailsEditable = false;
        this.tempContact = Object.assign({}, this.contact);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.icon {
  height: 12px;
  width: 12px;
  align-self: start;
  padding-top: 3px;
  img {
    vertical-align: top;
    max-width: fit-content;
    height: auto;
  }
}

.contact--panel {
  @include border-normal-left;
  font-size: $font-size-small;
  overflow-y: auto;
  background: $color-white;
  overflow: auto;
}

.contact--profile {
  width: 100%;
  // padding: $space-medium;
  padding: $space-normal $space-medium $zero;

  .user-thumbnail-box {
    margin-right: $space-normal;
  }
}

.contact--details {
  margin-top: 0.8rem;
  p {
    margin-bottom: 0;
  }
}

.personlDetailsEditableDiv {
  display: flex;
  justify-content: space-between;
}

.personal-details-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e6ed;
}

.contact-personal-details {
  margin-top: 1.5rem;
  p {
    margin-bottom: 0;
  }
}

.personal-single-detail {
  display: flex;
  width: 100%;
}

.personal-single-detail-label {
  padding-top: 4px;
  width: 20%;
}

.contact--info {
  display: flex;
  align-items: center;
}

input:disabled {
  background: none;
  border: 0;
}

.contact--name {
  @include text-ellipsis;

  font-weight: $font-weight-bold;
  font-size: $font-size-default;
}

.contact--email {
  @include text-ellipsis;

  color: $color-body;
  display: block;
  line-height: $space-medium;
  // text-decoration: underline;
}

.contact--bio {
  margin-top: $space-normal;
}

.conversation--details {
  @include border-normal-top;
  padding: $space-medium;
  width: 100%;
}

.conversation--labels {
  padding: $space-medium;

  .icon {
    margin-right: $space-micro;
    font-size: $font-size-micro;
    color: #fff;
  }

  .label {
    color: #fff;
    padding: 0.2rem;
  }
}

.close-button {
  position: absolute;
  right: $space-normal;
  top: $space-slab;
  font-size: $font-size-default;
  color: $color-heading;
  padding: 2px 4px;
  border: 1px solid #b9b5b5;
  border-radius: 6px;
  background: #efefef;
}

// New CSS

.contact-panel {
  display: flex;
  border-left: 1px solid #e0e6ed;
  font-size: 1.4rem;
  overflow-y: auto;
  background: #fbfbfc;
  overflow: auto;
  flex-direction: column;
}

.details-header {
  font-size: 20px;
  font-weight: bold;
  color: #1a1c1d;
  padding: 19px 12px;
  border-bottom: 1px solid #efeef0;
  background-color: #efeef0;
  line-height: 21px;
}

.details-card {
  border: 1px solid #dedddf;
  border-radius: 4px;
  background: #ffffff;
  padding: 16px;
  margin: 8px 8px 0px 8px;

  .title {
    font-size: 14px;
    font-weight: bold;
    color: #1a1c1d;
    margin-bottom: 16px;
  }

  .details .detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .details .detail .value {
    font-size: 14px;
    color: #697181;
    margin-left: 8px;
    overflow-wrap: anywhere;
    line-height: 16px;
    width: 100%;
  }
}

.detail {
  .conversation-single {
    padding: 12px 0px 12px 0px !important;
    border-bottom: 0 !important;
  }
}

.contact_input {
  color: inherit;
  height: 10px;
  margin: 0;
  font-size: inherit;
  padding: 11px 2px;
  border-radius: 2px !important;
  margin-top: -3px;
}

.contact_placeholder_details {
  color: #b7b8b9;
}

.contact_input:disabled {
  padding: 8px 0px;
  margin-top: 0px;
}
</style>
