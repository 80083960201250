<template>
  <div style="height: 100%;">
    <div class="scrollable-div">
      <p v-if="!inboxesList.length" class="no-items-error-message">
        {{ $t('INBOX_MGMT.LIST.404') }}
        <router-link
          v-if="isAdmin()"
          :to="frontendURL('settings/inboxes/new')"
        >{{ $t('SETTINGS.INBOXES.NEW_INBOX') }}</router-link>
      </p>
      <p v-else class="no-items-error-message">Please select an inbox from left panel</p>
    </div>
  </div>
</template>
<script>
/* global bus */

import { mapGetters } from 'vuex';
import adminMixin from '../../../../mixins/isAdmin';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  mixins: [adminMixin],
  data() {
    return {
      loading: {},
      showSettings: false,
      showDeletePopup: false,
      selectedInbox: {},
    };
  },
  computed: {
    ...mapGetters({
      inboxesList: 'inboxes/getInboxes',
    }),
  },
  methods: {
    frontendURL,
  },
};
</script>
