<template>
  <div style="display: flex; height: 32px;">
    <div>
      <img
        :src="inbox.widget_icon || imagePlaceholder"
        style="border-radius: 4px%; width: 32px; height: 32px;"
      >
    </div>
    <div
      style="margin-left: 8px; border: 1px solid #DEDDDF;
    border-radius: 4px; display: flex; width: 92%;"
    >
      <div class="resumable-browse-widget-icon">
        <div
          style="width: 98px;
    font-size: 14px;
    color: #3970F1;
    padding: 8px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;"
        >
          <img src="~dashboard/assets/new_images/Attach_blue_Icon.svg">
          <span style="margin-top: -1px;">Browse File</span>
        </div>
      </div>
      <div style="border-left: 1px; width: 100%; border-left: 1px solid #EFEEF0;">
        <input
          v-model.trim="inbox.widget_icon"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.BOT_ICON.PLACEHOLDER')"
          style="width: 100%;
    height: 30px;
    border: none;"
        >
      </div>
    </div>
  </div>
</template>

<script>
import Resumable from 'widget/store/resumable.js';
import Constants from 'dashboard/constants.js';

export default {
  components: {},
  props: {
    inbox: Object,
  },
  data() {
    return {
      imagePlaceholder: window.chatwootConfig.custom_website.sidebar_logo_url
    }
  },
  mounted() {
    this.uploadFile();
  },
  methods: {
    uploadFile() {
      let r = new Resumable({
        target: Constants.INBOX_SETTINGS.FILE_UPLOAD.URL,
        testChunks: true,
        maxFiles: 1,
        maxFileSize: Constants.INBOX_SETTINGS.FILE_UPLOAD.MAX_FILE_SIZE,
        fileType: Constants.INBOX_SETTINGS.FILE_UPLOAD.IMAGE_TYPES,
      });
      this.r = r;
      if (!this.r.support) {
        this.resumable_error = true;
      } else {
        var alreadyPresent = document.querySelector(
          '.resumable-browse-widget-icon input'
        );

        if (alreadyPresent == null) {
          this.resumable_drop = true;
          this.r.assignDrop(document.getElementsByClassName('resumable-drop'));
          this.r.assignBrowse(
            document.getElementsByClassName('resumable-browse-widget-icon')
          );
          this.resumable_list = true;
          this.r.on('fileAdded', file => {
            this.r.upload();
          });
          this.r.on('fileSuccess', (file, message) => {
            let res = JSON.parse(message);
            this.inbox.widget_icon = res.s3.url;
          });
          this.r.on('fileError', () => {});
          this.r.on('fileTypeErrorCallback', (file, errorCount) => {});
          this.r.on('fileProgress', file => {});
        }
      }
    },
  },
};
</script>
