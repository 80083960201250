<template>
  <div style="height: 100%">
    <woot-wizard :items="wizardItems"></woot-wizard>
    <div style="height: 90%">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
/* eslint no-console: 0 */
/* eslint-env browser */

export default {
  data() {
    return {
      wizardItems: this.$t('INBOX_MGMT.CREATE_FLOW'),
    };
  },
};
</script>
