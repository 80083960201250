<template>
  <div class="location" :style="{ background: '#FFFFFF' }">
    <div id="map-icon">
      <img
        style="width: inherit;"
        src="~dashboard/assets/images/location_icon.svg"
      />
    </div>
    <div id="coordinates">
      Latitude: {{payload.coordinates.lat}}
      <br />
      Longitude: {{payload.coordinates.long}}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';
import { required, email } from 'vuelidate/lib/validators';
import { Stream } from 'stream';

export default {
  components: {
    Spinner,
  },
  props: {
    contentType: String,
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contact/getUIFlags',
      widgetColor: 'appConfig/getWidgetColor',
    }),
    hasSubmitted() {
      return (
        this.messageContentAttributes &&
        this.messageContentAttributes.submitted_email
      );
    },
    getMapLink() {
      return (
        'http://maps.google.com/maps?q=' +
        this.payload.coordinates.lat +
        ',' +
        this.payload.coordinates.long
      );
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    postback(item) {
      this.$store.dispatch('conversation/sendMessage', {
        content: item.title,
        content_type: this.contentType,
        content_payload: item.payload,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables.scss';

.button-template {
  border-top-left-radius: 1.3em;
  border-bottom-left-radius: 1.3em;
  float: left;
  overflow: visible;
  border-bottom-right-radius: 1.3em;
  border-top-right-radius: 1.3em;
  clear: both;
  margin-top: 1px;
  position: relative;
  text-decoration: none;
  width: 100%;
  z-index: 0;
  outline: none;
}

.button-template-layout {
  border-top-left-radius: 1.3em;
  border-top-right-radius: 1.3em;
  overflow: hidden;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 1);
  line-height: 1.28em;
}

.margin-top-4 {
  margin-top: -4px;
}

.padding_6_12 {
  padding: 12px 12px 0 12px;
}

.padding-bottom {
  padding-bottom: 12px;
}

.button-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  position: relative;
  // font-weight: 600;
}

.button-text-space {
  visibility: hidden;
  white-space: pre-wrap;
}

.template-button {
  line-height: 32px;
  text-align: left;
}

.template-button-value {
  color: #0084ff;
  font-weight: 500;
  padding: 0 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
}

.template-button div a {
  text-decoration: none;
  cursor: pointer;
}

.location {
  border-top-left-radius: 1.3em;
  border-bottom-left-radius: 1.3em;
  overflow: hidden;
}

#map-icon {
  float: left;
  margin-right: 5px;
  width: 60px;
}
#coordinates {
  overflow: hidden;
  margin-top: 10px;
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-right: 10px;
}

.location {
    width: 100%;
}

@media screen and (max-width: 200px) {
  #one {
    float: left;
    margin-right: 0;
    width: auto;
    border: 0;
    border-bottom: 2px solid #000;
  }
}
</style>
