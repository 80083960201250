<template>
  <div class="channels-div" @click="onItemClick">
    <div>
      <img v-if="channel === 'website'" src="~dashboard/assets/new_images/Website_Graphic.svg">
      <img
        v-if="channel === 'multichannel'"
        src="~dashboard/assets/new_images/Multichannel_Graphic.svg"
      >
      <div class="channel_title">{{ channel }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    channel: {
      type: String,
      required: true,
    },
  },
  methods: {
    isActive(channel) {
      return ['facebook', 'website', 'multichannel'].includes(channel);
    },
    onItemClick() {
      if (this.isActive(this.channel)) {
        this.$emit('channel-item-click', this.channel);
      }
    },
  },
};
</script>

<style lang="scss">
.channels-div {
  padding: 0px 24px;
  border: 1px solid #dedddf;
  width: 144px;
  border-radius: 4px;
  margin-right: 84px;
  height: 96px;
  cursor: pointer;

  img {
    padding-bottom: 16px;
    margin-top: -40px;
  }

  .channel_title {
    font-size: 14px;
    font-weight: 500;
    color: #1a1c1d;
    text-transform: capitalize;
    padding-bottom: 23px;
    text-align: center;
  }
}

.channels-div:hover {
  border-color: #3970f1;
  .channel_title {
    color: #3970f1;
  }
}
</style>
