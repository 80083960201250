<template>
  <div>
    <form class="template-button-group" @submit.prevent="onSubmit()">
      <div class="items">
        <div class="item" v-for="(element, index) in payload.elements" :key="element.id">
          <div v-if="element.image.url">
            <img class="item1" :src="element.image.url" />
          </div>

          <div>
            <div
              class="template-title"
              :title="element.title"
              v-if="element.title"
              v-html="formatMessage(element.title)"
            ></div>
            <div
              class="template-subtitle"
              :title="element.sub_title"
              v-if="element.sub_title"
              v-html="formatMessage(element.sub_title)"
            ></div>
          </div>
          <div style="margin-bottom: 8px;">
            <div
              v-for="(button, buttonIndex) in element.buttons"
              :key="button.id"
              @click="trigger(button)"
              class="template-button"
              role="button"
            >
              <a class="template-button-value">{{button.title}}</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: {
    Spinner,
  },
  props: {
    contentType: String,
    messageId: {
      type: Number,
      required: true,
    },
    messageContentAttributes: {
      type: Object,
      default: () => {},
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contact/getUIFlags',
      widgetColor: 'appConfig/getWidgetColor',
    }),
    hasSubmitted() {
      return (
        this.messageContentAttributes &&
        this.messageContentAttributes.submitted_email
      );
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    postback(item) {
      this.$store.dispatch('conversation/sendMessage', {
        content: item.title,
        content_type: this.contentType,
        content_payload: item.payload,
      });
    },
    trigger(item) {
      if (item.type == 'web_url') {
        window.open(item.url);
      } else if (item.type == 'postback') {
        this.postback(item);
      }
    },
  },
  mixins: [messageFormatterMixin],
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables.scss';
$gray: #555;
$yellow: #f2e968;
$white: #efefef;

.grid-container {
  background: transparent;
  font-family: 'Rubik', sans-serif;
}

@supports (display: grid) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr auto;

    //Let the craziness begin!!!
    grid-template-areas:
      'header header header'
      'title title footer'
      'main main main';
    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
      grid-template-rows: 0.3fr 1fr auto 1fr;
      grid-template-areas:
        'header'
        'title'
        'main'
        'footer';
    }
  }

  .grid-item {
    color: #fff;
    // background: skyblue;
    padding: 3.5em 1em;
    font-size: 1em;
    font-weight: 700;
  }

  .main {
    color: lighten($gray, 25%);
    background-color: transparent;
    grid-area: main;
    padding: 0;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .items {
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
  }

  .items.active {
    background: rgba(255, 255, 255, 0.3);
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }

  .item {
    display: inline-block;
    // background: skyblue;
    // min-height: 150px;
    // min-width: 210px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    // margin: 1em 0em;
    margin-right: 4px;
    border-radius: 8px;
    width: 270px;
    @media screen and (max-width: 500px) {
      // min-height: 150px;
      // min-width: 210px;
      width: 80%;
    }
  }

  .item div img {
    width: calc(100% + 2px);
    // max-height: 225px;
    height: 100%;
    max-height: 160px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: -1px;
    margin-top: -1px;
    margin-right: -1px;
  }

  .template-button {
    line-height: 32px;
    text-align: left;
    font-size: 14px;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 4px;
    border-radius: 8px;
    background-color: #ffffff;
  }

  .template-button-value {
    color: #0084ff;
    font-weight: 500;
    padding: 0 0px;
    // border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    text-decoration: none;
    cursor: pointer;
  }

  .template-button-value:hover {
    color: #005aff;
    cursor: pointer;
  }

  .template-title {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    white-space: break-spaces;
    font-size: inherit;
    color: #3c4858;
    padding: 10px 10px 10px 10px;
  }

  .template-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    font-weight: 500;
    white-space: break-spaces;
    font-size: .8em;
    color: grey;
    padding: 4px 10px 10px 10px;
  }

  .template-title-style {
    padding: 10px;
  }
}
</style>
