<template>
  <div class="conversations-sidebar qw-conversation-list">
    <div class="conversation-sidebar-header-container">
      <div class="conversation-sidebar-header">
        <div :title="inbox.name || 'Conversations'" class="conversation-title">{{inbox.name || 'Conversations'}}</div>
        <chat-filter @statusFilterChange="getDataForStatusTab" @onAssignmentTabChange="getDataForTab"
          @conversationOrderChange="getDataForConversationOrderChange" :conv-stats="convStats"/>
      </div>

      <div class="conversation-search" >
        <div class="search-outer-div conversation-search">
          <conversation-search
            :active-search-key="activeSearchKey"
            :active-search-value="activeSearchValue"
            @searchChange="getDataForSearch"
          />
        </div>
      </div>
    </div>

    <!-- <div class="conversation-tab-selector-container">
      <div
        :class="['conversation-tab' , {'conversation-tab-active': (activeAssigneeTab == index) }]"
        v-for="(item, index) in assigneeTabItems"
        :key="item.id"
        @click="getDataForTab(index)"
      >{{item.name}} ({{item.count}})</div>
    </div> -->

    <p
      v-if="!chatListLoading && !getChatsForTab().length"
      class="content-box"
    >{{ $t('CHAT_LIST.LIST.404') }}</p>

    <transition-group name="conversations-list" v-else class="conversation-list-container" id="conversationsList">
      <conversation-card v-for="chat in getChatsForTab()" :key="chat.id" :chat="chat" :active-search-value="activeSearchValue" :active-search-key="activeSearchKey"/>
      <p v-if="chatListLoading" :key="'chatListLoading_1'" class="text-center">
        <span class="spinner message"></span>
      </p>
      <p
        v-if="isAllConversationLoaded"
        key="AllConversationLoaded_1"
        class="qw-all-conversation-loaded-section"
      >
        <span class="qw-all-conversation-loaded">All conversations loaded</span>
      </p>
    </transition-group>
  </div>
</template>

<script>
/* eslint-env browser */
/* eslint no-console: 0 */
import { mapGetters } from 'vuex';

import ChatFilter from './widgets/conversation/ChatFilter';
import ChatTypeTabs from './widgets/ChatTypeTabs';
import ConversationSearch from './widgets/ConversationSearch';
import ConversationCard from './widgets/conversation/ConversationCard';
import timeMixin from '../mixins/time';
import utitlityMixin from '../mixins/utility';
import conversationMixin from '../mixins/conversations';
import wootConstants from '../constants';
import { setTimeout } from 'timers';
import router from '../routes/index';
import { frontendURL } from '../helper/URLHelper';
const audioFile = require('assets/audio/notification_sound.mp3');

export default {
  components: {
    ChatTypeTabs,
    ConversationSearch,
    ConversationCard,
    ChatFilter,
  },
  mixins: [timeMixin, conversationMixin, utitlityMixin],
  props: [
    'conversationInbox',
    'pageTitle',
    'conversationInboxLabel',
    'conversationId',
  ],
  data() {
    return {
      activeAssigneeTab: 0,
      conversationSortOrder: wootConstants.CONVERSATION_SORT.DESC,
      activeSearchKey: 'text',
      activeSearchValue: null,
      activeStatus: 0,
      notificationCount: 0,
      documentTitle: window.chatwootConfig.custom_website.title || 'Conversation Management',
      pageNumber: 1,
    };
  },
  created() {
    bus.$on('playSound', (type, message) => {
      var inbox_info = this.$store.getters['inboxes/getInbox'](
        message.inbox_id
      );
      if (inbox_info.desktop_notification_sound == true) {
        if (document.visibilityState == 'hidden') {
          var title = this.documentTitle;
          var newTitle =
            '(' +
            (this.notificationCount = this.notificationCount + 1) +
            ') ' +
            title;
          document.title = newTitle;
        }
        var audio = new Audio(audioFile);
        audio.play();
      }
    });
    document.addEventListener('visibilitychange', () => {
      document.title = this.documentTitle;
      this.notificationCount = 0;
    });
  },
  beforeDestroy() {
    bus.$off('playSound');
    try {
      document.removeEventListener('visibilitychange', () => {});
      document
        .querySelector('#conversationsList')
        .removeEventListener('scroll', () => {});
    } catch (error) {}
  },
  computed: {
    ...mapGetters({
      chatLists: 'getAllConversations',
      mineChatsList: 'getMineChats',
      mineChatsList: 'getMineChats',
      allChatList: 'getAllStatusChats',
      unAssignedChatsList: 'getUnAssignedChats',
      chatListLoading: 'getChatListLoadingStatus',
      isAllConversationLoaded: 'isAllConversationLoadedStatus',
      currentUserID: 'getCurrentUserID',
      activeInbox: 'getSelectedInbox',
      activeLabel: 'getSelectedLabel',
      convStats: 'getConvTabStats',
      currentChat: 'getSelectedChat',
    }),
    assigneeTabItems() {
      return this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS').map((item, index) => ({
        id: index,
        name: item.NAME,
        count: this.convStats[item.KEY] || 0,
      }));
    },
    label() {
      return this.$store.getters['inboxes/getLabel'](
        this.activeInbox,
        this.activeLabel
      );
    },
    inbox() {
      if (this.activeInbox) {
        return this.$store.getters['inboxes/getInbox'](this.activeInbox);
      } else {
        return 'Conversations';
      }
      
    },
    getToggleStatus() {
      if (this.toggleType) {
        return 'Open';
      }
      return 'Resolved';
    },
  },
  mounted() {
    this.$watch('$store.state.route', () => {
      if (this.$store.state.route.name !== 'inbox_conversation') {
        this.$store.dispatch('emptyAllConversations');
        this.fetchData();
      }
    });
    this.inbox
    this.$store.dispatch('emptyAllConversations');
    this.fetchData();
    this.$store.dispatch('agents/get');
    setTimeout(this.addScrollListener(), 200);
    this.$watch('currentChat.id', () => {
      if (this.currentChat.id == null) {
        this.$router.push({path: frontendURL(`conversations/${null}`)});
      }
    });
    // this.addScrollListener();
  },
  methods: {
    addScrollListener() {
      const listElm = document.querySelector('#conversationsList');
      if (listElm) {
        listElm.addEventListener('scroll', e => {
          if (
            listElm.scrollTop + listElm.clientHeight >=
            listElm.scrollHeight
          ) {
            if (!this.chatListLoading) {
              if (this.isAllConversationLoaded == false) {
                this.pageNumber = this.pageNumber + 1;
                if (this.activeSearchValue) {
                  this.fetchConversationsWithSearch();
                } else {
                  this.fetchConversations();
                }
              }
            }
          }
        });
      }
    },
    fetchData() {
      if (this.chatLists.length === 0) {
        this.pageNumber = 1;
        this.activeSearchValue = null;
        this.activeSearchKey = 'text';
        this.fetchConversations();
        setTimeout(() => {
          this.addScrollListener();
        }, 200);
      }
    },
    fetchConversations() {
      var selectedInboxLocal =
        this.conversationInbox === undefined
          ? this.activeInbox
          : this.conversationInbox;
      var selectedLabelLocal =
        this.conversationInboxLabel === undefined
          ? this.activeLabel
          : this.conversationInboxLabel;
      this.$store.dispatch('loadConversations', {
        inboxId: selectedInboxLocal,
        labelId: selectedLabelLocal,
        assigneeType: this.activeAssigneeTab,
        sortOrder: this.conversationSortOrder,
        status: this.activeStatus ? 'resolved' : 'open',
        pageNumber: this.pageNumber,
      });
    },
    fetchConversationsWithSearch() {
      var selectedInboxLocal =
        this.conversationInbox == undefined
          ? this.activeInbox
          : this.conversationInbox;
      var selectedLabelLocal =
        this.conversationInboxLabel == undefined
          ? this.activeLabel
          : this.conversationInboxLabel;
      this.$store.dispatch('loadConversationsWithSearch', {
        inboxId: selectedInboxLocal,
        labelId: selectedLabelLocal,
        assigneeType: this.activeAssigneeTab,
        sortOrder: this.conversationSortOrder,
        status: this.activeStatus ? 'resolved' : 'open',
        searchKey: this.activeSearchKey,
        searchValue: this.activeSearchValue,
        pageNumber: this.pageNumber,
      });
    },
    getDataForTab(index) {
      // this.activeAssigneeTab == index;
      if (this.activeAssigneeTab !== index) {
        this.activeAssigneeTab = index;
        this.pageNumber = 1;
        this.$store.dispatch('emptyAllConversations');
        if (this.activeSearchValue) {
          this.fetchConversationsWithSearch();
        } else {
          this.fetchConversations();
        }
        setTimeout(() => {
          this.addScrollListener();
        }, 200);
      }
    },
    getDataForSearch(searchKey, searchValue) {
      try {
        this.$store.getters['globalVariables/setSearchObject']( searchKey, searchValue);
      } catch (error) {
        console.log(error);
      }
      this.activeSearchKey = searchKey;
      this.activeSearchValue = searchValue;
      this.pageNumber = 1;
      this.$store.dispatch('emptyAllConversations');
      this.fetchConversationsWithSearch();
      setTimeout(() => {
        this.addScrollListener();
      }, 200);
    },
    getDataForStatusTab(index) {
      if (this.activeStatus !== index) {
        this.activeStatus = index;
        this.pageNumber = 1;
        this.$store.dispatch('emptyAllConversations');
        if (this.activeSearchValue) {
          this.fetchConversationsWithSearch();
        } else {
          this.fetchConversations();
        }
        setTimeout(() => {
          this.addScrollListener();
        }, 200);
      }
    },
    getDataForConversationOrderChange(index) {
      if (this.conversationSortOrder !== index) {
        this.conversationSortOrder = index;
        this.pageNumber = 1;
        this.$store.dispatch('emptyAllConversations');
        if (this.activeSearchValue) {
          this.fetchConversationsWithSearch();
        } else {
          this.fetchConversations();
        }
        setTimeout(() => {
          this.addScrollListener();
        }, 200);
      }
    },
    getChatsForTab() {
      let copyList = [];
      if (this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE_SLUG.MINE) {
        copyList = this.mineChatsList.slice();
      } else if (
        this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE_SLUG.UNASSIGNED
      ) {
        copyList = this.unAssignedChatsList.slice();
      } else {
        copyList = this.allChatList.slice();
      }
      
      const sorted =  this.conversationSortOrder === wootConstants.CONVERSATION_SORT.DESC ? copyList.sort(
        (a, b) =>
          this.lastMessage(b).created_at - this.lastMessage(a).created_at //desc latest first
      ) : copyList.sort(
        (a, b) =>
          this.lastMessage(a).created_at - this.lastMessage(b).created_at //asc oldest first
      );

      if (sorted[0]) {
        if (this.conversationId == undefined || this.conversationId == null) {
          setTimeout(() => {
            router.replace({
              name: 'inbox_conversation',
              params: {
                conversation_id: sorted[0].id,
              },
            });
          }, 500);
        }
      }

      return sorted;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
.spinner {
  margin-top: $space-normal;
  margin-bottom: $space-normal;
}
</style>
