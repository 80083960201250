<template>
  <div class="avatar-upload-my-profile">
    <div style="display: flex; align-items: center;">
      <QWThumbnail :src="src" size="40px" :username="username"/>
      <div style="width: fit-content; padding: 10px 0px 10px 10px; font-size: 1.4rem; display:flex">
        <div class="resumable-browse-avatar-icon">
          <div class="avatar-upload-button">
            <img src="~dashboard/assets/new_images/Attach_blue_Icon.svg">
            <span v-if="src" style="margin-top: -2px;margin-left: 4px;">Change Image</span>
            <span v-else style="margin-top: -2px;margin-left: 4px;">Choose Image</span>
          </div>
        </div>
        <div v-if="src">
          <div class="avatar-upload-cancel" @click="removeProfilePic($event)">
            <span>Remove</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QWThumbnail from '../../../../components/widgets/QWThumbnail';
import Resumable from 'widget/store/resumable.js';
import Constants from 'dashboard/constants.js';

export default {
  components: {
    QWThumbnail,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: null,
    },
    username: {
      type: String,
    },
  },
  mounted() {
    this.uploadFile();
  },
  methods: {
    handleImageUpload(url) {
      this.$emit('change', {
        url: url,
      });
    },
    removeProfilePic(event) {
      event.preventDefault();
      this.handleImageUpload(null);
    },
    uploadFile() {
      let r = new Resumable({
        target: Constants.INBOX_SETTINGS.FILE_UPLOAD.URL,
        testChunks: true,
        maxFiles: 1,
        maxFileSize: Constants.INBOX_SETTINGS.FILE_UPLOAD.MAX_FILE_SIZE,
        fileType: Constants.INBOX_SETTINGS.FILE_UPLOAD.IMAGE_TYPES,
      });
      this.r = r;
      if (!this.r.support) {
        this.resumable_error = true;
      } else {
        var alreadyPresent = document.querySelector(
          '.resumable-browse-avatar-icon input'
        );

        if (!alreadyPresent) {
          this.resumable_drop = true;
          this.r.assignDrop(document.getElementsByClassName('resumable-drop'));
          this.r.assignBrowse(
            document.getElementsByClassName('resumable-browse-avatar-icon')
          );
          this.resumable_list = true;
          this.r.on('fileAdded', file => {
            this.r.upload();
          });
          this.r.on('fileSuccess', (file, message) => {
            let res = JSON.parse(message);
            this.handleImageUpload(res.s3.url);
          });
          this.r.on('fileError', () => {});
          this.r.on('fileTypeErrorCallback', (file, errorCount) => {});
          this.r.on('fileProgress', file => {});
        }
      }
    },
  },
};
</script>

<style lang="scss">
.avatar-upload-my-profile {
  .file-upload-button {
    border: 1px solid #757575;
    border-radius: 5px;
    padding: 5px 10px;
    width: fit-content;
    background: #efefef;
  }

  .remove-profile-button {
    border: 1px solid #757575;
    border-radius: 5px;
    padding: 5px 10px;
    width: fit-content;
    background: #efefef;
    margin-left: 10px;
  }

  button:hover {
    background: #d7d4d4;
  }

  .avatar-upload-button {
    width: fit-content;
    font-size: 14px;
    color: #3970f1;
    padding: 0px 8px;
    height: 30px;
    display: flex;
    line-height: 24px;
    justify-content: space-between;
    border: 1px solid #3970f1;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }

  .avatar-upload-cancel {
    width: fit-content;
    font-size: 14px;
    color: #969ba7;
    padding: 0px 8px;
    height: 30px;
    display: flex;
    line-height: 24px;
    justify-content: space-between;
    border: 1px solid #969ba7;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
