<template>
  <div class="scrollable-div">
    <div class="setting-form" style="padding-bottom: 20rem;">
      <div class="form-field">
        <div class="form-label">{{ $t('INBOX_MGMT.ADD.MULTI_CHANNEL.TITLE') }}</div>
        <div class="form-help-text m-t-8">{{ $t('INBOX_MGMT.ADD.MULTI_CHANNEL.DESC') }}</div>
      </div>
      <div class="form-field">
        <div class="form-label">
          {{ $t('INBOX_MGMT.ADD.MULTI_CHANNEL.CHANNEL_NAME.LABEL') }}
          <div class="required">*</div>
        </div>
        <div class="form-input">
          <input
            class="field"
            v-model.trim="websiteName"
            :placeholder="$t('INBOX_MGMT.ADD.MULTI_CHANNEL.CHANNEL_NAME.PLACEHOLDER', { title: title })"
          >
          <div v-if="websiteNameError" class="error-message">{{ websiteNameError }}</div>
        </div>
      </div>
      <div class="form-botton-element">
        <div class="form-button blue" @click="createChannel()">Continue</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Compact } from 'vue-color';
import { mapGetters } from 'vuex';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

export default {
  components: {
    PageHeader,
    Compact,
  },
  data() {
    return {
      error: null,
      errorDomain: null,
      websiteNameError: null,
      websiteName: '',
      websiteUrl: '',
      webhook: null,
      mc_agent_webhook: null,
      widgetColor: { hex: '#009CE0' },
      isCreating: false,
      title: window.chatwootConfig.custom_website.title,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },

  methods: {
    async createChannel() {
      this.errorDomain = null;
      this.error = null;
      this.websiteNameError = null;

      if (this.webhook != null) {
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i'
        ); // fragment locator

        if (!pattern.test(this.webhook)) {
          this.error = 'Valid URL required.';
        }
      }

      if (this.websiteName == null || this.websiteName == '') {
        this.websiteNameError = 'Valid name required.';
      }

      if (this.mc_agent_webhook != null) {
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i'
        ); // fragment locator

        if (!pattern.test(this.mc_agent_webhook)) {
          this.error = 'Valid URL required.';
        }
      }

      if (
        this.error == null &&
        this.errorDomain == null &&
        this.websiteNameError == null
      ) {
        const website = await this.$store.dispatch(
          'inboxes/createWebsiteChannel',
          {
            website: {
              website_name: this.websiteName,
              website_url: this.website_url,
              widget_color: '#0f0e6a',
              webhook: this.webhook,
              mc_agent_webhook: this.mc_agent_webhook,
              multi_channel: true,
            },
          }
        );
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: website.id,
          },
        });
      }
    },
  },
};
</script>
