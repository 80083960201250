<template>
  <div>
    <div class="messages" style="margin-top: 30px; display: none;">
      <div class="report-bar-new">
        <div style="display: flex;justify-content: space-between;">
          <div class="report-title">
            Messages
            <span class="report_tooltip" style="color: #1a1d1f;">
              <!-- <i class="fa fa-info-circle" aria-hidden="true" v-tooltip="{ content: $t('REPORT.HELP_TEXT.MESSAGE_TAB'), classes: ['top', 'report-top-hover'] }"></i> -->
            </span>
          </div>
          <div class="qw-export-button" @click="exportMessagesimage()">
            <div class="export-botton-title">Export</div>
          </div>
        </div>
        <woot-loading-state
          v-if="incomingMessagesReport.isFetching || outgoingMessagesReport.isFetching || botMessagesReport.isFetching"
          :message="$t('REPORT.LOADING_CHART')"
        />
        <div v-else class="chart-container">
          <woot-line
            v-if="incomingMessagesReport.data.length && outgoingMessagesReport.data.length && botMessagesReport.data.length"
            :collection="lineCollectionMessagesReport"
          />
          <span v-else class="empty-state">{{ $t('REPORT.NO_ENOUGH_DATA') }}</span>
        </div>
        <div class="report_stats_main_card" style="display: flex;margin-top: 20px;">
          <woot-report-stats-card
            v-for="(metric, index) in messagess_metrics"
            :key="metric.NAME"
            :desc="metric.DESC"
            :heading="metric.NAME"
            :color="metric.COLOR"
            :index="index"
            :on-click="changeSelection"
            :point="accountSummary[metric.KEY]"
            :selected="index === currentSelection"
          />
        </div>
      </div>
    </div>

    <div class="conversations" style="margin-top: 30px;">
      <div class="report-bar-new">
        <div style="display: flex;justify-content: space-between;">
          <div class="report-title">
            Conversations
            <span class="report_tooltip" style="color: #1a1d1f;">
              <!-- <i class="fa fa-info-circle" aria-hidden="true" v-tooltip="{ content: $t('REPORT.HELP_TEXT.CONVERSATION_TAB'), classes: ['top', 'report-top-hover'] }"></i> -->
            </span>
          </div>
          <div class="qw-export-button" @click="exportConversationsimage()">
            <div class="export-botton-title">Export</div>
          </div>
        </div>
        <woot-loading-state
          v-if="conversationReport.isFetching || openReport.isFetching || resolutionReport.isFetching"
          :message="$t('REPORT.LOADING_CHART')"
        />
        <div v-else class="chart-container">
          <woot-line
            v-if="conversationReport.data.length && openReport.data.length && resolutionReport.data.length"
            :chart-data="lineCollectionConversationsReport"
          />
          <span v-else class="empty-state">{{ $t('REPORT.NO_ENOUGH_DATA') }}</span>
        </div>
        <div class="report_stats_main_card" style="display: flex;margin-top: 20px;">
          <woot-report-stats-card
            v-for="(metric, index) in conversations_metrics"
            :key="metric.NAME"
            :desc="metric.DESC"
            :heading="metric.NAME"
            :color="metric.COLOR"
            :index="index"
            :on-click="changeSelection"
            :point="accountSummary[metric.KEY]"
            :selected="index === currentSelection"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import moment from 'moment';

export default {
  mixins: [clickaway],
  props: ['to', 'from', 'currentInboxSelection' ],
  data() {
    return {
      currentSelection: 0,
      isDestroyed: false,
    };
  },
  computed: {
    ...mapGetters({
      accountSummary: 'getAccountSummary',
      conversationReport: 'getConversationReports',
      openReport: 'getOpenReports',
      resolutionReport: 'getResolutionReports',
      incomingMessagesReport: 'getIncomingMessagesReports',
      outgoingMessagesReport: 'getOutgoingMessagesReports',
      botMessagesReport: 'getBotMessagesReports',
    }),
    lineCollectionMessagesReport() {
      if (
        this.incomingMessagesReport.isFetching ||
        this.outgoingMessagesReport.isFetching ||
        this.botMessagesReport.isFetching
      ) {
        return {};
      }

      if (
        this.incomingMessagesReport.data.length == 0 ||
        this.outgoingMessagesReport.data.length == 0 ||
        this.botMessagesReport.data.length == 0
      ) {
        return {};
      }

      const labels = this.incomingMessagesReport.data.map(element =>
        moment.unix(element.timestamp).format('DD/MMM')
      );

      const incoming_msg_data = this.incomingMessagesReport.data.map(
        element => element.value
      );
      const outgoing_msg_data = this.outgoingMessagesReport.data.map(
        element => element.value
      );
      const bot_msg_data = this.botMessagesReport.data.map(
        element => element.value
      );

      return {
        labels,
        datasets: [
          {
            label: this.messagess_metrics[0].NAME,
            backgroundColor: this.messagess_metrics[0].COLOR,
            borderColor: this.messagess_metrics[0].COLOR,
            showLine: true,
            spanGaps: true,
            lineTension: 0.3,
            fill: false,
            data: incoming_msg_data,
          },
          {
            label: this.messagess_metrics[1].NAME,
            backgroundColor: this.messagess_metrics[1].COLOR,
            borderColor: this.messagess_metrics[1].COLOR,
            showLine: true,
            spanGaps: true,
            lineTension: 0.3,
            fill: false,
            data: outgoing_msg_data,
          },
          {
            label: this.messagess_metrics[2].NAME,
            backgroundColor: this.messagess_metrics[2].COLOR,
            borderColor: this.messagess_metrics[2].COLOR,
            showLine: true,
            spanGaps: true,
            lineTension: 0.3,
            fill: false,
            data: bot_msg_data,
          },
        ],
      };
    },
    lineCollectionConversationsReport() {
      if (
        this.conversationReport.isFetching ||
        this.openReport.isFetching ||
        this.resolutionReport.isFetching
      ) {
        return {};
      }

      if (
        this.conversationReport.data.length == 0 ||
        this.openReport.data.length == 0 ||
        this.resolutionReport.data.length == 0
      ) {
        return {};
      }

      const labels = this.conversationReport.data.map(element =>
        moment.unix(element.timestamp).format('DD/MMM')
      );

      const conversation_data = this.conversationReport.data.map(
        element => element.value
      );
      const open_data = this.openReport.data.map(element => element.value);
      const resolution_data = this.resolutionReport.data.map(
        element => element.value
      );

      return {
        labels,
        datasets: [
          {
            label: this.conversations_metrics[0].NAME,
            backgroundColor: this.conversations_metrics[0].COLOR,
            borderColor: this.conversations_metrics[0].COLOR,
            showLine: true,
            spanGaps: true,
            lineTension: 0.3,
            fill: false,
            data: conversation_data,
          },
          {
            label: this.conversations_metrics[1].NAME,
            backgroundColor: this.conversations_metrics[1].COLOR,
            borderColor: this.conversations_metrics[1].COLOR,
            showLine: true,
            spanGaps: true,
            lineTension: 0.3,
            fill: false,
            data: open_data,
          },
          {
            label: this.conversations_metrics[2].NAME,
            backgroundColor: this.conversations_metrics[2].COLOR,
            borderColor: this.conversations_metrics[2].COLOR,
            showLine: true,
            spanGaps: true,
            lineTension: 0.3,
            fill: false,
            data: resolution_data,
          },
        ],
      };
    },
    conversations_metrics() {
      return this.$t('REPORT.CONVERSATION_METRICS');
    },
    messagess_metrics() {
      return this.$t('REPORT.MESSAGES_METRICS');
    },
  },
  mounted() {
    this.fetchAllData();
  },
  beforeDestroy() {
    this.isDestroyed = true;
  },
  methods: {
    exportMessagesimage() {
      let canvas = document
        .querySelectorAll('#line-chart')[0]
        .toDataURL('image/png');
      let link = document.createElement('a');
      link.download = 'Messages';
      link.href = canvas;
      link.click();
    },
    exportConversationsimage() {
      let canvas = document
        .querySelectorAll('#line-chart')[1]
        .toDataURL('image/png');
      let link = document.createElement('a');
      link.download = 'Conversations';
      link.href = canvas;
      link.click();
    },
    async fetchAllData() {
      const { from, to } = this;
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchAccountSummary', {
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
        await this.fetchMessagesReport();
        await this.fetchConversationsReport();
      }
    },
    async fetchConversationsReport() {
      const { from, to } = this;
      // conversation report
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchReportForMetric', {
          metric: this.conversations_metrics[0].KEY,
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }

      // open report
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchReportForMetric', {
          metric: this.conversations_metrics[1].KEY,
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }

      // resolution report
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchReportForMetric', {
          metric: this.conversations_metrics[2].KEY,
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }
    },
    async fetchMessagesReport() {
      const { from, to } = this;
      // incoming messages report
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchReportForMetric', {
          metric: this.messagess_metrics[0].KEY,
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }

      // outgoing messages report
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchReportForMetric', {
          metric: this.messagess_metrics[1].KEY,
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }

      // bot messages report
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchReportForMetric', {
          metric: this.messagess_metrics[2].KEY,
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }
    },
    converTimeToRedable(inputSeconds) {
      inputSeconds = Math.round(inputSeconds);
      const Days = Math.floor(inputSeconds / (60 * 60 * 24));
      const Hour = Math.floor((inputSeconds % (60 * 60 * 24)) / (60 * 60));
      const Minutes = Math.floor(
        ((inputSeconds % (60 * 60 * 24)) % (60 * 60)) / 60
      );
      const Seconds = Math.floor(
        ((inputSeconds % (60 * 60 * 24)) % (60 * 60)) % 60
      );
      let ddhhmmss = '';
      if (Days > 0) {
        ddhhmmss += Days + ' d ';
      }
      if (Hour > 0) {
        ddhhmmss += Hour + ' h ';
      }

      if (Minutes > 0) {
        ddhhmmss += Minutes + ' m ';
      }

      if (Seconds > 0) {
        ddhhmmss += Seconds + ' s ';
      }
      return ddhhmmss;
    },
    changeSelection(index) {
      this.currentSelection = index;
      this.fetchChartData();
    },
    async fetchChartData() {
      await this.fetchMessagesReport();
      await this.fetchConversationsReport();
    },
  },
  watch: {
    from(newfrom, oldfrom) {
      this.from = newfrom;
      this.fetchAllData();
    },
    to(newto, oldto) {
      this.to = newto;
      this.fetchAllData();
    },
    currentInboxSelection(newcurrentInboxSelection, oldcurrentInboxSelection) {
      this.currentInboxSelection = newcurrentInboxSelection;
      this.fetchAllData();
    },
  },
};
</script>
