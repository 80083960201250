<template>
  <section class="app-content-body">
    <chat-list
      :conversation-inbox="inboxId"
      :conversation-id="conversationId"
      :conversation-inbox-label="labelId"
      :page-title="$t('CHAT_LIST.TAB_HEADING')"
    ></chat-list>
    <conversation-box
      v-if="conversationId"
      :inbox-id="inboxId"
      :conversation-id="conversationId"
      :is-contact-panel-open="true"
      @contactPanelToggle="onToggleContactPanel"
    ></conversation-box>
    <contact-panel
      v-if="conversationId"
      :conversation-id="conversationId"
      :on-toggle="onToggleContactPanel"
    />
    <div v-else class="qw-no-conversation">
      <div v-if="!chatList.length">
        <img src="~dashboard/assets/new_images/no_conversations.svg" :alt="$t('CONVERSATION.NO_MESSAGE_1')" />
        <div>
          {{ $t('CONVERSATION.NO_MESSAGE_1') }}
          <br />
        </div>
      </div>
      <div v-else>
        <img src="~dashboard/assets/new_images/no_conversations.svg"  :alt="$t('CONVERSATION.404')" />
        <div>{{ $t('CONVERSATION.404') }}</div>
      </div>
    </div>
    <remind-availability-status
      :on-close="closeAvailabilityCheck"
      :show="currentUser.availability == 'away' && remindAvailabilityCheck" />
  </section>
</template>

<script>
/* eslint no-console: 0 */
/* global bus */
import { mapGetters } from 'vuex';

import ChatList from '../../../components/ChatList';
import ContactPanel from './ContactPanel';
import ConversationBox from '../../../components/widgets/conversation/ConversationBox';
import RemindAvailabilityStatus from './RemindAvailabilityStatus';

export default {
  components: {
    ChatList,
    ContactPanel,
    ConversationBox,
    RemindAvailabilityStatus,
  },

  data() {
    return {
      pageTitle: this.$state,
      isContactPanelOpen: false,
      remindAvailabilityCheck: false,
    };
  },
  computed: {
    ...mapGetters({
      chatList: 'getAllConversations',
      currentUser: 'getCurrentUser',
    }),
  },
  props: ['inboxId', 'conversationId', 'labelId'],

  mounted() {
    this.$watch('$store.state.route', () => {
      switch (this.$store.state.route.name) {
        case 'inbox_conversation':
          this.setActiveChat();
          break;
        case 'inbox_dashboard':
          try {
            this.isContactPanelOpen = false;
          } catch (error) {
            console.log(error);
          }
          break;
      }
    });
    this.$watch('chatList.length', () => {
      this.setActiveChat();
    });
    this.remindAvailabilityCheck = window.chatwootConfig.remindActivityStatus;
  },

  methods: {
    closeAvailabilityCheck() {
      window.chatwootConfig.remindActivityStatus = false;
      this.remindAvailabilityCheck = window.chatwootConfig.remindActivityStatus;
    },
    setActiveChat() {
      const conversationId = this.conversationId;
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      if (!chat) return;
      this.$store.dispatch('setActiveChat', chat).then(() => {
        bus.$emit('scrollToMessage');
      });
    },
    onToggleContactPanel() {
      this.isContactPanelOpen = !this.isContactPanelOpen;
    },
  },
};
</script>


<style lang="scss">
.qw-no-conversation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
}
</style>
