<template>
  <div>
    <div class="setting-form">
      <div>
        <div class="form-field">
          <div class="form-label">Add an agent to your team</div>
          <div
            class="form-help-text m-t-8"
          >You can add people who will be able to handle support for your inboxes</div>
        </div>
        <div class="form-field">
          <div class="form-label">
            Agent Name
            <div class="required">*</div>
          </div>
          <div class="form-input">
            <input
              class="field"
              v-model.trim="agentName"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
            >
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">
            Agent Type
            <div class="required">*</div>
          </div>
          <div class="form-input">
            <select v-model="agentType" class="field" @change="onTabChange()">
              <option v-for="item in agentTypeList" :key="item.label" :value="item">{{ item.label}}</option>
            </select>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">
            Email ID
            <div class="required">*</div>
          </div>
          <div class="form-input">
            <input
              class="field"
              v-model.trim="agentEmail"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="setting-form-actions">
      <div class="form-button blue m-r-24 button-fixed-width" @click="addAgent()">Add Agent</div>
      <div class="form-button grey button-fixed-width" @click="onClose">Cancel</div>
    </div>
  </div>
</template>

<script>
/* global bus */
/* eslint no-console: 0 */
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  props: ['onClose'],
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentType: this.$t('AGENT_MGMT.AGENT_TYPES')[1],
      vertical: 'bottom',
      horizontal: 'center',
      agentTypeList: this.$t('AGENT_MGMT.AGENT_TYPES'),
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
    }),
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(4),
    },
    agentEmail: {
      required,
      email,
    },
    agentType: {
      required,
    },
  },

  methods: {
    setPageName({ name }) {
      this.$v.agentType.$touch();
      this.agentType = name;
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async addAgent() {
      try {
        await this.$store
          .dispatch('agents/create', {
            name: this.agentName,
            email: this.agentEmail,
            role: this.agentType.name.toLowerCase(),
          })
          .then(response => {
            this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
            this.onClose();
            this.$store.dispatch('agents/get');
          })
          .catch(error => {
            if (error.response.data.message) {
              this.showAlert("Error: "+ error.response.data.message);
            } else if (error.response.data.error) {
              this.showAlert("Error: "+ error.response.data.error);
            } else {
              this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
            }
          });
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
