<template>
  <div class="report-single-card-parent">
    <div class="report-card_1" :class="{ 'active': selected }" >
      <div style="display: flex;justify-content: space-between;">
        <div class="stat_heading">{{heading}}</div>
        <div class="stat_count">{{point}}</div>
      </div>
      <div style="display: flex;justify-content: space-between;">
        <div class="desc_1" >{{desc}}</div>
        <div class="color_bar" :style="{background: color}" ></div>
      </div>
      
    </div>
  </div>
</template>
<script>

export default {
  props: {
    heading: String,
    color: String,
    point: [Number, String],
    index: Number,
    desc: String,
    selected: Boolean,
    onClick: Function,
  },
};
</script>

<style lang="scss">

.report-single-card-parent {
  width: 100%;
  padding: 24px;
  border-right: 1px solid #DEDDDF;
}

.report-single-card-parent:last-child { border-right: none; }

.report-card.active {
  font-weight: 600 !important;
}

.stat_heading {
  letter-spacing: 0px;
  color: #1A1C1D;
  font-size: 14px;
  font-weight: 600;
}

.stat_count {
  letter-spacing: 0px;
  color: #1A1C1D;
  font-size: 18px;
  font-weight: 600;
}

.desc_1 {
  text-align: left;
  letter-spacing: 0px;
  color: #697181;
  opacity: 1;
  font-size: 14px;
  line-height: 18px;
}

.color_bar {
  border-radius: 8px;
  opacity: 1;
  width: 50px;
  height: 10px;
  align-self: center;
  margin-top: 1px;
}

</style>