<template>
  <div id="app" class="app-wrapper app-root">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <woot-snackbar-box />
  </div>
</template>

<script>
import WootSnackbarBox from './components/SnackbarContainer';

export default {
  name: 'App',

  components: {
    WootSnackbarBox,
  },

  mounted() {
    Object.freeze(Object.prototype);
    this.$store.dispatch('set_user');
    this.$store.dispatch('validityCheck');
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
@import './assets/library/font-awesome-4.7.0/css/font-awesome.min.css';
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
