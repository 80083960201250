<template>
  <div class="canned-responses">
    <div class="settings-tabs">
      <div class="setting-tab" :class="{active: !showAddPopup}" @click="hideAddPopup">Responses List</div>
      <div
        class="setting-tab"
        :class="{active: showAddPopup}"
        @click="openAddPopup"
      >Add New Response</div>
    </div>
    <div v-if="showAddPopup" class="scrollable-div" style="height: calc(100% - 110px);">
      <add-canned :on-close="hideAddPopup"/>
    </div>
    <div v-if="!showAddPopup" class="scrollable-div">
      <woot-loading-state v-if="uiFlags.isFetching" :message="$t('CANNED_MGMT.LOADING')"/>
      <div v-else>
        <div v-if="!records.length" class="no-canned-responses">
          <p>{{ $t('CANNED_MGMT.LIST.404') }}</p>
        </div>
        <div v-else>
          <table class="simple-table">
            <tr class="simple-table-header border_bottom">
              <th>Short Code</th>
              <th>Content</th>
              <th>Actions</th>
            </tr>
            <div class="think-border"></div>
            <tr class="border_bottom" v-for="(response, index) in records" :key="response.id">
              <td style="width: 20%; word-break: break-all;">
                <div v-if="response.id == selectedResponse.id">
                  <input v-model.trim="response.short_code" type="text">
                </div>
                <span v-else>{{response.short_code}}</span>
              </td>
              <td style="width: 50% ; word-break: break-all;" class="p-r-24">
                <div v-if="response.id == selectedResponse.id">
                  <textarea v-model.trim="response.content" type="text" rows="2"/>
                </div>
                <span v-else>{{response.content}}</span>
              </td>
              <td style="width: 30%">
                <div>
                  <div
                    v-if="response.id == selectedResponse.id"
                    style="display: flex; align-self: flex-start;"
                  >
                    <div class="form-button blue" @click="editCannedResponse(response)">Save Changes</div>
                    <div
                      class="form-button grey"
                      @click="hideEditPopup(response)"
                      style="margin-left: 16px;"
                    >Cancel</div>
                  </div>
                  <div v-if="response.id == deleteResponse.id">
                    <div class="delete-confirmation-title">Are you sure you want to delete this?</div>
                    <div class="display-flex-space-between">
                      <div
                        class="form-button red m-t-16"
                        @click="confirmDeletion(response, index)"
                        style="width: 118px"
                      >Delete</div>
                      <div
                        class="form-button grey m-t-16"
                        @click="closeDeletePopup(response, index)"
                        style="width: 118px"
                      >Cancel</div>
                    </div>
                  </div>
                  <div
                    v-if="response.id != selectedResponse.id  && response.id != deleteResponse.id"
                    style="display: flex; align-self: flex-start;"
                  >
                    <div class="agent-action-options" @click="openEditPopup(response, index)">
                      <img src="~/assets/new_images/Edit_Icon.svg">
                    </div>
                    <div
                      class="agent-action-options"
                      @click="openDeletePopup(response, index)"
                      style="margin-left: 16px;"
                    >
                      <img src="~/assets/new_images/Delete_Icon.svg">
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <p
            v-if="records.length > 0"
            key="AllConversationLoaded_1"
            class="qw-all-conversation-loaded-section"
          >
            <span class="qw-all-conversation-loaded">All canned responses loaded</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global bus */
import { mapGetters } from 'vuex';

import AddCanned from './AddCanned';
import EditCanned from './EditCanned';
import DeleteCanned from './DeleteCanned';

export default {
  components: {
    AddCanned,
    EditCanned,
    DeleteCanned,
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      deleteResponse: {},
      cannedResponseAPI: {
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      records: 'getCannedResponses',
      uiFlags: 'getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteRejectText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteMessage() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedResponse.short_code
      } ?`;
    },
  },
  mounted() {
    // Fetch API Call
    this.$store.dispatch('getCannedResponse');
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.cannedResponseAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    openEditPopup(response) {
      this.selectedResponse = Object.assign({}, response);
    },
    hideEditPopup(response) {
      response.short_code = this.selectedResponse.short_code;
      response.content = this.selectedResponse.content;
      this.selectedResponse = {};
    },

    openDeletePopup(response, index) {
      this.deleteResponse = response;
    },
    closeDeletePopup(response, index) {
      this.deleteResponse = {};
    },

    confirmDeletion(response, index) {
      this.deleteCannedResponse(this.deleteResponse.id);
      this.closeDeletePopup();
    },

    deleteCannedResponse(id) {
      this.$store
        .dispatch('deleteCannedResponse', id)
        .then(() => {
          this.showAlert(this.$t('CANNED_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        })
        .catch(() => {
          this.showAlert(this.$t('CANNED_MGMT.DELETE.API.ERROR_MESSAGE'));
        });
    },

    editCannedResponse(cannedResponse) {
      this.$store
        .dispatch('updateCannedResponse', {
          id: cannedResponse.id,
          short_code: cannedResponse.short_code,
          content: cannedResponse.content,
        })
        .then(() => {
          this.showAlert(this.$t('CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE'));
          cannedResponse.editable = false;
        })
        .catch(() => {
          this.showAlert(this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE'));
        });
    },
  },
};
</script>


<style lang="scss">
.canned-responses {
  height: calc(100% - 67px);

  .scrollable-div {
    height: calc(100% - 50px);
  }

  input {
    height: 32px;
    width: 75%;
    margin-bottom: 0;
    font-size: 14px;
  }

  textarea {
    width: 75%;
    margin-bottom: 0;
    font-size: 14px;
  }

  .form-button {
    margin-top: 0;
  }

  .simple-table {
    width: 100%;
    padding: 24px;
    border-spacing: 2px;
    border-collapse: separate;
  }

  .simple-table-header {
    font-size: 14px;
    font-weight: bold;
    color: #1a1c1d;
    text-align: left;
  }

  tr.border_bottom {
    line-height: 18px;
  }

  tr.border_bottom th {
    margin-top: 16px;
    border-bottom: 1px solid#969BA7;
    padding-bottom: 20px;
    max-width: 210px;
  }

  tr.border_bottom td {
    border-bottom: 1px solid#EFEEF0;
  }

  tr > td {
    max-width: 258px;
    color: #1a1c1d;
    font-size: 14px;
    padding: 10px 0px;
    vertical-align: top;
  }

  .agent-action-options {
    padding: 7px;
    border: 1px solid #dedddf;
    border-radius: 4px;
    cursor: pointer;
  }

  .agent-action-options:hover {
    border-color: #3970f1;

    img {
      filter: brightness(3.5) sepia(1) hue-rotate(190deg) saturate(6);
    }
  }

  .no-canned-responses {
    width: 100%;
    padding: 24px;
  }
}
</style>