/* eslint no-console: 0 */
/* eslint no-undef: "error" */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */

export default {
  methods: {
    // This is a sample utility file for defining global functions and we cna use it in the file whenever we feel
    getWindowDomain() {
      return null;
    }
  },
};
