<template>
  <div class="app-content-body">
    <div class="common-sidebar qw-common-sidebar">
      <div class="title lato-bold">Settings</div>
      <div class="options sidebar-column-options">
        <router-link :to="accountPath" replace v-if="currentUser.role == 'administrator'">
          <div
            class="option lato-medium"
            :class="{ active : currentRoute == 'account'}"
          >Account Settings</div>
        </router-link>
        <router-link :to="myProfilePath" replace>
          <div
            class="option lato-medium"
            :class="{ active : currentRoute == 'profile_settings_index'}"
          >My Profile</div>
        </router-link>
        <router-link :to="agentPath" replace v-if="currentUser.role == 'administrator'">
          <div
            class="option lato-medium"
            :class="{ active : currentRoute == 'agent_list'}"
          >Agent Settings</div>
        </router-link>
        <router-link :to="cannedResponsePath" replace>
          <div
            class="option lato-medium"
            :class="{ active : currentRoute == 'canned_list'}"
          >Canned Responses</div>
        </router-link>
        <div
          style="cursor: pointer;"
          v-if="currentUser.role == 'administrator'"
          @click="inboxDropdownClicked"
        >
          <div
            class="option lato-medium display-flex-space-between"
            :class="{ active : ['settings_inbox_list', 'settings_inbox_show', 'settings_inbox_new',
        'settings_inboxes_page_channel',
        'settings_inboxes_add_agents',
        'settings_inbox_finish'].includes(currentRoute) }"
          >
            <span>Inbox Settings</span>
            <i class="fa fa-chevron-down qw-chevron" aria-hidden="true"></i>
          </div>
        </div>
        <div
          v-if="currentUser.role == 'administrator' && showInboxes"
          style="margin-bottom: 10rem;"
        >
          <div class="sidebar-column-inbox-list">
            <router-link v-for="inbox in inboxesList" :key="inbox.id" :to="changeInbox(inbox)">
              <div
                class="setting-inbox-option lato-medium"
                :title="inbox.name"
                :class="{active: (inbox.id == (selectedInbox == null ? null : selectedInbox.id)) }"
                @click="onInboxSelection(inbox)"
              >{{inbox.name}}</div>
            </router-link>
          </div>
          <div style="padding: 16px 24px">
            <div class="form-botton-element" @click="creatNewInbox">
              <div class="form-button grey" style="width: 100%;">Add New Inbox</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qw-common-sidebar-body columns conversation-wrap setting">
      <div class="setting-title lato-bold">
        <div>{{headerTitle}}</div>
        <div v-if="selectedInbox">
          <div v-if="!askInboxDeleteConfirmation">
            <div
              class="form-button red button-fixed-width"
              style="line-height: 14px;font-weight: 500;"
              @click="askInboxDeleteConfirmation = true"
            >Delete Inbox</div>
          </div>
          <div v-else style="width: 300px;font-size: 14px;margin: -22px 0px">
            <div class="delete-confirmation-title">Are you sure you want to delete this inbox?</div>
            <div class="display-flex-space-between" style="margin-top: -10px;">
              <div
                class="form-button red m-t-8 button-fixed-width"
                @click="onDeleteConfirmation(selectedInbox.id)"
                style="line-height: 14px;font-weight: 500;"
              >Delete</div>
              <div
                class="form-button grey m-t-8 button-fixed-width"
                @click="askInboxDeleteConfirmation = false"
                style="line-height: 14px;font-weight: 500;"
              >Cancel</div>
            </div>
          </div>
        </div>
      </div>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import SettingsHeader from './SettingsHeader';
import { frontendURL } from '../../../helper/URLHelper';
import { mapGetters } from 'vuex';
import router from '../../index';
import Vue from 'vue';

export default {
  components: {
    SettingsHeader,
  },
  props: {
    headerButtonText: String,
  },
  data() {
    return {
      headerTitle: '',
      showInboxes: false,
      selectedInbox: null,
      askInboxDeleteConfirmation: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      inboxesList: 'inboxes/getInboxes',
    }),
    currentPage() {
      return this.$store.state.route.name;
    },
    currentRoute() {
      if (this.$store.state.route.name == 'account') {
        this.showInboxes = false;
        this.selectedInbox = null;
        this.headerTitle = 'Account Settings';
      } else if (this.$store.state.route.name == 'agent_list') {
        this.showInboxes = false;
        this.selectedInbox = null;
        this.headerTitle = 'Agent Settings';
      } else if (this.$store.state.route.name == 'canned_list') {
        this.showInboxes = false;
        this.selectedInbox = null;
        this.headerTitle = 'Canned Settings';
      } else if (this.$store.state.route.name == 'settings_inbox_list') {
        this.headerTitle = 'Inbox Settings';
        if (this.selectedInbox == null) {
          this.showInboxes = true;
          if (this.inboxesList.length > 0) {
            this.selectedInbox = this.inboxesList[0];
            router.replace({
              name: 'settings_inbox_show',
              params: {
                inboxId: this.selectedInbox.id,
              },
            });
          }
        }
      } else if (this.$store.state.route.name == 'profile_settings_index') {
        this.showInboxes = false;
        this.selectedInbox = null;
        this.headerTitle = 'My Profile';
      }
      return this.$store.state.route.name;
    },
    accountPath() {
      return frontendURL('settings/account');
    },
    myProfilePath() {
      return frontendURL(`accounts/${this.accountId}/profile/settings`);
    },
    agentPath() {
      return frontendURL('settings/agents/list');
    },
    inboxPath() {
      return frontendURL('settings/inboxes/list');
    },
    cannedResponsePath() {
      return frontendURL('settings/canned-response/list');
    },
    currentInboxId() {
      return this.$store.state.route.params.inboxId;
    },
  },
  watch: {
    inboxesList(oldInboxesList, newInboxesList) {
      if (newInboxesList != undefined) {
        if (this.currentPage == 'settings_inbox_show') {
          const [present] = this.inboxesList.filter(
            inbox => this.currentInboxId == inbox.id
          );
          if (present) {
            this.selectedInbox = present;
            this.headerTitle = 'Inbox Settings > ' + this.selectedInbox.name;
          } else {
            this.headerTitle = 'Inbox Settings';
            this.selectedInbox = {};
            this.selectedInbox.id = this.currentInboxId;
          }
          this.showInboxes = true;
        }
      }
    },
  },
  mounted() {
    this.askInboxDeleteConfirmation = false;
    if (this.newInboxCreation()) {
      this.headerTitle = 'Inbox Settings > Add New Inbox';
    }
    if (this.currentPage == 'settings_inbox_show') {
      const [present] = this.inboxesList.filter(
        inbox => this.currentInboxId == inbox.id
      );
      if (present) {
        this.headerTitle = 'Inbox Settings > ' + this.selectedInbox.name;
        this.selectedInbox = present;
      } else {
        this.headerTitle = 'Inbox Settings';
        this.selectedInbox = {};
        this.selectedInbox.id = this.currentInboxId;
      }
    }
  },
  methods: {
    async onDeleteConfirmation(id) {
      try {
        await this.$store.dispatch('inboxes/delete', id);
        bus.$emit(
          'newToastMessage',
          this.$t('INBOX_MGMT.DELETE.API.SUCCESS_MESSAGE')
        );
        this.askInboxDeleteConfirmation = false;
        this.selectedInbox = null;
        router.replace({
          name: 'settings_inbox_list',
        });
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE')
        );
      }
    },
    onInboxSelection(inbox) {
      this.selectedInbox = inbox;
      this.headerTitle = 'Inbox Settings > ' + this.selectedInbox.name;
    },
    newInboxCreation() {
      return [
        'settings_inbox_new',
        'settings_inboxes_page_channel',
        'settings_inboxes_add_agents',
        'settings_inbox_finish',
      ].includes(this.currentPage);
    },
    changeInbox(inbox) {
      // `/app/settings/inboxes/${item.id}`
      return frontendURL(`settings/inboxes/${inbox.id}`);
    },
    showButton() {
      /* eslint-disable no-unneeded-ternary */
      return this.newButtonRoutes
        ? this.newButtonRoutes.indexOf(this.currentPage) > -1
        : true;
    },
    showNewButton() {
      return this.newButtonRoutes ? true : false;
    },
    inboxDropdownClicked() {
      this.showInboxes = !this.showInboxes;
      if (this.selectedInbox != null) {
        const [present] = this.inboxesList.filter(
          inbox => this.selectedInbox.id === inbox.id
        );
        if (present) {
          this.selectedInbox = present;
          this.headerTitle = 'Inbox Settings > ' + this.selectedInbox.name;
        } else {
          this.headerTitle = 'Inbox Settings';
        }

        router.replace({
          name: 'settings_inbox_show',
          params: {
            inboxId: this.selectedInbox.id,
          },
        });
        return;
      } else if (this.showInboxes && this.inboxesList.length > 0) {
        this.selectedInbox = this.inboxesList[0];
        this.headerTitle = 'Inbox Settings > ' + this.selectedInbox.name;

        router.replace({
          name: 'settings_inbox_show',
          params: {
            inboxId: this.selectedInbox.id,
          },
        });
        return;
      }
      if (this.inboxesList.length == 0) {
        router.replace({
          name: 'settings_inbox_new',
        });
      }
    },
    creatNewInbox() {
      this.selectedInbox = null;
      this.headerTitle = 'Inbox Settings > Add New Inbox';
      router.replace({
        name: 'settings_inbox_new',
      });
    },
  },
};
</script>


<style lang="scss">
.setting-title {
  font-size: 20px;
  color: #3970f1;
  padding: 17px 24px;
  border-bottom: 1px solid #efeef0;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
}

.setting-inbox-option {
  margin-bottom: 15px;
  line-height: 24px;
  font-weight: 500;
  color: #697181;
  overflow: hidden;
  text-overflow: ellipsis;
}

.setting-inbox-option.active {
  color: #3970f1;
  font-weight: 700;
}

.qw-common-sidebar {
  .option.active {
    color: #3970f1 !important;
  }

  .form-button.grey {
    color: #697181;
  }
  .form-button.grey:hover {
    background: #f6f6f7;
    color: #697181;
  }
}

.sidebar-column-inbox-list {
  padding: 16px 24px;
  max-height: 200px;
  overflow-y: auto;
}

.sidebar-column-inbox-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.sidebar-column-inbox-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #adabab;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>