import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins


const fontFamily =
  '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    labels: {
      fontFamily,
    },
    onHover: function(e) {
      e.target.style.cursor = 'pointer';
    }
  },
  hover: {
    onHover: function(e) {
       var point = this.getElementAtEvent(e);
       if (point.length) e.target.style.cursor = 'pointer';
       else e.target.style.cursor = 'default';
    }
 },
  scales: {
    xAxes: [
      {
        barPercentage: 1,
        ticks: {
          fontFamily,
          autoSkip: true,
          maxTicksLimit: 20
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontFamily,
        },
        gridLines: {
          display: true,
          color: "#f5f5f5"
        },
      },
    ],
  },
};

export default {
  extends: Line,
  props: ['chartData'],
  mixins: [reactiveProp],
  mounted() {
    this.renderChart(this.chartData, chartOptions);
  },
};
