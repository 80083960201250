export const state = {
  conversationsReplyText: {}
};

export const getters = {
  setReplyBoxTextByConversation: $state => ( conversationId, text ) => {
    $state.conversationsReplyText[conversationId] = text;
  },
  removeReplyBoxTextByConversation: $state => ( conversationId, text ) => {
    $state.conversationsReplyText[conversationId] = '';
  },
  getReplyBoxTextByConversation: $state => ( conversationId ) => {
    return $state.conversationsReplyText[conversationId] || '';
  },
};

export const actions = {
};

export const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
