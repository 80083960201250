<template>
  <div>
    <div class="add-canned-response">
      <div class="setting-form">
        <div>
          <div class="form-field">
            <div class="form-label">Add a canned response</div>
            <div
              class="form-help-text m-0"
            >Canned Responses are saved reply templates which can be used to quickly send out reply to conversation</div>
          </div>
          <div class="form-field">
            <div class="form-label">
              Short Code
              <div class="required">*</div>
            </div>
            <div class="form-input form-input-small-width">
              <input
                class="field"
                v-model.trim="shortCode"
                :placeholder="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
                @input="$v.shortCode.$touch"
              >
              <div v-if="shortCodeError" class="error-message">{{ shortCodeError }}</div>
            </div>
          </div>
          <div class="form-field">
            <div class="form-label">
              Content
              <div class="required">*</div>
            </div>
            <div class="form-input form-input-small-width">
              <textarea
                class="field"
                v-model.trim="content"
                :placeholder="$t('CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER')"
                @input="$v.content.$touch"
              ></textarea>
              <div v-if="contentError" class="error-message">{{ contentError }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-form-actions">
        <div
          class="form-button blue m-r-24 button-fixed-width"
          @click="addCannedResponse"
        >Add Response</div>
        <div class="form-button grey button-fixed-width" @click="onClose">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
/* global bus */
/* eslint no-console: 0 */
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  props: ['onClose'],
  data() {
    return {
      shortCode: '',
      content: '',
      agentType: '',
      vertical: 'bottom',
      horizontal: 'center',
      shortCodeError: null,
      contentError: null,
      addCanned: {
        showAlert: false,
        showLoading: false,
        message: '',
      },
      agentTypeList: this.$t('CANNED_MGMT.AGENT_TYPES'),
      show: true,
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
    agentType: {
      required,
    },
  },

  methods: {
    setPageName({ name }) {
      this.$v.agentType.$touch();
      this.agentType = name;
    },
    showAlert() {
      bus.$emit('newToastMessage', this.addCanned.message);
    },
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    addCannedResponse() {
      // Show loading on button

      this.shortCodeError = null;
      this.contentError = null;
      
      if (this.shortCode == null || this.shortCode.trim() == '') {
        this.shortCodeError = 'Short code can not be empty';
      }
      if (this.content == null || this.content.trim() == '') {
        this.contentError = 'Content can not be empty';
      }

      if (this.shortCodeError || this.contentError) {
        return;
      }

      this.addCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('createCannedResponse', {
          short_code: this.shortCode,
          content: this.content,
        })
        .then(() => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          this.addCanned.message = this.$t(
            'CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'
          );
          this.showAlert();
          this.resetForm();
          this.onClose();
        })
        .catch(() => {
          this.addCanned.showLoading = false;
          this.addCanned.message = this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE');
          this.showAlert();
        });
    },
  },
};
</script>