<template>
  <div>
    <div style="display: none">
      <div class="wizard-body small-9 columns">
        <page-header
          :header-title="$t('INBOX_MGMT.ADD.AUTH.TITLE')"
          :header-content="$t('INBOX_MGMT.ADD.AUTH.DESC')"
        />
        <div class="row channels">
          <channel-item
            v-for="channel in channelList"
            :key="channel"
            :channel="channel"
            @channel-item-click="initChannelAuth"
          />
        </div>
      </div>
    </div>
    <div class="setting-form scrollable-div">
      <div>
        <div class="form-field">
          <div class="form-label">Choose a channel</div>
          <div
            class="form-help-text m-0"
          >Select a channel you want to integrate with {{title}}.</div>
          <div style="display: flex; margin-top: 70px;">
            <channel-item
              v-for="channel in channelList"
              :key="channel"
              :channel="channel"
              @channel-item-click="initChannelAuth"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChannelItem from 'dashboard/components/widgets/ChannelItem';
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader';

export default {
  components: {
    ChannelItem,
    PageHeader,
  },
  data() {
    return {
      // channelList: ['website', 'facebook', 'twitter', 'telegram', 'line'],
      channelList: ['website', 'multichannel'],
      title: window.chatwootConfig.custom_website.title,
    };
  },
  methods: {
    initChannelAuth(channel) {
      const params = {
        page: 'new',
        sub_page: channel,
      };
      router.push({ name: 'settings_inboxes_page_channel', params });
    },
  },
};
</script>
