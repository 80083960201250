<template>
  <div class="add-agent-while-creating setting-form scrollable-div" style="padding-bottom: 20rem;">
    <div class="form-field">
      <div class="form-label">{{ $t('INBOX_MGMT.ADD.AGENTS.TITLE') }}</div>
      <div class="form-help-text m-0" v-html="$t('INBOX_MGMT.ADD.AGENTS.DESC')"></div>

      <div class="form-input m-t-16" style="max-width: 100%;">
        <div style="position: relative">
          <div
            v-if="agentDropdownShow"
            v-on-clickaway="onToggleDropdown"
            class="qw-dropdown-menu-items"
          >
            <div
              v-if="selectedAgents.length == agentList.length"
              class="conversation-agent-filter-no-element"
              @click="onToggleDropdown"
            >No agent found!</div>
            <div v-else v-for="(agent, index) in agentList" :key="agent.id">
              <div
                v-if="showIfNotSelected(agent, index)"
                class="conversation-agent-filter-element"
                @click="agentSelected(agent, index)"
              >
                <div class="conversation-agent-single-element">
                  <QWThumbnail
                    :src="agent.profile_pic"
                    class="m-r-4"
                    size="16px"
                    :username="agent.name"
                  />
                  <div class="title">{{agent.name}}</div>
                </div>
                <div class="cross-sign">
                  <div style="margin-top: -1px;">+</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class style="display: flex;">
          <div class="conversation-agent-filter" @click="onToggleDropdown">
            <img src="~/assets/new_images/User_Icon_Black.svg" size="16px" class="m-r-4">
            <div
              style="display: flex; justify-content: space-between; width: inherit; align-items: center;"
            >
              <div class="title">Add Agent</div>
              <i class="fa fa-chevron-down qw-chevron" aria-hidden="true"></i>
            </div>
          </div>

          <div
            v-if="selectedAgents"
            style="display: flex; width: inherit; margin-left: 16px; flex-flow: wrap;"
          >
            <div
              class="conversation-agent-selected-filter"
              v-for="(item, index) in selectedAgents"
              :key="item.id"
            >
              <QWThumbnail :src="item.profile_pic" class="m-r-4" size="16px" :username="item.name"/>
              <div
                style="display: flex; justify-content: space-between; width: inherit; line-height: 24px;"
              >
                <div class="title">{{item.name}}</div>
                <div @click="removeAgentFromSelected(item, index)">
                  <i class="fa fa-times-circle"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          <div class="form-botton-element m-t-40">
      <div class="form-button blue" @click="addAgents()">Continue</div>
    </div>
    </div>
  </div>
</template>

<script>
/* eslint no-console: 0 */
/* global bus */
import { mapGetters } from 'vuex';

import InboxMembersAPI from '../../../../api/inboxMembers';
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader';
import QWThumbnail from '../../../../components/widgets/QWThumbnail';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  components: {
    PageHeader,
    QWThumbnail,
  },

  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
  },

  data() {
    return {
      selectedAgents: [],
      isCreating: false,
      agentDropdownShow: false,
    };
  },

  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
    }),
  },

  mounted() {
    this.$store.dispatch('agents/get');
  },

  methods: {
    agentSelected(item, index) {
      this.selectedAgents.push(item);
    },
    showIfNotSelected(item, index) {
      const [present] = this.selectedAgents.filter(
        agent => item.id === agent.id
      );
      if (present) {
        return false;
      } else {
        return true;
      }
    },
    removeAgentFromSelected(item, index) {
      this.selectedAgents.splice(index, 1);
    },
    onToggleDropdown() {
      this.agentDropdownShow = !this.agentDropdownShow;
    },
    async addAgents() {
      this.isCreating = true;
      const inboxId = this.$route.params.inbox_id;
      const selectedAgents = this.selectedAgents.map(x => x.id);

      try {
        await InboxMembersAPI.create({ inboxId, agentList: selectedAgents });
        router.replace({
          name: 'settings_inbox_finish',
          params: {
            page: 'new',
            inbox_id: this.$route.params.inbox_id,
          },
        });
      } catch (error) {
        bus.$emit('newToastMessage', error.message);
      }
      this.isCreating = false;
    },
  },
};
</script>


<style lang="scss">
.add-agent-while-creating {
  .qw-dropdown-menu-items {
    position: absolute;
    top: 3.5rem;
    background: white;
    border: 1px solid #d4d8dc;
    z-index: 999;
    width: max-content;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    max-height: 250px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px #1a1c1d29;
  }

  .conversation-agent-filter {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid #dedddf;
    border-radius: 4px;
    height: 32px;
    color: #1a1c1d;
    width: 144px;
    font-weight: 500;

    img {
      margin-right: 4px;
    }

    .title {
      color: #1a1c1d;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .conversation-agent-selected-filter {
    margin: 0px 0px 8px 8px;
    display: flex;
    align-items: center;
    border: 1px solid #dedddf;
    border-radius: 4px;
    padding: 4px 8px;
    height: 32px;
    color: #1a1c1d;
    font-weight: 500;
    width: fit-content;

    img {
      margin-right: 4px;
    }

    .title {
      color: #1a1c1d;
      font-size: 14px;
      margin-right: 9px;
    }

    i {
      font-size: 16px;
      padding-top: 5px;
      cursor: pointer;
    }
  }

  .conversation-agent-selected-filter:hover {
    background: #fff8eb;
    border-color: #ffa600;

    i {
      color: #1a1c1d;
    }
  }

  .conversation-agent-filter:hover {
    border-color: #3970f1;
    color: #3970f1;

    .title {
      color: #3970f1;
    }

    img {
      filter: brightness(3.5) sepia(1) hue-rotate(190deg) saturate(6);
    }
  }

  .conversation-agent-filter-element {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    cursor: pointer;

    img {
      margin-right: 4px;
    }

    .title {
      color: inherit;
      font-size: 14px;
      margin-right: 8px;
    }

    .cross-sign {
      border-radius: 50%;
      border: 1px solid #969ba7;
      font-size: 14px;
      padding: 0px 3px;
      height: 16px;
      width: 16px;
      align-self: center;
      color: #969ba7;
    }
  }

  .conversation-agent-single-element {
    display: flex;
    justify-content: space-between;
  }

  .conversation-agent-filter-no-element {
    display: flex;
    padding: 8px 12px;
    .title {
      color: inherit;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .conversation-agent-filter-element:hover {
    background: #3970f1;

    .title {
      color: white;
    }

    .avatar-container {
      background: white !important;
      color: #3970f1 !important;
    }

    .cross-sign {
      color: white;
      border-color: white;
    }
  }
}
</style>
