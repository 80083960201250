<template>
        <button class="resumable-browse">
          <span :style="`background-color: ${color}`" class="icon-holder"></span>
        </button>
</template>

<script>
    import Resumable from 'widget/store/resumable.js';
    import axios from 'axios'
    import Constants from '../../../../dashboard/constants.js';

    export default {
        data() {
            return {
                resumable_error: false,
                resumable_drop: false,
                resumable_list: false,
                resumableDrag: false,
                activeColor: 'green',
                progress_text: '',
                progressState: true,
                progress_bar: {
                    width: ''
                }
            }
        },
        props: {
            color: {
                type: String,
                default: '#6e6f73',
            },
            onFileUpload: {
                type: Function,
                default: () => {},
            },
            updateProgress: {
                type: Function,
                default: () => {},
            },
        },
        methods: {
            uploadFile(event) {
                this.r.addFile(event.clipboardData.files[0]);
            },
            cancelFileUpload() {
                this.r.cancel();
            }
        },
        filters: {
            size: function (value) {
                if (!value) {
                    return 0;
                }
                let s = value / (1024 * 1024);
                if (parseInt(s, 10) > 0) {
                    return s.toFixed(1) + 'M';
                } else {
                    return (value / 1024).toFixed(1) + 'Kb'
                }
            }
        },
        watch: {
            fileList(newValue, oldValue) {
                if (newValue.length === 0) {
                    for (let i = 0; i < oldValue.length; i++) {
                        oldValue[i].cancel()
                    }
                }
            }
        },
        mounted() {

            this.$nextTick(() => {
                
                let r = new Resumable({
                    target: Constants.INBOX_SETTINGS.FILE_UPLOAD.URL,
                    // target: 'http://localhost:3001/upload',
                    testChunks: true,
                    maxFileSize: 10485760,
                    fileType: Constants.INBOX_SETTINGS.FILE_UPLOAD.FILE_TYPES
                })
                this.r = r;
                if (!this.r.support) {
                    this.resumable_error = true;
                } else {
                    this.resumable_drop = true;
                    this.r.assignDrop(document.getElementsByClassName('resumable-drop'));
                    this.r.assignBrowse(document.getElementsByClassName('resumable-browse'));
                    this.resumable_list = true;
                    this.r.on('fileAdded', (file) => {
                        this.r.upload()
                    })
                    this.r.on('fileSuccess', (file, message) => {
                        let res = JSON.parse(message);
                        this.progress_text = 'Uploaded successfully'
                        var file_object = {
                            name : file.file.name,
                            url : res.s3.url,
                            type : file.file.type,
                            size : file.file.size,
                            lastModified : file.file.lastModified
                        }
                        var type = 'application';
                        if (file.file.type.startsWith('image')) {
                            type = 'image';
                        } else if (file.file.type.startsWith('video')) {
                            type = 'video';
                        } else if (file.file.type.startsWith('audio')) {
                            type = 'audio';
                        }

                        this.onFileUpload(file_object, type);
                        this.r.removeFile(file);
                    })
                    this.r.on('fileError', (file, message) => {
                        this.progressState = false;
                        this.activeColor = 'red';
                        this.progress_text = 'Upload failed';
                        this.progress_bar = {
                            width: 0 + '%'
                        }
                        var fileType = file.file.name.split('.').pop();
                        var defaultFileTypeText = 'this file type';
                        alert('Sorry, ' + (fileType || defaultFileTypeText) + ' is not accepted.');
                        this.updateProgress(this.progress_bar.width, file.file.name, false);
                    })
                    this.r.on('fileProgress', (file) => {
                        this.progress_bar = {
                            width: Math.floor(file.progress() * 100) + '%'
                        }
                        this.updateProgress(this.progress_bar.width, file.file.name, true);
                        
                    })
                }
            })
        }
    }

</script>


<style scoped lang="scss">

@import '~widget/assets/scss/variables.scss';

    .resumable-browse {
      background: transparent;
      border: 0;
      cursor: pointer;
      position: relative;
      outline: none;
      vertical-align: bottom;

        .icon-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            fill: #969BA7;
            // color: #969BA7;
            font-size: $font-size-big;
            // font-weight: $font-weight-medium;
            width: 8px;
            height: $space-normal;
            -webkit-mask-image: url('~dashboard/assets/new_images/Attach_Icon.svg');
            mask-image: url('~dashboard/assets/new_images/Attach_Icon.svg');
        }
    }

    .resumable-error {
        font-size: 14px;
        font-style: italic;
    }

    .resumable-drop {
        padding: 15px;
        font-size: 13px;
        text-align: center;
        color: #666;
        font-weight: bold;
        background-color: #eee;
        border: 2px dashed #aaa;
        border-radius: 10px;
        margin-top: 40px;
        z-index: 9999;
    }

    .resumable-dragover {
        padding: 30px;
        color: #555;
        background-color: #ddd;
        border: 1px solid #999;
    }
    /* Uploader: Progress bar */

    .progress-container {
        height: 7px;
        background: #9CBD94;
        position: relative;
    }

    .progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: #45913A;
        width: 0;
    }

    .progress-text {
        font-size: 11px;
        line-height: 9px;
        padding-left: 10px;
    }

    .resumable-file-name {
        position: absolute;
        left: 80px;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .resumable-list {
        background-color: #eee;
        margin: 5px 0 5px;
        width: 50%;
        overflow: auto;
    }
    ul {
        list-style: none;
        /*-webkit-padding-start: 0*/
    }
</style>
