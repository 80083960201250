<template>
  <div class="report-data-table-labels">
      <data-table
        :data="labelStatistics.length > 0 ? labelStatistics : []"
        :columns-to-display="$t('REPORT.LABEL_REPORT_STRUCTURE.COLUMNS')"
        :filter-key="searchQueryLabel"
        :display-names="display_names_label"
      >
        <template slot="caption">
          <div style="display: flex; justify-content: flex-end;">
            <input
              class="search-option"
              placeholder="Search label"
              type="text"
              v-model="searchQueryLabel"
            >
            <i
              class="icon ion-search search-icon"
              style="align-self: center; font-size: 22px; color: #c0cdda; margin-left: -26px;"
            ></i>
          </div>
        </template>
        <template slot="nodata">
            <div class="empty-data">
              No data is available to show
            </div>
        </template>
      </data-table>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../../../../components/widgets/Thumbnail';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  components: {
    Thumbnail,
  },
  props: ['to', 'from', 'currentInboxSelection' ],
  data() {
    return {
      searchQueryLabel: '',
      isDestroyed: false,
      display_names_label: {
        name: 'Name',
        total_assigned: 'Assigned conversations',
        total_unassigned: 'Unassigned conversations',
        total_conversations: 'Total conversations',
        total_agents: 'Total Agents',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      labelStatistics: 'getLabelStatistics',
    }),
  },
  mounted() {
    this.generateLabelReport();
  },
  beforeDestroy() {
    this.isDestroyed = true;
  },
  methods: {
    async generateLabelReport() {
      const { from, to } = this;
      
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchLabelList', {
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null
        });
      }

      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchLabelStatistics', {
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null
        });
      }
    },
  },
  watch: {
    from(newfrom, oldfrom) {
      this.from = newfrom;
      this.generateLabelReport();
    },
    to(newto, oldto) {
      this.to = newto;
      this.generateLabelReport();
    },
    currentInboxSelection(newcurrentInboxSelection, oldcurrentInboxSelection) {
      this.currentInboxSelection = newcurrentInboxSelection;
      this.generateLabelReport();
    }
  },
};
</script>


<style lang="scss">

.report-data-table-labels {
  table {
    border: 1px solid #dedddf;
    border-radius: 8px;
    font-size: 13px;

    th,
    td {
      text-align: right;
      padding: 8px;
    }

    th:first-child,
    td:first-child {
      text-align: left;
      padding: 8px;
    }
  }

  th {
    text-transform: capitalize;
    background-color: #f9f9f9;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  th.active {
    color: #3970f1;
  }

  th.active .arrow.asc {
    opacity: 1;
    border-bottom: 4px solid #3970f1;
  }

  th.active .arrow.dsc {
    opacity: 1;
    border-top: 4px solid #3970f1;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

  .arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #c7c4c4;
  }

  .arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #c7c4c4;
  }

  .empty-data {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    color: #9ba1ac;
  }
}
</style>