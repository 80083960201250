<template>
  <div>
    <div class="chat-header">
      <div class="chat-user">
        <CardQWThumbnail
          :src="chat.meta.sender.thumbnail"
          size="32px"
          :badge="chat.meta.sender.channel"
          :username="chat.meta.sender.name"
          :color-code="chat.color_code"
        />
        <div class="chat-user-name">{{ chat.meta.sender.name }}</div>
      </div>
      <div class="chat-options">
        <div
          v-if="currentUser.role == 'administrator' || currentChat.status === 1"
          class="user-details-options"
          style="margin-right: 8px;"
          @click="showOptions"
        >
          <i class="fa fa-ellipsis-v three-dot" aria-hidden="true"></i>
        </div>
        <div style="position: relative">
          <div v-if="showOptionsMenu" v-on-clickaway="showOptions" class="main-element">
            <div class="conversation-header-sub-menu">
              <ul style="margin: 0 !important;">
                <div
                  v-if="currentChat.status === 1"
                  @click="toggleStatus()"
                  class="conversation-header-sub-menu-element"
                >
                  <img src="~/assets/new_images/Open_Icon_Small.svg">
                  <span class="title-text">Open Conversation</span>
                </div>
                <div
                  v-if="currentUser.role == 'administrator'"
                  @click="exportSingleConversationRequest"
                  class="conversation-header-sub-menu-element"
                >
                  <img src="~/assets/new_images/Export_Icon.svg">
                  <span class="title-text">Export Conversation</span>
                </div>
                <div
                  v-if="currentUser.role == 'administrator'"
                  class="conversation-header-sub-menu-element"
                >
                  <div>
                    <div>
                      <img src="~/assets/new_images/Delete_Icon.svg" style="margin-top: -5px;">
                      <span
                        @click="confirmForDeletion = true"
                        class="title-text"
                      >Delete Conversation</span>
                    </div>

                    <div
                      v-if="confirmForDeletion"
                      style="max-width: 20rem; font-size: 12px; padding-top: 5px; padding-bottom: 5px"
                    >
                      <div
                        style="line-height: initial;"
                      >This operation is irreversible. Are you sure you want to delete this conversation?</div>
                      <div
                        class="conversation-explort-setting-form-actions"
                        style="margin-top: 10px;"
                      >
                        <div
                          @click="onConfirmDelete"
                          class="form-button red m-r-24 button-fixed-width p-0"
                        >Yes</div>
                        <div
                          @click="cancelDelete"
                          class="form-button grey button-fixed-width p-0"
                        >No</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <QWAgentDropdown
          :selected-agent="selectedChat"
          :agent-list="agentList"
          :assign-agent="assignAgent"
        />
        <ResolveButton/>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
/* global bus */

import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import CardQWThumbnail from '../CardQWThumbnail';
import QWAgentDropdown from '../QWAgentDropdown';
import ResolveButton from '../../buttons/ResolveButton';
import conversationMixin from '../../../mixins/conversations';

export default {
  components: {
    CardQWThumbnail,
    QWAgentDropdown,
    ResolveButton,
  },
  mixins: [clickaway, conversationMixin],
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
  },

  async mounted() {
    this.getAccountDetails();
    this.agentsList = await this.$store.dispatch(
      'inboxMembers/getAgentsByInbox',
      {
        inboxId: this.chat.inbox_id,
      }
    );
  },

  data() {
    return {
      currentChatAssignee: null,
      agentsList: [],
      selectedChat: null,
      showOptionsMenu: false,
      confirmForDeletion: false,
      accountName: null,
    };
  },

  watch: {
    async contactId(newContactId, prevContactId) {
      if (newContactId && newContactId !== prevContactId) {
        this.agentsList = await this.$store.dispatch(
          'inboxMembers/getAgentsByInbox',
          {
            inboxId: this.chat.inbox_id,
          }
        );
      }
    },
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      all_agents: 'agents/getAgents',
      currentUser: 'getCurrentUser',
    }),
    agentList() {
      if (this.currentChat.meta.assignee) {
        this.selectedChat = {
          user_id: this.currentChat.meta.assignee.id || 0,
          name: this.currentChat.meta.assignee.name,
        };
      } else {
        this.selectedChat = null;
      }
      if (this.agentsList.length > 0) {
        return [
          {
            confirmed: true,
            name: 'None',
            user_id: 0,
            role: 'agent',
            account_id: 0,
            email: 'None',
          },
          ...this.agentsList,
        ];
      } else {
        return [
          {
            confirmed: true,
            name: 'None',
            user_id: 0,
            role: 'agent',
            account_id: 0,
            email: 'None',
          },
        ];
      }
    },
    contactId() {
      return this.chat.id;
    },
    viewProfileButtonLabel() {
      return `${this.isContactPanelOpen ? 'Hide' : 'View'}  Profile`;
    },
  },

  methods: {
    toggleStatus() {
      this.$store.dispatch('toggleStatus', this.currentChat.id).then(() => {
        bus.$emit('newToastMessage', this.$t('CONVERSATION.CHANGE_STATUS'));
      });
      this.showOptions();
    },

    assignAgent(agent) {
      this.$store
        .dispatch('assignAgent', {
          conversationId: this.currentChat.id,
          agentId: agent.user_id,
        })
        .then(() => {
          bus.$emit('newToastMessage', this.$t('CONVERSATION.CHANGE_AGENT'));
        });
    },
    removeAgent() {
      this.$store
        .dispatch('assignAgent', {
          conversationId: this.currentChat.id,
          agentId: null,
        })
        .then(() => {
          bus.$emit('newToastMessage', this.$t('CONVERSATION.CHANGE_AGENT'));
        });
    },
    showOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
      this.confirmForDeletion = false;
    },
    async exportSingleConversationRequest() {
      var response = await this.$store.dispatch(
        'exportConversation',
        this.currentChat.id
      );
      if (response.status == 200) {
        this.exportConversation(
          this.currentChat,
          response.data.success,
          this.accountName,
          this.currentUser,
          this.all_agents
        );
      }
      this.showOptions();
    },
    cancelDelete() {
      this.confirmForDeletion = false;
    },
    async onConfirmDelete() {
      var response = await this.$store.dispatch(
        'deleteConversation',
        this.currentChat.id
      );
      if (response.status == 200) {
        this.showAlert('Conversation deleted successfully');
      }
      this.confirmForDeletion = false;
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async getAccountDetails() {
      try {
        var response = await this.$store.dispatch('accounts/accountDetails');
        if (response.status == 200) {
          this.accountName = response.data.success.current_account.name;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conversation-header-menu:active {
  background: #efefef;
}

.conversation-header-menu-part {
  width: 20px;
  height: 20px;
  background-image: radial-gradient(circle, black 2px, transparent 3px);
  background-size: 100% 33.33%;
}

.conversation-header-menu {
  position: relative;
  align-self: center;
  border: 1px solid #e4e4e4;
  margin-left: 5px;
  border-radius: 2px;
  background: #f9fafc;
}

.conversation-explort-setting-form-actions {
  display: flex;
}

.conversation-header-sub-menu {
  position: absolute;
  left: -4rem;
  top: 3.5rem;
  background: white;
  border: 1px solid #d4d8dc;
  z-index: 999;
  width: max-content;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
}

.conversation-header-sub-menu-element {
  padding: 2px 15px 2px 15px;
  font-size: 1.5rem;
  font-family: inherit;
  color: #1a1c1d;
  border-radius: 0;

  img {
    height: 12px;
    width: 12px;
    margin-right: 2px;
  }

  .title-text {
    font-size: 14px;
    color: #1a1c1d;
  }
}

.conversation-header-sub-menu-element:hover {
  background: #3970f1;
  color: white;
  cursor: pointer;
  border-radius: 0;

  img {
    filter: brightness(0) invert(1);
  }

  .title-text {
    font-size: 14px;
    color: white;
  }
}

.main-element {
  position: absolute;
}
</style>
