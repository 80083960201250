import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import InboxLabelAPI from '../../api/inboxLabels';

const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
    fetchingItem: false,
    creatingItem: false,
    updatingItem: false,
    deletingItem: false,
  },
};

const getters = {
  getInboxLabels(_state) {
    return _state.records;
  },
  getLabel(lableId) {
    return lableId;
  }
};

const actions = {
  get: async ({ commit }, { inboxId }) => {
    return await InboxLabelAPI.show(inboxId);
  },
  create: async ({ commit }, { id, name }) => {
    try {
      return await InboxLabelAPI.create({ id, name });
    } catch (error) {
      throw error;
    }
  },
  update: async ({ commit }, { id, ...agentParams }) => {
    try {
      return await InboxLabelAPI.update(id, agentParams);
    } catch (error) {
      throw error;
    }
  },
  delete: async ({ commit }, agentId) => {
    try {
      await InboxLabelAPI.delete(agentId);
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
