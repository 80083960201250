<template>
  <div class="row settings--form--body">
    <div>
      <p class="description">
        {{ description }} 
        </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },

  },

};
</script>
