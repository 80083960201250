<template>
  <div>
    <div class="conversation-single" :class="{ active: isActiveChat} " @click="cardClick(chat)">
      <div class="conversation-user-profile">
        <div class="conversation-user-profile-details">
          <div class="conversation-user-profile-picture">
            <CardQWThumbnail
              v-if="!hideThumbnail"
              :src="chat.meta.sender.thumbnail"
              :badge="chat.meta.sender.channel"
              class="columns"
              :color-code="chat.color_code"
              :username="chat.meta.sender.name"
              size="40px"
            />

            <div class="conversation-user-messaging-service">
              <img
                title="User is coming from WhatsApp Channel"
                v-if="chat.meta.sender.mc_type === 'whatsapp'"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/whatsapp.svg"
              >
              <img
                title="User is coming from Line Channel"
                v-if="chat.meta.sender.mc_type === 'line'"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/line.svg"
              >
              <img
                title="User is coming from Facebook Messenger Channel"
                v-if="chat.meta.sender.mc_type === 'facebook_messenger'"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/messenger.svg"
              >
              <img
                title="User is coming from Slack Channel"
                v-if="chat.meta.sender.mc_type === 'slack'"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/slack.svg"
              >
              <img
                title="User is coming from WebWidget/Website Channel"
                v-if="chat.meta.sender.mc_type === null"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/chat-widget.svg"
              >
              <img
                title="User is coming from Email Channel"
                v-if="chat.meta.sender.mc_type === 'email'"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/email.svg"
              >
              <img
                title="User is coming from Youtube Channel"
                v-if="chat.meta.sender.mc_type === 'youtube'"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/youtube.svg"
              >
              <img
                title="User is coming from Twitter Channel"
                v-if="chat.meta.sender.mc_type === 'twitter'"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/twitter.svg"
              >
              <img
                title="User is coming from Facebook Channel"
                v-if="chat.meta.sender.mc_type === 'facebook'"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/facebook.svg"
              >
              <img
                title="User is coming from LinkedIn Channel"
                v-if="chat.meta.sender.mc_type === 'linkedin'"
                style="width: 15px; height:15px !important"
                src="~/dashboard/assets/images/channels/linkedin.svg"
              >
            </div>
          </div>
          <div style="width: 100%; height: 40px; display: flex; flex-direction: column; padding-left: 8px; justify-content: space-between;" >
            <div class="conversation-user-name">{{ chat.meta.sender.name }}</div>
            <div style="display: flex; align-items: center; justify-content: space-between;" >
              <div class="conversation-inbox-tag">{{ inboxName(chat.inbox_id) }}</div>
              <div class="conversation-user-time">{{ dynamicTime(lastMessage(chat).created_at) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="conversation-preview-text l-h-24">
        <span style="width: 95%; overflow: hidden; text-overflow: ellipsis;">
          <span
            v-if="lastMessage(chat).message_type == 0 && $sanitize(extractMessageText(lastMessage(chat))).length > 0"
            v-html="prepareContent($sanitize(extractMessageText(lastMessage(chat))))"
          ></span>
          <span
            v-else-if="lastMessage(chat).message_type == 0 && $sanitize(extractMessageText(lastMessage(chat))).length == 0"
            v-html="'<i>*Unsupported content*</i>'"
          ></span>
          <span
            v-else
            v-html="prepareContent(extractMessageText(lastMessage(chat)))"
          >{{extractMessageText(lastMessage(chat))}}</span>
        </span>
        <span class="unread-chat-tick" v-if="isLastInformationText || this.getUnreadCount > 0"></span>
      </div>
      <!-- <div class="conversation-inbox-tag">{{ inboxName(chat.inbox_id) }}</div> -->
    </div>
  </div>
</template>
<script>
/* eslint no-console: 0 */
/* eslint no-extra-boolean-cast: 0 */
import { mapGetters } from 'vuex';
import CardQWThumbnail from '../CardQWThumbnail';
import getEmojiSVG from '../emoji/utils';
import conversationMixin from '../../../mixins/conversations';
import timeMixin from '../../../mixins/time';
import router from '../../../routes';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  components: {
    CardQWThumbnail,
  },

  mixins: [timeMixin, conversationMixin],
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
    activeSearchValue: {
      type: String,
      default: null,
    },
    activeSearchKey: {
      type: String,
      default: null,
    },
    hideInboxName: {
      type: Boolean,
      default: false,
    },
    hideThumbnail: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      inboxesList: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
      activeLabel: 'getSelectedLabel',
    }),

    isActiveChat() {
      return this.currentChat.id === this.chat.id;
    },

    getUnreadCount() {
      var count = this.unreadMessagesCount(this.chat);
      return count;
    },

    hasUnread() {
      return this.getUnreadCount > 0;
    },

    isLastInformationText() {
      if (this.getUnreadCount == 0) {
        var lastMsg = this.chat.messages.last();
        if (
          lastMsg.message_type === 2 &&
          lastMsg.content_type == 'text' &&
          lastMsg.private !== true
        ) {
          if (this.chat.messages.last().content == 'Conversation created') {
            return true;
          } else if (
            this.chat.messages.last().content.startsWith('Assigned to')
          ) {
            return true;
          }
        }
      } else {
        return false;
      }
    },

    isInboxNameVisible() {
      return !this.activeInbox;
    },
  },

  methods: {
    cardClick(chat) {
      router.push({
        path: frontendURL(`conversations/${chat.id}`),
      });
    },
    prepareContent(content = '') {
      if (this.activeSearchKey == 'text') {
        return content.replace(
          new RegExp(`(${this.activeSearchValue})`, 'ig'),
          '<span class="searchkey--highlight">$1</span>'
        );
      } else {
        return content;
      }
    },
    extractMessageText(chatItem) {
      
      if (chatItem.content_type == 'media') {
        var media_payload = JSON.parse(chatItem.payload);
        var key = 'CHAT_LIST.ATTACHMENTS.file' 
        if (media_payload.elements.type == 'image') {
          key = 'CHAT_LIST.ATTACHMENTS.image';
        } else if (media_payload.elements.type == 'video') {
          key = 'CHAT_LIST.ATTACHMENTS.video';
        } else if (media_payload.elements.type == 'iframe') {
          key = 'CHAT_LIST.ATTACHMENTS.file';
        } else if (media_payload.elements.type == 'audio') {
          key = 'CHAT_LIST.ATTACHMENTS.audio';
        }

        if (chatItem.content) {
          return `
            ${chatItem.content}
            <i class="${this.$t(`${key}.ICON`)}"></i>
          `;
        } else {
          return `
            ${this.$t(`${key}.CONTENT`)}
            <i class="${this.$t(`${key}.ICON`)}"></i>
          `;
        }
      }
      
      if (chatItem.content) {
        return chatItem.content;
      }

      return ' ';
    },
    getEmojiSVG,

    inboxName(inboxId) {
      const stateInbox = this.$store.getters['inboxes/getInbox'](inboxId);
      return stateInbox.name || '';
    },
  },
};
</script>



<style lang="scss">
.searchkey--highlight {
  background: #fde9c8;
  color: #000;
  padding: 0px 2px;
}
</style>
