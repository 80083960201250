<template>
  <div class="row settings--form--header" style="padding-bottom: 10px !important;">
    <div class="medium-8 adjust--width">
      <img v-if="codeFor == 'android'" src="~/dashboard/assets/new_images/Android_SDK_Icon.svg" alt="android-icon">
      <img v-if="codeFor == 'ios'" src="~/dashboard/assets/new_images/Apple_SDK_Icon.svg" alt="ios-icon">
      <p class="title">
        {{ title }}
      </p>
    </div>
    <div v-if="buttonText" class="medium-4 text-right">
      <woot-submit-button
        class="default"
        :button-text="buttonText"
        @click="onClick()"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    codeFor: {
      type: String,
      required: true,
    },
    link :{
      type: String,
      required: true,
    }
  },
  methods: {
    onClick() {
      window.open(this.link);
        },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables';

.settings--form--header {
  align-items: center;
  border-bottom: 1px solid $color-border;
  display: flex;
  margin-bottom: $space-normal;
  padding: $space-normal 0;

  .button {
    margin-bottom: 0;
  }

  .title {
    font-size: $font-size-default;
    margin-top: 7px;
  }
}
.adjust--width img {
    width:40px;
    margin-right: 10px;
    float: left;
}

.adjust--width p{
    display: block;
}
</style>
