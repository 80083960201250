
<template>
  <label class="switch" style="margin-bottom: 0">
    <input class="switch-input" :name="name" :id="id" :disabled="disabled" v-model="value" type="checkbox">
    <div class="switch-paddle" :for="name">
      <span class="show-for-sr">on off</span>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    isFullwidth: Boolean,
    type: String,
    size: String,
    checked: Boolean,
    name: String,
    id: String,
  },
  data() {
    return {
      value: this.checked,
    };
  },
  watch: {
    checked(newchecked, oldchecked) {
      this.value = newchecked;
    },
    value(newvalue, oldvalue) {
      this.$emit('input', newvalue);
    },
  },
};
</script>
<style lang="scss" scoped>

.switch-paddle {
    background: #fbc916;
}

input:checked ~ .switch-paddle {
    background: #1bb057;
}

</style>
