import ApiClient from '../ApiClient';

class WebChannel extends ApiClient {
  constructor() {
    super('widget/inboxes');
  }

  markSeen({ inboxId, contactId }) {
    return axios.post(`${this.url}/mark_seen`, {
      inbox_id: inboxId,
      contact_id: contactId,
    });
  }

}

export default new WebChannel();
