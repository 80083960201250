<template>
  <div v-if="currentChat.id" class="qw-conversation-box columns conversation-wrap" >
    <messages-view
      :inbox-id="inboxId"
      :is-contact-panel-open="isContactPanelOpen"
      @contactPanelToggle="onToggleContactPanel"
    />
  </div>
  <div v-else class="columns conversation-wrap" style="height: 100%; width: 100%;" >
    <empty-state />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import EmptyState from './EmptyState';
import MessagesView from './MessagesView';

export default {
  components: {
    EmptyState,
    MessagesView,
  },

  props: {
    inboxId: {
      type: [Number, String],
      default: '',
      required: false,
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    conversationClass() {
      return `medium-${
        this.isContactPanelOpen ? '6' : '8'
      } columns conversation-wrap`;
    },
  },
  methods: {
    onToggleContactPanel() {
      this.$emit('contactPanelToggle');
    },
  },
};
</script>
