class MessageFormatter {
  constructor(message) {
    this.message = message || '';
  }

  formatMessage() {
    const linkifiedMessage = this.linkify();
    return linkifiedMessage.replace(/\n/g, '<br>');
  }

  linkify() {
    if (!this.message) {
      return '';
    }
    var urlRegex = /(https?:\/\/[^"'\s]+)/g;

    try {
      return this.message.replace(
        urlRegex,
        url => {
          var output = this.message.match(/<a\s+(?:[^>]*?\s+)?href=(\\?["'])(https?.*)\1/);
          if (output != null) {
            return url;
          } else {
            return `<a rel="noreferrer noopener nofollow" href="${url}" target="_blank">${url}</a>`
          }
        }
      );
    } catch (error) {
      return this.message.replace(
        urlRegex,
        url => `<a rel="noreferrer noopener nofollow" href="${url}" target="_blank">${url}</a>`
      );
    }
  }

  get formattedMessage() {
    return this.formatMessage();
  }
}

export default MessageFormatter;
