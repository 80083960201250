<template>
  <div class="scrollable-div" style="height: calc(100% - 127px);">
    <div class="setting-form">
      <div class="form-field">
        <div class="form-label">{{ $t('PROFILE_SETTINGS.FORM.PROFILE_IMAGE.LABEL') }}</div>
        <div class="form-input">
          <avatar-uploader
            :label="$t('PROFILE_SETTINGS.FORM.PROFILE_IMAGE.LABEL')"
            :src="profile_pic"
            :username="currentUser.name"
            @change="handleImageUpload"
          />
        </div>
      </div>
      <div class="form-field">
        <div class="form-label">{{ $t('PROFILE_SETTINGS.FORM.NAME.LABEL') }}</div>
        <div class="form-input">
          <input
            class="field"
            type="text"
            v-model="name"
            :placeholder="$t('PROFILE_SETTINGS.FORM.NAME.PLACEHOLDER')"
          >
        </div>
      </div>
      <div class="form-field">
        <div class="form-label">{{ $t('PROFILE_SETTINGS.FORM.EMAIL.LABEL') }}</div>
        <div class="form-input">
          <input
            class="field"
            type="text"
            v-model="email"
            :placeholder="$t('PROFILE_SETTINGS.FORM.EMAIL.PLACEHOLDER')"
            disabled
          >
        </div>
      </div>
    </div>
    <div class="setting-form-actions">
      <div class="form-button blue m-r-24 button-fixed-width" @click="updateUser($event)">Update</div>
      <div class="form-button grey button-fixed-width" @click="cancelUpdateUser($event)">Cancel</div>
    </div>
  </div>
</template>

<script>
/* global bus */
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import AvatarUploader from './AvatarUploader.vue';

export default {
  components: {
    AvatarUploader,
  },
  data() {
    return {
      profile_pic: '',
      name: '',
      email: '',
      isUpdating: false,
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      minLength: minLength(6),
    },
    passwordConfirmation: {
      minLength: minLength(6),
      isEqPassword(value) {
        if (value !== this.password) {
          return false;
        }
        return true;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
    }),
  },
  watch: {
    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId) {
        this.initializeUser();
      }
    },
  },
  mounted() {
    if (this.currentUserId) {
      this.initializeUser();
    }
  },
  methods: {
    initializeUser() {
      this.name = this.currentUser.name;
      this.email = this.currentUser.email;
      this.profile_pic = this.currentUser.profile_pic;
    },
    cancelUpdateUser() {
      this.initializeUser();
    },
    async updateUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        bus.$emit('newToastMessage', this.$t('PROFILE_SETTINGS.FORM.ERROR'));
        return;
      }
      this.isUpdating = true;
      try {
        var response = await this.$store.dispatch('updateProfile', {
          name: this.name,
          email: this.email,
          profile_pic: this.profile_pic || '',
        });
        this.isUpdating = false;
      } catch (error) {
        console.log(error);

        this.isUpdating = false;
      }
    },
    handleImageUpload({ url }) {
      this.profile_pic = url;
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings {
  padding: 24px;
  overflow: auto;
}

.profile--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;
  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }
  .small-9 {
    padding: $space-normal;
  }
}
// input:disabled{
//   background: #efefef;
// }
</style>