import { Line } from 'vue-chartjs';

const fontFamily =
  '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    labels: {
      fontFamily,
    },
    onHover: function(e) {
      e.target.style.cursor = 'pointer';
    }
  },
  hover: {
    onHover: function(e) {
       var point = this.getElementAtEvent(e);
       if (point.length) e.target.style.cursor = 'pointer';
       else e.target.style.cursor = 'default';
    }
 },
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {

          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
              label += ': ';
          }

          var inputSeconds = tooltipItem.yLabel;
          const Days = Math.floor( inputSeconds / (60 * 60 * 24) );
          const Hour = Math.floor((inputSeconds % (60 * 60 * 24)) / (60 * 60));
          const Minutes = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) / 60 );
          const Seconds = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) % 60 );
          let ddhhmmss  = '';
          if (Days > 0){
              ddhhmmss += Days + ' d ';
          }
          if (Hour > 0){
              ddhhmmss += Hour + ' h ';
          }
    
          if (Minutes > 0){
              ddhhmmss += Minutes + ' m ';
          }
  
          if (Seconds > 0){
              ddhhmmss += Seconds + ' s ';
          }

          label += (ddhhmmss || 0);
          return label;
      }
    }
  },
  scales: {
    xAxes: [
      {
        barPercentage: 1,
        ticks: {
          fontFamily,
          autoSkip: true,
          maxTicksLimit: 20
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        type: 'linear',
        distribution: 'series',
        ticks: {
          callback: value => {
            if (value) {
              var inputSeconds = value;
              const Days = Math.floor( inputSeconds / (60 * 60 * 24) );
              const Hour = Math.floor((inputSeconds % (60 * 60 * 24)) / (60 * 60));
              const Minutes = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) / 60 );
              const Seconds = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) % 60 );
              let ddhhmmss  = '';
              if (Days > 0){
                ddhhmmss += Days + ' d ';
              }
              if (Hour > 0){
                  ddhhmmss += Hour + ' h ';
              }
        
              if (Minutes > 0){
                  ddhhmmss += Minutes + ' m ';
              }
      
              if (Seconds > 0){
                  ddhhmmss += Seconds + ' s ';
              }
              return ddhhmmss;
            } else {
              return '0s';
            }

          },
          fontFamily,
        },
        gridLines: {
          display: true,
          color: "#f5f5f5"
        },
      },
    ],
  },
};

export default {
  extends: Line,
  props: ['collection'],
  mounted() {
    this.renderChart(this.collection, chartOptions);
  },
};
