/* global axios */

import endPoints from './endPoints';

export default {
  getAccountReports(metric, from, to, inboxId) {
    const { url } = endPoints('reports').account(metric, from, to, inboxId);
    return axios.get(url);
  },
  getAgentReports(metric, from, to, agentId) {
    const { url } = endPoints('reports').agent(metric, from, to, agentId);
    return axios.get(url);
  },
  getAgentSummary(accountId, from, to, agentId) {
    const { url } = endPoints('reports').agentSummary(accountId, from, to, agentId);
    return axios.get(url);
  },
  getAccountSummary(accountId, from, to, inboxId) {
    const urlData = endPoints('reports').accountSummary(accountId, from, to, inboxId);
    return axios.get(urlData.url);
  },
  getTeamStatistics(accountId, from, to, inboxId) {
    const urlData = endPoints('reports').teamStatistics(accountId, from, to, inboxId);
    return axios.get(urlData.url);
  },
  getResponseTimeStatistics(accountId, from, to, inboxId) {
    const urlData = endPoints('reports').responseTimeStatistics(accountId, from, to, inboxId);
    return axios.get(urlData.url);
  },
  getLabelList(accountId, from, to, inboxId, identifier) {
    const urlData = endPoints('reports').accountlabelList(accountId, from, to, inboxId, identifier);
    return axios.get(urlData.url);
  },
  getLabelStatistics(accountId, from, to, inboxId, identifier) {
    const urlData = endPoints('reports').labelStatistics(accountId, from, to, inboxId, identifier);
    return axios.get(urlData.url);
  }
};
