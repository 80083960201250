<template>
  <div v-if="false">
    <div style="position: relative">
      <div v-if="dropdownShow" v-on-clickaway="onToggleDropdown" class="qw-dropdown-menu-items">
        <div
          class="conversation-status-filter-element"
          v-for="item in $t('CHAT_LIST.CHAT_STATUS_ITEMS')"
          :key="item['VALUE']"
          @click="onSelect(item['VALUE'])"
        >
          <img src="~/assets/new_images/Open_Icon_Small.svg" v-if="item['TEXT'] == 'Open'">
          <img src="~/assets/new_images/Resolved_Icon_Small.svg" v-else>
          <div class="title">{{item['TEXT']}}</div>
        </div>
      </div>
    </div>
    <div class="conversation-status-filter" @click="onToggleDropdown">
      <img src="~/assets/new_images/Open_Icon_Small.svg" v-if="activeIndex == 0">
      <img src="~/assets/new_images/Resolved_Icon_Small.svg" v-else>
      <div class="title">{{$t('CHAT_LIST.CHAT_STATUS_ITEMS')[activeIndex]['TEXT']}}</div>
      <i class="fa fa-chevron-down" aria-hidden="true" style="font-size: 8px; color:#1A1C1D;"></i>
    </div>
  </div>
  <div v-else>
    <div style="position: relative">
      <div v-if="dropdownShow" v-on-clickaway="onToggleDropdown" class="qw-dropdown-menu-items">
        <div style="width: 100%; padding-bottom: 5px; border-bottom: 1px solid #EFEEF0;">
          <div
            :class="['conversation-status-filter-element' , {'active': (activeIndex == index) }]"
            v-for="(item, index) in $t('CHAT_LIST.CHAT_STATUS_ITEMS')"
            :key="item['VALUE']"
            @click="onSelect(item['VALUE'])"
          >
            <div class="title">{{item['TEXT']}}</div>
          </div>
        </div>
        <div style="width: 100%; padding-top: 5px; border-bottom: 1px solid #EFEEF0;">
          <div
            :class="['conversation-status-filter-element' , {'active': (activeAssigneeTab == index) }]"
            v-for="(item, index) in assigneeTabItems"
            :key="item.id"
            @click="onAssigneeTabChange(item, index)"
          >
            <div class="title display-flex-space-between">
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
        <div style="width: 100%; padding-top: 5px; ">
          <div
            v-for="(item) in $t('CHAT_LIST.CONVERSATION_ORDER')"
            :class="['conversation-status-filter-element' , {'active': (conversationOrder == item['VALUE']) }]"
            :key="item['VALUE']"
            @click="onConversationOrderChange(item['VALUE'])"
          >
            <div class="title">{{item['TEXT']}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="conversation-status-filter" @click="onToggleDropdown">
      <div
        class="title"
      >{{activeAssigneeTabName + ', ' + $t('CHAT_LIST.CHAT_STATUS_ITEMS')[activeIndex]['TEXT']+ ', ' + (this.conversationOrder == 1 ? 'Latest' : 'Oldest') }}</div>
      <i class="fa fa-chevron-down" aria-hidden="true" style="font-size: 8px; color:#1A1C1D;"></i>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import wootConstants from '../../../constants';

export default {
  mixins: [clickaway],
  data: () => ({
    activeIndex: 0,
    dropdownShow: false,
    activeAssigneeTab: 0,
    conversationOrder: wootConstants.CONVERSATION_SORT.DESC,
    activeAssigneeTabName: 'Mine',
  }),
  props: {
    convStats: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$store.dispatch('setChatFilter', this.activeIndex);
  },
  computed: {
    assigneeTabItems() {
      return this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS').map((item, index) => ({
        id: index,
        name: item.NAME,
        count: this.convStats[item.KEY] || 0,
      }));
    },
  },
  methods: {
    onToggleDropdown() {
      this.dropdownShow = !this.dropdownShow;
    },
    onSelect(value) {
      this.activeIndex = value;
      this.$store.dispatch('setChatFilter', this.activeIndex);
      this.$emit('statusFilterChange', this.activeIndex);
      this.dropdownShow = !this.dropdownShow;
    },
    onTabChange() {
      this.$store.dispatch('setChatFilter', this.activeIndex);
      this.$emit('statusFilterChange', this.activeIndex);
    },
    onConversationOrderChange(index) {
      this.conversationOrder = index;
      this.$emit('conversationOrderChange', this.conversationOrder);
      this.dropdownShow = !this.dropdownShow;
    },
    onAssigneeTabChange(item, index) {
      this.activeAssigneeTab = index;
      this.activeAssigneeTabName = item.name;
      this.$emit('onAssignmentTabChange', this.activeAssigneeTab);
      this.dropdownShow = !this.dropdownShow;
    },
  },
};
</script>

<style scoped lang="scss">
.qw-dropdown-menu-items {
  position: absolute;
  top: 3.5rem;
  background: white;
  border: 1px solid #d4d8dc;
  z-index: 999;
  width: 250px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  box-shadow: 0px 0px 8px #1a1c1d29;
}

.conversation-status-filter-element {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  cursor: pointer;

  img {
    margin-right: 4px;
  }

  .title {
    width: 100%;
    color: inherit;
    font-size: 14px;
  }
}

.conversation-status-filter-element:hover {
  background: #3970f1;

  .title {
    color: white;
  }

  img {
    filter: brightness(0) invert(1);
  }
}
</style>