<template>
  <div class="medium-12 column login">
    <div class="text-center medium-12 login__hero align-self-top">
      <img :src="main_logo_url" alt="logo" class="hero__logo" />
      <h2 class="hero__title">{{title}}</h2>
    </div>
    <div class="row align-center">
      <form class="login-box medium-4 column align-self-middle" v-on:submit.prevent="login()">
        <div class="column log-in-form">
          <h4 class="font-consistent">{{$t('SET_NEW_PASSWORD.TITLE')}}</h4>
          <label :class="{ 'error': $v.credentials.password.$error }">
            {{$t('LOGIN.PASSWORD.LABEL')}}
            <input
              type="password"
              v-bind:placeholder="$t('SET_NEW_PASSWORD.PASSWORD.PLACEHOLDER')"
              v-model.trim="credentials.password"
              @input="$v.credentials.password.$touch"
            />
            <span
              class="message"
              v-if="$v.credentials.password.$error"
            >{{$t('SET_NEW_PASSWORD.PASSWORD.ERROR')}}</span>
          </label>
          <label :class="{ 'error': $v.credentials.confirmPassword.$error }">
            {{$t('SET_NEW_PASSWORD.CONFIRM_PASSWORD.LABEL')}}
            <input
              type="password"
              v-bind:placeholder="$t('SET_NEW_PASSWORD.CONFIRM_PASSWORD.PLACEHOLDER')"
              v-model.trim="credentials.confirmPassword"
              @input="$v.credentials.confirmPassword.$touch"
            />
            <span
              class="message"
              v-if="$v.credentials.confirmPassword.$error"
            >{{$t('SET_NEW_PASSWORD.CONFIRM_PASSWORD.ERROR')}}</span>
          </label>
          <woot-submit-button
            :disabled="$v.credentials.password.$invalid || $v.credentials.confirmPassword.$invalid || newPasswordAPI.showLoading"
            :button-text="$t('SET_NEW_PASSWORD.SUBMIT')"
            :loading="newPasswordAPI.showLoading"
            button-class="expanded"
            style="background : #3970f1;"
          ></woot-submit-button>
          <!-- <input type="submit" class="button " v-on:click.prevent="login()" v-bind:value="" > -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* global bus */

import { required, minLength } from 'vuelidate/lib/validators';
import Auth from '../../api/auth';
import { frontendURL } from '../../helper/URLHelper';
import WootSubmitButton from '../../components/buttons/FormSubmitButton';

export default {
  components: {
    WootSubmitButton,
  },
  props: {
    resetPasswordToken: String,
    redirectUrl: String,
    config: String,
  },
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        confirmPassword: '',
        password: '',
      },
      newPasswordAPI: {
        message: '',
        showLoading: false,
      },
      error: '',
      main_logo_url: window.chatwootConfig.custom_website.main_logo_url,
      title: window.chatwootConfig.custom_website.title,
    };
  },
  mounted() {
    // If url opened without token
    // redirect to login
    if (!this.resetPasswordToken) {
      window.location = '/';
    }
  },
  validations: {
    credentials: {
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        minLength: minLength(6),
        isEqPassword(value) {
          if (value !== this.credentials.password) {
            return false;
          }
          return true;
        },
      },
    },
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.newPasswordAPI.showLoading = false;
      bus.$emit('newToastMessage', message);
    },
    login() {
      this.newPasswordAPI.showLoading = true;
      const credentials = {
        confirmPassword: this.credentials.confirmPassword,
        password: this.credentials.password,
        resetPasswordToken: this.resetPasswordToken,
      };
      Auth.setNewPassword(credentials)
        .then(res => {
          if (res.status === 200) {
            this.showAlert(this.$t('SET_NEW_PASSWORD.API.SUCCESS_MESSAGE'));
            window.location = frontendURL('login');
          }
        })
        .catch(() => {
          this.showAlert(this.$t('SET_NEW_PASSWORD.API.ERROR_MESSAGE'));
        });
    },
  },
};
</script>
