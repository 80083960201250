// import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';

export const state = {
  searchObject: {
    searchKey: null,
    searchValue: null,
    isMessageSearchInProgress: false
  }
};

export const getters = {
  setSearchObject: $state => ( searchKey, searchValue ) => {
    $state.searchObject.searchKey = searchKey;
    $state.searchObject.searchValue = searchValue;
    return {};
  },
  getSearchObject(state) {
    return state.searchObject;
  },
  getIsMessageSearchInProgress(state) {
    return state.searchObject.isMessageSearchInProgress;
  },
  setIsMessageSearchInProgress: $state => ( data ) => {
    return $state.searchObject.isMessageSearchInProgress = data;
  },
};

export const actions = {
};

export const mutations = {
  [types.default.UPDATE_TEXT_SEARCH_STATUS](_state) {
    _state.searchObject.isMessageSearchInProgress = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
