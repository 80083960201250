import endPoints from './endPoints';

export default {
  getAccountDetails() {
    var endpointURL = endPoints('accounts').url;
    return axios.get(endpointURL);
  },
  deleteAccountRequest(accountId) {
    var endpointURL = endPoints('accounts').url;
    return axios.delete(endpointURL + '/' + accountId);
  },
  exportAccountRequest(accountId) {
    var endpointURL = endPoints('accounts').url;
    return axios.post(endpointURL + '/' + accountId + '/export_request');
  },
};
