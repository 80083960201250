<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog">
        <section class="modal-header">
            <div class="buttons" style="margin-right: 10px;">
              <span @click="zoomin"><img class="gallary-icons" src="https://quickwork-chat-files.s3.amazonaws.com/common/utils/buttons/zoom-in.png" ></span>
              <span @click="zoomout"><img class="gallary-icons" src="https://quickwork-chat-files.s3.amazonaws.com/common/utils/buttons/zoom-out.png" ></span>
              <span @click="close"> <img class="gallary-icons" src="https://quickwork-chat-files.s3.amazonaws.com/common/utils/buttons/close.png" > </span>
            </div>
        </section>
        <section class="modal-body">
          <div>
          
            <transition-group name="fade" tag="div">
              <div v-for="i in [index]" key="modal--slider">
                <img :id="sliderId+index" :src="currentImg" />
              </div>
            </transition-group>
            
            
            <button class="prev" v-if="this.images.length > 1" @click="prev"  >&#10094;</button>
            <button class="next" v-if="this.images.length > 1" @click="next"  >&#10095;</button>
            <!-- <a class="prev" v-if="this.images.length > 1" @click="prev" href="#">&#10094;</a>
            <a class="next" v-if="this.images.length > 1" @click="next" href="#">&#10095;</a> -->
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  props: ["images", "currentIndex"],
  data() {
    return {
      timer: null,
      index : null,
      sliderId : Math.random()
    };
  },
  methods: {
    close() {
      this.index = null;
      this.$emit("close");
    },
    setWidth: function(width){
      var myImg = document.getElementById(this.sliderId+this.index);
      myImg.style.width = width;
    },
    getWidth : function(){
      var myImg = document.getElementById(this.sliderId+this.index);
      var width = myImg.clientWidth;
      return width;
    },
    next: function() {
      this.setWidth("100%");
      if(this.index==this.images.length-1){
          this.index = 0;
      }
      else{
          this.index += 1;
      }
    },
    prev: function() {
      this.setWidth("100%");
      if(this.index<=0){
          this.index = this.images.length-1;
      }
      else{
          this.index -= 1;
      }
    },
    zoomin: function() {
      var currWidth = this.getWidth();
      this.setWidth(currWidth + 50 + "px");
    },
    zoomout: function() {
      var currWidth = this.getWidth();
      if (currWidth <= 300){ 
        return false
      }
      else {
        this.setWidth(currWidth - 50 + "px");
      }
    }
  },

  computed: {
    currentImg: function() {
      if(this.index==null){
        this.index = this.currentIndex;
      }
      return this.images[Math.abs(this.index) % this.images.length].url;
    }
  }
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color:rgba(0, 0, 0, 0.9);
  justify-content: center;
  align-items: center;
  display: none;
  z-index: 1024;
}

.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10000000;
  height: 100%;

}


.modal-header {
  position: fixed;
  width: 100%;
  z-index: 10000001;
  padding-right: 30px;
}

.modal-body {
  position: relative;
  margin: auto;

}

.btn-close {
  cursor: pointer;
  position: fixed;
  right: 2px;
  width: auto;
  padding:10px 15px ;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;

}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}
.buttons{
  float: right;
}
.button-white{
  color: #fff;
  padding:5px 5px 5px 10px;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}


.modal-body img {
  display: block;
  margin: auto;
  width: 98%;
  height: auto;
  margin-top:25px;
}

.fade-enter-active,
.fade-leave-active {
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

.prev,
.next {
  cursor: pointer;
  position: fixed;
  top: 50%;
  width: auto;
  padding: 20px 10px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.9);

}

/* Position the "next button" to the right */
.next {
  right: 0;
}

.prev {
  left: 0;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.fa-times-circle:hover,.fa-search-plus:hover,.fa-search-minus:hover {
  color: red;
}

.gallary-icons {
  width: 15px;
  height: 15px;
  margin: 1px;
  padding: 2px;
  background: #f9f6f6;
  border: 2px solid #f9f6f6;
}

</style>
