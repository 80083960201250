<template>
  <aside class="sidebar">
    <router-link :to="dashboardPath" replace class="logo">
      <img :src="sidebar_logo_url" :title="title" style="width:32px; height:32px;">
    </router-link>
    <div class="navigation">
      <div class="qw-nav-conversation-dropdown-selector" style="height: inherit;">
        <div style="position: relative">
          <transition name="slide-fade">
            <div
              v-if="dropdownShow"
              v-on-clickaway="onToggleDropdown"
              class="qw-dropdown-menu-items"
            >
              <div class="left-arrow"></div>
              <div
                class="conversation-agent-filter-element nav-dropdown-conversation-name"
                @click="showAllConversations()"
              >
                <router-link
                  class="inbox-item-name"
                  :to="dashboardPath"
                  replace
                  :class="[{ 'active' : (currentRoute == 'home' || (currentRoute == 'inbox_conversation' && !activeInbox.id)) }]"
                  style="color: #1A1C1D"
                >
                  <div class="title">All Conversations</div>
                </router-link>
              </div>

              <div>
                <div class="nav-dropdown-conversation-name">Inboxes</div>
                <div class="inbox-dropdown">
                  <router-link
                    class="conversation-agent-filter-element"
                    v-for="inbox in inboxSection.children"
                    :key="inbox.key"
                    :to="inbox.toState"
                  >
                    <div
                      @click="onInboxSelection(inbox)"
                      class="inbox-item-name"
                      :title="inbox.label"
                      :class="{active: (inbox.id == activeInbox.id && (currentRoute == 'inbox_dashboard' || currentRoute == 'inbox_conversation') ) }"
                    >
                      <div :title="inbox.label" class="title">
                        <span class="circle-dot"></span>
                        {{inbox.label}}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div
          class="option"
          style="cursor: pointer"
          @click="onToggleDropdown"
          :class="[{ 'active' : (currentRoute == 'home' || currentRoute == 'inbox_conversation' || currentRoute == 'inbox_dashboard') } ]"
        >
          <div class="image-container" title="Conversations | Click to view all inboxes">
            <img src="~dashboard/assets/new_images/Nav_Conversations_Black.svg">
          </div>
        </div>
      </div>
      <router-link
        :to="reportsPath"
        replace
        v-if="currentUser.role == 'administrator'"
        style="height: inherit;"
      >
        <div
          @click="activeInbox = {}"
          class="option"
          :class="[{ 'active' : currentRoute == 'settings_account_reports'  } ]"
        >
          <div class="image-container" title="Reports">
            <img src="~dashboard/assets/new_images/Nav_Reports.svg">
          </div>
        </div>
      </router-link>
      <router-link :to="settingsPath" replace style="height: inherit;">
        <div
          @click="activeInbox = {}"
          class="option"
          :class="[{ 'active' : isInboxTabActive } ]"
          style="border-bottom: 1px solid #EFEEF0;"
        >
          <div class="image-container" title="Settings">
            <img src="~dashboard/assets/new_images/Nav_Settings.svg">
          </div>
        </div>
      </router-link>
      <div style="display: none;">
        <div class="option" style="display: block;">
          <div style="display: flex;align-items: center;">
            <div
              :class="[ 'image-container', { 'image-container-active' : currentRoute == 'inbox_dashboard' || (currentRoute == 'inbox_conversation' && activeInbox.id) } ]"
            >
              <img src="~dashboard/assets/new_images/Nav_Inboxes.svg">
            </div>
            <div
              :class="[ 'title', { 'active' : currentRoute == 'inbox_dashboard' || (currentRoute == 'inbox_conversation' && activeInbox.id) } ]"
              style="width: 70%;"
            >
              <div style="display: flex;justify-content: space-between;">
                <div>Inboxes</div>
                <div>
                  <i
                    class="fa fa-chevron-down"
                    aria-hidden="true"
                    style="font-size: 10px; color:#1A1C1D;"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="inbox-dropdown">
            <router-link
              v-for="inbox in inboxSection.children"
              :key="inbox.key"
              :to="inbox.toState"
            >
              <div
                @click="onInboxSelection(inbox)"
                class="inbox-item-name"
                :title="inbox.label"
                :class="{active: (inbox.id == activeInbox.id && (currentRoute == 'inbox_dashboard' || currentRoute == 'inbox_conversation') ) }"
              >{{inbox.label}}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="profile">
      <div class="user-details" @click="showOptions()" title="Profile">
        <QWThumbnail :src="currentUser.profile_pic" :username="currentUser.name" size="32px" :status="getCurrentAgentAvailablityStatus()" />
      </div>
      <div style="position: relative">
        <transition name="slide-fade">
          <div v-if="showOptionsMenu" v-on-clickaway="showOptions" class="user-details-popup">
            <div class="left-arrow"></div>
            <div>
              <div
                class="user-details"
                style="padding: 8px 12px; border-bottom: 1px solid #d4d8dc; background: #fbfbfc;"
              >
                <QWThumbnail
                  :src="currentUser.profile_pic"
                  :username="currentUser.name"
                  size="40px"
                  style="margin-right: 8px;"
                  :status="getCurrentAgentAvailablityStatus()"
                />
                <div class="user-name-role">
                  <div :title="currentUser.name" class="name">{{ currentUser.name }}</div>
                  <div :title="currentUser.role" class="role">{{ currentUser.role }}</div>
                </div>
              </div>
              <div 
                style="padding: 8px 12px; border-bottom: 1px solid #d4d8dc; margin-bottom: 4px; background: #fbfbfc; display: flex; justify-content: space-between;"
              >
                <div class="title" style="color: #1A1C1D; font-size: 14px; align-self: center;">
                  <div v-if="currentUser.availability == 'active'">Active</div>
                  <div v-else>Away</div>
                </div>
                <div class="status_change_action">
                  <woot-switch
                    :checked="getCurrentAgentAvailablityStatusBoolean()"
                    @input="changeFunctionCalled"
                    v-tooltip="{ content: 'Set your status as ' + getStatusToBeUpdatedTo() + ' and opt ' + (getStatusToBeUpdatedTo() == 'active' ? 'in':'out') + ' for automatic assignment', classes: ['right', 'right-hover'] }"
                   />
                </div>
              </div>
              <router-link
                class="sidebar-agent-profile-option"
                :to="`/app/accounts/${accountId}/profile/settings`"
                @click="showOptions()"
              >
                <div class="title">{{ $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}</div>
              </router-link>
              <div
                class="sidebar-agent-profile-option"
                @click="openNewTab('https://docs.chat.quickwork.co'); showOptions();"
              >
                <div class="title">Help Docs</div>
              </div>
              <div class="sidebar-agent-profile-option" @click.prevent="logout()">
                <div class="title">Log out</div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import md5 from 'md5';
import { mixin as clickaway } from 'vue-clickaway';

import adminMixin from '../../mixins/isAdmin';
import Auth from '../../api/auth';
import SidebarItem from './SidebarItem';
import WootStatusBar from '../widgets/StatusBar';
import { frontendURL } from '../../helper/URLHelper';
import QWThumbnail from '../widgets/QWThumbnail';
import sidemenuItems from '../../i18n/default-sidebar';

export default {
  components: {
    SidebarItem,
    WootStatusBar,
    QWThumbnail,
  },
  mixins: [clickaway, adminMixin],
  props: {
    route: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOptionsMenu: false,
      activeInbox: {},
      dropdownShow: false,
      sidebar_logo_url: null,
      title: null,
      description: null,
      activeColor: "#1bb057",
      awayColor: "#fbc916",
      isAvailabilityStatusUpdating: false
    };
  },
  created() {
    this.sidebar_logo_url = window.chatwootConfig.custom_website.sidebar_logo_url;
    this.title = window.chatwootConfig.custom_website.title;
    this.description = window.chatwootConfig.custom_website.description;
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      daysLeft: 'getTrialLeft',
      subscriptionData: 'getSubscription',
      accountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxes',
    }),
    isInboxTabActive() {
      return [
        'account',
        'agent_list',
        'canned_list',
        'profile_settings_index',
        'settings_inbox_list',
        'settings_inbox_show',
        'settings_inbox_new',
        'settings_inboxes_page_channel',
        'settings_inboxes_add_agents',
        'settings_inbox_finish',
      ].includes(this.currentRoute);
    },
    accessibleMenuItems() {
      // get all keys in menuGroup
      const groupKey = Object.keys(sidemenuItems);

      let menuItems = [];
      // Iterate over menuGroup to find the correct group
      for (let i = 0; i < groupKey.length; i += 1) {
        const groupItem = sidemenuItems[groupKey[i]];
        // Check if current route is included
        const isRouteIncluded = groupItem.routes.includes(this.currentRoute);
        if (isRouteIncluded) {
          menuItems = Object.values(groupItem.menuItems);
        }
      }

      if (!window.chatwootConfig.billingEnabled) {
        menuItems = this.filterBillingRoutes(menuItems);
      }

      return this.filterMenuItemsByRole(menuItems);
    },
    currentRoute() {
      return this.$store.state.route.name;
    },
    shouldShowInboxes() {
      return sidemenuItems.common.routes.includes(this.currentRoute);
    },
    inboxSection() {
      return {
        icon: 'ion-folder',
        label: 'Inboxes',
        hasSubMenu: true,
        newLink: true,
        key: 'inbox',
        cssClass: 'menu-title align-justify',
        toState: frontendURL('settings/inboxes'),
        toStateName: 'settings_inbox_list',
        children: this.inboxes.map(inbox => ({
          id: inbox.id,
          label: inbox.name,
          labels: inbox.labels,
          toState: frontendURL(`inbox/${inbox.id}`),
        })),
      };
    },
    reportsPath() {
      return frontendURL('reports');
    },
    settingsPath() {
      return frontendURL('settings');
    },
    dashboardPath() {
      return frontendURL('dashboard');
    },
    shouldShowStatusBox() {
      return (
        window.chatwootConfig.billingEnabled &&
        (this.subscriptionData.state === 'trial' ||
          this.subscriptionData.state === 'cancelled')
      );
    },
    statusBarClass() {
      if (this.subscriptionData.state === 'trial') {
        return 'warning';
      }
      if (this.subscriptionData.state === 'cancelled') {
        return 'danger';
      }
      return '';
    },
    trialMessage() {
      return `${this.daysLeft} ${this.$t('APP_GLOBAL.TRIAL_MESSAGE')}`;
    },
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
    if (this.$store.state.route.params.inbox_id) {
      this.activeInbox = {};
      this.activeInbox.id = this.$store.state.route.params.inbox_id;
    }
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async updateAvailabilityStatus(){
      try {
        var status = this.getStatusToBeUpdatedTo();
        await this.$store.dispatch('changeAvailabilityStatus', { status } )
          .then(response => {
            this.showAlert('Status marked as '+ status+ ' successfully.');
            return;
          })
          .catch(error => {
            this.showAlert('Error: Could not update the status. Please try again later.');
            return;
          });
      } catch (error) {
        console.error(error);
      }
    },
    getStatusToBeUpdatedTo() {
      return this.getCurrentAgentAvailablityStatusBoolean() == true ? 'away' : 'active';
    },
    getCurrentAgentAvailablityStatus() {
      return this.getCurrentAgentAvailablityStatusBoolean() == true ? 'active' : 'away';
    },
    getCurrentAgentAvailablityStatusBoolean() {
      if (this.currentUser.hasOwnProperty('availability')) {
        return this.currentUser.availability == 'active' ? true : false;
      } else {
        return true;
      }
    },
    changeFunctionCalled(changedStatus){
      if (this.getCurrentAgentAvailablityStatusBoolean() != changedStatus) {
        this.updateAvailabilityStatus();
      }
    },
    openNewTab(link) {
      window.open(link);
    },
    showAllConversations() {
      this.activeInbox = {};
      this.dropdownShow = false;
      this.$store.dispatch('setActiveInbox', null);
    },
    onToggleDropdown() {
      this.dropdownShow = !this.dropdownShow;
    },
    onInboxSelection(inbox) {
      this.onToggleDropdown();
      this.activeInbox = inbox;
      this.$store.dispatch('setActiveInbox', this.activeInbox.id);
    },
    gravatarUrl() {
      const hash = md5(this.currentUser.email);
      return `${window.WootConstants.GRAVATAR_URL}${hash}?default=404`;
    },
    filterBillingRoutes(menuItems) {
      return menuItems.filter(
        menuItem => !menuItem.toState.includes('billing')
      );
    },
    filterMenuItemsByRole(menuItems) {
      if (!this.currentUser.name) {
        return [];
      }
      const { role } = this.currentUser;
      return menuItems.filter(
        menuItem =>
          window.roleWiseRoutes[role].indexOf(menuItem.toStateName) > -1
      );
    },
    logout() {
      Auth.logout();
    },

    showOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid #e0e6ed;
}

.qw-nav-conversation-dropdown-selector {
  a {
    color: #1a1c1d;
  }

  .inbox-dropdown {
    font-size: 16px;
    line-height: 24px;
    color: #1a1c1d;
    max-height: 288px;
    overflow-y: auto;
    // margin-left: 20px;
  }

  .inbox-dropdown::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  .inbox-dropdown::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #adabab;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .nav-dropdown-conversation-name {
    padding: 6px 12px;
    height: inherit;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }

  .inbox-item-name {
    color: #697181;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .qw-dropdown-menu-items {
    position: absolute;
    // top: 5.5rem;
    left: 50px;
    background: white;
    border: 1px solid #d4d8dc;
    z-index: 999;
    width: max-content;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 8px #1a1c1d29;
    min-width: -webkit-fill-available;
    max-height: 400px;

    // transition: ease-in 0.3s;
    // transition-delay: 0.3s;
    // transition: all .3s ease-in-out;
    // opacity: 0;
    // transition: max-width 2s, max-height 4s;

    .left-arrow {
      transform: translate3d(-6px, 15px, 0px) rotate(45deg);
      z-index: -1;
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: white;
      border-left: 1px solid #d4d8dc;
      border-bottom: 1px solid #d4d8dc;
    }
  }

  .conversation-agent-filter {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid #dedddf;
    border-radius: 4px;
    height: 32px;
    color: #1a1c1d;

    img {
      margin-right: 4px;
    }

    .title {
      color: #1a1c1d;
      font-size: 14px;
      margin-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 122px;
      width: 100%;
      line-height: 24px;
    }
  }

  .conversation-agent-filter:hover {
    border-color: #3970f1;
    color: #3970f1;

    .title {
      color: #3970f1;
    }
  }

  .conversation-agent-filter-element {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    cursor: pointer;

    img {
      margin-right: 4px;
    }

    .title {
      font-size: 14px;
      margin-right: 8px;
      line-height: 24px;
    }
  }

  .conversation-agent-filter-element:hover {
    background: #3970f1;

    .avatar-container {
      background: white;
      color: #3970f1;
    }

    .title {
      color: white;
    }
    .inbox-item-name {
      color: white !important;
    }
    .circle-dot {
      background-color: white;
    }
  }
}
</style>
