export default {
  AUTHENTICATE: 'AUTHENTICATE',
  CLEAR_USER: 'LOGOUT',
  SET_CURRENT_USER: 'SET_CURRENT_USER',

  //Account
  SET_ACCOUNT_UI_FLAG: 'SET_ACCOUNT_UI_FLAG',

  // Chat List
  RECEIVE_CHAT_LIST: 'RECEIVE_CHAT_LIST',
  SET_ALL_CONVERSATION: 'SET_ALL_CONVERSATION',
  EMPTY_ALL_CONVERSATION: 'EMPTY_ALL_CONVERSATION',
  SET_CONV_TAB_META: 'SET_CONV_TAB_META',
  CLEAR_LIST_LOADING_STATUS: 'CLEAR_LIST_LOADING_STATUS',
  SET_LIST_LOADING_STATUS: 'SET_LIST_LOADING_STATUS',
  SET_IS_ALL_CONVERSATION_LOADED_STATUS: 'SET_IS_ALL_CONVERSATION_LOADED_STATUS',
  SET_ALL_MESSAGES_LOADED: 'SET_ALL_MESSAGES_LOADED',
  SET_ALL_NEXT_MESSAGES_LOADED: 'SET_ALL_NEXT_MESSAGES_LOADED',
  CLEAR_ALL_MESSAGES_LOADED: 'CLEAR_ALL_MESSAGES_LOADED',
  CHANGE_CHAT_STATUS_FILTER: 'CHANGE_CHAT_STATUS_FILTER',
  UPDATE_ASSIGNEE: 'UPDATE_ASSIGNEE',

  // Active chat
  CURRENT_CHAT_WINDOW: 'CURRENT_CHAT_WINDOW',
  CLEAR_CURRENT_CHAT_WINDOW: 'CLEAR_CURRENT_CHAT_WINDOW',
  APPEND_MESSAGES: 'APPEND_MESSAGES',
  CLEAR_ALL_MESSAGES: 'CLEAR_ALL_MESSAGES',
  RESOLVE_CONVERSATION: 'RESOLVE_CONVERSATION',
  ADD_CONVERSATION: 'ADD_CONVERSATION',
  REOPEN_CONVERSATION: 'REOPEN_CONVERSATION',
  RESOLVE_CONVERSATION_D: 'RESOLVE_CONVERSATION_D',
  ADD_CONVERSATION_LABEL: 'ADD_CONVERSATION_LABEL',
  REMOVE_CONVERSATION_LABEL: 'REMOVE_CONVERSATION_LABEL',
  SEND_MESSAGE: 'SEND_MESSAGE',
  ASSIGN_AGENT: 'ASSIGN_AGENT',
  SET_CHAT_META: 'SET_CHAT_META',
  ADD_MESSAGE: 'ADD_MESSAGE',
  MARK_SEEN: 'MARK_SEEN',
  MARK_MESSAGE_READ: 'MARK_MESSAGE_READ',
  FB_TYPING: 'FB_TYPING',
  WEB_TYPING: 'WEB_TYPING',
  SET_PREVIOUS_CONVERSATIONS: 'SET_PREVIOUS_CONVERSATIONS',
  SET_NEXT_CONVERSATIONS: 'SET_NEXT_CONVERSATIONS',
  SET_ACTIVE_INBOX: 'SET_ACTIVE_INBOX',
  SET_ACTIVE_LABEL: 'SET_ACTIVE_LABEL',

  // Inboxes
  SET_INBOXES_UI_FLAG: 'SET_INBOXES_UI_FLAG',
  SET_INBOXES: 'SET_INBOXES',
  SET_INBOX_ITEM: 'SET_INBOX_ITEM',
  ADD_INBOXES: 'ADD_INBOXES',
  EDIT_INBOXES: 'EDIT_INBOXES',
  DELETE_INBOXES: 'DELETE_INBOXES',

  // Agent
  SET_AGENT_FETCHING_STATUS: 'SET_AGENT_FETCHING_STATUS',
  SET_AGENT_CREATING_STATUS: 'SET_AGENT_CREATING_STATUS',
  SET_AGENT_UPDATING_STATUS: 'SET_AGENT_UPDATING_STATUS',
  SET_AGENT_DELETING_STATUS: 'SET_AGENT_DELETING_STATUS',
  SET_AGENTS: 'SET_AGENTS',
  ADD_AGENT: 'ADD_AGENT',
  EDIT_AGENT: 'EDIT_AGENT',
  DELETE_AGENT: 'DELETE_AGENT',

  // Canned Response
  SET_CANNED_UI_FLAG: 'SET_CANNED_UI_FLAG',
  SET_CANNED: 'SET_CANNED',
  ADD_CANNED: 'ADD_CANNED',
  EDIT_CANNED: 'EDIT_CANNED',
  DELETE_CANNED: 'DELETE_CANNED',

  // Contacts
  SET_CONTACT_UI_FLAG: 'SET_CONTACT_UI_FLAG',
  SET_CONTACT_ITEM: 'SET_CONTACT_ITEM',
  SET_CONTACTS: 'SET_CONTACTS',
  EDIT_CONTACT: 'EDIT_CONTACT',
  UPDATE_CONTACT_DETAILS: 'UPDATE_CONTACT_DETAILS',
  UPDATE_CONVERSATION_CONTACT_DETAILS: 'UPDATE_CONVERSATION_CONTACT_DETAILS',

  // Contact Conversation
  SET_CONTACT_CONVERSATIONS_UI_FLAG: 'SET_CONTACT_CONVERSATIONS_UI_FLAG',
  SET_CONTACT_CONVERSATIONS: 'SET_CONTACT_CONVERSATIONS',
  
  //Conversation
  DELETE_CONVERSATION: 'DELETE_CONVERSATION',

  //Message
  UPDATE_TEXT_SEARCH_STATUS: 'UPDATE_TEXT_SEARCH_STATUS',

  // Conversation Label
  SET_CONVERSATION_LABELS_UI_FLAG: 'SET_CONVERSATION_LABELS_UI_FLAG',
  SET_CONVERSATION_LABELS: 'SET_CONVERSATION_LABELS',

  // Conversation Properties
  SET_CONVERSATION_PROPERTIES_UI_FLAG: 'SET_CONVERSATION_PROPERTIES_UI_FLAG',
  
  // Reports
  SET_ACCOUNT_REPORTS: 'SET_ACCOUNT_REPORTS',
  SET_ACCOUNT_SUMMARY: 'SET_ACCOUNT_SUMMARY',
  SET_TEAM_SUMMARY: 'SET_TEAM_SUMMARY',
  SET_RESPONSE_TIME_SUMMARY: 'SET_RESPONSE_TIME_SUMMARY',
  SET_LABEL_SUMMARY: 'SET_LABEL_SUMMARY',
  RESET_LABEL_SUMMARY: 'RESET_LABEL_SUMMARY',
  
  SET_INCOMING_MESSAGES_REPORTS: 'SET_INCOMING_MESSAGES_REPORTS',
  SET_OUTGOING_MESSAGES_REPORTS: 'SET_OUTGOING_MESSAGES_REPORTS',
  SET_BOT_MESSAGES_REPORTS: 'SET_BOT_MESSAGES_REPORTS',
  SET_CONVERSATIONS_REPORTS: 'SET_CONVERSATIONS_REPORTS',
  SET_OPEN_REPORTS: 'SET_OPEN_REPORTS',
  SET_RESOLUTION_REPORTS: 'SET_RESOLUTION_REPORTS',
  SET_AVG_RESPONSE_TIME_REPORTS: 'SET_AVG_RESPONSE_TIME_REPORTS',
  SET_MEDIAN_RESPONSE_TIME_REPORTS: 'SET_MEDIAN_RESPONSE_TIME_REPORTS',

  TOGGLE_ACCOUNT_REPORT_LOADING: 'TOGGLE_ACCOUNT_REPORT_LOADING',
  TOGGLE_INCOMING_MESSAGES_REPORTS_LOADING : 'TOGGLE_INCOMING_MESSAGES_REPORTS_LOADING',
  TOGGLE_OUTGOING_MESSAGES_REPORTS_LOADING : 'TOGGLE_OUTGOING_MESSAGES_REPORTS_LOADING',
  TOGGLE_BOT_MESSAGES_REPORTS_LOADING : 'TOGGLE_BOT_MESSAGES_REPORTS_LOADING',
  TOGGLE_CONVERSATIONS_REPORTS_LOADING : 'TOGGLE_CONVERSATIONS_REPORTS_LOADING',
  TOGGLE_OPEN_REPORTS_LOADING : 'TOGGLE_OPEN_REPORTS_LOADING',
  TOGGLE_RESOLUTION_REPORTS_LOADING : 'TOGGLE_RESOLUTION_REPORTS_LOADING',
  TOGGLE_AVG_RESPONSE_TIME_REPORTS_LOADING : 'TOGGLE_AVG_RESPONSE_TIME_REPORTS_LOADING',
  TOGGLE_MEDIAN_RESPONSE_TIME_REPORTS_LOADING : 'TOGGLE_MEDIAN_RESPONSE_TIME_REPORTS_LOADING',

  // Billings
  SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
  TOGGLE_SUBSCRIPTION_LOADING: 'TOGGLE_SUBSCRIPTION_LOADING',

  // Conversation Metadata
  SET_CONVERSATION_METADATA: 'SET_CONVERSATION_METADATA',

  // LABEL Report
  ADDED_LABEL_REPORT_DATA: 'ADDED_LABEL_REPORT_DATA'
};
