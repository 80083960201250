import SidebarColumn from '../SidebarColumn';
import Index from './Index.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('settings/account'),
      roles: ['administrator'],
      component: SidebarColumn,
      props: {
        headerTitle: 'SIDEBAR_ITEMS.ACCOUNT_SETTINGS',
        icon: 'ion-gear-a',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'account',
          component: Index,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
