<template>
  <div>
    <form v-if="!hasSubmitted && payload.elements" class="button-template-group" @submit.prevent="onSubmit()">
      <div class="button-template-layout">
        <div class="margin-top-4">
          <div class="padding_6_12">
          </div>
          <div class="template-button">
            <div v-for="(item, index) in payload.elements.buttons" :key="item.id" @click="trigger(item)" role="button">
              <a class="template-button-value">{{item.title}}</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: {
    Spinner,
  },
  props: {
    contentType: String,
    messageId: {
      type: Number,
      required: true,
    },
    messageContentAttributes: {
      type: Object,
      default: () => {},
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contact/getUIFlags',
      widgetColor: 'appConfig/getWidgetColor',
    }),
    hasSubmitted() {
      return (
        this.messageContentAttributes &&
        this.messageContentAttributes.submitted_email
      );
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    postback(item) {
      this.$store.dispatch('conversation/sendMessage', {
        content : item.title,
        content_type: this.contentType,
        content_payload : item.payload
      });
    },
    trigger(item) {
      if (item.type == 'web_url') {
        window.open(item.url);
      } else if (item.type == 'postback') {
        this.postback(item);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables.scss';

.button-template {
  border-top-left-radius: 1.3em;
  border-bottom-left-radius: 1.3em;
  float: left;
  overflow: visible;
  border-bottom-right-radius: 1.3em;
  border-top-right-radius: 1.3em;
  clear: both;
  margin-top: 1px;
  position: relative;
  text-decoration: none;
  width: 100%;
  z-index: 0;
  outline: none;
}

.button-template-layout {
  border-top-left-radius: 1.3em;
  border-top-right-radius: 1.3em;
  overflow: hidden;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 1);
  line-height: 1.28em;
}

.margin-top-4 {
  margin-top: -4px;
}

.padding_6_12 {
  padding: 12px 12px 0 12px;
}

.padding-bottom {
  padding-bottom: 12px;
}

.button-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  position: relative;
  // font-weight: 600;
}

.button-text-space {
  visibility: hidden;
  white-space: pre-wrap;
}

.template-button {
  line-height: 32px;
  text-align: left;
}

.template-button-value {
  color: #0084ff;
  font-weight: 500;
  padding: 0 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
}

.template-button div a {
  text-decoration: none;
  cursor: pointer;
}
</style>
