/* eslint arrow-body-style: ["error", "always"] */

const endPoints = {
  resetPassword: {
    url: 'auth/password',
  },
  register: {
    url: 'api/v1/accounts.json',
  },
  validityCheck: {
    url: '/auth/validate_token',
  },
  profileUpdate: {
    url: '/api/v1/profile',
  },
  changeAvailabilityStatus: {
    url: '/api/v1/profile/availability',
  },
  accounts: {
    url: '/api/v1/accounts',
  },
  logout: {
    url: 'auth/sign_out',
  },

  me: {
    url: 'api/v1/conversations.json',
    params: { type: 0, page: 1 },
  },

  getInbox: {
    url: 'api/v1/conversations.json',
    params: { inbox_id: null },
  },

  fetchFacebookPages: {
    url: 'api/v1/callbacks/get_facebook_pages.json',
    params: { omniauth_token: '' },
  },

  reports: {
    account(metric, from, to, inboxId) {
      if (inboxId) {
        return {
          url: `/api/v1/reports/account?metric=${metric}&since=${from}&to=${to}&inbox_id=${inboxId}`,
        }; 
      } else {
        return {
          url: `/api/v1/reports/account?metric=${metric}&since=${from}&to=${to}`,
        };
      }
    },
    agent(metric, from, to, agentId) {
      return {
        url: `/api/v1/reports/agent?metric=${metric}&since=${from}&to=${to}&id=${agentId}`,
      };
    },
    agentSummary(accountId, from, to, agentId) {
      return {
        url: `/api/v1/reports/${accountId}/agent_summary?since=${from}&to=${to}&id=${agentId}`,
      };
    },
    accountSummary(accountId, from, to, inboxId) {
      if (inboxId) {
        return {
          url: `/api/v1/reports/${accountId}/account_summary?since=${from}&to=${to}&inbox_id=${inboxId}`,
        }; 
      } else {
        return {
          url: `/api/v1/reports/${accountId}/account_summary?since=${from}&to=${to}`,
        };
      }
    },
    teamStatistics(accountId, from, to, inboxId) {
      if (inboxId) {
        return {
          url: `/api/v1/reports/${accountId}/team_statistics?since=${from}&to=${to}&inbox_id=${inboxId}`,
        }; 
      } else {
        return {
          url: `/api/v1/reports/${accountId}/team_statistics?since=${from}&to=${to}`,
        };
      }
    },
    responseTimeStatistics(accountId, from, to, inboxId) {
      if (inboxId) {
        return {
          url: `/api/v1/reports/${accountId}/response_statistics?since=${from}&to=${to}&inbox_id=${inboxId}`,
        }; 
      } else {
        return {
          url: `/api/v1/reports/${accountId}/response_statistics?since=${from}&to=${to}`,
        };
      }
    },
    accountlabelList(accountId, from, to, inboxId, identifier) {
      if (inboxId) {
        return {
          url: `/api/v1/reports/${accountId}/label_list?since=${from}&to=${to}&inbox_id=${inboxId}&identifier=${identifier}`,
        }; 
      } else {
        return {
          url: `/api/v1/reports/${accountId}/label_list?since=${from}&to=${to}&identifier=${identifier}`,
        };
      }
    },
    labelStatistics(accountId, from, to, inboxId, identifier) {
      if (inboxId) {
        return {
          url: `/api/v1/reports/${accountId}/label_statistics?since=${from}&to=${to}&inbox_id=${inboxId}&identifier=${identifier}`,
        }; 
      } else {
        return {
          url: `/api/v1/reports/${accountId}/label_statistics?since=${from}&to=${to}&identifier=${identifier}`,
        };
      }
    },
  },

  subscriptions: {
    get() {
      return {
        url: '/api/v1/subscriptions',
      };
    },
  },
};

export default page => {
  return endPoints[page];
};
