<template>
  <div class="block_report-single-card-parent">
    <div class="block_report-card_1" >
      <div class="block_stat_heading">
        <div v-if="showMedian">
          {{heading.median}}
        </div>
        <div v-else>
          {{heading.avg}}
        </div>
        <div>
          <div class="conversation-header-menu">
            <div @click="showOptions" class="report-block-button" >
              <div class="conversation-header-menu-part"></div>
            </div>
            <div style="position: relative">
                <div v-if="showOptionsMenu" v-on-clickaway="showOptions" class="main-element">
                  <div  class="conversation-header-sub-menu">
                    <ul style="margin: 0 !important;">
                      <div>
                        <div @click="changeSelection(false)"  class="conversation-header-sub-menu-element">
                          <span class="conversation-header-sub-menu-element-text"> Average</span>
                        </div>
                        <div @click="changeSelection(true)"  class="conversation-header-sub-menu-element">
                          <span class="conversation-header-sub-menu-element-text"> Median</span>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block_stat_count">
        <div v-if="showMedian">
          {{point.median}}
        </div>
        <div v-else>
          {{point.avg}}
        </div>
      </div>
      <div class="block_desc_1" >
        <div v-if="showMedian">
          {{desc.median}}
        </div>
        <div v-else>
          {{desc.avg}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],

  props: {
    heading: Object,
    point: Object,
    desc: Object,
  },

  data() {
    return {
      showOptionsMenu: false,
      showMedian: true,
    };
  },

  methods: {
    showOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
      this.confirmForDeletion = false;
    },
    changeSelection(flag) {
      this.showMedian = flag;
      this.showOptions();
    }
  }
};
</script>

<style lang="scss">

.block_report-single-card-parent {
  width: 100%;
  padding: 24px;
  border-right: 1px solid #DEDDDF;
  border: 1px solid #DEDDDF;
  border-radius: 8px;
}

.block_report-single-card-parent:first-child { 
  margin-right: 10px; 
}

.block_report-single-card-parent:last-child {
  margin-left: 10px;
}

.block_report-card.active {
  font-weight: 600 !important;
}

.block_stat_heading {
  letter-spacing: 0px;
  color: #1A1C1D;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.block_stat_count {
  letter-spacing: 0px;
  color: #1A1C1D;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 0px;
}

.block_desc_1 {
  text-align: left;
  letter-spacing: 0px;
  color: #697181;
  opacity: 1;
  font-size: 14px;
  line-height: 18px;
}

.block_color_bar {
  border-radius: 8px;
  opacity: 1;
  width: 50px;
  height: 10px;
  align-self: center;
}

.conversation-header-menu:active {
  background: #efefef;
}

.conversation-header-menu-part {
  width: 15px;
  height: 15px;
  margin: 5px;
  background-image: radial-gradient(circle, #1A1C1D 2px, transparent 2px);
  background-size: 100% 33.33%;
}

.conversation-header-menu {
    position: relative;
    align-self: center;
    border: 1px solid #DEDDDF;
    border-radius: 4px;
    width: fit-content;
    cursor: pointer;
}

.conversation-header-menu:hover {
    border-color:  #3970f1;
    
    .conversation-header-menu-part {
      background-image: radial-gradient(circle, #3970f1 2px, transparent 2px);
    }

}

.conversation-header-sub-menu {
  position: absolute;
  right: -26px;
  top: 2px;
  background: white;
  border: 1px solid #d4d8dc;
  border: 1px solid #DEDDDF;
  z-index: 999;
  width: -webkit-max-content;
  width: -moz-max-content;
  border-radius: 4px;
  padding: 5px 0px;
}

.conversation-header-sub-menu-element {
  padding: 2px 15px 2px 15px;
  font-size: 1.5rem;
  font-family: inherit;
  color: #35495e;
}

.conversation-header-sub-menu-element:hover {
  background: #3970f1;
  color: white;
  cursor: pointer;
  // border: 1px solid;
  // border-radius: 3px;
}

.conversation-header-sub-menu-element-text {
  font-size: 14px;
}

.main-element {
  position: absolute;
}
</style>