/* eslint no-console: 0 */
/* eslint no-undef: "error" */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import moment from 'moment';

export default {
  methods: {
    lastMessage(m) {
      return m.messages.last();
    },
    unreadMessagesCount(m) {
      return m.messages.filter(
        chat =>
          chat.created_at * 1000 > m.agent_last_seen_at * 1000 &&
          (chat.message_type === 0 && chat.private !== true)
      ).length;
    },
    readMessages(m) {
      return m.messages.filter(
        chat => chat.created_at * 1000 <= m.agent_last_seen_at * 1000
      );
    },
    unReadMessages(m) {
      return m.messages.filter(
        chat => chat.created_at * 1000 > m.agent_last_seen_at * 1000
      );
    },
    appendText(initial, add){
      return initial = initial + add;
    },
    getAgentName(all_agents, select_agent){
      for (let index = 0; index < all_agents.length; index++) {
        const element = all_agents[index];
        if (select_agent == element.id) {
          return element.name;
        }
      }
      return 'Bot'
    },
    exportConversation(selectedConversation, messages, account_name, currentUser, all_agents) {
      var today = new Date();
      var filename = 'Conversation_'+selectedConversation.id+'_'+today.getFullYear()+'_'+(today.getMonth()+1)+'_'+today.getDate();
      var text = 'Conversation with '+ selectedConversation.meta.sender.name;
      for (let index = 0; index < messages.length; index++) {
        const element = messages[index];
        // Start of the conversation
        if (index == 0) {
          var initialText = "\n--- Started on " + moment.utc(element.created_at, 'YYYY-MM-DD[T]HH:mm:ss[z]').local().format("MMMM DD, YYYY [at] HH:mm:ss") + ' ---';
          text = this.appendText(text, initialText)
        }
        var time = moment.utc(element.created_at, 'YYYY-MM-DD[T]HH:mm:ss[z]').local().format("YYYY-MM-DD HH:mm");
        if (element.message_type == 'incoming') {
          //User sent msg
          if (element.content_type == 'text') {
            var singleMessage = "\n\n" + time + " | " + selectedConversation.meta.sender.name + ": " + element.content;
            text = this.appendText(text, singleMessage)
          } else if (element.content_type == 'media') {
            if (element.content) {
              var singleMessage = "\n\n" + time + " | " + selectedConversation.meta.sender.name + ": " + element.content + ' [Media Template]';
              text = this.appendText(text, singleMessage)
            } else {
              var singleMessage = "\n\n" + time + " | " + selectedConversation.meta.sender.name + ": " + '[Media Template]';
              text = this.appendText(text, singleMessage)
            }
          } else if (element.content_type == 'location') {
            if (element.content) {
              var singleMessage = "\n\n" + time + " | " + selectedConversation.meta.sender.name + ": " + element.content + ' [Location Template]';
              text = this.appendText(text, singleMessage)
            } else {
              var singleMessage = "\n\n" + time + " | " + selectedConversation.meta.sender.name + ": " + '[Location Template]';
              text = this.appendText(text, singleMessage)
            }
          }
        } else {
          
          //agent or bot sent msg
          if (element.private == false) {

            if (element.content_type == 'text') {
              var singleMessage = "\n\n" + time + " | " + this.getAgentName(all_agents, element.user_id) + " from "+account_name+": " + element.content;
              text = this.appendText(text, singleMessage)
            } else if (element.content_type == 'media') {
              if (element.content) {
                var singleMessage = "\n\n" + time + " | " + this.getAgentName(all_agents, element.user_id) + " from "+account_name+": " + element.content + ' [Media Template]';
                text = this.appendText(text, singleMessage)
              } else {
                var singleMessage = "\n\n" + time + " | " + this.getAgentName(all_agents, element.user_id) + " from "+account_name+": " + '[Media Template]';
                text = this.appendText(text, singleMessage)
              }
            } else if (element.content_type == 'button_template') {
              if (element.content) {
                var singleMessage = "\n\n" + time + " | " + this.getAgentName(all_agents, element.user_id) + " from "+account_name+": " + element.content + ' [Button Template]';
                text = this.appendText(text, singleMessage)
              } else {
                var singleMessage = "\n\n" + time + " | " + this.getAgentName(all_agents, element.user_id) + " from "+account_name+": " + '[Button Template]';
                text = this.appendText(text, singleMessage)
              }
            } else if (element.content_type == 'quick_reply') {
              if (element.content) {
                var singleMessage = "\n\n" + time + " | " + this.getAgentName(all_agents, element.user_id) + " from "+account_name+": " + element.content + ' [QuickReply Template]';
                text = this.appendText(text, singleMessage)
              } else {
                var singleMessage = "\n\n" + time + " | " + this.getAgentName(all_agents, element.user_id) + " from "+account_name+": " + '[QuickReply Template]';
                text = this.appendText(text, singleMessage)
              }
            } else if (element.content_type == 'carousel') {
              if (element.content) {
                var singleMessage = "\n\n" + time + " | " + this.getAgentName(all_agents, element.user_id) + " from "+account_name+": " + element.content + ' [Carousel Template]';
                text = this.appendText(text, singleMessage)
              } else {
                var singleMessage = "\n\n" + time + " | " + this.getAgentName(all_agents, element.user_id) + " from "+account_name+": " + '[Carousel Template]';
                text = this.appendText(text, singleMessage)
              }
            }
            
          }
          
        }
      }
      text = text + "\n\n--- Exported by "+ currentUser.name +" from "+ account_name +" on " + moment.utc(today.getTime()).local().format("MMMM DD, YYYY [at] HH:mm:ss") + " ---";
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  },
};
