<template>
  <div class="row empty-state adjust--image">
    <div class="margin-auto">
    <img src="~/dashboard/assets/new_images/Android_SDK_Icon.svg" alt="android-icon">
    <h3 class="title-android">{{title}}</h3>
    </div>
    <slot />
  </div>
</template>
<script>

export default {
  props: {
    title: String,
    message: String,
    buttonText: String,
  },
};
</script>
<style type="text/css">
.adjust--image img { 
    width: 53px;
    margin-right: 10px;
    float: left;
     }
.title-android {
    font-size: 4rem;
    font-weight: 100;
    display: flex;
    margin-bottom: 0;
    font-size: $font-size-default;
  }

.margin-auto {
  margin: auto;
  align-content: center;
  text-align: center;
  display: block;
}

</style>
