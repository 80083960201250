<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('LABEL_MGMT.ADD.TITLE')"
        :header-content="$t('LABEL_MGMT.ADD.DESC')"
      />
      <form class="row" @submit.prevent="createLabel()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.name.$error }">
            {{ $t('LABEL_MGMT.ADD.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="name"
              type="text"
              :placeholder="$t('LABEL_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.name.$touch"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                  $v.name.$invalid ||
                  addLabel.showLoading
              "
              :button-text="$t('LABEL_MGMT.ADD.FORM.SUBMIT')"
              :loading="addLabel.showLoading"
            />
            <a @click="onClose">Cancel</a>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
/* global bus */
/* eslint no-console: 0 */
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../../components/Modal';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  props: ['onClose'],
  data() {
    return {
      name: '',
      addLabel: {
        showAlert: false,
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  computed: {
    currentInboxId() {
      return this.$route.params.inboxId;
    },
  },
  validations: {
    name: {
      required
    },
  },

  methods: {
    showAlert() {
      bus.$emit('newToastMessage', this.addLabel.message);
    },
    resetForm() {
      this.name = '';
      this.$v.name.$reset();
    },
    createLabel() {
      // Show loading on button
      this.addLabel.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('inboxLabels/create', {
          id: this.currentInboxId,
          name: this.name
        })
        .then(() => {
          // Reset Form, Show success message
          this.addLabel.showLoading = false;
          this.addLabel.message = this.$t(
            'LABEL_MGMT.ADD.API.SUCCESS_MESSAGE'
          );
          this.showAlert();
          this.resetForm();
          this.onClose();
          this.$store.dispatch('inboxes/get');
        })
        .catch(error => {
          
          try {
            if (error.response.data.error == "Label with same name already exists!") {
              this.addLabel.showLoading = false;
              this.addLabel.message = this.$t('LABEL_MGMT.ADD.API.ALREADY_EXISTS_MESSAGE');
              this.showAlert();
            } else {
              his.addLabel.showLoading = false;
              this.addLabel.message = this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE');
              this.showAlert();
            }
          } catch (error) {
            this.addLabel.showLoading = false;
            this.addLabel.message = this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE');
            this.showAlert();
          }

        });
    },
  },
};
</script>
