<template>
  <div class="finish-add-inbox scrollable-div setting-form" style="padding-bottom: 20rem;">
    <div class="form-field">
      <div class="form-label">{{ $t('INBOX_MGMT.FINISH.TITLE') }}</div>
      <div class="form-help-text" v-html="message"></div>
    </div>

    <div class="form-field">
      <div class="form-label">{{ $t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_HEADING') }}</div>
      <div class="form-help-text" v-html="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_SUB_HEAD')"></div>
      <div class="form-input">
        <woot-code
          lang="javascript"
          v-if="currentInbox.website_token"
          :script="websiteScript"
          type="Code"
        ></woot-code>
      </div>
    </div>

    <!-- -->

    <div class="form-field" style="max-width: 540px;">
      <div class="form-label display-flex-space-between">
        <div style="width: 32px;">
          <img src="~/dashboard/assets/new_images/Android_SDK_Icon.svg" width="32px" height="32px">
        </div>
        <div class="display-flex-space-between" style="width: 100%; padding-left: 8px;">
          <div>{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.ANDROID_SDK.LABEL') }}</div>
          <div>
            <div
              class="form-button blue m-0 lato-regular"
              @click="openInNewWindow($t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.ANDROID_SDK.SDK_DOC_LINK'))"
            >Check Documentation</div>
          </div>
        </div>
      </div>
      <div
        class="form-help-text"
        v-html="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.ANDROID_SDK.DESCRIPTION', { title: title})"
      ></div>
    </div>

    <div class="form-field" style="max-width: 540px;">
      <div class="form-label display-flex-space-between">
        <div style="width: 32px;">
          <img src="~/dashboard/assets/new_images/Apple_SDK_Icon.svg" width="32px" height="32px">
        </div>
        <div class="display-flex-space-between" style="width: 100%; padding-left: 8px;">
          <div>{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.IOS_SDK.LABEL') }}</div>
          <div>
            <div
              class="form-button blue m-0 lato-regular"
              @click="openInNewWindow($t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.IOS_SDK.SDK_DOC_LINK'))"
            >Check Documentation</div>
          </div>
        </div>
      </div>
      <div class="form-help-text" v-html="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.IOS_SDK.DESCRIPTION', { title: title})"></div>
    </div>

    <div class="form-field">
      <div class="form-input">
        <router-link
          :to="inboxPath"
        >
          <div
            class="form-button blue m-0 lato-regular"
          >Go to Dashboard</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { createWebsiteWidgetScript } from 'dashboard/helper/scriptGenerator';
import EmptyState from '../../../../components/widgets/EmptyState';
import EmptyStateSdk from '../../../../components/widgets/EmptyStateSdk';
import SettingsFormHeader from '../../../../components/SettingsFormHeader.vue';
import SettingsFormBody from '../../../../components/SettingsFormBody.vue';
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  components: {
    EmptyState,
    EmptyStateSdk,
    SettingsFormHeader,
    SettingsFormBody,
  },
  data() {
    return {
      title: window.chatwootConfig.custom_website.title,
    }
  },
  methods: {
    openInNewWindow(link) {
      window.open(link, '_blank');
    },
  },
  computed: {
    currentInbox() {
      return this.$store.getters['inboxes/getInbox'](
        this.$route.params.inbox_id
      );
    },
    inboxPath() {
      return frontendURL(`inbox/${this.$route.params.inbox_id}`);
    },
    message() {
      if (this.currentInbox.multi_channel == true) {
        return this.$t('INBOX_MGMT.ADD.MULTI_CHANNEL.FINISH.SUCCESS', {title: this.title});
      } else {
        if (!this.$route.params.website_token) {
          return this.$t('INBOX_MGMT.FINISH.MESSAGE');
        }

        return this.$t('INBOX_MGMT.FINISH.WEBSITE_SUCCESS');
      }
    },
    websiteScript() {
      return createWebsiteWidgetScript(this.currentInbox.website_token);
    },
  },
};
</script>
<style lang="scss" scoped>
.finish-add-inbox {
  .form-help-text {
    margin-top: -1px !important;
    margin-bottom: 15px;
  }
}
</style>
