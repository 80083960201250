<template>
  <div class="contact-conversation-property-panel details">
    <div class="title display-flex-space-between">
      <div>Properties</div>
      <div>
        <div v-if="!addNewElement" @click="addElementNew()" class="add-property-button">
          <i class="fa fa-plus-circle"></i> Add
        </div>
      </div>
    </div>
    <div v-if="!uiFlags.isFetching" class="m-b-16">
      <div>
        <div v-if="propertiesEditable">
          <transition name="modal-fade">
            <div v-if="propertiesEditable" class="modal-mask" transition="modal" @click="onClose">
              <i class="ion-android-close modal--close" @click="onClose"></i>
              <div class="modal-container" @click.stop>
                <div class="property-modal">
                  <div class="property-header">
                    <div class="property-title">Property > {{contactDetail.name}}</div>
                    <!-- <div @click="addElement()" class="form-button blue m-0">Add Property</div> -->
                  </div>
                  <div class="property-list">
                    <!-- <div style="margin-bottom: 20px;">
                      <i
                        class="information-data-pill"
                        v-if="!conversationProperties.length"
                      >{{ $t('CONTACT_PANEL.PROPERTY.NO_RECORDS_FOUND') }}</i>
                      <i
                        v-else
                      >Note: You can attach multiple properties to a conversation. You can either create a new key by typing the name manually or choose from the dropdown which gives you a list of all the keys which are currently used by other conversations in this inbox to quickly attach the property.</i>
                    </div>-->
                    <div
                      v-for="(item, index) in conversationProperties"
                      :key="index"
                      @change="onChangeTrigger()"
                    >
                      <div class="property-details--item">
                        <div
                          style="display: flex; justify-content: space-between; margin-bottom: 8px;"
                        >
                          <div style="font-weight: 600; font-size: 12px;">
                            Property: {{index+1}}
                            <span
                              style="color:red; padding-left: 2rem;font-weight: 100; margin-bottom: 8px;"
                            >
                              <span
                                class="comma"
                                v-if="item.duplicateKeyPresent == true"
                              >Duplicate key not allowed</span>
                              <span
                                class="comma"
                                v-if="item.valid == false && item.error"
                                v-text="item.error"
                              ></span>
                            </span>
                          </div>
                          <div style="m-r-4" @click="removeElement(index)">
                            <i class="fa fa-times-circle"></i>
                          </div>
                        </div>
                        <div class="property-details--item__label">
                          <!-- <div class="property-key-div">
                            <p>Key</p>
                          </div>-->
                          <!-- {{ item.key }} -->
                          <div style="width: 100%; display: flex;">
                            <div style="width: 30%; margin-right: 5px;">
                              <div class="property_key_title">Enter Key</div>
                              <input
                                type="text"
                                class="contact_input"
                                v-model.trim="item.key"
                                placeholder="Add new key"
                                :disabled="!propertiesEditable"
                                @blur="validationRegex(item, index, $event, false)"
                                style=" border-radius: 2px;"
                              >
                            </div>
                            <div style="width: 30%; margin-right: 5px;">
                              <div class="property_key_title">Or Select Key</div>
                              <multiselect
                                v-model="item.key"
                                :options="allPropertiesKeys"
                                :searchable="true"
                                :close-on-select="true"
                                @close="validationRegex(item, index, $event, false)"
                                placeholder="Select from list"
                                :show-labels="false"
                                class="contact_input"
                                style="border-radius: 2px;"
                              ></multiselect>
                            </div>
                            <div style="width: 40%;">
                              <div class="property_key_title">Enter Value</div>
                              <input
                                type="text"
                                class="contact_input"
                                v-model.trim="item.value"
                                placeholder="Enter value"
                                :disabled="!propertiesEditable"
                                @blur="validationRegex(item, index, $event, true)"
                                style="border-radius: 2px;"
                              >
                            </div>

                            <!-- <div style="padding: 10px 4px;">or</div> -->
                          </div>
                        </div>
                        <!-- <div class="property-details--item__value">
                          <div class="property-key-div">Value</div>
                          <div style="width: 90%; display: flex;">
                            <input
                              type="text"
                              class="contact_input"
                              v-model.trim="item.value"
                              placeholder="Enter value"
                              :disabled="!propertiesEditable"
                              @blur="validationRegex(item, index, $event, true)"
                            >
                          </div>
                        </div>-->
                      </div>
                    </div>
                  </div>
                  <div class="property-footer setting-form-actions" style="width: 100%;">
                    <div
                      @click="onSubmit()"
                      :disabled="!(propertiesEditable && activateSaveButton)"
                      class="form-button blue button-fixed-width m-0"
                    >Save</div>
                    <div @click="onClose()" class="form-button grey button-fixed-width m-0">Cancel</div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div v-else>
          <i
            class="information-data-pill"
            v-if="!addNewElement && !conversationProperties.length"
          >{{ $t('CONTACT_PANEL.PROPERTY.NO_RECORDS_FOUND') }}</i>
          <div
            v-for="(item, index) in conversationProperties"
            :key="index"
            class="conversation--details"
          >
            <div class="detail l-h-normal" v-if="showMore || (!showMore && index < 4)">
              <div class="icon" :title="item.key" v-html="prepareContent($sanitize(formatMessage(item.key)))"></div>
              <div class="value" v-html="prepareContent($sanitize(formatMessage(item.value)))"></div>
            </div>
          </div>
          <div v-if="addNewElement" class="add-element-section">
            <div class="display-flex-space-between">
              <input
                v-if="!showPropertyDropdown"
                type="text"
                class="contact_input"
                v-model.trim="newKey"
                placeholder="Enter Key"
                style="margin-right: 4px;"
              >
              <multiselect
                v-else
                v-model="newKey"
                :options="allPropertiesKeys"
                :searchable="true"
                :close-on-select="true"
                placeholder="Select Key"
                :show-labels="false"
                class="contact_input"
                style="margin-right: 4px;"
              ></multiselect>
              <div
                @click="showPropertyDropdown = !showPropertyDropdown"
                :title="showPropertyDropdown == true ? 'Enter key' : 'Select from existing properties'"
                style="border: 1px solid rgb(224, 230, 236); padding: 8px; margin-top: -4px; border-radius: 4px; height: 32px;"
              >
                <i class="fa fa-exchange"></i>
              </div>
            </div>
            <div>
              <input
                type="text"
                class="contact_input"
                v-model.trim="newValue"
                placeholder="Enter Value"
              >
            </div>

            <div class="display-flex-space-between">
              <div></div>
              <div class="display-flex-space-between">
                <div
                  class="property-single-add-buttons red"
                  style="margin-right: 6px;"
                  @click="cancelAddingProperty()"
                >
                  <i class="fa fa-times"></i>
                </div>
                <div
                  class="property-single-add-buttons green"
                  :disabled="newValue == null || newKey == null"
                  @click="proceedAddingProperty"
                >
                  <i class="fa fa-check"></i>
                </div>
              </div>
            </div>
            <!-- <div
              v-if="conversationProperties.length > 0"
              style="border-bottom: 1px solid #EFEEF0; padding-top: 10px; margin-bottom: 10px;"
            ></div>-->
          </div>
        </div>
      </div>
    </div>
    <spinner v-else></spinner>
    <div class="more-edit m-t-8">
      <div class="see-more">
        <div v-if="conversationProperties.length">
          <span @click="showMore = !showMore" v-if="showMore">Show less</span>
          <span
            @click="showMore = !showMore"
            v-if="!showMore && conversationProperties.length > 4"
          >Show more</span>
        </div>
      </div>
      <div
        v-if="!addNewElement && conversationProperties.length > 0"
        class="edit"
        @click="enableProperties()"
      >Edit</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import Spinner from 'shared/components/Spinner.vue';
import Modal from '../../../components/Modal';
import { setTimeout } from 'timers';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';

export default {
  components: {
    Spinner,
    Modal,
  },
  mixins: [messageFormatterMixin],
  props: {
    conversationId: {
      type: [String, Number],
      required: true,
    },
    contactDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      conversationProperties: [],
      allPropertiesKeys: [],
      current_conversation: {},
      propertiesEditable: false,
      activateSaveButton: false,
      addNewElement: false,
      showPropertyDropdown: false,
      showMore: false,
      allAvailableKeys: ['name', 'age', 'a', 'number'],
      newKey: null,
      newValue: null,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'conversationProperty/getUIFlags',
      activeInbox: 'getSelectedInbox',
      searchObject: 'globalVariables/getSearchObject',
    }),
  },
  methods: {
    prepareContent(content = '') {
      if (this.searchObject.searchKey == 'property') {
        return content.replace(
          new RegExp(`(${this.searchObject.searchValue})`, 'ig'),
          '<span class="searchkey--highlight">$1</span>'
        );
      } else {
        return content;
      }
    },
    async onSubmit() {
      var property_to_send = {};
      for (let index = 0; index < this.conversationProperties.length; index++) {
        const element = this.conversationProperties[index];
        if (element.valid == false || element.duplicateKeyPresent == true) {
          if (element.duplicateKeyPresent == true) {
            if (this.duplicatePresent(index, element.key)) {
              this.showAlert(
                'There is some problem with property ' +
                  (index + 1) +
                  ', please resolve before saving!'
              );
              return;
            } else {
              this.conversationProperties[index].duplicateKeyPresent = false;
            }
          }
        }
        if (element.key == null || element.key == '') {
          var errorMessage = 'Key can not be empty';
          this.conversationProperties[index].error = errorMessage;
          this.conversationProperties[index].valid = false;
          Vue.set(
            this.conversationProperties,
            this.conversationProperties[index].valid,
            false
          );
          Vue.set(
            this.conversationProperties,
            this.conversationProperties[index].error,
            errorMessage
          );
          this.showAlert(
            'There is some problem with property ' +
              (index + 1) +
              ', please resolve before saving!'
          );
          return;
        } else if (element.value == null || element.value == '') {
          var errorMessage = 'Value can not be empty';
          this.conversationProperties[index].error = errorMessage;
          this.conversationProperties[index].valid = false;
          Vue.set(
            this.conversationProperties,
            this.conversationProperties[index].valid,
            false
          );
          Vue.set(
            this.conversationProperties,
            this.conversationProperties[index].error,
            errorMessage
          );
          this.showAlert(
            'There is some problem with property ' +
              (index + 1) +
              ', please resolve before saving!'
          );
          return;
        } else {
          element.valid = true;
        }
        if (element.value != null && element.value != '') {
          property_to_send[element.key] = element.value;
        }
      }

      var result = await this.$store.dispatch(
        'conversationProperty/saveProperty',
        {
          conversationId: this.conversationId,
          property: property_to_send,
        }
      );

      if (result.status == 200) {
        this.propertiesEditable = false;
        this.showAlert('Properties saved');
        this.fetchConversationProperties();
      } else {
        this.showAlert('Something went wrong, please try again!');
      }
    },
    onClose() {
      this.fetchConversationProperties();
      this.propertiesEditable = false;
    },
    duplicatePresent(i, key) {
      for (let index = 0; index < this.conversationProperties.length; index++) {
        const element = this.conversationProperties[index];
        if (i != index) {
          if (element.key === key) {
            return true;
          }
        }
      }
      return false;
    },
    validationRegex(item, i, event, valueCheck) {
      if (valueCheck) {
        if (item.value == null || item.value == '') {
          item.error = 'Value can not be empty';
          item.valid = false;
          return;
        } else {
          item.valid = true;
        }
      } else {
        if (item.key == null || item.key == '') {
          item.error = 'Key can not be empty';
          item.valid = false;
          item.duplicateKeyPresent = false;
          return;
        } else {
          item.valid = true;
          if (this.duplicatePresent(i, item.key)) {
            item.duplicateKeyPresent = true;
            return;
          } else {
            item.duplicateKeyPresent = false;
          }
        }
      }
    },
    addElement() {
      this.conversationProperties.push({ key: null, value: null });
      try {
        setTimeout(() => {
          var conversationPanel = document.querySelector('.property-list');
          conversationPanel.scrollTop = conversationPanel.scrollHeight;
        }, 100);
      } catch (error) {}
    },
    addElementNew() {
      this.addNewElement = true;
      this.fetchAllPropertyKeys();
    },
    cancelAddingProperty() {
      // this.fetchConversationProperties();
      this.addNewElement = false;
      this.newKey = null;
      this.newValue = null;
      this.propertiesEditable = false;
    },
    async proceedAddingProperty() {

      if (
        this.newKey == null ||
        this.newKey.trim() == '' ||
        this.newValue == null ||
        this.newValue.trim() == ''
      ) {
        this.showAlert(
          'Error: property name or value can not be empty'
        );
        return;
      }

      var property_to_send = {};
      for (let index = 0; index < this.conversationProperties.length; index++) {
        const element = this.conversationProperties[index];
        property_to_send[element.key] = element.value;
        if (element.key === this.newKey) {
          this.showAlert('Error: property with the same key alread exists');
          return;
        }
      }

      this.conversationProperties.push({
        key: this.newKey,
        value: this.newValue,
      });

      property_to_send[this.newKey] = this.newValue;

      var result = await this.$store.dispatch(
        'conversationProperty/saveProperty',
        {
          conversationId: this.conversationId,
          property: property_to_send,
        }
      );

      if (result) {
        if (result.status == 200) {
          this.propertiesEditable = false;
          this.fetchConversationProperties();
          this.showAlert('Properties saved');
          this.addNewElement = false;
          this.newKey = null;
          this.newValue = null;
        } else {
          this.showAlert('Something went wrong, please try again!');
        }
      }
    },
    removeElement(i) {
      this.conversationProperties.splice(i, 1);
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async fetchConversationProperties() {
      this.conversationProperties = [];
      try {
        var conversation_properties = await this.$store.dispatch(
          'conversationProperty/get',
          this.conversationId
        );
        this.conversationProperties = conversation_properties.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAllPropertyKeys() {
      this.allPropertiesKeys = [];
      try {
        var all_properties_keys = await this.$store.dispatch(
          'conversationProperty/getAllPropertyKeys',
          this.conversationId
        );
        this.allPropertiesKeys = all_properties_keys.data;
      } catch (error) {
        console.log(error);
      }
    },
    enableProperties() {
      this.fetchAllPropertyKeys();
      this.propertiesEditable = true;
      this.activateSaveButton = false;
    },
    propertyObjectLength() {
      var count = Object.keys(this.conversationProperties).length;
      return count;
    },
    async updateLabels() {
      const labelList = this.selectedlabels.map(el => el.label_id);
      try {
        await this.$store.dispatch('conversationLabels/create', {
          conversationId: this.conversationId,
          labels: labelList,
        });
        this.showAlert(this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('LABEL_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    onChangeTrigger() {
      this.activateSaveButton = true;
    },
  },
  watch: {
    conversationId(newConversationId, prevConversationId) {
      if (newConversationId == undefined || newConversationId == null) {
        this.conversationProperties = [];
        this.allPropertiesKeys = [];
        this.propertiesEditable = false;
        this.activateSaveButton = false;
        this.newKey = null;
        this.newValue = null;
        this.addNewElement = false;
        return;
      }
      if (newConversationId !== prevConversationId) {
        this.propertiesEditable = false;
        this.addNewElement = false;
        this.fetchConversationProperties();
      }
    },
    conversationProperties(
      newConversationProperties,
      prevConversationProperties
    ) {
      if (this.propertiesEditable && newConversationProperties) {
        this.onChangeTrigger();
      }
    },
  },
  mounted() {
    this.propertiesEditable = false;
    this.activateSaveButton = false;
    this.addNewElement = false;
    this.fetchConversationProperties();
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.contact-conversation-property-panel {
  .property_key_title {
    padding-bottom: 4px;
    font-size: 12px;
  }

  .property-single-add-buttons {
    border: 1px solid #efeef0;
    border-radius: 2px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .property-single-add-buttons.red:hover {
    background: #ff5959;
    border: 1px solid #ff5959;

    i {
      color: white;
    }
  }

  .property-single-add-buttons.green:hover {
    background: #3970f1;
    border: 1px solid #3970f1;

    i {
      color: white;
    }
  }

  .information-data-pill {
    font-style: normal;
    font-size: 14px;
    color: #697181;
  }

  .add-element-section {
    font-size: 14px;

    input {
      font-size: 14px;
    }
  }

  .modal-container {
    font-size: 14px;
    margin: 5rem 0rem !important;
    background: white;
    height: 80%;
    width: 40%;
    padding: 0;
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    color: #1a1c1d;
    margin-bottom: 16px;
  }

  .add-property-button {
    color: #3970f1;
    cursor: pointer;
    font-size: 12px;
  }

  .conversation--details {
    width: 100%;

    .detail .value {
      font-size: 14px;
      color: #697181;
      width: 60%;
      overflow-wrap: anywhere;
    }

    .detail {
      display: flex;
      margin-bottom: 16px;
    }

    .detail .icon {
      font-size: 14px;
      color: #1a1c1d;
      overflow: hidden;
      width: 40%;
      text-overflow: ellipsis;
    }
  }

  .property-details--item {
    // border-bottom: 1px solid #e0e6ec;
    margin-bottom: 10px;
    padding-bottom: 10px;

    .property-details--item__icon {
      padding-right: $space-micro;
    }

    .property-details--item__label {
      font-weight: $font-weight-medium;
      margin-bottom: $space-micro;
      display: flex;
    }

    .property-details--item__value {
      word-break: break-all;
      display: flex;
    }
  }

  .property-details--item_new {
    padding: 0px 4px;
    margin-bottom: 4px;
    border-radius: 4px;
    // padding-bottom: $space-normal;

    &:last-child {
      padding-bottom: 0;
    }

    .property-details--item__icon {
      padding-right: $space-micro;
    }

    .property-details--item__label {
      margin-bottom: 4px;
      display: flex;
      height: 32px !important;
    }

    .property-details--item__value {
      display: flex;
      height: 32px !important;
    }
  }

  input:disabled {
    background: none;
    border: 0;
  }

  .property-key-div {
    align-self: center;
    width: 10%;
  }

  .contact_input {
    color: inherit;
    height: 32px;
    margin: 0 0 4px 0;
    font-size: 14px;
  }

  .contact_input:focus {
    outline: none;
  }

  .property-modal {
    height: 100%;
    background: white;
  }

  .property-header {
    font-size: 20px;
    align-items: center;
    height: 50px;
    padding: 12px;
    color: #3970f1;
    border-bottom: 1px solid #dedddf;
    display: flex;
    justify-content: space-between;

    .property-title {
      font-size: 16px;
      font-weight: 700;
      max-width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 24px;
    }
  }
  .property-list {
    padding: 12px;
    height: calc(100% - 100px);
    overflow-y: auto !important;
    overflow: auto !important;
  }
  .property-footer {
    height: 50px;
    padding: 12px;
    border-top: 1px solid #dedddf;
    display: flex;
    justify-content: space-between;
  }

  button {
    outline: none;
  }

  .status--filter {
    background-color: $color-background;
    float: right;
    font-size: $font-size-mini;
    height: $space-medium;
    width: auto;
  }

  .comma:not(:first-child) {
    margin-left: -0.3em;
  }

  /* next 2 rules are for spacing when the first .comma is empty too */
  .comma:first-child:empty ~ .comma:not(:empty) {
    margin-left: 0;
  }

  .comma:first-child:empty ~ .comma:not(:empty) ~ .comma:not(:empty) {
    margin-left: -0.3em;
  }

  .comma:empty {
    display: none;
  }

  .comma:not(:first-child):before {
    content: ', ';
  }

  .comma:not(:first-child):before {
    content: ', ';
  }

  .comma:empty + .comma:not(:empty):before {
    content: '';
  }

  .comma:not(:empty) ~ .comma:empty + .comma:not(:empty):before {
    content: ', ';
  }
}
</style>
