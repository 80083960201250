<template>
  <div class="inbox-all-settings">
    <div class="settings-tabs">
      <div
        class="setting-tab"
        :class="{active: selectedTab == 'general'}"
        @click="selectedTab = 'general'"
      >General</div>
      <div
        class="setting-tab"
        :class="{active: selectedTab == 'toolkits'}"
        @click="selectedTab = 'toolkits'"
      >Toolkits</div>
      <div
        class="setting-tab"
        :class="{active: selectedTab == 'agents'}"
        @click="selectedTab = 'agents'"
      >Agents</div>
      <div
        class="setting-tab"
        :class="{active: selectedTab == 'webhook'}"
        @click="selectedTab = 'webhook'"
      >Webhook</div>
      <div
        class="setting-tab"
        :class="{active: selectedTab == 'permissions'}"
        @click="selectedTab = 'permissions'"
      >Configuration</div>
      <div
        class="setting-tab"
        :class="{active: selectedTab == 'labels'}"
        @click="selectedTab = 'labels'"
      >Labels</div>
    </div>
    <div class="scrollable-div" style="height: calc(100% - 110px);" v-if="selectedTab == 'general'">
      <div class="setting-form">
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.INBOX_SETTING.NAME_TITLE') }}</div>
          <div class="form-input">
            <input class="field" type="text" v-model.trim="inboxName" :placeholder="'Inbox Name'">
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.INTRO.HEADING') }}</div>
          <div class="form-input">
            <input
              class="field"
              type="text"
              v-model.trim="inbox.intro_heading"
              :placeholder="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.INTRO.HEADING_PLACEHOLDER')"
            >
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.INTRO.BODY') }}</div>
          <div class="form-input">
            <input
              class="field"
              type="text"
              v-model.trim="inbox.intro_body"
              :placeholder="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.INTRO.BODY_PLACEHOLDER')"
            >
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">
            <span>{{ $t('INBOX_MGMT.ADD.INBOX_SETTING.INBOX_TYPE') + ' :' }}</span>
            <span v-if="inbox.multi_channel === true" style="color: #1f2e3d; font-weight: 100;" > Multichannel </span>
            <span v-if="inbox.multi_channel === false" style="color: #1f2e3d; font-weight: 100;" > Website </span>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL') }}</div>
          <div class="form-input">
            <Chrome
              v-if="inbox.widget_color"
              v-model="inbox.widget_color"
              class="widget-color--selector"
            />
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.BOT_ICON.LABEL') }}</div>
          <div class="form-help-text" v-html="$t('INBOX_MGMT.SETTINGS_POPUP.BOT_ICON_SUB_TEXT')"></div>
          <div class="form-input">
            <bot-icon :inbox="inbox"></bot-icon>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_ICON.LABEL') }}</div>
          <div class="form-help-text" v-html="$t('INBOX_MGMT.SETTINGS_POPUP.WIDGET_ICON_SUB_TEXT')"></div>
          <div class="form-input">
            <widget-icon :inbox="inbox"></widget-icon>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_BACKGROUND_COLOR.LABEL') }}</div>
          <div class="form-input">
            <Chrome
              v-if="inbox.widget_background_color"
              v-model="inbox.widget_background_color"
              class="widget-color--selector"
            />
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_FONT_SIZE.LABEL') }}</div>
          <div class="form-help-text" v-html="'Please enter font size along with it\'s unit. Default unit is \'px\' (example: 14px).'"></div>
          <div class="form-input">
            <input class="field" type="text" v-model.trim="inbox.widget_font_size" :placeholder="'Font size'">
          </div>
        </div>
      </div>
      <div class="setting-form-actions">
        <div
          class="form-button blue m-r-24 button-fixed-width"
          @click="updateGeneralInfo()"
        >Save Changes</div>
        <div class="form-button grey button-fixed-width" @click="cancelUpdate">Cancel</div>
      </div>
    </div>
    <div class="scrollable-div" v-if="selectedTab == 'toolkits'">
      <div class="setting-form">
        <div class="form-field" style="display: flex;">
          <div style="width: 318px;">
            <div class="form-label">Inbox ID</div>
            <div class="form-input">
              <woot-code :script="''+inbox.id" type="ID"></woot-code>
            </div>
          </div>
          <div style="margin-left: 24px; width: 318px;">
            <div class="form-label">API Key</div>
            <div class="form-input">
              <woot-code :script="currentUser.api_key" type="Key"></woot-code>
            </div>
          </div>
        </div>

        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_HEADING') }}</div>
          <div class="form-help-text" v-html="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_SUB_HEAD')"></div>
          <div class="form-input">
            <woot-code lang="javascript" :script="webWidgetScript" type="Code"></woot-code>
          </div>
        </div>

        <div class="form-field" style="max-width: 540px;">
          <div class="form-label display-flex-space-between">
            <div style="width: 32px;">
              <img
                src="~/dashboard/assets/new_images/Android_SDK_Icon.svg"
                width="32px"
                height="32px"
              >
            </div>
            <div class="display-flex-space-between" style="width: 100%; padding-left: 8px;">
              <div>{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.ANDROID_SDK.LABEL') }}</div>
              <div>
                <div
                  class="form-button blue m-0 lato-regular"
                  @click="openInNewWindow($t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.ANDROID_SDK.SDK_DOC_LINK'))"
                >Check Documentation</div>
              </div>
            </div>
          </div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.ANDROID_SDK.DESCRIPTION', { title: title })"
          ></div>
        </div>

        <div class="form-field" style="max-width: 540px;">
          <div class="form-label display-flex-space-between">
            <div style="width: 32px;">
              <img
                src="~/dashboard/assets/new_images/Apple_SDK_Icon.svg"
                width="32px"
                height="32px"
              >
            </div>
            <div class="display-flex-space-between" style="width: 100%; padding-left: 8px;">
              <div>{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.IOS_SDK.LABEL') }}</div>
              <div>
                <div
                  class="form-button blue m-0 lato-regular"
                  @click="openInNewWindow($t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.IOS_SDK.SDK_DOC_LINK'))"
                >Check Documentation</div>
              </div>
            </div>
          </div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.IOS_SDK.DESCRIPTION', { title: title })"
          ></div>
        </div>
      </div>
    </div>
    <div class="scrollable-div" style="height: calc(100% - 110px);" v-if="selectedTab == 'agents'">
      <div class="setting-form">
        <div class="form-field">
          <div
            class="form-label"
          >{{ $t('INBOX_MGMT.ADD.AGENT_ASSIGNMENT_SETTING.AGENT_ASSIGNMENT') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.ADD.AGENT_ASSIGNMENT_SETTING.AGENT_ASSIGNMENT_DESCRIPTION')"
          ></div>
          <div class="form-input">
            <div class="other-input-type">
              <input type="radio" id="one" :value="true" v-model="inbox.agent_assignment">
              <label for="one">On</label>
            </div>
            <div class="other-input-type">
              <input type="radio" id="two" :value="false" v-model="inbox.agent_assignment">
              <label for="two">Off</label>
            </div>
          </div>
        </div>

        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT')"
          ></div>
          <div class="form-input" style="max-width: 100%;">
            <div style="position: relative">
              <div
                v-if="agentDropdownShow"
                v-on-clickaway="onToggleDropdown"
                class="qw-dropdown-menu-items"
              >
                <div
                  v-if="selectedAgents.length == agentList.length"
                  class="conversation-agent-filter-no-element"
                  @click="onToggleDropdown"
                >No agent found!</div>
                <div v-else v-for="(agent, index) in agentList" :key="agent.id">
                  <div
                    v-if="showIfNotSelected(agent, index)"
                    class="conversation-agent-filter-element"
                    @click="agentSelected(agent, index)"
                  >
                    <div class="conversation-agent-single-element">
                      <QWThumbnail
                        :src="agent.profile_pic"
                        class="m-r-4"
                        size="16px"
                        :username="agent.name"
                      />
                      <div class="title">{{agent.name}}</div>
                    </div>
                    <div class="cross-sign">
                      <div style="margin-top: -1px;">+</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class style="display: flex;">
              <div class="conversation-agent-filter" @click="onToggleDropdown">
                <img src="~/assets/new_images/User_Icon_Black.svg" size="16px" class="m-r-4">
                <div
                  style="display: flex; justify-content: space-between; width: inherit; align-items: center;"
                >
                  <div class="title">Add Agent</div>
                  <i class="fa fa-chevron-down qw-chevron" aria-hidden="true"></i>
                </div>
              </div>

              <div
                v-if="selectedAgents"
                style="display: flex; width: inherit; margin-left: 16px; flex-flow: wrap;"
              >
                <div
                  class="conversation-agent-selected-filter"
                  v-for="(item, index) in selectedAgents"
                  :key="item.id"
                >
                  <QWThumbnail
                    :src="item.profile_pic"
                    class="m-r-4"
                    size="16px"
                    :username="item.name"
                  />
                  <div
                    style="display: flex; justify-content: space-between; width: inherit; line-height: 24px;"
                  >
                    <div class="title">{{item.name}}</div>
                    <div @click="removeAgentFromSelected(item, index)">
                      <i class="fa fa-times-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-form-actions">
        <div
          class="form-button blue m-r-24 button-fixed-width"
          @click="updateAgentInfo()"
        >Save Changes</div>
        <div class="form-button grey button-fixed-width" @click="cancelUpdate">Cancel</div>
      </div>
    </div>
    <div class="scrollable-div" style="height: calc(100% - 110px);" v-if="selectedTab == 'webhook'">
      <div class="setting-form">
        <div v-if="inbox.multi_channel === false" class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WEBHOOK.LABEL') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_WEBHOOK_SUB_TEXT')"
          ></div>
          <div class="form-input">
            <input
              class="field"
              type="text"
              v-model.trim="inbox.webhook"
              :placeholder="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WEBHOOK.PLACEHOLDER')"
            >
          </div>
        </div>

        <div v-if="inbox.multi_channel === true" class="form-field">
          <div
            class="form-label"
          >{{ $t('INBOX_MGMT.ADD.MULTI_CHANNEL.MULTICHANNEL_AGENT_WEBHOOK.LABEL') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_MULTICHANNEL_AGENT_WEBHOOK_SUB_TEXT', {title: title})"
          ></div>
          <div class="form-input">
            <input
              class="field"
              type="text"
              v-model.trim="inbox.mc_agent_webhook"
              :placeholder="$t('INBOX_MGMT.ADD.MULTI_CHANNEL.MULTICHANNEL_AGENT_WEBHOOK.PLACEHOLDER')"
            >
          </div>
        </div>

        <div v-if="inbox.multi_channel === true" class="form-field">
          <div
            class="form-label"
          >{{ $t('INBOX_MGMT.ADD.MULTI_CHANNEL.WEBWIDGET_USER_WEBHOOK.LABEL') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_MULTICHANNEL_WEBWIGET_USER_WEBHOOK_SUB_TEXT', { title: title })"
          ></div>
          <div class="form-input">
            <input
              class="field"
              type="text"
              v-model.trim="inbox.webhook"
              :placeholder="$t('INBOX_MGMT.ADD.MULTI_CHANNEL.WEBWIDGET_USER_WEBHOOK.PLACEHOLDER')"
            >
          </div>
        </div>

        <div v-if="events.inbox_id" class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBHOOK_EVENTS.TITLE') }}</div>
          <div class="form-help-text" v-html="$t('INBOX_MGMT.ADD.WEBHOOK_EVENTS.DESCRIPTION')"></div>
          <div class="form-input">
            <div class="other-input-type">
              <input
                class="field"
                type="checkbox"
                id="user_created_event"
                v-model="events.user_created_event"
              >
              <label for="user_created_event">New user event</label>
            </div>
            <div class="other-input-type">
              <input
                class="field"
                type="checkbox"
                id="assignee_change_event"
                v-model="events.assignee_change_event"
              >
              <label for="assignee_change_event">Conversation agent change event</label>
            </div>
            <div class="other-input-type">
              <input
                class="field"
                type="checkbox"
                id="conversation_status_change_event"
                v-model="events.conversation_status_change_event"
              >
              <label for="conversation_status_change_event">Conversation status change event</label>
            </div>
            <div v-if="inbox.multi_channel === true" class="other-input-type">
              <input
                class="field"
                type="checkbox"
                id="typing_event"
                v-model="events.typing_event"
              >
              <label for="typing_event">Agent typing event</label>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-form-actions">
        <div
          class="form-button blue m-r-24 button-fixed-width"
          @click="updateWebhookInfo()"
        >Save Changes</div>
        <div class="form-button grey button-fixed-width" @click="cancelUpdate">Cancel</div>
      </div>
    </div>
    <div
      class="scrollable-div"
      style="height: calc(100% - 110px);"
      v-if="selectedTab == 'permissions'"
    >
      <div class="setting-form">
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.DEFAUL_CONVERSATION_STATUS.TITLE') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.ADD.DEFAUL_CONVERSATION_STATUS.DESCRIPTION')"
          ></div>
          <div class="form-input">
            <div class="other-input-type">
              <input
                class="field"
                type="radio"
                id="open_status"
                :value="0"
                v-model="inbox.default_conversation_status"
              >
              <label for="open_status">Open</label>
            </div>
            <div class="other-input-type">
              <input
                class="field"
                type="radio"
                id="resolved_status"
                :value="1"
                v-model="inbox.default_conversation_status"
              >
              <label for="resolved_status">Resolved</label>
            </div>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.LOCATION_SETTING.LOCATION') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.ADD.LOCATION_SETTING.LOCATION_DESCRIPTION')"
          ></div>
          <div class="form-input">
            <div class="other-input-type">
              <input
                class="field"
                type="radio"
                id="one_render_location"
                :value="true"
                v-model="inbox.render_location"
              >
              <label for="one_render_location">Show</label>
            </div>
            <div class="other-input-type">
              <input
                class="field"
                type="radio"
                id="two_render_location"
                :value="false"
                v-model="inbox.render_location"
              >
              <label for="two_render_location">Hide</label>
            </div>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.FILE_UPLOAD_SETTING.FILE_UPLOAD') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.ADD.FILE_UPLOAD_SETTING.FILE_UPLOAD_DESCRIPTION')"
          ></div>
          <div class="form-input">
            <div class="other-input-type">
              <input
                type="radio"
                class="field"
                id="file_upload_one"
                :value="true"
                v-model="inbox.render_file_upload"
              >
              <label for="file_upload_one">Show</label>
            </div>
            <div class="other-input-type">
              <input
                type="radio"
                class="field"
                id="file_upload_two"
                :value="false"
                v-model="inbox.render_file_upload"
              >
              <label for="file_upload_two">Hide</label>
            </div>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.DESKTOP_NOTIFICATION_SOUND.FILE_UPLOAD') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.ADD.DESKTOP_NOTIFICATION_SOUND.FILE_UPLOAD_DESCRIPTION')"
          ></div>
          <div class="form-input">
            <div class="other-input-type">
              <input
                type="radio"
                class="field"
                id="desktop_notification_sound_on"
                :value="true"
                v-model="inbox.desktop_notification_sound"
              >
              <label for="desktop_notification_sound_on">Turn Notifications On</label>
            </div>
            <div class="other-input-type">
              <input
                type="radio"
                class="field"
                id="desktop_notification_sound_off"
                :value="false"
                v-model="inbox.desktop_notification_sound"
              >
              <label for="desktop_notification_sound_off">Turn Notifications Off</label>
            </div>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.FIREBASE_SETTING.SERVER_KEY') }}</div>
          <div
            class="form-help-text"
            v-html="$t('INBOX_MGMT.ADD.FIREBASE_SETTING.SERVER_KEY_DESCRIPTION')"
          ></div>
          <div class="form-input">
            <input
              class="field"
              type="text"
              v-model.trim="inbox.firebase_server_key"
              :placeholder="$t('INBOX_MGMT.ADD.FIREBASE_SETTING.SERVER_KEY_PLACEHOLDER')"
            >
          </div>
        </div>
        <div class="form-field">
          <!-- pankaj -->
          <div class="form-label">{{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WORKING_HOURS.LABEL') }}</div>
          <div
            class="form-help-text m-b-0"
            v-html="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WORKING_HOURS.PLACEHOLDER')"
          ></div>
          <div class="form-input">
            <!-- <select v-model="selected"> -->
            <!-- <div
              class="form-button blue m-0 lato-regular"
              @click="fetchWorkingHours()"
            >fetchWorkingHours</div>-->
            <div
              v-if="!openWorkingHoursSection"
              class="m-t-8 form-button blue m-0 lato-regular"
              @click="toggleWorkingHoursSection()"
            >Add Hours</div>
            <div style="width:100%" v-else>
              <div class style="display: flex;">
                <div class="working-hours-field-title">Timezone</div>
                <div
                  class="required"
                  style="font-weight: bold; margin-top: 20px; margin-left: 2px; font-size: 14px;"
                >*</div>
              </div>
              <multiselect
                v-if="timeZoneList.length > 0"
                v-model="workingHours.timezone"
                :options="timeZoneList"
                :searchable="true"
                :close-on-select="true"
                placeholder="Please select your timezone"
                :show-labels="false"
                class="contact_input"
                style="border-radius: 2px;"
              ></multiselect>
              <div style="width:100%">
                <div class="working-hours-field-title">Schedule hours</div>
                <div style="width:100%" v-if="workingHours.days">
                  <div id="monday-div" class="work_hours">
                    <div class="day-name">
                      <input
                        type="checkbox"
                        id="monday"
                        @change="onStatusChange(workingHours.days[1])"
                        v-model="workingHours.days[1].working"
                      >
                      <div style="padding-left: 5px">
                        <div>Monday</div>
                      </div>
                    </div>
                    <div class="day-body">
                      <div v-if="!workingHours.days[1].working">Closed all day</div>
                      <div
                        v-else
                        v-for="(time, timeIndex) in workingHours.days[1].working_hours"
                        :key="timeIndex"
                        class="select-time"
                      >
                        <div class="select-time">
                          <div style="width: 122px">
                            <!-- track-by="label_id" -->
                            <multiselect
                              v-if="hoursDropdownListStart.length > 0"
                              v-model="workingHours.days[1].working_hours[timeIndex].from"
                              :options="hoursDropdownListStart"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="Start Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                          <div style="width: 122px; margin-left: 5px">
                            <multiselect
                              v-if="hoursDropdownListEnd.length > 0"
                              v-model="workingHours.days[1].working_hours[timeIndex].to"
                              :options="hoursDropdownListEnd"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="End Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                        </div>
                        <div>
                          <div
                            class="form-button red m-0 lato-regular"
                            v-if="showRemoveTiming(workingHours.days[1], timeIndex)"
                            @click="removeTimingStatus(workingHours.days[1], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >-</div>
                          <div
                            class="form-button blue m-0 lato-regular"
                            v-if="showAddTiming(workingHours.days[1], timeIndex)"
                            @click="addTimingStatus(workingHours.days[1], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >+</div>
                        </div>
                        <!-- @close="validationRegex(item, index, $event, false)" -->
                      </div>
                    </div>
                  </div>
                  <div id="tueday-div" class="work_hours">
                    <div class="day-name">
                      <input
                        type="checkbox"
                        id="tueday"
                        @change="onStatusChange(workingHours.days[2])"
                        v-model="workingHours.days[2].working"
                      >
                      <div style="padding-left: 5px">
                        <div>Tuesday</div>
                      </div>
                    </div>
                    <div class="day-body">
                      <div v-if="!workingHours.days[2].working">Closed all day</div>
                      <div
                        v-else
                        v-for="(time, timeIndex) in workingHours.days[2].working_hours"
                        :key="timeIndex"
                        class="select-time"
                      >
                        <div class="select-time">
                          <div style="width: 122px">
                            <!-- track-by="label_id" -->
                            <multiselect
                              v-if="hoursDropdownListStart.length > 0"
                              v-model="workingHours.days[2].working_hours[timeIndex].from"
                              :options="hoursDropdownListStart"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="Start Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                          <div style="width: 122px; margin-left: 5px">
                            <multiselect
                              v-if="hoursDropdownListEnd.length > 0"
                              v-model="workingHours.days[2].working_hours[timeIndex].to"
                              :options="hoursDropdownListEnd"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="End Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                        </div>
                        <div>
                          <div
                            class="form-button red m-0 lato-regular"
                            v-if="showRemoveTiming(workingHours.days[2], timeIndex)"
                            @click="removeTimingStatus(workingHours.days[2], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >-</div>
                          <div
                            class="form-button blue m-0 lato-regular"
                            v-if="showAddTiming(workingHours.days[2], timeIndex)"
                            @click="addTimingStatus(workingHours.days[2], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >+</div>
                        </div>
                        <!-- @close="validationRegex(item, index, $event, false)" -->
                      </div>
                    </div>
                  </div>
                  <div id="wednesday-div" class="work_hours">
                    <div class="day-name">
                      <input
                        type="checkbox"
                        id="wednesday"
                        @change="onStatusChange(workingHours.days[3])"
                        v-model="workingHours.days[3].working"
                      >
                      <div style="padding-left: 5px">
                        <div>Wednesday</div>
                      </div>
                    </div>
                    <div class="day-body">
                      <div v-if="!workingHours.days[3].working">Closed all day</div>
                      <div
                        v-else
                        v-for="(time, timeIndex) in workingHours.days[3].working_hours"
                        :key="timeIndex"
                        class="select-time"
                      >
                        <div class="select-time">
                          <div style="width: 122px">
                            <!-- track-by="label_id" -->
                            <multiselect
                              v-if="hoursDropdownListStart.length > 0"
                              v-model="workingHours.days[3].working_hours[timeIndex].from"
                              :options="hoursDropdownListStart"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="Start Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                          <div style="width: 122px; margin-left: 5px">
                            <multiselect
                              v-if="hoursDropdownListEnd.length > 0"
                              v-model="workingHours.days[3].working_hours[timeIndex].to"
                              :options="hoursDropdownListEnd"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="End Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                        </div>
                        <div>
                          <div
                            class="form-button red m-0 lato-regular"
                            v-if="showRemoveTiming(workingHours.days[3], timeIndex)"
                            @click="removeTimingStatus(workingHours.days[3], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >-</div>
                          <div
                            class="form-button blue m-0 lato-regular"
                            v-if="showAddTiming(workingHours.days[3], timeIndex)"
                            @click="addTimingStatus(workingHours.days[3], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >+</div>
                        </div>
                        <!-- @close="validationRegex(item, index, $event, false)" -->
                      </div>
                    </div>
                  </div>
                  <div id="thursday-div" class="work_hours">
                    <div class="day-name">
                      <input
                        type="checkbox"
                        id="thursday"
                        @change="onStatusChange(workingHours.days[4])"
                        v-model="workingHours.days[4].working"
                      >
                      <div style="padding-left: 5px">
                        <div>Thursday</div>
                      </div>
                    </div>
                    <div class="day-body">
                      <div v-if="!workingHours.days[4].working">Closed all day</div>
                      <div
                        v-else
                        v-for="(time, timeIndex) in workingHours.days[4].working_hours"
                        :key="timeIndex"
                        class="select-time"
                      >
                        <div class="select-time">
                          <div style="width: 122px">
                            <!-- track-by="label_id" -->
                            <multiselect
                              v-if="hoursDropdownListStart.length > 0"
                              v-model="workingHours.days[4].working_hours[timeIndex].from"
                              :options="hoursDropdownListStart"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="Start Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                          <div style="width: 122px; margin-left: 5px">
                            <multiselect
                              v-if="hoursDropdownListEnd.length > 0"
                              v-model="workingHours.days[4].working_hours[timeIndex].to"
                              :options="hoursDropdownListEnd"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="End Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                        </div>
                        <div>
                          <div
                            class="form-button red m-0 lato-regular"
                            v-if="showRemoveTiming(workingHours.days[4], timeIndex)"
                            @click="removeTimingStatus(workingHours.days[4], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >-</div>
                          <div
                            class="form-button blue m-0 lato-regular"
                            v-if="showAddTiming(workingHours.days[4], timeIndex)"
                            @click="addTimingStatus(workingHours.days[4], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >+</div>
                        </div>
                        <!-- @close="validationRegex(item, index, $event, false)" -->
                      </div>
                    </div>
                  </div>
                  <div id="friday-div" class="work_hours">
                    <div class="day-name">
                      <input
                        type="checkbox"
                        id="friday"
                        @change="onStatusChange(workingHours.days[5])"
                        v-model="workingHours.days[5].working"
                      >
                      <div style="padding-left: 5px">
                        <div>Friday</div>
                      </div>
                    </div>
                    <div class="day-body">
                      <div v-if="!workingHours.days[5].working">Closed all day</div>
                      <div
                        v-else
                        v-for="(time, timeIndex) in workingHours.days[5].working_hours"
                        :key="timeIndex"
                        class="select-time"
                      >
                        <div class="select-time">
                          <div style="width: 122px">
                            <!-- track-by="label_id" -->
                            <multiselect
                              v-if="hoursDropdownListStart.length > 0"
                              v-model="workingHours.days[5].working_hours[timeIndex].from"
                              :options="hoursDropdownListStart"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="Start Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                          <div style="width: 122px; margin-left: 5px">
                            <multiselect
                              v-if="hoursDropdownListEnd.length > 0"
                              v-model="workingHours.days[5].working_hours[timeIndex].to"
                              :options="hoursDropdownListEnd"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="End Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                        </div>
                        <div>
                          <div
                            class="form-button red m-0 lato-regular"
                            v-if="showRemoveTiming(workingHours.days[5], timeIndex)"
                            @click="removeTimingStatus(workingHours.days[5], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >-</div>
                          <div
                            class="form-button blue m-0 lato-regular"
                            v-if="showAddTiming(workingHours.days[5], timeIndex)"
                            @click="addTimingStatus(workingHours.days[5], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >+</div>
                        </div>
                        <!-- @close="validationRegex(item, index, $event, false)" -->
                      </div>
                    </div>
                  </div>
                  <div id="saturday-div" class="work_hours">
                    <div class="day-name">
                      <input
                        type="checkbox"
                        id="saturday"
                        @change="onStatusChange(workingHours.days[6])"
                        v-model="workingHours.days[6].working"
                      >
                      <div style="padding-left: 5px">
                        <div>Saturday</div>
                      </div>
                    </div>
                    <div class="day-body">
                      <div v-if="!workingHours.days[6].working">Closed all day</div>
                      <div
                        v-else
                        v-for="(time, timeIndex) in workingHours.days[6].working_hours"
                        :key="timeIndex"
                        class="select-time"
                      >
                        <div class="select-time">
                          <div style="width: 122px">
                            <!-- track-by="label_id" -->
                            <multiselect
                              v-if="hoursDropdownListStart.length > 0"
                              v-model="workingHours.days[6].working_hours[timeIndex].from"
                              :options="hoursDropdownListStart"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="Start Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                          <div style="width: 122px; margin-left: 5px">
                            <multiselect
                              v-if="hoursDropdownListEnd.length > 0"
                              v-model="workingHours.days[6].working_hours[timeIndex].to"
                              :options="hoursDropdownListEnd"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="End Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                        </div>
                        <div>
                          <div
                            class="form-button red m-0 lato-regular"
                            v-if="showRemoveTiming(workingHours.days[6], timeIndex)"
                            @click="removeTimingStatus(workingHours.days[6], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >-</div>
                          <div
                            class="form-button blue m-0 lato-regular"
                            v-if="showAddTiming(workingHours.days[6], timeIndex)"
                            @click="addTimingStatus(workingHours.days[6], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >+</div>
                        </div>
                        <!-- @close="validationRegex(item, index, $event, false)" -->
                      </div>
                    </div>
                  </div>
                  <div id="sunday-div" class="work_hours">
                    <div class="day-name">
                      <input
                        type="checkbox"
                        id="sunday"
                        @change="onStatusChange(workingHours.days[0])"
                        v-model="workingHours.days[0].working"
                      >
                      <div style="padding-left: 5px">
                        <div>Sunday</div>
                      </div>
                    </div>
                    <div class="day-body">
                      <div v-if="!workingHours.days[0].working">Closed all day</div>
                      <div
                        v-else
                        v-for="(time, timeIndex) in workingHours.days[0].working_hours"
                        :key="timeIndex"
                        class="select-time"
                      >
                        <div class="select-time">
                          <div style="width: 122px">
                            <!-- track-by="label_id" -->
                            <multiselect
                              v-if="hoursDropdownListStart.length > 0"
                              v-model="workingHours.days[0].working_hours[timeIndex].from"
                              :options="hoursDropdownListStart"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="Start Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                          <div style="width: 122px; margin-left: 5px">
                            <multiselect
                              v-if="hoursDropdownListEnd.length > 0"
                              v-model="workingHours.days[0].working_hours[timeIndex].to"
                              :options="hoursDropdownListEnd"
                              label="readable"
                              :searchable="true"
                              :close-on-select="true"
                              placeholder="End Time"
                              :show-labels="false"
                              class="contact_input"
                              style="border-radius: 2px;"
                            ></multiselect>
                          </div>
                        </div>
                        <div>
                          <div
                            class="form-button red m-0 lato-regular"
                            v-if="showRemoveTiming(workingHours.days[0], timeIndex)"
                            @click="removeTimingStatus(workingHours.days[0], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >-</div>
                          <div
                            class="form-button blue m-0 lato-regular"
                            v-if="showAddTiming(workingHours.days[0], timeIndex)"
                            @click="addTimingStatus(workingHours.days[0], timeIndex)"
                            style="height: 30px; width: 30px; padding: 4px; font-size: 18px;"
                          >+</div>
                        </div>
                        <!-- @close="validationRegex(item, index, $event, false)" -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-t-8">
                <div
                  v-if="workingHours.days != null && !askDeleteWorkingHoursConfirmation"
                  class="form-button red m-0 lato-regular"
                  @click="askDeleteWorkingHoursConfirmation = !askDeleteWorkingHoursConfirmation"
                >Remove Working Hours</div>
                <div v-if="askDeleteWorkingHoursConfirmation == true">
                  <div
                    style="font-size: 14px;"
                    class="delete-confirmation-title"
                  >Are you sure you want to remove working hours?</div>
                  <div class="display-flex-space-between">
                    <div
                      class="form-button red m-t-16"
                      @click="confirmWorkingHoursDeletion()"
                      style="width: 118px"
                    >Delete</div>
                    <div
                      class="form-button grey m-t-16"
                      @click="askDeleteWorkingHoursConfirmation = !askDeleteWorkingHoursConfirmation"
                      style="width: 118px"
                    >Cancel</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-form-actions">
        <div
          class="form-button blue m-r-24 button-fixed-width"
          @click="updatePermissionInfo"
        >Save Changes</div>
        <div class="form-button grey button-fixed-width" @click="cancelUpdate">Cancel</div>
      </div>
    </div>
    <div class="scrollable-div" v-if="selectedTab == 'labels'">
      <div class="setting-form">
        <div class="form-field">
          <div class="form-label display-flex-space-between">
            <div>All labels</div>
            <div v-if="!labels.showAddPopup">
              <div class="form-button blue m-0 lato-regular" @click="openAddLabelPopup()">Add Label</div>
            </div>
            <div v-else style="display:flex">
              <input class="field" v-model.trim="newLabelName" :placeholder="'Label name'">
              <div class="form-button blue" @click="createLabel()" style="margin-left: 8px">Save</div>
              <div
                class="form-button grey"
                @click="labels.showAddPopup = false;"
                style="margin-left: 8px"
              >Cancel</div>
            </div>
          </div>
        </div>
        <div>
          <p
            v-if="!labels.isFetchingLabels  && labelList.length == 0"
            class="no-items-error-message"
          >{{ $t('LABEL_MGMT.LIST.404') }}</p>
          <woot-loading-state v-if="labels.isFetchingLabels" :message="$t('LABEL_MGMT.LOADING')"/>
          <table v-if="labelList.length > 0" class="simple-table">
            <tr class="simple-table-header border_bottom">
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
            <div class="think-border"></div>

            <tr class="border_bottom" v-for="(label, index) in labelList" :key="index">
              <td style="width: 20%; word-break: break-all;">{{label.label_id}}</td>
              <td style="width: 50% ; word-break: break-all;" class="p-r-24">
                <div v-if="label.label_id == labels.selectedLabel.label_id">
                  <input v-model.trim="label.name" type="text" placeholder="Label name">
                </div>
                <span v-else>{{label.name}}</span>
              </td>
              <td style="width: 30%">
                <div>
                  <div
                    v-if="label.label_id == labels.selectedLabel.label_id"
                    style="display: flex; align-self: flex-start;"
                  >
                    <div class="form-button blue" @click="saveEditLabel(label, index)">Save Changes</div>
                    <div
                      class="form-button grey"
                      @click="hideEditPopup(label, index)"
                      style="margin-left: 16px;"
                    >Cancel</div>
                  </div>
                  <div v-if="label.label_id == labels.deleteLabel.label_id">
                    <div class="delete-confirmation-title">Are you sure you want to delete this?</div>
                    <div class="display-flex-space-between">
                      <div
                        class="form-button red m-t-16"
                        @click="confirmDeletion(label, index)"
                        style="width: 118px"
                      >Delete</div>
                      <div
                        class="form-button grey m-t-16"
                        @click="closeDeletePopup(label, index)"
                        style="width: 118px"
                      >Cancel</div>
                    </div>
                  </div>
                  <div
                    v-if="label.label_id != labels.deleteLabel.label_id && label.label_id != labels.selectedLabel.label_id"
                    style="display: flex; align-self: flex-start;"
                  >
                    <div class="agent-action-options" @click="openEditPopup(label, index)">
                      <img src="~/assets/new_images/Edit_Icon.svg">
                    </div>
                    <div
                      class="agent-action-options"
                      @click="openDeletePopup(label, index)"
                      style="margin-left: 16px;"
                    >
                      <img src="~/assets/new_images/Delete_Icon.svg">
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-console: 0 */
/* global bus */
import { mapGetters } from 'vuex';
import {
  createWebsiteWidgetScript,
  createMessengerScript,
} from 'dashboard/helper/scriptGenerator';
import { Chrome } from 'vue-color';
import SettingsFormHeader from '../../../../components/SettingsFormHeader.vue';
import SettingsFormBody from '../../../../components/SettingsFormBody.vue';
import SettingsSdkHeader from '../../../../components/SettingsSdkHeader.vue';
import AddLabel from './labels/AddLabel';
import EditLabel from './labels/EditLabel';
import DeleteLabel from './labels/DeleteLabel';
import BotIcon from './widget/BotIcon';
import WidgetIcon from './widget/WidgetIcon';
import QWThumbnail from '../../../../components/widgets/QWThumbnail';
import { mixin as clickaway } from 'vue-clickaway';
import timeMixin from '../../../../mixins/time';
import Vue from 'vue';

export default {
  mixins: [clickaway, timeMixin],
  components: {
    Chrome,
    SettingsFormHeader,
    SettingsFormBody,
    SettingsSdkHeader,
    AddLabel,
    EditLabel,
    DeleteLabel,
    BotIcon,
    WidgetIcon,
    QWThumbnail,
  },
  data() {
    return {
      selectedTab: 'general',
      agentDropdownShow: false,
      newLabelName: null,
      selectedAgents: [],
      loading: {},
      labelList: [],
      events: {},
      workingHours: {},
      oldWorkingHours: {},
      labels: {
        isFetchingLabels: false,
        showAddPopup: false,
        showEditPopup: false,
        showDeleteConfirmationPopup: false,
        selectedLabel: {},
        deleteLabel: {},
      },
      timeZoneList: [],
      selectedTimezone: null,
      openWorkingHoursSection: false,
      hoursDropdownListStart: [],
      hoursDropdownListEnd: [],
      askDeleteWorkingHoursConfirmation: false,
      isUpdating: false,
      isAgentListUpdating: false,
      isWebhookUpdating: false,
      isMultichannelAgentWebhookUpdating: false,
      areIntrosUpdating: false,
      isBoticonUpdating: false,
      isWidgeticonUpdating: false,
      isServerKeyUpdating: false,
      isRenderLocationUpdating: false,
      isAgentAssignmentUpdating: false,
      isRenderFileUploadUpdating: false,
      isNotificationSoundSettingUpdating: false,
      isEventsSettingUpdating: false,
      inboxName: null,
      isInboxNameUpdating: false,
      title: null,
    };
  },
  created() {
    this.title = window.chatwootConfig.custom_website.title;
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'inboxes/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      var inbox = this.$store.getters['inboxes/getInbox'](this.currentInboxId);
      this.inboxName = inbox.name;
      if (inbox.widget_background_color == null) {
        inbox.widget_background_color = '#ecf3f9';
      }
      if (inbox.widget_font_size == null) {
        inbox.widget_font_size = '14px';
      }
      return inbox;
    },
    webWidgetScript() {
      return createWebsiteWidgetScript(this.inbox.website_token);
    },
    messengerScript() {
      return createMessengerScript(this.inbox.page_id);
    },
    deleteConfirmText() {
      return `${this.$t('LABEL_MGMT.DELETE.CONFIRM.YES')} ${
        this.labels.selectedLabel.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('LABEL_MGMT.DELETE.CONFIRM.NO')} ${
        this.labels.selectedLabel.name
      }`;
    },
    deleteMessage() {
      return `${this.$t('LABEL_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.labels.selectedLabel.name
      } ?`;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'settings_inbox_show') {
        this.fetchInboxSettings();
      }
    },
  },
  mounted() {
    this.fetchInboxSettings();
    this.timeZoneList = this.getTimezoneList();
    this.getDropdownOfWorkingHoursStart();
    this.getDropdownOfWorkingHoursEnd();
  },
  methods: {
    toggleWorkingHoursSection() {
      this.openWorkingHoursSection = !this.openWorkingHoursSection;
    },
    getDropdownOfWorkingHoursStart() {
      var x = 15; //minutes interval
      var times = []; // time array
      var tt = 0; // start time
      var ap = ['AM', 'PM']; // AM-PM

      //loop to increment the time and push results in array
      for (var i = 0; tt < 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        var mm = tt % 60; // getting minutes of the hour in 0-55 format
        var hours = ('0' + (hh % 12)).slice(-2);
        var hours_to_store_in_24 = parseInt(('0' + hh).slice(-2));
        var minutes = ('0' + mm).slice(-2);
        var minutes_to_store = parseInt(('0' + mm).slice(-2));

        var localDate = new Date();
        var offset = localDate.getTimezoneOffset();

        localDate.setHours(hours_to_store_in_24);
        localDate.setMinutes(minutes_to_store);

        var prefix = Math.floor(hh / 12);
        
        this.hoursDropdownListStart[i] = {
          readable: hours + ':' + minutes + ' ' + ap[prefix],
          hours: hours_to_store_in_24,
          minutes: minutes_to_store,
          offset: offset,
        };

        tt = tt + x;
      }
    },
    getDropdownOfWorkingHoursEnd() {
      var x = 15; //minutes interval
      var times = []; // time array
      var tt = 15; // start time
      var ap = ['AM', 'PM']; // AM-PM

      //loop to increment the time and push results in array
      for (var i = 0; tt < 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        var mm = tt % 60; // getting minutes of the hour in 0-55 format
        var hours = ('0' + (hh % 12)).slice(-2);
        var hours_to_store_in_24 = parseInt(('0' + hh).slice(-2));
        var minutes = ('0' + mm).slice(-2);
        var minutes_to_store = parseInt(('0' + mm).slice(-2));

        var localDate = new Date();
        var offset = localDate.getTimezoneOffset();
        localDate.setHours(hours_to_store_in_24);
        localDate.setMinutes(minutes_to_store);

        var prefix = Math.floor(hh / 12);
        this.hoursDropdownListEnd[i] = {
          readable: hours + ':' + minutes + ' ' + ap[prefix],
          hours: hours_to_store_in_24,
          minutes: minutes_to_store,
          offset: offset,
        }; // pushing data in array in [00:00 - 12:00 AM/PM format]

        tt = tt + x;
      }

      var localDate = new Date();
      var offset = localDate.getTimezoneOffset();
      localDate.setHours(23);
      localDate.setMinutes(59);
      this.hoursDropdownListEnd[i] = {
        readable: '11:59 PM',
        hours: 23,
        minutes: 59,
        offset: offset,
      }; // pushing data in array in [00:00 - 12:00 AM/PM format]
    },
    onStatusChange(day) {
      if (day.working) {
        if (day.working_hours.length < 1) {
          var startTime = this.hoursDropdownListStart[0];
          // console.log("hoursDropdownListStart.length: ", this.hoursDropdownListStart.length);
          
          var endTime = this.hoursDropdownListEnd[this.hoursDropdownListEnd.length - 1];
          // day.working_hours.push();
          day.working_hours.push({
            to: endTime,
            from: startTime,
          });
        }
      } else {
        var itemsToBeRemoved = [];
        for (let index = 0; index < day.working_hours.length; index++) {
          const element = day.working_hours[index];
          if (element.from == null) {
            itemsToBeRemoved.push(index);
          } else if (element.to == null) {
            itemsToBeRemoved.push(index);
          }
        }
        for (let index = 0; index < itemsToBeRemoved.length; index++) {
          const element = itemsToBeRemoved[index];
          day.working_hours.splice(element, 1);

          if (itemsToBeRemoved.length - 1 == index) {
            itemsToBeRemoved = [];
          }
        }
      }
    },
    addTimingStatus(day, timeIndex) {
      day.working_hours.push({
        from: null,
        to: null,
      });
    },
    removeTimingStatus(day, timeIndex) {
      day.working_hours.splice(timeIndex, 1);
    },
    showAddTiming(day, timeIndex) {
      return false;
      // var element = day.working_hours[timeIndex];
      // if ((day.working_hours.length - 1) == timeIndex) {
      //   if (element.from == null) {
      //     return false;
      //   } else if (element.to == null){
      //     return false;
      //   };
      //   return true;
      // }
      // return false;
    },
    showRemoveTiming(day, timeIndex) {
      return false;
      // var element = day.working_hours[timeIndex];
      // if (timeIndex == 0) {
      //   return false;
      // }
      // return true;
    },
    agentSelected(item, index) {
      this.selectedAgents.push(item);
    },
    showIfNotSelected(item, index) {
      const [present] = this.selectedAgents.filter(
        agent => item.id === agent.id
      );
      if (present) {
        return false;
      } else {
        return true;
      }
    },
    removeAgentFromSelected(item, index) {
      this.selectedAgents.splice(index, 1);
    },
    onToggleDropdown() {
      this.agentDropdownShow = !this.agentDropdownShow;
    },
    openInNewWindow(link) {
      window.open(link, '_blank');
    },
    openAddLabelPopup() {
      this.labels.showAddPopup = true;
    },
    hideAddLabelPopup() {
      this.labels.showAddPopup = false;
      this.newLabelName = null;
      this.fetchLabels();
    },
    openEditPopup(label, index) {
      this.labels.selectedLabel = Object.assign({}, label);
    },
    hideEditPopup(label, index) {
      label.name = this.labels.selectedLabel.name;
      this.labels.selectedLabel = {};
    },
    // Delete Modal Functions
    openDeletePopup(label, index) {
      this.labels.deleteLabel = label;
    },
    closeDeletePopup(label, index) {
      this.labels.deleteLabel = {};
    },
    confirmDeletion(label, index) {
      this.deleteCannedResponse(this.labels.deleteLabel.label_id);
      this.closeDeletePopup(label, index);
    },

    createLabel() {
      this.$store
        .dispatch('inboxLabels/create', {
          id: this.currentInboxId,
          name: this.newLabelName,
        })
        .then(() => {
          this.showAlert(this.$t('LABEL_MGMT.ADD.API.SUCCESS_MESSAGE'));
          this.hideAddLabelPopup();
        })
        .catch(error => {
          // console.log(error.response);
          try {
            if (error.response.data.error == 'Label with same name already exists!') {
              this.showAlert(this.$t('LABEL_MGMT.ADD.API.ALREADY_EXISTS_MESSAGE'));
            } else if (error.response.data.error) {
              this.showAlert(error.response.data.error);
            } else if (error.response.data.message) {
              this.showAlert(error.response.data.message);
            } else {
              this.showAlert(this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE'));
            }
          } catch (error) {
            this.showAlert(this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE'));
          }
        });
    },

    async updateGeneralInfo() {
      try {
        if (this.inbox.widget_font_size.match("^[0-9.]+$")) {
          this.inbox.widget_font_size = this.inbox.widget_font_size.trim() + 'px'
        }
        var response = await this.$store.dispatch(
          'inboxes/updateInboxSettings',
          {
            id: this.inbox.channel_id,
            website: {
              inbox_name: this.inboxName,
              intro_heading: this.inbox.intro_heading,
              intro_body: this.inbox.intro_body,
              widget_color: this.getWidgetColor(this.inbox.widget_color),
              widget_background_color: this.getWidgetBackgroundColor(this.inbox.widget_background_color),
              widget_font_size: this.inbox.widget_font_size.trim(),
              bot_icon: this.inbox.bot_icon,
              widget_icon: this.inbox.widget_icon,
            },
          }
        );

        if (response.status == 200) {
          this.$store.dispatch('inboxes/get');
          this.showAlert('Changes saved successfully!');
        } else {
          this.showAlert(
            'Sorry could not updated changes. Please try again later!'
          );
          console.error(response);
        }
      } catch (error) {
        this.showAlert(
          'Sorry could not updated changes. Please try again later!'
        );
        console.error(error);
      }
    },
    async updateWorkingHoursSetting() {
      await this.$store.dispatch('inboxes/updateWorkingHours', {
        id: this.inbox.id,
        working_hour: this.workingHours,
      });
    },
    async confirmWorkingHoursDeletion() {
      this.workingHours.days = null;
      await this.$store.dispatch('inboxes/updateWorkingHours', {
        id: this.inbox.id,
        working_hour: this.workingHours,
      });
      this.openWorkingHoursSection = false;
      this.workingHours.days = [];
    },
    isSaveChangesInWorkingHours() {
      var changes, i, item, j, len;
      if (
        JSON.stringify(this.oldWorkingHours.days) ===
        JSON.stringify(this.workingHours.days)
      ) {
        return false;
      }
      changes = [];
      for (i = j = 0, len = this.workingHours.days.length; j < len; i = ++j) {
        item = this.workingHours.days[i];
        if (
          JSON.stringify(item) !== JSON.stringify(this.oldWorkingHours.days[i])
        ) {
          changes.push(item);
        }
      }
      if (changes.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    async updateAgentInfo() {
      try {
        this.updateAgents();
        var response = await this.$store.dispatch(
          'inboxes/updateInboxSettings',
          {
            id: this.inbox.channel_id,
            website: {
              agent_assignment: this.inbox.agent_assignment,
            },
          }
        );
        if (response.status == 200) {
          this.$store.dispatch('inboxes/get');
          this.showAlert('Changes saved successfully!');
        } else {
          this.showAlert(
            'Sorry could not updated changes. Please try again later!'
          );
          console.error(response);
        }
      } catch (error) {
        this.showAlert(
          'Sorry could not updated changes. Please try again later!'
        );
        console.error(error);
      }
    },
    async updateWebhookInfo() {
      try {
        this.updateEventsSetting();
        var response = await this.$store.dispatch(
          'inboxes/updateInboxSettings',
          {
            id: this.inbox.channel_id,
            website: {
              webhook: this.inbox.webhook,
              mc_agent_webhook: this.inbox.mc_agent_webhook,
            },
          }
        );
        if (response.status == 200) {
          this.$store.dispatch('inboxes/get');
          this.showAlert('Changes saved successfully!');
        } else {
          this.showAlert(
            'Sorry could not updated changes. Please try again later!'
          );
          console.error(response);
        }
      } catch (error) {
        this.showAlert(
          'Sorry could not updated changes. Please try again later!'
        );
        console.error(error);
      }
    },
    async updatePermissionInfo() {
      try {
        var response = await this.$store.dispatch(
          'inboxes/updateInboxSettings',
          {
            id: this.inbox.channel_id,
            website: {
              render_location: this.inbox.render_location,
              render_file_upload: this.inbox.render_file_upload,
              desktop_notification_sound: this.inbox.desktop_notification_sound,
              default_conversation_status: this.inbox.default_conversation_status,
              firebase_server_key: this.inbox.firebase_server_key,
            },
          }
        );

        if (this.openWorkingHoursSection) {
          if (
            this.workingHours.timezone != null &&
            this.workingHours.timezone != 'null'
          ) {
            this.updateWorkingHoursSetting();
          } else {
            this.showAlert('Error: Timezone is mandatory!');
            return;
          }
        }

        if (response.status == 200) {
          this.$store.dispatch('inboxes/get');
          this.showAlert('Changes saved successfully!');
        } else {
          this.showAlert(
            'Sorry could not updated changes. Please try again later!'
          );
          console.error(response);
        }
      } catch (error) {
        this.showAlert(
          'Sorry could not updated changes. Please try again later!'
        );
        console.error(error);
      }
    },

    cancelUpdate() {
      this.fetchInboxSettings();
    },

    deleteCannedResponse(id) {
      this.$store
        .dispatch('inboxLabels/delete', id)
        .then(() => {
          this.showAlert(this.$t('LABEL_MGMT.DELETE.API.SUCCESS_MESSAGE'));
          this.fetchLabels();
          this.$store.dispatch('inboxes/get');
        })
        .catch(() => {
          this.showAlert(this.$t('LABEL_MGMT.DELETE.API.ERROR_MESSAGE'));
        });
    },
    saveEditLabel(label, index) {
      this.$store
        .dispatch('inboxLabels/update', {
          id: label.label_id,
          inbox_id: this.currentInboxId,
          name: label.name,
        })
        .then(() => {
          this.showAlert(this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'));
          this.labels.selectedLabel = {};
          this.$store.dispatch('inboxes/get');
        })
        .catch(error => {
          try {
            if (
              error.response.data.error ==
              'Label with same name already exists!'
            ) {
              this.showAlert(
                this.$t('LABEL_MGMT.ADD.API.ALREADY_EXISTS_MESSAGE')
              );
            } else if (error.response.data.error) {
              this.showAlert(error.response.data.error);
            } else if (error.response.data.message) {
              this.showAlert(error.response.data.message);
            } else {
              this.showAlert(this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE'));
            }
          } catch (error) {
            this.showAlert(this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE'));
          }
        });
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    fetchInboxSettings() {
      this.selectedAgents = [];
      this.labelList = [];
      this.openWorkingHoursSection = false;
      this.$store.dispatch('agents/get');
      this.$store.dispatch('inboxes/get').then(() => {
        this.fetchAttachedAgents();
        this.fetchLabels();
        this.fetchInboxEvents();
        this.fetchWorkingHours();
      });
    },
    async fetchLabels() {
      this.labels.isFetchingLabels = true;
      try {
        const response = await this.$store.dispatch('inboxLabels/get', {
          inboxId: this.currentInboxId,
        });
        const {
          data: { payload },
        } = response;
        this.labelList = payload;
      } catch (error) {
        console.log(error);
      }
      this.labels.isFetchingLabels = false;
    },
    async fetchAttachedAgents() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.currentInboxId,
        });
        const {
          data: { payload },
        } = response;
        payload.forEach(el => {
          const [item] = this.agentList.filter(
            agent => agent.id === el.user_id
          );
          if (item) {
            this.selectedAgents.push(item);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchInboxEvents() {
      try {
        const response = await this.$store.dispatch('inboxes/getInboxEvents', {
          inboxId: this.currentInboxId,
        });
        const {
          data: { payload },
        } = response;
        this.events = payload;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWorkingHours() {
      try {
        const response = await this.$store.dispatch('inboxes/getWorkingHours', {
          inboxId: this.currentInboxId,
        });
        const {
          data: { payload },
        } = response;
        this.workingHours = payload;
        // day 0 = Monday
        if (this.workingHours.days == null) {
          this.workingHours.days = {
            0: {
              day: 0,
              working: false,
              working_hours: [],
            },
            1: {
              day: 1,
              working: false,
              working_hours: [],
            },
            2: {
              day: 2,
              working: false,
              working_hours: [],
            },
            3: {
              day: 3,
              working: false,
              working_hours: [],
            },
            4: {
              day: 4,
              working: false,
              working_hours: [],
            },
            5: {
              day: 5,
              working: false,
              working_hours: [],
            },
            6: {
              day: 6,
              working: false,
              working_hours: [],
            },
          };
        } else {
          this.openWorkingHoursSection = !this.openWorkingHoursSection;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateAgents() {
      const agentList = this.selectedAgents.map(el => el.id);
      await this.$store.dispatch('inboxMembers/create', {
        inboxId: this.currentInboxId,
        agentList,
      });
    },
    async updateEventsSetting() {
      await this.$store.dispatch('inboxes/updateInboxEvents', {
        id: this.inbox.id,
        event: {
          user_created_event: this.events.user_created_event,
          assignee_change_event: this.events.assignee_change_event,
          conversation_status_change_event: this.events.conversation_status_change_event,
          typing_event: this.events.typing_event,
        },
      });
    },
    getWidgetColor() {
      return typeof this.inbox.widget_color !== 'object'
        ? this.inbox.widget_color
        : this.inbox.widget_color.hex8;
    },
    getWidgetBackgroundColor() {
            return typeof this.inbox.widget_background_color !== 'object'
        ? this.inbox.widget_background_color
        : this.inbox.widget_background_color.hex8;
    }
  },
  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
  },
};
</script>




<style lang="scss">
.inbox-all-settings {
  height: calc(100% - 67px);

  .scrollable-div {
    height: calc(100% - 50px);
  }

  .form-help-text {
    margin-top: -1px !important;
    margin-bottom: 15px;
  }

  input {
    height: 32px;
    width: 75%;
    margin-bottom: 0;
  }

  textarea {
    width: 75%;
    margin-bottom: 0;
  }

  .form-button {
    margin-top: 0;
  }

  .simple-table {
    width: 100%;
    border-spacing: 2px;
    border-collapse: separate;
  }

  .simple-table-header {
    font-size: 14px;
    font-weight: bold;
    color: #1a1c1d;
    text-align: left;
  }

  tr.border_bottom th {
    margin-top: 16px;
    border-bottom: 1px solid#969BA7;
    padding-bottom: 20px;
    max-width: 210px;
  }

  tr.border_bottom td {
    border-bottom: 1px solid#EFEEF0;
  }

  tr > td {
    max-width: 258px;
    color: #1a1c1d;
    font-size: 14px;
    padding: 10px 0px;
    vertical-align: top;
  }

  .agent-action-options {
    padding: 7px;
    border: 1px solid #dedddf;
    border-radius: 4px;
    cursor: pointer;
  }

  .agent-action-options:hover {
    border-color: #3970f1;

    img {
      filter: brightness(3.5) sepia(1) hue-rotate(190deg) saturate(6);
    }
  }

  .other-input-type {
    display: flex;
    align-items: center;
  }

  .qw-dropdown-menu-items {
    position: absolute;
    top: 3.5rem;
    background: white;
    border: 1px solid #d4d8dc;
    z-index: 999;
    width: max-content;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    max-height: 250px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px #1a1c1d29;
  }

  .conversation-agent-filter {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid #dedddf;
    border-radius: 4px;
    height: 32px;
    color: #1a1c1d;
    width: 144px;
    font-weight: 500;

    img {
      margin-right: 4px;
    }

    .title {
      color: #1a1c1d;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .conversation-agent-selected-filter {
    margin: 0px 0px 8px 8px;
    display: flex;
    align-items: center;
    border: 1px solid #dedddf;
    border-radius: 4px;
    padding: 4px 8px;
    height: 32px;
    color: #1a1c1d;
    font-weight: 500;
    width: fit-content;

    img {
      margin-right: 4px;
    }

    .title {
      color: #1a1c1d;
      font-size: 14px;
      margin-right: 9px;
    }

    i {
      font-size: 16px;
      padding-top: 5px;
      cursor: pointer;
    }
  }

  .conversation-agent-selected-filter:hover {
    background: #fff8eb;
    border-color: #ffa600;

    i {
      color: #1a1c1d;
    }
  }

  .conversation-agent-filter:hover {
    border-color: #3970f1;
    color: #3970f1;

    .title {
      color: #3970f1;
    }

    img {
      filter: brightness(3.5) sepia(1) hue-rotate(190deg) saturate(6);
    }
  }

  .conversation-agent-filter-element {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    cursor: pointer;

    img {
      margin-right: 4px;
    }

    .title {
      color: inherit;
      font-size: 14px;
      margin-right: 8px;
    }

    .cross-sign {
      border-radius: 50%;
      border: 1px solid #969ba7;
      font-size: 14px;
      padding: 0px 3px;
      height: 16px;
      width: 16px;
      align-self: center;
      color: #969ba7;
    }
  }

  .conversation-agent-single-element {
    display: flex;
    justify-content: space-between;
  }

  .conversation-agent-filter-no-element {
    display: flex;
    padding: 8px 12px;
    .title {
      color: inherit;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .conversation-agent-filter-element:hover {
    background: #3970f1;

    .title {
      color: white;
    }

    .avatar-container {
      background: white !important;
      color: #3970f1 !important;
    }

    .cross-sign {
      color: white;
      border-color: white;
    }
  }

  .work_hours {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0px;
    margin: 10px 0px;
    background: #f8f9fb;
    font-size: 14px;
    min-height: 62px;
    height: 100%;
    align-items: center;

    .day-name {
      display: flex;
      padding: 5px;
      width: 30%;
      color: #1a1c1d;
      font-weight: 500;
      padding-left: 10px;
    }
    .day-body {
      padding: 5px;
      width: 70%;
    }

    .select-time {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }

  .working-hours-field-title {
    font-size: 14px;
    font-weight: 500;
    color: #1a1c1d;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
</style>