<template>
  <div v-if="currentChat.status == 1" class="conversation-resolved addGreen">
    <img src="~/assets/new_images/Resolved_Icon_Small.svg">
    <div class="resolved-text">
      <span>Resolved</span>
    </div>
  </div>
  <div
    v-else
    class="conversation-resolved"
    @click="toggleStatus"
    v-tooltip="{ content: currentStatus + ' Conversation', classes: ['bottom', 'bottom-hover'] }"
  >
    <img src="~/assets/new_images/Resolved_Icon_Small.svg">
  </div>
</template>

<script>
/* eslint no-console: 0 */
/* global bus */
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';

export default {
  props: ['conversationId'],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    currentStatus() {
      const ButtonName = this.currentChat.status === 0 ? 'Resolve' : 'Reopen';
      return ButtonName;
    },
    buttonClass() {
      return this.currentChat.status === 0 ? 'success' : 'warning';
    },
    buttonIconClass() {
      return this.currentChat.status === 0 ? 'ion-checkmark' : 'ion-refresh';
    },
  },
  components: {
    Spinner,
  },
  methods: {
    toggleStatus() {
      this.isLoading = true;
      this.$store.dispatch('toggleStatus', this.currentChat.id).then(() => {
        bus.$emit('newToastMessage', this.$t('CONVERSATION.CHANGE_STATUS'));
        this.isLoading = false;
      });
    },
  },
};
</script>

