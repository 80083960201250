<template>
  <div>
    <div>
      <div class="chat-respond" :class="{ 'private-note-selected': isPrivate }">
        <div class="chat-respond-tabs">
          <div class="reply" :class="{ 'active': !isPrivate }" @click="makeReply">Reply</div>
          <div class="reply" :class="{ 'active': isPrivate }" @click="makePrivate">Private Note</div>
        </div>
        <div v-if="fileUploadStart == true" class="resumable-progress">
          <table style="table-layout: fixed; width: 100%; margin-bottom: 0;">
            <tr>
              <td width="100%">
                <div width="100%" class="file-name" v-bind:style="{ color: textColor }">
                  <div>
                    Uploading :
                    <span v-html="fileUploadStatus"></span>
                  </div>
                  <div>
                    Name :
                    <span v-html="fileUploadName" :title="`${fileUploadName}`"></span>
                  </div>
                </div>
              </td>
              <td>
                <i
                  @click="handleCancelFileUpload"
                  title="Cancel"
                  style="cursor: pointer;"
                  class="fa fa-times"
                ></i>
              </td>
            </tr>
            <tr>
              <td width="100%">
                <div class="progress-container" :style="`background: ${progressBarColor}20`">
                  <div
                    class="progress-bar"
                    :style="`width: ${fileUploadStatus}; background: ${progressBarColor}`"
                  ></div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="send-content">
          <div class="canned-response-selector">
            <canned-response
              style="display: absolute"
              v-if="showCannedResponsesList"
              v-on-clickaway="hideCannedResponse"
              :canned-search-key="cannedSearchKey"
              :on-keyenter="replaceText"
              :on-click="replaceText"
            />
          </div>

          <textarea
            ref="messageInput"
            v-model="message"
            rows="1"
            class="input"
            type="text"
            :placeholder="$t(messagePlaceHolder())"
            @click="onClick()"
            @blur="onBlur()"
            v-on:keyup="onKeyUp()"
          />
        </div>
        <div class="send-options">
          <div class="quick-actions">
            <div style="position: relative">
              <emoji-input
                v-if="showEmojiPicker"
                v-on-clickaway="hideEmojiPicker"
                :on-click="emojiOnClick"
              />
            </div>
            <i
              class="fa fa-smile-o"
              @click="toggleEmojiPicker()"
              aria-hidden="true"
              style="font-size: 20px;color: #969BA7;margin-right: 16px; cursor: pointer;"
            ></i>
            <!-- <i
              class="icon ion-happy-outline"
              :class="{ active: showEmojiPicker }"
              @click="toggleEmojiPicker()"
              style="font-size: 20px; color: #969BA7; margin-right: 16px; cursor: pointer;"
            />-->
            <div>
              <file-upload
                ref="file_upload"
                :on-file-upload="onFileUpload"
                :update-progress="updateProgress"
              />
            </div>

            <!-- <img src="~dashboard/assets/new_images/Attach_Icon.svg"/> -->
          </div>
          <div
            class="send-button"
            :disabled="disableButton()"
            @click="sendMessage"
            :class="{active: (message.length != 0 && message.length < 641) }"
          >Send</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-console: 0 */

import { mapGetters } from 'vuex';
import emojione from 'emojione';
import { mixin as clickaway } from 'vue-clickaway';

import EmojiInput from '../emoji/EmojiInput';
import CannedResponse from './CannedResponse';
import FileUpload from './FileUpload';

export default {
  mixins: [clickaway],
  props: {
    conversationId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      message: '',
      isPrivate: false,
      showEmojiPicker: false,
      showCannedResponsesList: false,
      fileUploadStatus: '0%',
      progressBarColor: '#0f0f6a',
      textColor: 'inherit',
      fileUploadName: '-',
      fileUploadStart: false,
      cannedSearchKey: '',
      isTypingOn: false
    };
  },
  beforeDestroy() {

  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      cannedMessages: 'getCannedResponses',
    }),
    channelType() {
      const {
        meta: {
          sender: { channel },
        },
      } = this.currentChat;
      return channel;
    },
  },
  components: {
    EmojiInput,
    CannedResponse,
    FileUpload,
  },
  watch: {
    conversationId(oldConversationId, newConversationId) {
      if (oldConversationId != newConversationId) {
        var output = this.$store.getters['contextualReplyBoxContent/getReplyBoxTextByConversation'](this.conversationId);
        try {
          this.message = output;
        } catch (error) {
          this.clearMessage(); 
        }
      }
      // this.$refs['messageInput'].focus();
    },
    message(val) {
      if (this.isPrivate) {
        return;
      }
      if (val.length == 1) {
        if (this.isTypingOn == false) {
          this.isTypingOn = true;
          this.toggleTyping('on');
        }
      }
      const isSlashCommand = val[0] === '/';
      // const hasNextWord = val.includes(' ');
      // const isShortCodeActive = isSlashCommand && !hasNextWord;
      const isShortCodeActive = isSlashCommand;
      if (isShortCodeActive) {
        this.showCannedResponsesList = true;
        if (val.length > 1) {
          this.cannedSearchKey = val.substr(1, val.length);
        } else {
          this.cannedSearchKey = '';
          if (this.cannedMessages.length == 0) {
            this.$store.dispatch('getCannedResponse');
          }
        }
      } else {
        this.showCannedResponsesList = false;
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyEvents);
    this.$refs.messageInput.addEventListener('paste', this.pasteFile, false);
    // this.$refs['messageInput'].focus();
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    handleKeyEvents(e) {
      if (this.isEscape(e)) {
        this.hideEmojiPicker();
        this.hideCannedResponse();
      } else if (this.isEnter(e)) {
        if (!e.shiftKey) {
          e.preventDefault();
          this.sendMessage();
        }
      }
    },
    pasteFile(event) {
      if (event.clipboardData.files[0]) {
        this.$refs.file_upload.uploadFile(event);
        event.preventDefault();
        if (event.clipboardData.getData('text')) {
          this.message = this.message.replace(
            event.clipboardData.getData('text')
          );
        }
      }
    },
    handleCancelFileUpload() {
      this.$refs.file_upload.cancelFileUpload();
      this.fileUploadStatus = 'Canceled';
      this.textColor = 'red';
      var _this = this;
      setTimeout(function() {
        _this.fileUploadStatus = '0%';
        _this.textColor = 'black';
        _this.fileUploadStart = false;
      }, 1500);
    },
    isEnter(e) {
      return e.keyCode === 13;
    },
    isEscape(e) {
      return e.keyCode === 27; // ESCAPE
    },
    onFileUpload(file_object, file_type) {
      this.$store
        .dispatch('sendFile', {
          conversationId: this.currentChat.id,
          private: this.isPrivate,
          payload: {
            elements: {
              type: file_type,
              media: [file_object],
            },
          },
          message_type: 3,
          content_type: 8,
        })
        .then(() => {
          this.$emit('scrollToMessage');
        });
    },
    updateProgress(status, fileName, start) {
      this.fileUploadStatus = status;
      this.fileUploadName = fileName;
      this.fileUploadStart = start;

      var _this = this;
      if (status == '100%') {
        setTimeout(function() {
          _this.fileUploadStatus =
            'Completed <i class="fa fa-check-circle"></i>';
          setTimeout(function() {
            _this.fileUploadStart = false;
          }, 500);
        }, 200);
      }
    },
    sendMessage() {
      const isMessageEmpty = !this.message.replace(/\n/g, '').length;
      if (isMessageEmpty) {
        return;
      }
      if (!this.showCannedResponsesList) {
        this.$store
          .dispatch('sendMessage', {
            conversationId: this.currentChat.id,
            message: this.message,
            private: this.isPrivate,
            sender: {
              profile_pic: this.currentUser.profile_pic,
              name: this.currentUser.name,
            },
          })
          .then(() => {
            this.$emit('scrollToMessage');
          }).catch((error) => {
            this.showAlert(error.response.data.message);
          });
        this.clearMessage();
        this.hideEmojiPicker();
        this.storeContextualConversationInputReplyBoxContent();
      }
    },
    replaceText(message) {
      setTimeout(() => {
        this.message = message;
      }, 100);
    },
    makePrivate() {
      this.isPrivate = true;
      this.$refs.messageInput.focus();
    },
    makeReply() {
      this.isPrivate = false;
      this.$refs.messageInput.focus();
    },
    emojiOnClick(emoji) {
      this.message = emojione.shortnameToUnicode(
        `${this.message}${emoji.shortname} `
      );
    },
    clearMessage() {
      this.message = '';
      this.isTypingOn = false;
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    hideCannedResponse() {
      this.showCannedResponsesList = false;
    },

    onBlur() {
      window.clearTimeout(window.typingTimeout);
      this.toggleTyping('off');
      this.isTypingOn = false;
    },

    onKeyUp() {
      window.clearTimeout(window.typingTimeout);
      window.typingTimeout = setTimeout(() => {
        this.toggleTyping('off');
        this.isTypingOn = false;
        window.clearTimeout(window.typingTimeout);
      }, 20000);
    },

    storeContextualConversationInputReplyBoxContent() {
      // Store in front end state with conversation_id
      if (this.message != null || this.message != '') {
        if (this.message.trim() != '') {
          this.$store.getters['contextualReplyBoxContent/setReplyBoxTextByConversation']( this.conversationId, this.message);
        }
      }
      if (this.message.trim() == '') {
        this.$store.getters['contextualReplyBoxContent/removeReplyBoxTextByConversation']( this.conversationId);
      }
    },

    onClick() {
      this.markSeen();
      this.isTypingOn = true;
      this.toggleTyping('on');
    },

    markSeen() {
      if (this.channelType === 'Channel::FacebookPage') {
        this.$store.dispatch('markSeen', {
          inboxId: this.currentChat.inbox_id,
          contactId: this.currentChat.meta.sender.id,
        });
      }
    },

    toggleTyping(status) {
      if (
        this.channelType === 'Channel::FacebookPage' ||
        this.channelType === 'Channel::WebWidget'
      ) {

        if (status === 'off') {
          this.storeContextualConversationInputReplyBoxContent(); 
        }

        this.$store.dispatch('toggleTyping', {
          status,
          conversationId: this.currentChat.id,
        });
      }
    },

    disableButton() {
      const messageHasOnlyNewLines = !this.message.replace(/\n/g, '').length;
      return (
        this.message.length === 0 ||
        this.message.length > 640 ||
        messageHasOnlyNewLines
      );
    },

    messagePlaceHolder() {
      const placeHolder = this.isPrivate
        ? 'CONVERSATION.FOOTER.PRIVATE_MSG_INPUT'
        : 'CONVERSATION.FOOTER.MSG_INPUT';
      return placeHolder;
    },
  },
};
</script>

<style lang="scss">
.resumable-progress {
  width: 100%;
  background: transparent;
  margin-bottom: -6px;
  padding: 10px 10px 0px 0px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.progress-container {
  border-radius: 10px;
  height: 5px;
  position: relative;
}
.progress-bar {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
}

// .progress-text {font-size:11px; line-height:9px; padding-left:10px;}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
  font-size: small;
}
</style>