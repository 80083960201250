<template>
  <modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="'Availability Status'" :header-content="'You are currently inactive and opted out for automatic assignment. Do you want to change your status to active?'" />
    <div class="modal-footer delete-item">
        <div style="width: 100%; display: flex; justify-content: space-between;">
          <div class="form-button blue" @click="setActive" >Set Active</div> 
          <div class="form-button grey" @click="onClose" >Cancel</div> 
        </div>
      </div>
  </modal>
</template>

<script>
import Modal from '../../../components/Modal';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    onClose: Function,
    onConfirm: Function
  },
  methods: {
    async setActive() {
      await this.$store.dispatch('changeAvailabilityStatus', { status: 'active' });
      this.onClose();
    }
  }
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/setting-form.scss';

.modal-mask {
  backdrop-filter: blur(0px);
}
</style>

