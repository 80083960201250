<template>
  <span class="message-text__wrap">
    <!-- <span class="time">{{ readableTime }}</span> -->
      <span v-if="message && contentType == 'text' && messageType == 0 && $sanitize(formatMessage(message)).length > 0" v-html="prepareContent($sanitize(message))"></span>
      <span v-if="message && contentType == 'text' && messageType == 0 && $sanitize(formatMessage(message)).length == 0" v-html="'<i>*Unsupported content*</i>'"></span>
      <span v-if="message && contentType == 'text' && messageType == 1" v-html="prepareContent($sanitize(message))"></span>
      <span v-if="message && messageType != 0 && messageType != 1 " v-html="prepareContent(message)"></span>
    <div>
      <quick-reply
        v-if="shouldShowQuickReply"
        :message-id="messageId"
        :message-content-attributes="messageContentAttributes"
        :payload="payload"
        :content-type="contentType"
      />
    </div>
    <div>
      <carousel
        v-if="shouldShowCarousel"
        :message-id="messageId"
        :message-content-attributes="messageContentAttributes"
        :payload="payload"
        :content-type="contentType"
      />
    </div>
    <div>
      <span v-if="shouldShowTableCard">Table template is sent to user</span>
    </div>
    <div>
      <media
        v-if="shouldShowImageMedia"
        :message-id="messageId"
        :message-content-attributes="messageContentAttributes"
        :payload="payload"
        :content-type="contentType"
      />
    </div>
    <div>
      <Location
        v-if="shouldShowLocationTemplate"
        :payload="payload"
        :content-type="contentType"
        :message-type="messageType"
      />
    </div>
</span>
</template>

<script>
import ButtonTemplate from '../../../../../widget/components/template/ButtonTemplate';
import QuickReply from '../../../../../widget/components/template/QuickReply';
import Carousel from '../../../../../widget/components/template/Carousel';
import Media from '../../../../../widget/components/template/Media';
import Location from '../../../../../widget/components/template/LocationTemplate';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import { mapGetters } from 'vuex';
import TableCard from '../../../../../widget/components/template/TableCard.vue';

export default {

  name: 'AgentMessageBubble',
  components: {
    ButtonTemplate,
    QuickReply,
    Carousel,
    Media,
    Location,
    TableCard
  },
  mixins: [messageFormatterMixin],
  props: {
  	readableTime: String,
    message: String,
    contentType: String,
    payload: {
      type: Object,
      default: () => {},
    },
    messageType: Number,
    messageId: Number,
    messageContentAttributes: {
      type: Object,
      default: () => {},
    },

  },

  mounted() {
    document.querySelectorAll("a").forEach(function(a){
      a.setAttribute('target', '_blank');
    });
  },


  computed: {
    ...mapGetters({
      searchObject: 'globalVariables/getSearchObject',
    }),

    shouldShowButtonTemplate() {
      return this.contentType === 'button_template' && this.messageType === 3;
    },
    shouldShowQuickReply() {
      return this.contentType === 'quick_reply' && this.messageType === 3;
    },
    shouldShowCarousel() {
      return this.contentType === 'carousel' && this.messageType === 3;
    },
    shouldShowTableCard() {
      return this.contentType === 'table_card' && this.messageType === 3;
    },
    shouldShowImageMedia() {
      return this.contentType === 'media' && ( this.messageType === 3 || this.messageType === 0);
    },
    shouldShowLocationTemplate() {
      return this.contentType === 'location' && this.messageType === 0;
   },
  },

  methods: {
    prepareContent(content = '') {
      if (this.searchObject.searchKey == 'text') {
        return content.replace(
          new RegExp(`(${this.searchObject.searchValue})`, 'ig'),
          '<span class="searchkey--highlight">$1</span>'
        );
      } else {
        return content;
      }
    },
  }
}

</script>
