/* eslint no-param-reassign: 0 */
import moment from 'moment';
import Cookies from 'js-cookie';

Cookies.defaults = { sameSite: 'Lax' };

export const getLoadingStatus = state => state.fetchAPIloadingStatus;
export const setLoadingStatus = (state, status) => {
  state.fetchAPIloadingStatus = status;
};

export const setUser = (userData, expiryDate) =>
  Cookies.set('user', userData, {
    expires: expiryDate.diff(moment(), 'days'),
    secure: true
  });

export const getHeaderExpiry = response => moment.unix(response.headers.expiry);