<template>
  <div class="medium-10 column signup">
    <div class="text-center medium-12 signup--hero">
      <img :src="main_logo_url" alt="logo" class="hero--logo" />
      <h2 class="hero--title">{{ $t('REGISTER.TRY_WOOT', {title: title}) }}</h2>
      <p class="hero--sub">{{ $t('REGISTER.TRY_WOOT_SUB') }}</p>
    </div>

    <div class="row align-center">
      <div class="medium-5 column" style="align-self: center;">
        <ul class="signup--features">
          <li>
            <img src="~dashboard/assets/images/multi_agent.svg" style="margin-right: 13px;" />
            Multi-agent
          </li>
          <li>
            <img src="~dashboard/assets/images/omnichannel.svg" style="margin-right: 13px;" />
            Omnichannel
          </li>
          <li>
            <img src="~dashboard/assets/images/Chatbot compatible-03.svg" style="margin-right: 13px;" />
            Chatbot compatible
          </li>
          <li>
            <i class="ion-locked secure"></i>Enterprise grade security
          </li>
        </ul>
      </div>
      <div class="medium-5 column">
        <form class="signup--box login-box" @submit.prevent="submit()">
          <div class="column log-in-form">
            <label :class="{ error: $v.credentials.name.$error }">
              {{ $t('REGISTER.ACCOUNT_NAME.LABEL') }}
              <input
                v-model.trim="credentials.name"
                type="text"
                :placeholder="$t('REGISTER.ACCOUNT_NAME.PLACEHOLDER')"
                @input="$v.credentials.name.$touch"
              />
              <span
                v-if="$v.credentials.name.$error"
                class="message"
              >{{ $t('REGISTER.ACCOUNT_NAME.ERROR') }}</span>
            </label>
            <label :class="{ error: $v.credentials.email.$error }">
              {{ $t('REGISTER.EMAIL.LABEL') }}
              <input
                v-model.trim="credentials.email"
                type="email"
                :placeholder="$t('REGISTER.EMAIL.PLACEHOLDER')"
                @input="$v.credentials.email.$touch"
              />
              <span
                v-if="$v.credentials.email.$error"
                class="message"
              >{{ $t('REGISTER.EMAIL.ERROR') }}</span>
            </label>
            <woot-submit-button
              :disabled="
                $v.credentials.name.$invalid ||
                  $v.credentials.email.$invalid ||
                  register.showLoading
              "
              :button-text="$t('REGISTER.SUBMIT')"
              :loading="register.showLoading"
              button-class="large expanded"
              style="background : #3970f1;"
            ></woot-submit-button>
            <p class="accept--terms" v-html="$t('REGISTER.TERMS_ACCEPT')"></p>
          </div>
        </form>
        <div class="column text-center sigin--footer">
          <span>Already have an account?</span>
          <router-link to="auth/login">{{ title }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ion-stats-bars ion-chatbox-working ion-loop:removed from features
/* global bus */

import { required, minLength, email } from 'vuelidate/lib/validators';
import Auth from '../../api/auth';
import { frontendURL } from '../../helper/URLHelper';

export default {
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        name: '',
        email: '',
      },
      register: {
        message: '',
        showLoading: false,
      },
      error: '',
      main_logo_url: null,
      title: null,
      description: null,
    };
  },
  created() {
    this.main_logo_url = window.chatwootConfig.custom_website.main_logo_url;
    this.title = window.chatwootConfig.custom_website.title;
    this.description = window.chatwootConfig.custom_website.description;
  },
  validations: {
    credentials: {
      name: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.register.showLoading = false;
      bus.$emit('newToastMessage', message);
    },
    submit() {
      this.register.showLoading = true;
      Auth.register(this.credentials)
        .then(res => {
          if (res.status === 200) {
            if (!res.data.data.confirmed) {
              this.register.showLoading = false;
              bus.$emit('newToastMessageWithDuration', 'We have sent a confirmation link on your email. Please verify to continue.', 3000);
              setTimeout(() => {
                window.location = frontendURL('login');
              }, 3000);
            }        
          }
        })
        .catch(error => {
          let errorMessage = this.$t('REGISTER.API.ERROR_MESSAGE');
          if (error.response && error.response.data.message) {
            errorMessage = error.response.data.message;
          }
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>
