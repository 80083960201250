/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
import moment from 'moment';

import Vue from 'vue';
import * as types from '../mutation-types';
import Report from '../../api/reports';

const state = {
  fetchingStatus: false,
  reportData: [],
  accountReport: {
    isFetching: false,
    data: [],
  },
  teamSummary: [],
  responseTimeSummary: {},
  labelSummary: [],
  incomingMessages: {
    isFetching: false,
    data: [],
  },
  outgoingMessages: {
    isFetching: false,
    data: [],
  },
  botMessages: {
    isFetching: false,
    data: [],
  },
  conversationReport: {
    isFetching: false,
    data: [],
  },
  openReport: {
    isFetching: false,
    data: [],
  },
  resolutionReport: {
    isFetching: false,
    data: [],
  },
  avgResponseTimeReport: {
    isFetching: false,
    data: [],
  },
  medianResponseTimeReport: {
    isFetching: false,
    data: [],
  },
  accountSummary: {
    avg_first_response_time: 0,
    avg_resolution_time: 0,
    conversations_count: 0,
    open_count: 0,
    incoming_messages_count: 0,
    outgoing_messages_count: 0,
    resolutions_count: 0,
  },
};

const getters = {
  getAccountReports(_state) {
    return _state.accountReport;
  },
  getIncomingMessagesReports(_state) {
    return _state.incomingMessages;
  },
  getOutgoingMessagesReports(_state) {
    return _state.outgoingMessages;
  },
  getBotMessagesReports(_state) {
    return _state.botMessages;
  },
  getConversationReports(_state) {
    return _state.conversationReport;
  },
  getOpenReports(_state) {
    return _state.openReport;
  },
  getResolutionReports(_state) {
    return _state.resolutionReport;
  },
  getAvgResponseTimeReports(_state) {
    return _state.avgResponseTimeReport;
  },
  getMedianResponseTimeReports(_state) {
    return _state.medianResponseTimeReport;
  },
  getAccountSummary(_state) {
    return _state.accountSummary;
  },
  getTeamStatistics(_state) {
    return _state.teamSummary;
  },
  getResponseTimeStatistics(_state) {
    return _state.responseTimeSummary;
  },
  getLabelStatistics(_state) {
    return _state.labelSummary;
  },
};

const actions = {
  async fetchReportForMetric({ commit }, reportObj) {

    if (reportObj.metric == 'incoming_messages_count') {
      commit(types.default.TOGGLE_INCOMING_MESSAGES_REPORTS_LOADING, true);
    } else if (reportObj.metric == 'outgoing_messages_count') {
      commit(types.default.TOGGLE_OUTGOING_MESSAGES_REPORTS_LOADING, true);
    } else if (reportObj.metric == 'bot_messages_count') {
      commit(types.default.TOGGLE_BOT_MESSAGES_REPORTS_LOADING, true);
    }else if (reportObj.metric == 'conversations_count') {
      commit(types.default.TOGGLE_CONVERSATIONS_REPORTS_LOADING, true);
    } else if (reportObj.metric == 'open_count') {
      commit(types.default.TOGGLE_OPEN_REPORTS_LOADING, true);
    }else if (reportObj.metric == 'open_count') {
      commit(types.default.TOGGLE_OPEN_REPORTS_LOADING, true);
    } else if (reportObj.metric == 'avg_response_time_count') {
      commit(types.default.TOGGLE_AVG_RESPONSE_TIME_REPORTS_LOADING, true);
    } else if (reportObj.metric == 'median_response_time_count') {
      commit(types.default.TOGGLE_MEDIAN_RESPONSE_TIME_REPORTS_LOADING, true);
    }

    await Report.getAccountReports(reportObj.metric, reportObj.from, reportObj.to, reportObj.inboxId ).then(incomingMsgs => {
      let { data } = incomingMsgs;
      var listDate = [];
      var dateMove = new Date(reportObj.from * 1000);
      while (dateMove.getTime() < (reportObj.to*1000)){
        listDate.push(dateMove.getTime());
        dateMove.setDate(dateMove.getDate()+1);
      };
      var local_array = [];
      for (let index = 0; index < listDate.length; index++) {
        const date_element = listDate[index];
        var found = false;
        for (let j = 0; j < data.length; j++) {
          var element = data[j];
          var timeStamp = moment(element['0'], "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'").valueOf();
          if (date_element == timeStamp) {
            found = true;
            local_array.push({
              timestamp: date_element/1000,
              value: element['1']
            })
          }
        }
        if (found == false) {
          local_array.push({
            timestamp: date_element/1000,
            value: 0
          })
        }
      }

      if (reportObj.metric == 'incoming_messages_count') {
        commit(types.default.SET_INCOMING_MESSAGES_REPORTS, local_array);
        commit(types.default.TOGGLE_INCOMING_MESSAGES_REPORTS_LOADING, false);
      } else if (reportObj.metric == 'outgoing_messages_count') {
        commit(types.default.SET_OUTGOING_MESSAGES_REPORTS, local_array);
        commit(types.default.TOGGLE_OUTGOING_MESSAGES_REPORTS_LOADING, false);
      } else if (reportObj.metric == 'bot_messages_count') {
        commit(types.default.SET_BOT_MESSAGES_REPORTS, local_array);
        commit(types.default.TOGGLE_BOT_MESSAGES_REPORTS_LOADING, false);
      } else if (reportObj.metric == 'conversations_count') {
        commit(types.default.SET_CONVERSATIONS_REPORTS, local_array);
        commit(types.default.TOGGLE_CONVERSATIONS_REPORTS_LOADING, false);
      } else if (reportObj.metric == 'open_count') {
        commit(types.default.SET_OPEN_REPORTS, local_array);
        commit(types.default.TOGGLE_OPEN_REPORTS_LOADING, false);
      } else if (reportObj.metric == 'resolutions_count') {
        commit(types.default.SET_RESOLUTION_REPORTS, local_array);
        commit(types.default.TOGGLE_RESOLUTION_REPORTS_LOADING, false);
      } else if (reportObj.metric == 'avg_response_time_count') {
        commit(types.default.SET_AVG_RESPONSE_TIME_REPORTS, local_array);
        commit(types.default.TOGGLE_AVG_RESPONSE_TIME_REPORTS_LOADING, false);
      } else if (reportObj.metric == 'median_response_time_count') {
        commit(types.default.SET_MEDIAN_RESPONSE_TIME_REPORTS, local_array);
        commit(types.default.TOGGLE_MEDIAN_RESPONSE_TIME_REPORTS_LOADING, false);
      }

    });
  },
  fetchAccountReport({ commit }, reportObj) {
    commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, true);
    Report.getAccountReports(
      reportObj.metric,
      reportObj.from,
      reportObj.to,
      reportObj.inboxId
    ).then(accountReport => {
      let { data } = accountReport;
      if (reportObj.metric === 'avg_first_response_time' || reportObj.metric === 'avg_resolution_time') {

        data = data.filter(el => moment() > moment.unix(el.timestamp));
        data = data.map(element => {
          /* eslint-disable operator-assignment */
          element.value = (element.value / 3600).toFixed(2);
          return element;
        });
        commit(types.default.SET_ACCOUNT_REPORTS, data);

      } else {

        var listDate = [];
        var dateMove = new Date(reportObj.from * 1000);
        while (dateMove.getTime() < (reportObj.to*1000)){
          listDate.push(dateMove.getTime());
          dateMove.setDate(dateMove.getDate()+1);
        };
        var local_array = [];
        for (let index = 0; index < listDate.length; index++) {
          const date_element = listDate[index];
          var found = false;
          for (let j = 0; j < data.length; j++) {
            var element = data[j];
            var timeStamp = moment(element['0'], "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'").valueOf();
            if (date_element == timeStamp) {
              found = true;
              local_array.push({
                timestamp: date_element/1000,
                value: element['1']
              })
            }
          }
          if (found == false) {
            local_array.push({
              timestamp: date_element/1000,
              value: 0
            })
          }

        }
        commit(types.default.SET_ACCOUNT_REPORTS, local_array);
      }

      
      commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
    });
  },
  async fetchAccountSummary({ commit }, reportObj) {
    await Report.getAccountSummary(1, reportObj.from, reportObj.to, reportObj.inboxId)
      .then(accountSummary => {
        commit(types.default.SET_ACCOUNT_SUMMARY, accountSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
  fetchAgentSummary({ commit }, reportObj) {
    Report.getAgentSummary(1, reportObj.from, reportObj.to, reportObj.agentId)
      .then(accountSummary => {
        commit(types.default.SET_ACCOUNT_SUMMARY, accountSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
  async fetchTeamStatistics({ commit }, reportObj) {
    await Report.getTeamStatistics(1, reportObj.from, reportObj.to, reportObj.inboxId)
      .then(teamSummary => {
        commit(types.default.SET_TEAM_SUMMARY, teamSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
  async fetchResponseTimeStatistics({ commit }, reportObj) {
    await Report.getResponseTimeStatistics(1, reportObj.from, reportObj.to, reportObj.inboxId)
      .then(responseTimeSummary => {
        commit(types.default.SET_RESPONSE_TIME_SUMMARY, responseTimeSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
  async fetchLabelList({ commit }, reportObj) {
    var identifier = Date.now();
    commit(types.default.RESET_LABEL_SUMMARY, identifier);
    await Report.getLabelList(1, reportObj.from, reportObj.to, reportObj.inboxId, identifier)
      .then(labelSummary => {
        commit(types.default.SET_LABEL_SUMMARY, labelSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
  async fetchLabelStatistics({ commit, state }, reportObj) {
    await Report.getLabelStatistics(1, reportObj.from, reportObj.to, reportObj.inboxId, state.identifier)
      .then(labelSummary => {
        // commit(types.default.SET_LABEL_SUMMARY, labelSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
};

const mutations = {
  [types.default.SET_ACCOUNT_REPORTS](_state, accountReport) {
    _state.accountReport.data = accountReport;
  },
  [types.default.SET_INCOMING_MESSAGES_REPORTS](_state, data) {
    _state.incomingMessages.data = data;
  },
  [types.default.SET_OUTGOING_MESSAGES_REPORTS](_state, data) {
    _state.outgoingMessages.data = data;
  },
  [types.default.SET_BOT_MESSAGES_REPORTS](_state, data) {
    _state.botMessages.data = data;
  },
  [types.default.SET_CONVERSATIONS_REPORTS](_state, data) {
    _state.conversationReport.data = data;
  },
  [types.default.SET_RESOLUTION_REPORTS](_state, data) {
    _state.resolutionReport.data = data;
  },
  [types.default.SET_AVG_RESPONSE_TIME_REPORTS](_state, data) {
    _state.avgResponseTimeReport.data = data;
  },
  [types.default.SET_MEDIAN_RESPONSE_TIME_REPORTS](_state, data) {
    _state.medianResponseTimeReport.data = data;
  },
  [types.default.SET_OPEN_REPORTS](_state, data) {
    _state.openReport.data = data;
  },
  [types.default.TOGGLE_INCOMING_MESSAGES_REPORTS_LOADING](_state, flag) {
    _state.incomingMessages.isFetching = flag;
  },
  [types.default.TOGGLE_OUTGOING_MESSAGES_REPORTS_LOADING](_state, flag) {
    _state.outgoingMessages.isFetching = flag;
  },
  [types.default.TOGGLE_BOT_MESSAGES_REPORTS_LOADING](_state, flag) {
    _state.botMessages.isFetching = flag;
  },
  [types.default.TOGGLE_RESOLUTION_REPORTS_LOADING](_state, flag) {
    _state.resolutionReport.isFetching = flag;
  },
  [types.default.TOGGLE_AVG_RESPONSE_TIME_REPORTS_LOADING](_state, flag) {
    _state.avgResponseTimeReport.isFetching = flag;
  },
  [types.default.TOGGLE_MEDIAN_RESPONSE_TIME_REPORTS_LOADING](_state, flag) {
    _state.medianResponseTimeReport.isFetching = flag;
  },
  [types.default.TOGGLE_OPEN_REPORTS_LOADING](_state, flag) {
    _state.openReport.isFetching = flag;
  },
  [types.default.TOGGLE_CONVERSATIONS_REPORTS_LOADING](_state, flag) {
    _state.conversationReport.isFetching = flag;
  },
  [types.default.TOGGLE_ACCOUNT_REPORT_LOADING](_state, flag) {
    _state.accountReport.isFetching = flag;
  },
  [types.default.SET_ACCOUNT_SUMMARY](_state, summaryData) {
    _state.accountSummary = summaryData;
    // // Average First Response Time
    // let avgFirstResTimeInHr = 0;
    // if (summaryData.avg_first_response_time) {
    //   avgFirstResTimeInHr = (
    //     summaryData.avg_first_response_time / 3600
    //   ).toFixed(2);
    //   avgFirstResTimeInHr = `${avgFirstResTimeInHr} Hr`;
    // }
    // // Average Resolution Time
    // let avgResolutionTimeInHr = 0;
    // if (summaryData.avg_resolution_time) {
    //   avgResolutionTimeInHr = (summaryData.avg_resolution_time / 3600).toFixed(
    //     2
    //   );
    //   avgResolutionTimeInHr = `${avgResolutionTimeInHr} Hr`;
    // }
    // _state.accountSummary.avg_first_response_time = avgFirstResTimeInHr;
    // _state.accountSummary.avg_resolution_time = avgResolutionTimeInHr;
  },
  [types.default.SET_TEAM_SUMMARY](_state, summaryData) {
    _state.teamSummary = summaryData;
  },
  [types.default.SET_RESPONSE_TIME_SUMMARY](_state, summaryData) {
    _state.responseTimeSummary = summaryData;
  },
  [types.default.SET_LABEL_SUMMARY](_state, summaryData) {
    _state.labelSummary = summaryData;
  },
  [types.default.RESET_LABEL_SUMMARY](_state, identifier) {
    _state.labelSummary = [];
    _state.identifier = identifier;
  },
  [types.default.ADDED_LABEL_REPORT_DATA](_state, data) {

    if(data.identifier == _state.identifier) {

      var incoming_data = data.data;

      var i = _state.labelSummary.length;
      var replaced = false;

      while (i--) {
        var report_row = _state.labelSummary[i];
        if (incoming_data.id === report_row.id) {
          Vue.set(report_row, 'total_agents', incoming_data.total_agents);
          Vue.set(report_row, 'total_assigned', incoming_data.total_assigned);
          Vue.set(report_row, 'total_conversations', incoming_data.total_conversations);
          Vue.set(report_row, 'total_unassigned', incoming_data.total_unassigned);
          replaced = true;
          break;
        }
      }

      if (!replaced) {
        console.log(data.data);
        console.log(_state.labelSummary);
      }
      
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
