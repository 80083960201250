/* eslint arrow-body-style: 0 */
import ConversationView from './ConversationView';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('dashboard'),
      name: 'home',
      roles: ['administrator', 'agent'],
      component: ConversationView,
      props: () => {
        return { inboxId: null, labelId: null };
      },
    },
    // {
    //   path: frontendURL('inbox/:inbox_id/label/:label_id'),
    //   name: 'inbox_dashboard',
    //   roles: ['administrator', 'agent'],
    //   component: ConversationView,
    //   props: route => {
    //     return { inboxId: route.params.inbox_id, labelId: route.params.label_id };
    //   },
    // },
    {
      path: frontendURL('inbox/:inbox_id'),
      name: 'inbox_dashboard',
      roles: ['administrator', 'agent'],
      component: ConversationView,
      props: route => {
        return { inboxId: route.params.inbox_id, labelId: null  };
      },
    },
    {
      path: frontendURL('conversations/:conversation_id'),
      name: 'inbox_conversation',
      roles: ['administrator', 'agent'],
      component: ConversationView,
      props: route => {
        return { conversationId: route.params.conversation_id };
      },
    },
  ],
};
