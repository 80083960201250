<template>
  <div
    v-if="payload && payload.elements"
    class="items"
  >
    <div v-if="payload.elements.media.length == 1">
      <div v-for="(item, index) in payload.elements.media" :key="item.id">
        <div v-if="payload.elements.type == 'image'" class="single_image_space">
          <div v-if="item.url" class="single_image_div">
            <a @click="showModal(item.url)" class="full_width_height">
              <img :class="imageHelper(payload.elements.media)" :src="item.url">
            </a>
            <modal
              :images="payload.elements.media"
              :currentIndex="index"
              :class="{modalvisibility:item.url == modalVisibity}"
              @close="closeModal"
            />
          </div>
        </div>
        <div v-if="payload.elements.type == 'video'" class="video_space">
          <a :href="item.url" target="_blank">
            <video class="video_single_align" :src="item.url" controls/>
          </a>
        </div>
        <div v-if="payload.elements.type == 'iframe'" class="video_space">
          <a :href="item.url" target="_blank">
            <iframe :src="item.url"></iframe>
          </a>
        </div>
        <div v-if="payload.elements.type == 'audio'" class="video_space">
          <a :href="item.url" target="_blank">
            <audio controls style="border: 1px solid white;border-radius:30px; max-width: 100%;">
              <source :src="item.url" type="audio/ogg">
              <source :src="item.url" type="audio/mpeg">Your browser does not support the audio element.
            </audio>
          </a>
        </div>
        <div v-if="payload.elements.type == 'application'" class="application_space">
          <a
            :href="item.url"
            target="_blank"
            style="text-decoration: none !important;color: inherit;"
          >
            <div class="application">
              <div id="file-icon">
                <img
                  style="max-width: 60px; height: inherit;"
                  src="~dashboard/assets/images/file.svg"
                >
              </div>
              <div id="file_name">
                {{item.name}}
                <img src="~dashboard/assets/images/file_open.png" style="width: 10px;">
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-else v-for="(item, index) in payload.elements.media" :key="item.id" class="inline">
      <div v-if="payload.elements.type == 'image'" class="image_space">
        <div v-if="item.url" class="single_image_div">
          <a @click="showModal(item.url)" class="full_width_height">
            <img :class="imageHelper(payload.elements.media)" :src="item.url">
          </a>
          <modal
            :images="payload.elements.media"
            :currentIndex="index"
            :class="{modalvisibility:item.url == modalVisibity}"
            @close="closeModal"
          />
        </div>
      </div>
      <div v-if="payload.elements.type == 'video'" class="video_space">
        <a :href="item.url" target="_blank">
          <video class="video_single_align" :src="item.url" controls/>
        </a>
      </div>
      <div v-if="payload.elements.type == 'iframe'" class="video_space">
        <a :href="item.url" target="_blank">
          <iframe :src="item.url"></iframe>
        </a>
      </div>
      <div v-if="payload.elements.type == 'audio'" class="video_space">
        <a :href="item.url" target="_blank">
          <audio controls style="border: 1px solid white;border-radius:30px">
            <source :src="item.url" type="audio/ogg">
            <source :src="item.url" type="audio/mpeg">Your browser does not support the audio element.
          </audio>
        </a>
      </div>
      <div v-if="payload.elements.type == 'application'" class="application_space">
        <a
          :href="item.url"
          target="_blank"
          style="text-decoration: none !important;color: inherit;"
        >
          <div class="application">
            <div id="file-icon">
              <img
                style="max-width: 60px; height: inherit;"
                src="~dashboard/assets/images/file.svg"
              >
            </div>
            <div id="file_name">
              {{item.name}}
              <img src="~dashboard/assets/images/file_open.png" style="width: 10px;">
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';
import modal from './Modal';

export default {
  components: {
    Spinner,
    modal,
  },
  props: {
    contentType: String,
    messageId: {
      type: Number,
      required: false,
    },
    messageContentAttributes: {
      type: Object,
      default: () => {},
    },
    payload: {
      type: Object,
      default: () => {},
    },
    backgroundColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modalVisibity: undefined,
      incrementModal: 0,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    imageHelper(media) {
      let classValue;
      if (media.length == 1) {
        classValue = 'image_single_align';
      } else {
        classValue = 'image_align';
      }
      return classValue;
    },
    showModal(url) {
      this.modalVisibity = url;
    },
    closeModal() {
      this.modalVisibity = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables.scss';
.items {
  // font-size: 16px;
  // text-align: left;
  // top: calc(-60px);
  // line-height: 0;

  display: flex;
  flex-flow: row wrap;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 276px;
}

.item {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.inline {
  flex-basis: 50%;
  width: 100%;
  max-width: 134px;
  height: auto;
  padding: 2px;
  background: white;
  border-radius: 6px;
}

.image_align {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image_single_align {
  width: 100%;
}

.video_single_align {
  width: 100%;
  max-height: 225px;
  max-width: 272px;
}

.image_space {
  height: 134px;
  width: 134px;
  overflow: hidden;
  border-radius: 6px;
}

.single_image_space {
  max-height: 272px;
  width: auto;
  max-width: 272px;
  // width: 272px;
  overflow: hidden;
  border-radius: 6px;
}

.single_image_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.full_width_height {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // background: #ffffff;
  cursor: pointer;
  border-radius: 6px;
}

.video_space {
  margin-top: 2px;
  margin-right: 2px;
}

.modalvisibility {
  display: flex;
}

.sqs-audio-player {
  background-color: red !important;
  background-image: linear-gradient(red, red) !important;
  background: -webkit-linear-gradient(red, red) !important;
  border: 0px solid #b3b3b3 !important;
  border-radius: 0px 0px 0px 0px;
  color: red;
}
.audio-author {
  color: red !important;
}
.audio-title {
  color: red !important;
}
.volume .bar.active {
  border-right-color: red !important;
}
.volume .bar {
  border-right-color: red !important;
}
.progress {
  background: none repeat scroll 0 0 red !important;
}
.controls {
  color: red !important;
}

.application {
  overflow: hidden;
  width: 100%;
}

#file-icon {
  float: left;
  height: inherit !important;
}

#file_name {
  overflow: hidden;
  word-break: break-all;
  margin: 5px 0 5px 0;
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-right: 10px;
}

.agent .application {
  border-top-left-radius: 1.3em;
  border-top-right-radius: 1.3em;
  border-bottom-right-radius: 1.3em;
  background: white;
}

.user .application {
  border-top-right-radius: 1.3em;
  border-top-left-radius: 1.3em;
  border-bottom-left-radius: 1.3em;
  background: white;
}
</style>
