<template>
  <router-link
    :to="menuItem.toState"
    active-class="sidebar-option-active"
  >
    <div class="sidebar-option">
      <div v-bind:class="['image-container']"
      >
        <img src="~dashboard/assets/new_images/Nav_Conversations_White.svg">
      </div>
      <div class="title">{{ menuItem.label }}</div>
    </div>


    <div v-if="menuItem.hasSubMenu" class="nested vertical menu">
      <router-link
        v-for="child in menuItem.children"
        :key="child.id"
        active-class="active"
        :class="computedInboxClass(child)"
        tag="li"
        :to="child.toState"
      >
        <a>{{ child.label }}</a>
        <ul v-if="child.labels.length > 0" class="nested vertical menu inbox-label">
          <router-link
                  v-for="singleLabel in child.labels"
                  :key="singleLabel.id"
                  active-class="active"
                  :class="computedInboxLabelClass(singleLabel)"
                  tag="li"
                  :to="fetchFrontendURL(child, singleLabel)"
                >
                <a> <i class="fas fa-tags"></i> {{singleLabel.name}}</a>
          </router-link>
        </ul>
      </router-link>
    </div>
  </router-link>
</template>

<script>
/* eslint no-console: 0 */
import { mapGetters } from 'vuex';

import router from '../../routes';
import { frontendURL } from '../../helper/URLHelper';
import auth from '../../api/auth';

export default {
  props: {
    menuItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      labelList: {},
    };
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      activeLabel: 'getSelectedLabel',
    }),
    getMenuItemClass() {
      return this.menuItem.cssClass
        ? `side-menu ${this.menuItem.cssClass}`
        : 'side-menu';
    },
    computedClass() {
      // If active Inbox is present
      // donot highlight conversations
      if (this.activeInbox) return ' ';

      if (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      ) {
        return 'active';
      }
      return ' ';
    },
  },
  methods: {
    computedInboxClass(child) {
      if (parseInt(this.activeInbox, 10) === child.channel_id) {
        return 'active flex-container';
      }
      return ' ';
    },
    computedInboxLabelClass(childLabel){
      if (parseInt(this.activeInbox, 10) === childLabel.channel_id) {
        return 'active flex-container';
      }
      return ' ';
    },
    newLinkClick() {
      router.push({ name: 'settings_inbox_new', params: { page: 'new' } });
    },
    showItem(item) {
      return auth.isAdmin() && item.newLink !== undefined;
    },
    fetchFrontendURL(inbox, label){
      return frontendURL(`inbox/${inbox.id}/label/${label.id}`)
    }
  },
};
</script>

<style lang="scss" scoped>
// .inbox-label a {
//   font-size: 1.2rem;
//   padding: 8px;
//   word-break: break-all;
//   line-height: 15px;
// }
// .inbox-label {
//   padding: 2px 0 0 2px;
// }
// .inbox-label .active a {
//   padding: 8px;
// }
</style>
