<template>
  <div class="settings-tabs" :class="classObject">
    <div
      v-for="item in items"
      :key="item.route"
      class="setting-tab"
      :class="{ active: isActive(item), over: isOver(item) }"
    >{{ item.title }}</div>
  </div>
</template>

<script>
/* eslint no-console: 0 */
export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    isFullwidth: Boolean,
  },

  computed: {
    classObject() {
      return 'full-width';
    },
    activeIndex() {
      return this.items.findIndex(i => i.route === this.$route.name);
    },
  },
  methods: {
    isActive(item) {
      return this.items.indexOf(item) === this.activeIndex;
    },
    isOver(item) {
      return this.items.indexOf(item) < this.activeIndex;
    },
  },
};
</script>
