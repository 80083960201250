<template>
  <div class="items">
    <div id="tableDiv" v-if="payload.elements" class="table-area" :style="{backgroundColor: getBackgeoundColor }">
      <div v-if="payload.elements.title" class="title" :style="{color: getPrimaryColor }" >{{payload.elements.title}}</div>
      <div v-if="payload.elements.subtitle" class="subtitle" :style="{color: getSecondaryColorForSubtitle }">{{payload.elements.subtitle}}</div>
      <div class="table-elements">
        <table>
          <tbody>
            <tr :style="{backgroundColor: getBackgeoundColor , borderColor: getSecondaryColorForBorder }">
                <th :style="{color: getSecondaryColorForSubtitle }" v-for="single_column in payload.elements.columns" :key="single_column.id">{{single_column.header}}</th>
            </tr>
            <tr :style="{backgroundColor: getBackgeoundColor , borderColor: getSecondaryColorForBorder }" 
            v-for="single_row in payload.elements.rows" :key="single_row.id">
                <td :style="{color: getSecondaryColor, textDecoration: getUnderscore(single_cell), cursor: getCursor(single_cell) }" v-for="single_cell in single_row.cells" :key="single_cell.id" @click="trigger(single_cell)">
                  {{single_cell.text}}
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: {
    Spinner,
  },
  props: {
    contentType: String,
    messageId: {
      type: Number,
      required: true,
    },
    messageContentAttributes: {
      type: Object,
      default: () => {},
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
      defaultPrimaryColor: "#3c4858",
      defaultSecondaryColor: "#21201d",
      defaultBackgroundColor: "#ffffff",
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contact/getUIFlags',
      widgetColor: 'appConfig/getWidgetColor',
    }),
    hasSubmitted() {
      return (
        this.messageContentAttributes &&
        this.messageContentAttributes.submitted_email
      );
    },
    getSecondaryColorForBorder() {
      if (this.payload.elements.configuration.secondaryColor) {
        return this.payload.elements.configuration.secondaryColor + "20";
      } else {
        return this.defaultSecondaryColor + "20";
      }
    },
    getSecondaryColorForSubtitle() {
      if (this.payload.elements.configuration.secondaryColor) {
        return this.payload.elements.configuration.secondaryColor + "80";
      } else {
        return this.defaultSecondaryColor + "80";
      }
    },
    getPrimaryColor() {
      if (this.payload.elements.configuration.primaryColor) {
        return this.payload.elements.configuration.primaryColor;
      } else {
        return this.widgetColor;
      }
    },
    getSecondaryColor() {
      if (this.payload.elements.configuration.secondaryColor) {
        return this.payload.elements.configuration.secondaryColor;
      } else {
        return this.defaultSecondaryColor;
      }
    },
    getBackgeoundColor() {
      if (this.payload.elements.configuration.backgroundColor) {
        return this.payload.elements.configuration.backgroundColor;
      } else {
        return this.defaultBackgroundColor;
      }
    }
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    postback(item) {
      this.$store.dispatch('conversation/sendMessage', {
        content: item.text,
        content_type: this.contentType,
        content_payload: item.payload,
      });
    },
    trigger(item) {
      if (item.type == 'web_url') {
        window.open(item.url);
      } else if (item.type == 'postback') {
        this.postback(item);
      }
    },
    getUnderscore(item) {
      if (item.type == 'web_url' || item.type == 'postback') {
        return 'underline';
      }
      return 'none';
    },
    getCursor(item) {
      if (item.type == 'web_url' || item.type == 'postback') {
        return 'pointer';
      }
      return 'auto';
    }
  },
  mixins: [messageFormatterMixin],
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables.scss';

.table-area{
  padding: 10px 10px 10px 10px;
  background: #f8f8f8;
  border-radius: 4px;
  width: max-content;
  max-width: -webkit-fill-available;
  overflow-y: auto;

  
  .title {
    padding: 5px;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 8px;
  }

  .subtitle {
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 8px;
  }
}
 
 .table-elements {
  overflow-x: auto;
  max-height: 190px;
  padding: 5px 5px 0px 5px;
}

table {
  border: 0;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: max-content;
  // table-layout: fixed; giving problem in safari
}
table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}
table tr {
    background-color: #f8f8f8;
    border-bottom: 1px solid #ddd;
    padding: .35em;
}
table td {
  padding: 12px;
  text-align: center;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}
table td:first-child  {
  // text-align: left;
  padding: 12px 12px 12px 0px !important;
}
table th:first-child   {
  // text-align: left;
  padding: 12px 12px 12px 0px !important;
}
table th {
  padding: 12px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 400;
}


    table td::before {
        content: attr(name);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
        table tr:last-child {
        border-bottom: 0;
    }

// @media screen and (max-width: 500px) {
//     body {
//         line-height: 1.25;
//         margin: 0px 15px;
//     }
//     table {
//         border: 0;
//         width: max-content;
//     }
// }
</style>
