/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class MessageApi extends ApiClient {
  constructor() {
    super('conversations');
  }

  create({ conversationId, message, private: isPrivate }) {
    return axios.post(`${this.url}/${conversationId}/messages`, {
      message,
      private: isPrivate,
    });
  }

  sendFile({ conversationId, message, private: isPrivate, payload, message_type, content_type }) {
    return axios.post(`${this.url}/${conversationId}/messages`, {
      message: null,
      private: isPrivate,
      payload,
      message_type,
      content_type
    });
  }

  getPreviousMessages({ conversationId, before }) {
    return axios.get(`${this.url}/${conversationId}`, {
      params: { before },
    });
  }

  getNextMessages({ conversationId, after }) {
    return axios.get(`${this.url}/${conversationId}`, {
      params: { after },
    });
  }
}

export default new MessageApi();
