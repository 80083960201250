/* global axios */
import ApiClient from '../ApiClient';

class InboxEventApi extends ApiClient {
  constructor() {
    super('inbox_events');
  }

  getInboxEvents({ inboxId }) {
    return axios.get(`${this.url}/${inboxId}`);
  }

}

export default new InboxEventApi();
