/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
import Vue from 'vue';
import auth from '../../../api/auth';
import * as types from '../../mutation-types';
import wootConstants from '../../../constants';
import getters, { getSelectedChatConversation } from './getters';
import { state as globalVariablesState } from '../globalVariables';
import actions from './actions';

const state = {
  allConversations: [],
  convTabStats: {
    mineCount: 0,
    unAssignedCount: 0,
    allCount: 0,
  },
  selectedChat: {
    id: null,
    meta: {},
    status: null,
    seen: false,
    agentTyping: 'off',
    dataFetched: false,
  },
  listLoadingStatus: true,
  chatStatusFilter: wootConstants.ASSIGNEE_TYPE_SLUG.OPEN,
  currentInbox: null,
  currentLabel: null,
  isAllConversationLoaded: false,
};

// mutations
const mutations = {
  [types.default.SET_ALL_CONVERSATION](_state, conversationList) {
    const newAllConversations = [..._state.allConversations];
    if (conversationList.length < wootConstants.PAGE_LIMIT) {
      _state.isAllConversationLoaded = true
    }

    conversationList.forEach(conversation => {
      const indexInCurrentList = newAllConversations.findIndex(
        c => c.id === conversation.id
      );
      if (indexInCurrentList < 0) {
        newAllConversations.push(conversation);
      }
    });
    _state.allConversations = newAllConversations;
  },
  [types.default.EMPTY_ALL_CONVERSATION](_state) {
    _state.allConversations = [];
    _state.selectedChat = {
      id: null,
      meta: {},
      status: null,
      seen: false,
      agentTyping: 'off',
      dataFetched: false,
    };
    _state.isAllConversationLoaded = false;
  },
  [types.default.SET_ALL_MESSAGES_LOADED](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'allMessagesLoaded', true);
  },

  [types.default.SET_ALL_NEXT_MESSAGES_LOADED](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'allNextMessagesLoaded', true);
  },

  [types.default.CLEAR_ALL_MESSAGES_LOADED](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'allMessagesLoaded', false);
  },
  [types.default.CLEAR_CURRENT_CHAT_WINDOW](_state) {
    _state.selectedChat.id = null;
    _state.selectedChat.agentTyping = 'off';
  },

  [types.default.DELETE_CONVERSATION](_state, id) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    _state.allConversations.splice(_state.allConversations.indexOf(chat), 1)
    _state.selectedChat = {
      id: null,
      meta: {},
      status: null,
      seen: false,
      agentTyping: 'off',
      dataFetched: false,
    };
  },

  [types.default.SET_PREVIOUS_CONVERSATIONS](_state, { id, data }) {
    if (data.length) {
      const [chat] = _state.allConversations.filter(c => c.id === id);
      chat.messages.unshift(...data);
    }
  },

  [types.default.SET_NEXT_CONVERSATIONS](_state, { id, data }) {
    if (data.length) {
      const [chat] = _state.allConversations.filter(c => c.id === id);
      chat.messages.push(...data);
    }
  },

  [types.default.SET_CONV_TAB_META](
    _state,
    {
      mine_count: mineCount,
      unassigned_count: unAssignedCount,
      all_count: allCount,
    } = {}
  ) {
    Vue.set(_state.convTabStats, 'mineCount', mineCount);
    Vue.set(_state.convTabStats, 'allCount', allCount);
    Vue.set(_state.convTabStats, 'unAssignedCount', unAssignedCount);
  },

  [types.default.CURRENT_CHAT_WINDOW](_state, activeChat) {
    if (activeChat) {
      Object.assign(_state.selectedChat, activeChat);
      Vue.set(_state.selectedChat.meta, 'assignee', activeChat.meta.assignee);
      Vue.set(_state.selectedChat.meta, 'status', activeChat.meta.status);
    }
  },

  [types.default.APPEND_MESSAGES](_state, { id, data }) {
    if (data.length) {
      const [chat] = _state.allConversations.filter(c => c.id === id);
      chat.messages = data;
      Vue.set(chat, 'dataFetched', true);
    }
  },

  [types.default.SET_CHAT_META](_state, { id, data }) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    if (data !== undefined) {
      Vue.set(chat, 'labels', data.labels);
    }
  },

  [types.default.ASSIGN_AGENT](_state, assignee) {
    const [chat] = getSelectedChatConversation(_state);
    chat.meta.assignee = assignee;
    if (assignee === null) {
      Object.assign(_state.selectedChat.meta.assignee, assignee);
    }
  },

  [types.default.RESOLVE_CONVERSATION](_state, status) {
    const [chat] = getSelectedChatConversation(_state);
    chat.status = status;
    _state.selectedChat.status = status;
  },

  [types.default.SEND_MESSAGE](_state, data) {

    const [chat] = getSelectedChatConversation(_state);
    const previousMessageIds = chat.messages.map(m => m.id);
    if (!previousMessageIds.includes(data.id)) {
      chat.messages.push(data);
    }
  },

  [types.default.ADD_MESSAGE](_state, data) {
    var user_conversation = data.conversation;
    if (_state.chatStatusFilter == user_conversation.status) {
      var message = data.message;
      if (message.message_type == 0) {
        window.bus.$emit('playSound', "ADD_MESSAGE", message);
      } else {
        try {
          if (message.message_type == 2 && message.content_type == 'text') {
            window.bus.$emit('playSound', "ADD_MESSAGE", message);
          }
        } catch (error) {
        }
      }
      var [chat] = _state.allConversations.filter(
        c => c.id === message.conversation_id
      );
      if (!chat) {
        try {
          if (globalVariablesState.searchObject.searchValue != null) {
            return;
          }
          if (_state.currentInbox) {
            if (_state.currentInbox == user_conversation.inbox_id) {
              if (_state.currentLabel == null) {
                _state.allConversations.push(user_conversation);
                [chat] = _state.allConversations.filter(
                  c => c.id === message.conversation_id
                );
              }
            }
          } else {
            _state.allConversations.push(user_conversation);
            [chat] = _state.allConversations.filter(
              c => c.id === message.conversation_id
            );
          }
        } catch (error) {
          console.log(error);
        }
      }

      const previousMessageIds = chat.messages.map(m => m.id);
      if (!previousMessageIds.includes(message.id)) {
        chat.messages.push(message);
        if (_state.selectedChat.id === message.conversation_id) {
          window.bus.$emit('scrollToMessage');
        }
      }
    }

  },

  [types.default.ADD_CONVERSATION](_state, conversation) {
    if (globalVariablesState.searchObject.searchValue == null) {
      if (_state.currentInbox) {
        if (_state.currentInbox == conversation.inbox_id) {
          if (_state.currentLabel == null) {
            _state.allConversations.push(conversation);
          }
        }
      } else {
        _state.allConversations.push(conversation);
      }
    }
  },

  [types.default.UPDATE_CONTACT_DETAILS](_state, contact) {
    _state.selectedChat.meta.sender.name = contact.name;
  },

  [types.default.UPDATE_CONVERSATION_CONTACT_DETAILS](_state, {contactId, contactData}) {
    const [chat] = getSelectedChatConversation(_state);
    if (chat.meta.sender.id === contactId) {
      chat.meta.sender.name = contactData.name;
      chat.meta.sender.thumbnail = contactData.thumbnail;
    }
    if (_state.selectedChat.meta.sender.id === contactId) {
      _state.selectedChat.meta.name = contactData.name;
      _state.selectedChat.meta.thumbnail = contactData.thumbnail;
    }
  },

  [types.default.REOPEN_CONVERSATION](_state, conversation) {

    var i = _state.allConversations.length
    while (i--) {
      var chat = _state.allConversations[i]
      if (chat.id === conversation.id) {
        _state.allConversations.splice(i, 1);
        if (_state.selectedChat.id == conversation.id) {
          _state.selectedChat = {
            id: null,
            meta: {},
            status: null,
            seen: false,
            agentTyping: 'off',
            dataFetched: false,
          };
        }
        break;
      }
    }
    if (globalVariablesState.searchObject.searchValue == null) {
      _state.allConversations.push(conversation);
    }
  },

  [types.default.RESOLVE_CONVERSATION_D](_state, conversation) {

    var i = _state.allConversations.length
    while (i--) {
      var chat = _state.allConversations[i]
      if (chat.id === conversation.id) {
        _state.allConversations.splice(i, 1);
        if (_state.selectedChat.id == conversation.id) {
          _state.selectedChat = {
            id: null,
            meta: {},
            status: null,
            seen: false,
            agentTyping: 'off',
            dataFetched: false,
          };
        }
        break;
      }
    }
    if (globalVariablesState.searchObject.searchValue == null) {
      _state.allConversations.push(conversation);
    }

  },

  [types.default.ADD_CONVERSATION_LABEL](_state, data) {
    var conversation = data.conversation
    if (_state.currentInbox) {
      if (_state.currentInbox == conversation.inbox_id) {
        if (_state.currentLabel) {
          if (parseInt(data.label_id) === parseInt(_state.currentLabel)) {
            _state.allConversations.push(conversation);
          }
        }
      }
    }

  },

  [types.default.REMOVE_CONVERSATION_LABEL](_state, data) {
    var conversation = data.conversation
    if (_state.currentInbox) {
      if (_state.currentInbox == conversation.inbox_id) {
        if (_state.currentLabel) {
          if (parseInt(data.label_id) === parseInt(_state.currentLabel)) {
            var i = _state.allConversations.length
            while (i--) {
              var chat = _state.allConversations[i]
              if (chat.id === conversation.id) {
                _state.allConversations.splice(i, 1);
              }
            }
          }
        }
      }
    }

  },

  [types.default.MARK_SEEN](_state) {
    _state.selectedChat.seen = true;
  },

  [types.default.FB_TYPING](_state, { status }) {
    _state.selectedChat.agentTyping = status;
  },

  [types.default.SET_LIST_LOADING_STATUS](_state) {
    _state.listLoadingStatus = true;
  },

  [types.default.SET_IS_ALL_CONVERSATION_LOADED_STATUS](_state) {
    _state.isAllConversationLoaded = false;
  },

  [types.default.CLEAR_LIST_LOADING_STATUS](_state) {
    _state.listLoadingStatus = false;
  },

  [types.default.MARK_MESSAGE_READ](_state, { id, lastSeen }) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    chat.agent_last_seen_at = lastSeen;
  },

  [types.default.CHANGE_CHAT_STATUS_FILTER](_state, data) {
    _state.chatStatusFilter = data;
  },

  // Update assignee on action cable message
  [types.default.UPDATE_ASSIGNEE](_state, payload) {
    const [chat] = _state.allConversations.filter(c => c.id === payload.id);
    if (chat) {
      chat.meta.assignee = payload.assignee;
    }
  },

  [types.default.SET_ACTIVE_INBOX](_state, inboxId) {
    _state.currentInbox = inboxId;
  },

  [types.default.SET_ACTIVE_LABEL](_state, labelId) {
    _state.currentLabel = labelId;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
