<template>
  <div>
    <div v-if="!hasSubmitted && payload.elements" class="items">
      <div class="position_relative">
        <div class="scroll">
          <div class="inline_elem">
            <div v-for="(item, index) in payload.elements" :key="item.id" class="item">
              <div class="margin_6_4">
                <div class="align_item">
                  <div class="flex_adjust">
                    <div
                      class="bg-adjust border-adjust padding-adjust white-space"
                      role="button"
                      @click="trigger(item)"
                    >
                      <a class="a-adjust">{{item.title}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Spinner from 'shared/components/Spinner';

export default {
  components: {
    Spinner,
  },
  props: {
    contentType: String,
    messageId: {
      type: Number,
      required: true,
    },
    messageContentAttributes: {
      type: Object,
      default: () => {},
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contact/getUIFlags',
      widgetColor: 'appConfig/getWidgetColor',
    }),
    hasSubmitted() {
      return (
        this.messageContentAttributes &&
        this.messageContentAttributes.quick_reply
      );
    },
  },
  methods: {
    ...mapActions('conversation', [ 'sendLocation']),
    handleSendLocation(content) {
      this.sendLocation({
        content,
      });
    },
    sendMessage(item) {
      this.$store.dispatch('conversation/sendMessage', {
        content: item.title,
        content_type: this.contentType,
        content_payload: item.payload,
      });
    },
    postback(item) {
      this.sendMessage(item);
      this.$store.dispatch('contact/updateContactAttributesForQuickReply', {
        quickReply: item.title,
        messageId: this.messageId,
      });
    },
    locationTrigger(item) {

      var deviceOS = "unknown";
      var data = item.payload;

      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/windows phone/i.test(userAgent)) {
          deviceOS = "windowsPhone";
      }
      if (/android/i.test(userAgent)) {
          deviceOS = "android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          deviceOS = "ios";
      }
      
      switch(deviceOS) {
        case "android":
          try {
            Android.fetchLocation(JSON.stringify(data));
          } catch (error) {
            console.log(error);
            // If mobile couldn't sent data using Android function then It will try sending location using web (IP based).
            this.fetchLocationOnWeb(data);
          }
          break;
        case "ios":
          try {
            window.webkit.messageHandlers.fetchLocation.postMessage(JSON.stringify(data));
          } catch (error) {
            console.log(error);
            // If mobile couldn't sent data using iOS function then It will try sending location using web (IP based).
            this.fetchLocationOnWeb(data);
          }
          break;
        case "windowsPhone":
        default:
          this.fetchLocationOnWeb(data);
      }
      
      // try {
      //   Android.fetchLocation(JSON.stringify(item.payload));
      // } catch (error) {
      //   let data = {};
      //   data['data'] = item.payload;
      //   data['qw-status'] = 'error';
      //   data['qw-platform'] = 'web';
      //   data['qw-message'] = 'functionaltiy not available on web';
      //   item.payload = data;
      //   this.postback(item);
      // }
    },
    fetchLocationOnWeb(data){
      navigator.geolocation.getCurrentPosition(position => {
        this.onLocationCaptured(position, data)
      }, err => {
        if (err.message == 'User denied Geolocation') {
          alert(err.message + ': Please enable location permission in your browser!');
        } else {
          alert(err.message + '\nMake sure your location setting is on');
        }
      }, {enableHighAccuracy:true} )
    },
    onLocationCaptured(position, data){
      var location = {
        lat : position.coords.latitude,
        long : position.coords.longitude,
        data : data
      }
      this.handleSendLocation(location);
    },
    trigger(item) {
      if (item.type == 'web_url') {
        this.sendMessage(item);
        window.open(item.url);
      } else if (item.type == 'postback') {
        this.postback(item);
      } else if (item.type == 'location') {
        this.locationTrigger(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables.scss';
$gray: #555;
$yellow: #f2e968;
$white: #efefef;

.grid-container {
  background: $white;
  font-family: 'Rubik', sans-serif;
}

@supports (display: grid) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr auto;

    //Let the craziness begin!!!
    grid-template-areas:
      'header header header'
      'title title footer'
      'main main main';
    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
      grid-template-rows: 0.3fr 1fr auto 1fr;
      grid-template-areas:
        'header'
        'title'
        'main'
        'footer';
    }
  }

  .grid-item {
    color: #fff;
    background: skyblue;
    padding: 3.5em 1em;
    font-size: 1em;
    font-weight: 700;
  }

  .items {
    // font-size: 16px;
    // pointer-events: none;
    // position: relative;
    text-align: left;
    top: calc(-60px);
  }

  .position_relative {
    position: relative;
  }

  // .scroll

  //   overflow: hidden;
  //   white-space: nowrap;
  //

  .inline_elem {
    display: flex;
    left: 0;
    position: relative;
    transition: left 500ms ease-out;
    white-space: nowrap;
    z-index: 0;
    flex-wrap: wrap;
  }

  .item {
    display: inline;
    vertical-align: middle;
    cursor: pointer;
  }

  .margin_6_4 {
    margin: 2px 0px;
  }

  .align_item {
    // align-items: center;
    display: flex;
    flex-direction: column;
  }

  .align_item .flex_adjust {
    min-width: 44px;
  }

  .flex_adjust {
    // align-items: center;
    //display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: auto;
    margin-right: 0.4rem;
  }
  .white-space{
    white-space:normal;
  }

  .border-adjust {
    border: 1px solid #0084ff;
  }
  .bg-adjust {
    background: #ffffff69;
    border-radius: 1.3em;
    color: #0084ff;
    display: inline-block;
  }
  .padding-adjust {
    padding-left: 12px;
    padding-right: 12px;
  }

  .a-adjust {
    line-height: 29px;
    text-decoration: none;
    cursor: pointer;
  }

  .a-adjust:hover {
    color: #005aff !important;
  }
}
</style>
