<template>
  <div>
    <div class="report-response-time">
      <div class="response_time">
        <div style="display: flex;">
          <woot-report-stats-block
            v-for="metric in account_level_response_time_metrics"
            :key="metric.AVG.NAME"
            :desc="{ avg: metric.AVG.DESC, median: metric.MEDIAN.DESC}"
            :heading="{ avg: metric.AVG.NAME, median: metric.MEDIAN.NAME}"
            :point="{ avg: converTimeToRedable(responseTimeSummary[metric.AVG.KEY]) || '-', median: converTimeToRedable(responseTimeSummary[metric.MEDIAN.KEY]) || '-'}"
          >{{metric}}</woot-report-stats-block>
        </div>
      </div>
    </div>

    <div class="avg_response_time" style=" margin-bottom:30px; display: none;">
      <div class="report-bar-new">
        <div style="display: flex;justify-content: space-between;">
          <div class="report-title">Response Time</div>
          <div class="qw-export-button" @click="exportResponseTimeimage()">
            <div class="export-botton-title">Export</div>
          </div>
        </div>
        <woot-loading-state
          v-if="avgResponseTimeReport.isFetching || medianResponseTimeReport.isFetching"
          :message="$t('REPORT.LOADING_CHART')"
        />
        <div v-else class="chart-container">
          <woot-line-time
            v-if="avgResponseTimeReport.data.length && medianResponseTimeReport.data.length"
            :collection="barCollectionAvgResponseTimeReport"
          />
          <span v-else class="empty-state">{{ $t('REPORT.NO_ENOUGH_DATA') }}</span>
        </div>
      </div>
    </div>

    <div class="team_statistics">
      <div>
        <div class="report-data-table-teams">
          <data-table
            :data="teamStatistics"
            :columns-to-display="$t('REPORT.TEAM_REPORT_STRUCTURE.COLUMNS')"
            :filter-key="searchQueryTeam"
            :display-names="display_names_team"
          >
            <template slot="caption">
              <div style="display: flex; justify-content: flex-end;">
                <input
                  class="search-option"
                  placeholder="Search agent"
                  type="text"
                  v-model="searchQueryTeam"
                >
                <i
                  class="icon ion-search search-icon"
                  style="    align-self: center; font-size: 22px; color: #c0cdda; margin-left: -26px;"
                ></i>
              </div>
            </template>

            <template slot="name" slot-scope="props">
              <div style="display: flex;">
                <thumbnail
                  :src="props.entry.profile_pic"
                  class="columns"
                  :username="props.entry.email"
                  size="25px"
                />
                <span style="padding-left: 5px;align-self: center;">{{props.entry.name}}</span>
              </div>
            </template>

            <!-- <template slot="avg_response_time" slot-scope="props">
              <div
                style="text-align: right;"
              >{{props.entry.avg_response_time ? converTimeToRedable(props.entry.avg_response_time) : '-'}}</div>
            </template> -->

            <!-- <template
              slot="median_response_time"
              slot-scope="props"
            >{{props.entry.median_response_time ? converTimeToRedable(props.entry.median_response_time) : '-'}}</template> -->

            <template slot="current_login_time" slot-scope="props">
              {{props.entry.current_login_time ? dynamicTimeWithFormatToReadableDate(props.entry.current_login_time) : '-'}}
            </template>

            <template slot="nodata">
              <div class="empty-data">
                No data is available to show
              </div>
            </template>

          </data-table>
        </div>
        <div style="font-size: 14px; padding-top: 10px; line-height: 18px;">
          This table shows list of all the agents with their respective activities performed between the time range specified in the filter on top. Please note,
          <b>Current Login Time</b> is not based on the time range, it is simply the last login time of the agent.
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../../../../components/widgets/Thumbnail';
import { mixin as clickaway } from 'vue-clickaway';
import moment from 'moment';

export default {
  mixins: [clickaway],
  components: {
    Thumbnail,
  },
  props: ['to', 'from', 'currentInboxSelection' ],
  data() {
    return {
      searchQueryTeam: '',
      isDestroyed: false,
      display_names_team: {
        name: 'Name',
        total_open: 'Open',
        total_closed: 'Closed',
        total_conversation : 'Total',
        participated_in: 'Participated',
        avg_response_time: 'Avg Response Time',
        median_response_time: 'Median Response Time',
        current_login_time: 'Current login time',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      responseTimeSummary: 'getResponseTimeStatistics',
      teamStatistics: 'getTeamStatistics',
      avgResponseTimeReport: 'getAvgResponseTimeReports',
      medianResponseTimeReport: 'getMedianResponseTimeReports',
    }),
    barCollectionAvgResponseTimeReport() {
      if (
        this.avgResponseTimeReport.isFetching ||
        this.medianResponseTimeReport.isFetching
      ) {
        return {};
      }
      if (
        this.avgResponseTimeReport.data.length == 0 ||
        this.medianResponseTimeReport.data.length == 0
      )
        return {};
      const labels = this.avgResponseTimeReport.data.map(element =>
        moment.unix(element.timestamp).format('DD/MMM')
      );
      const data_avg = this.avgResponseTimeReport.data.map(
        element => element.value || 0
      );
      const data_median = this.medianResponseTimeReport.data.map(
        element => element.value || 0
      );
      return {
        labels,
        datasets: [
          {
            label: 'Median Response Time',
            backgroundColor: '#4DB362',
            borderColor: '#4DB362',
            showLine: true,
            spanGaps: true,
            lineTension: 0.3,
            fill: false,
            data: data_median,
          },
          {
            label: 'Avg Response Time',
            backgroundColor: '#F0CA2D',
            borderColor: '#F0CA2D',
            showLine: true,
            spanGaps: true,
            lineTension: 0.3,
            fill: false,
            data: data_avg,
          },
        ],
      };
    },
    metrics() {
      return this.$t('REPORT.METRICS');
    },
    account_level_response_time_metrics() {
      return this.$t('REPORT.ACCOUNT_LEVEL_RESPONSE_TIME_METRICS');
    },
  },
  mounted() {
    this.fetchAllTeamReports();
  },
  beforeDestroy() {
    this.isDestroyed = true;
  },
  methods: {
    dynamicTimeWithFormatToReadableDate(date) {
      return moment
        .utc(date, 'YYYY-MM-DD[T]HH:mm:ss[z]')
        .local()
        .format('DD-MM-YYYY HH:mm:ss');
    },
    exportResponseTimeimage() {
      let canvas = document
        .querySelectorAll('#line-chart')[0]
        .toDataURL('image/png');
      let link = document.createElement('a');
      link.download = 'Response Time';
      link.href = canvas;
      link.click();
    },    converTimeToRedable(inputSeconds) {
      inputSeconds = Math.round(inputSeconds);
      const Days = Math.floor(inputSeconds / (60 * 60 * 24));
      const Hour = Math.floor((inputSeconds % (60 * 60 * 24)) / (60 * 60));
      const Minutes = Math.floor(
        ((inputSeconds % (60 * 60 * 24)) % (60 * 60)) / 60
      );
      const Seconds = Math.floor(
        ((inputSeconds % (60 * 60 * 24)) % (60 * 60)) % 60
      );
      let ddhhmmss = '';
      if (Days > 0) {
        ddhhmmss += Days + ' d ';
      }
      if (Hour > 0) {
        ddhhmmss += Hour + ' h ';
      }

      if (Minutes > 0) {
        ddhhmmss += Minutes + ' m ';
      }

      if (Seconds > 0) {
        ddhhmmss += Seconds + ' s ';
      }
      return ddhhmmss;
    },
    async fetchAllTeamReports(){
      await this.generateTeamPerformanceReport();
      await this.generateResponseTimeReport();
      await this.fetchResponseTimeReport();
    },
    async generateTeamPerformanceReport() {
      const { from, to } = this;
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchTeamStatistics', {
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }
    },
    async generateResponseTimeReport() {
      const { from, to } = this;
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchResponseTimeStatistics', {
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }
    },
    async fetchResponseTimeReport() {
      const { from, to } = this;
      // average response time report
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchReportForMetric', {
          metric: 'avg_response_time_count',
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }
      if (!this.isDestroyed) {
        await this.$store.dispatch('fetchReportForMetric', {
          metric: 'median_response_time_count',
          from,
          to,
          inboxId: this.currentInboxSelection
            ? this.currentInboxSelection.id
            : null,
        });
      }
    },
  },
  watch: {
    from(newfrom, oldfrom) {
      this.from = newfrom;
      this.fetchAllTeamReports();
    },
    to(newto, oldto) {
      this.to = newto;
      this.fetchAllTeamReports();
    },
    currentInboxSelection(newcurrentInboxSelection, oldcurrentInboxSelection) {
      this.currentInboxSelection = newcurrentInboxSelection;
      this.fetchAllTeamReports();
    }
  },
};
</script>


<style lang="scss">

.team_report_tooltip {
  font-size: 14px !important;
  color: #485463 !important;
}

.report-data-table-teams {
  table {
    border: 1px solid #dedddf;
    border-radius: 8px;
    font-size: 12px;

    th,
    td {
      text-align: right;
      padding: 8px;
    }

    th:first-child,
    td:first-child {
      text-align: left;
      padding: 8px;
    }
  }

  th {
    text-transform: capitalize;
    background-color: #f9f9f9;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  th.active {
    color: #3970f1;
  }

  th.active .arrow.asc {
    opacity: 1;
    border-bottom: 4px solid #3970f1;
  }

  th.active .arrow.dsc {
    opacity: 1;
    border-top: 4px solid #3970f1;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

  .arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #c7c4c4;
  }

  .arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #c7c4c4;
  }
  
  .empty-data {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    color: #9ba1ac;
  }
}

</style>