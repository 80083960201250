<template>
  <div class="scrollable-div">
    <div class="setting-form">
      <div>
        <div class="form-field">
          <div class="form-label">Account Name</div>
          <div class="form-input">
            <input class="field" type="text" v-model="name" disabled>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">White Label</div>
          <div class="form-input">
            <input class="field" type="text" v-model="whiteLabel" disabled>
          </div>
          <div v-if="productTitle == 'Conversation Management'"
            class="form-help-text"
            v-html="'You can remove ‘Powered by Quickwork’ by enabling this field. Please contact <a href=\'mailto:support@quickwork.co\' target=\'_blank\' style=\'color: #3a70f1;\' >support@quickwork.co</a> for enabling it'"
          ></div>
        </div>
        <div class="form-field">
          <div class="form-label">Export Account</div>
          <div
            class="form-help-text m-t-8"
          >Export account action will generate a dump of all the conversations and messages. You will be receiving these details on your registered email id. This process may take maximum 7 working days.</div>
          <div class="form-botton-element">
            <div
              class="form-button blue m-t-16"
              :class="{ disabled : alreadyRequestedExportAccount}"
              @click="onRequestExport"
            >Export Account</div>
            <span class="request-submitted" v-if="alreadyRequestedExportAccount">
              Request submitted
              <i class="icon ion-checkmark"></i>
            </span>
          </div>
        </div>
        <div class="form-field">
          <div class="form-label">Delete Account</div>
          <div
            class="form-help-text m-t-8"
          >Delete account action will delete your entire account including agents, canned responses, labels, conversations and inboxes with all logs. This process may take maximum 7 working days to reflect.</div>
          <div class="form-input">
            <div v-if="!askDeleteConfirmation">
              <div class="form-botton-element">
                <div
                  class="form-button red m-t-16"
                  :class="{ disabled : alreadyRequestedDeleteAccount}"
                  @click="onRequestDelete"
                >Delete Account</div>
                <span class="request-submitted" v-if="alreadyRequestedDeleteAccount">
                  Request submitted
                  <i class="icon ion-checkmark"></i>
                </span>
              </div>
            </div>
            <div v-else style="padding-top: 16px;width: 300px;font-size: 14px;">
              <div class="delete-confirmation-title">Are you sure you want to delete your account?</div>
              <div class="display-flex-space-between">
                <div
                  class="form-button red m-t-16"
                  @click="onDeleteConfirmation($event)"
                  style="width: 118px"
                >Delete</div>
                <div
                  class="form-button grey m-t-16"
                  @click="onClose($event)"
                  style="width: 118px"
                >Cancel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import Modal from '../../../../components/Modal';
import utitlityMixin from '../../../../mixins/utility';

export default {
  components: {
    Modal,
  },
  mixins: [utitlityMixin],
  data() {
    return {
      id: '',
      name: '',
      whiteLabel: false,
      locale: 'en',
      domain: '',
      domainEmailsEnabled: false,
      supportEmail: '',
      features: {},
      getAccount: {},
      askDeleteConfirmation: false,
      alreadyRequestedDeleteAccount: false,
      alreadyRequestedExportAccount: false,
      productTitle: window.chatwootConfig.custom_website.title,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
  },
  validations: {
    name: {
      required,
    },
    locale: {
      required,
    },
    whiteLabel: {
      required,
    },
  },
  mounted() {
    if (!this.id) {
      this.initializeAccount();
    }
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    onRequestDelete(event) {
      event.preventDefault();
      if (this.alreadyRequestedDeleteAccount == true) {
        return;
      }
      this.askDeleteConfirmation = true;
    },
    onClose(event) {
      if (event) {
        event.preventDefault();
      }
      this.askDeleteConfirmation = false;
    },
    async onDeleteConfirmation(event) {
      event.preventDefault();
      try {
        var response = await this.$store.dispatch('accounts/accountDelete', {
          accountId: this.accountId,
        });
        this.onClose(event);
        if (response.status == 200) {
          this.alreadyRequestedDeleteAccount = true;
          this.showAlert('Request submitted successfully!');
        } else {
          this.showAlert('Something went wrong. Please try again later.');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onRequestExport() {
      event.preventDefault();
      if (this.alreadyRequestedExportAccount == true) {
        return;
      }
      try {
        var response = await this.$store.dispatch('accounts/accountExport', {
          accountId: this.accountId,
        });
        this.onClose(event);
        if (response.status == 200) {
          this.alreadyRequestedExportAccount = true;
          this.showAlert('Request submitted successfully!');
        } else {
          this.showAlert('Something went wrong. Please try again later.');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async initializeAccount() {
      try {
        var response = await this.$store.dispatch('accounts/accountDetails');
        if (response.status == 200) {
          this.whiteLabel = response.data.success.current_account.white_labeled;
          this.name = response.data.success.current_account.name;
          if (response.data.success.delete_account_request != null) {
            this.alreadyRequestedDeleteAccount = true;
          }
          if (response.data.success.export_account_request != null) {
            this.alreadyRequestedExportAccount = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
      // try {
      //   await this.$store.dispatch('accounts/get');
      //   const {
      //     name,
      //     locale,
      //     id,
      //     domain,
      //     support_email,
      //     domain_emails_enabled,
      //     features,
      //   } = this.getAccount(this.accountId);

      //   Vue.config.lang = locale;
      //   this.name = name;
      //   this.locale = locale;
      //   this.id = id;
      //   this.domain = domain;
      //   this.supportEmail = support_email;
      //   this.domainEmailsEnabled = domain_emails_enabled;
      //   this.features = features;
      // } catch (error) {
      //   // Ignore error
      // }
    },

    // async updateAccount() {
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
    //     return;
    //   }
    //   try {
    //     await this.$store.dispatch('accounts/update', {
    //       locale: this.locale,
    //       name: this.name,
    //       domain: this.domain,
    //       support_email: this.supportEmail,
    //       domain_emails_enabled: this.domainEmailsEnabled,
    //     });
    //     Vue.config.lang = this.locale;
    //     this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
    //   } catch (error) {
    //     this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
    //   }
    // },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';
@import '~dashboard/assets/scss/setting-form.scss';

.modal-container {
  background: #fbfbfc;
  padding: 10px;
}

.request-submitted {
  padding-left: 10px;
  font-size: 1.4rem;
  color: #1c941c;
  font-weight: 600;
  align-self: center;
  margin-top: 16px;
}
</style>
