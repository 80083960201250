/* global axios */
import ApiClient from '../ApiClient';

class WorkingHourApi extends ApiClient {
  constructor() {
    super('working_hours');
  }

  getWorkingHours({ inboxId }) {
    return axios.get(`${this.url}/${inboxId}`);
  }

}

export default new WorkingHourApi();
