<template>
  <div class="code--container">
    <highlight-code :lang="lang">{{ script }}</highlight-code>
    <div
      class="form-button blue m-t-24 button-fixed-width"
      :class="{copied: clicked}"
      @click="onCopy"
    >
      <span v-if="clicked">Copied</span>
      <span v-else>Copy {{type}}</span>
    </div>
  </div>
</template>

<script>
/* global bus */
import 'highlight.js/styles/default.css';
import copy from 'copy-text-to-clipboard';

export default {
  props: {
    script: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      clicked: false,
    };
  },
  methods: {
    onCopy() {
      this.clicked = !this.clicked;
      copy(this.script);
      bus.$emit('newToastMessage', this.$t('COMPONENTS.CODE.COPY_SUCCESSFUL'));
    },
  },
};
</script>

<style lang="scss" scoped>
.code--container {
  padding: 24px;
  border: 1px solid #dedddf;
  border-radius: 4px;

  .form-button.blue.copied {
    background: #4db362;
    border-color: #4db362;
    color: white;
  }
}
</style>
