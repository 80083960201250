<template>
  <div
    v-if="data.attachment || data.content || data.content_type == 'media' || data.content_type == 'location' || data.content_type == 'table_card'"
    class="chat-conversations"
  >
    <div v-if="groupByDate" class="chat-center">
      <div class="chat-conversation-center-bubble">
        <div class="chat-content">
          <div class="content center groupdate">
            <div>{{dateGroup}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="alignBubble == 'left'" class="chat-left">
      <div class="chat-conversation-left-bubble">
        <div class="chat-content">
          <div class="chat-user-profile-picture" style="height: 32px; width: 32px;">
            <div v-if="showLeftSideNameAndProfile">
              <CardQWThumbnail
                :src="currentChat.meta.sender.profile_pic"
                size="32px"
                :username="currentChat.meta.sender.name"
                :color-code="currentChat.color_code"
              />
            </div>
          </div>
          <div class="content left">
            <div class="datetime-tooltip-outer">
              <div class="datetime-tooltip">
                <div class="datetime-tooltip-arrow"></div>
                <div class="datetime-tooltip-inner" v-html="sentByMessage"></div>
              </div>
            </div>
            <bubble-text
              v-if="data.content || data.content_type == 'media' || data.content_type == 'location'"
              :content-type="contentType"
              :payload="payload"
              :message="message"
              :message-id="messageId"
              :message-type="messageType"
              :message-content-attributes="messageContentAttributes"
              :readable-time="readableTime"
            />
          </div>
        </div>
        <div class="chat-user-name-small left" v-if="showLeftSideNameAndProfile">
          <div>{{currentChat.meta.sender.name}}</div>
          <div class="chat-time">{{readableTime}}</div>
        </div>
      </div>
    </div>
    <div v-if="alignBubble == 'center'" class="chat-center">
      <div class="chat-conversation-center-bubble">
        <div class="chat-content">
          <div class="content center">
            <div class="datetime-tooltip-outer">
              <div class="datetime-tooltip">
                <div class="datetime-tooltip-arrow"></div>
                <div class="datetime-tooltip-inner" v-html="sentByMessage"></div>
              </div>
            </div>
            <i v-if="data.message_type === 2" class="icon ion-person"/>
            <bubble-text
              v-if="data.content || data.content_type == 'media' || data.content_type == 'location'"
              :content-type="contentType"
              :payload="payload"
              :message="message"
              :message-id="messageId"
              :message-type="messageType"
              :message-content-attributes="messageContentAttributes"
              :readable-time="readableTime"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="alignBubble == 'right'" class="chat-right">
      <div class="chat-conversation-right-bubble">
        <div class="chat-content" style="justify-content: flex-end;">
          <div class="content right" :class="{private : isPrivate}">
            <div class="datetime-tooltip-outer">
              <div class="datetime-tooltip">
                <div class="datetime-tooltip-arrow"></div>
                <div class="datetime-tooltip-inner" v-html="sentByMessage"></div>
              </div>
            </div>
            <bubble-text
              class="chat-message"
              v-if="data.content || data.content_type == 'media' || data.content_type == 'location' || data.content_type == 'table_card'"
              :content-type="contentType"
              :payload="payload"
              :message="message"
              :message-id="messageId"
              :message-type="messageType"
              :message-content-attributes="messageContentAttributes"
              :readable-time="readableTime"
            />
            <div v-if="isPrivate" class="private-message">
              <i
                style="position: absolute; right: -10px; top: -10px;"
                v-if="isPrivate"
                v-tooltip.right="toolTipMessage"
                class="icon ion-android-lock"
                @mouseenter="isHovered = true"
                @mouseleave="isHovered = false"
              />
            </div>
          </div>
          <div class="chat-user-profile-picture" style="height: 32px; width: 32px;">
            <div v-if="showRightSideNameAndProfile">
              <QWThumbnail
                v-if="data.sender"
                :src="data.sender.profile_pic"
                size="32px"
                :username="data.sender.name"
              />
            </div>
          </div>
        </div>
        <div class="chat-user-name-small right" v-if="showRightSideNameAndProfile">
          <div v-if="data.sender">{{data.sender.name}}</div>
          <div class="chat-time">{{readableTime}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-named-as-default */
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import timeMixin from '../../../mixins/time';
import BubbleText from './bubble/Text';
import QWThumbnail from '../QWThumbnail';
import CardQWThumbnail from '../CardQWThumbnail';

export default {
  components: {
    BubbleText,
    QWThumbnail,
    CardQWThumbnail
  },
  mixins: [timeMixin, messageFormatterMixin],
  props: {
    currentChat: {
      type: Object,
    },
    data: {
      type: Object,
      required: true,
    },
    nextMessage: {
      type: Object,
      required: false,
    },
    lastMessageDate: {
      required: false,
    },
    currentMessageDate: {
      required: false,
    },
    type: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      isHovered: false,
      dateGroup: null,
    };
  },
  computed: {
    message() {
      return this.formatMessage(this.data.content);
    },
    payload() {
      if (this.data.payload == null || this.data.payload == undefined) {
        this.data.payload = {};
        return this.data.payload;
      }
      if (typeof this.data.payload === 'object') {
        this.data.payload;
      } else {
        return JSON.parse(this.data.payload);
      }
    },
    contentType() {
      return this.data.content_type;
    },
    messageContentAttributes() {
      return this.data.content_attributes;
    },
    messageId() {
      return this.data.id;
    },
    messageType() {
      return this.data.message_type;
    },
    alignBubble() {
      if (this.data.message_type == 0) {
        return 'left';
      } else if (this.data.message_type == 2) {
        return 'center';
      } else {
        return 'right';
      }
    },
    readableTime() {
      return this.dynamicTime(this.data.created_at);
    },
    isBubble() {
      return [0, 1, 3].includes(this.data.message_type);
    },
    isPrivate() {
      return this.data.private;
    },
    toolTipMessage() {
      return this.data.private
        ? { content: this.$t('CONVERSATION.VISIBLE_TO_AGENTS') }
        : false;
    },
    groupByDate() {
      var oldDate = this.toDateWithDay(this.lastMessageDate);
      var newDate = this.toDateWithDay(this.currentMessageDate);
      if (oldDate === newDate) {
        this.dateGroup = null;
        return false;
      }
      this.dateGroup = newDate;
      return true;
    },
    sentByMessage() {
      var dateTime = this.toDateTimeWithDay(this.data.created_at);
      return dateTime;
      // if (this.data.message_type == 0) {
      //   return { content: `${dateTime}`, classes: 'top' };
      // }
      // if (this.data.message_type == 2) {
      //   return { content: `${dateTime}` };
      // }
      // if (this.data.message_type == 3) {
      //   if (!this.isHovered) {
      //     return { content: `${dateTime}`, classes: 'top' };
      //   }
      // }
      // return false;
    },
    wrapClass() {
      return {
        wrap: this.isBubble,
        'activity-wrap': !this.isBubble,
      };
    },
    showRightSideNameAndProfile() {
      if (this.nextMessage == null) {
        return true;
      } else {
        if (this.nextMessage.sender) {
          try {
            if (this.data.sender.name != this.nextMessage.sender.name) {
              return true;
            } else {
              var oldDate = this.toDateWithDay(this.nextMessage.created_at);
              var newDate = this.toDateWithDay(this.currentMessageDate);
              if (oldDate === newDate) {
                return false;
              } else {
                return true;
              }
            }
          } catch (error) {
            return true;
          }
        } else {
          return true;
        }
      }
    },
    showLeftSideNameAndProfile() {
      if (this.nextMessage == null) {
        return true;
      } else {
        if (this.data.message_type != this.nextMessage.message_type) {
          return true;
        } else {
          var oldDate = this.toDateWithDay(this.nextMessage.created_at);
          var newDate = this.toDateWithDay(this.currentMessageDate);
          if (oldDate === newDate) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
.chat-conversations {
  width: inherit;
}

.chat-conversation-right-bubble {
  width: inherit;
}

.chat-conversation-left-bubble {
  width: inherit;
}

.chat-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2px;
  width: inherit;
  line-height: initial;
}

.chat-center {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 6px;
  width: inherit;
}

.chat-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2px;
  width: inherit;
  line-height: initial;
}

.chat-content {
  display: flex;
  align-items: flex-end;
}

.content {
  padding: 8px 12px;
  font-size: 14px;
}

.content.left {
  background: #f6f6f7;
  color: #1a1c1d;
  border-radius: 4px;
  margin-left: 8px;
  min-width: 100px;
  max-width: 86.5%;
  word-break: break-word;
}

.content.center {
  background: #fff8ec;
  color: #1a1c1d;
  border-radius: 8px;
  width: fit-content;
  max-width: 100%;
  word-break: break-word;
  padding: 8px 12px;
}

.content.center.groupdate {
  background: #969ba7;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 12px;
  color: white;
  border-radius: 25px;
  line-height: 24px;
  // box-shadow: 0px 0px 8px #1a1c1d29;
}

.content.right {
  background: #eff4fe;
  color: #1a1c1d;
  border-radius: 4px;
  margin-right: 8px;
  min-width: 100px;
  max-width: 86.5%;
  word-break: break-word;
}

.content.right.private {
  background: #fff8ec;
}

.content.right {
  .private-message {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
}

.chat-message {
  line-height: normal;
}

.chat-user-name-small.left {
  display: flex;
  color: #697181;
  font-weight: bold;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 8px;
  margin-left: 40px;
}

.chat-user-name-small.right {
  display: flex;
  color: #697181;
  font-weight: bold;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 40px;
  align-items: flex-end;
  justify-content: flex-end;
}

.chat-time {
  margin-left: 10px;
  color: #697181;
  font-weight: 400;
}

.datetime-tooltip-outer {
  display: flex;
  justify-content: center;
  position: relative;
}

.datetime-tooltip {
  position: absolute;
  top: -30px;
  z-index: 999;
  visibility: hidden;
  transition: ease-in 0.3s;
  transition-delay: 0.3s;
  background: #969ba7;
  color: white;
  width: 160px;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
  opacity: 0;
  line-height: 24px;

  .datetime-tooltip-arrow {
    top: 22px;
    position: absolute;
    left: 50%;
    border-style: solid;
    border-color: transparent transparent #969ba7 transparent;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #969ba7;
  }
}

.content.left:hover .datetime-tooltip {
  display: block;
  opacity: 1;
  visibility: visible;
}

.content.center:hover .datetime-tooltip {
  display: block;
  opacity: 1;
  visibility: visible;
}

.content.right:hover .datetime-tooltip {
  display: block;
  opacity: 1;
  visibility: visible;
}
</style>
