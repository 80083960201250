<template>
  <div class="medium-12 column login">
    <div class="text-center medium-12 login__hero align-self-top">
      <img :src="main_logo_url" alt="logo" class="hero__logo" />
      <h2 class="hero__title">{{title}}</h2>
      <h3 class="hero__sub">{{description}}</h3>
    </div>
    <div class="row align-center">
      <div class="small-12 medium-4 column">
        <form class="login-box column align-self-top" v-on:submit.prevent="login()">
          <div class="column log-in-form">
            <!-- <h4 class="text-center">{{$t('LOGIN.TITLE')}}</h4> -->
            <label :class="{ 'error': $v.credentials.email.$error }">
              {{$t('LOGIN.EMAIL.LABEL')}}
              <input
                type="text"
                v-bind:placeholder="$t('LOGIN.EMAIL.PLACEHOLDER')"
                v-model.trim="credentials.email"
                @input="$v.credentials.email.$touch"
              />
            </label>
            <label :class="{ 'error': $v.credentials.password.$error }">
              {{$t('LOGIN.PASSWORD.LABEL')}}
              <input
                type="password"
                v-bind:placeholder="$t('LOGIN.PASSWORD.PLACEHOLDER')"
                v-model.trim="credentials.password"
                @input="$v.credentials.password.$touch"
              />
            </label>
            <div v-show="recaptcha.required" id="html_element"  style="display: flex;justify-content: center;"></div>
            <br />
            <woot-submit-button
              :disabled="$v.credentials.email.$invalid || $v.credentials.password.$invalid || loginApi.showLoading || (recaptcha.required && !recaptcha.verified)"
              :button-text="$t('LOGIN.SUBMIT')"
              :loading="loginApi.showLoading"
              button-class="large expanded"
              style="background : #3970f1;"
            ></woot-submit-button>
            <!-- <input type="submit" class="button " v-on:click.prevent="login()" v-bind:value="" > -->
          </div>
        </form>
        <div class="column text-center sigin__footer">
          <p>
            <router-link to="auth/reset/password">{{$t('LOGIN.FORGOT_PASSWORD')}}</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* #0f0f6a:the blue background */
/* global bus */

import { required, email } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../components/buttons/FormSubmitButton';
// import router from '../../routes';

export default {
  components: {
    WootSubmitButton,
  },
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        email: '',
        password: '',
      },
      loginApi: {
        message: '',
        showLoading: false,
      },
      error: '',
      main_logo_url: null,
      title: null,
      description: null,
      sitekey: null,
      recaptcha: {
        required: false,
        verified: false,
        token: null
      }
    };
  },
  created() {
    this.main_logo_url = window.chatwootConfig.custom_website.main_logo_url;
    this.title = window.chatwootConfig.custom_website.title;
    this.description = window.chatwootConfig.custom_website.description;
    this.sitekey = window.sitekey;
  },
  validations: {
    credentials: {
      password: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    renderReCaptcha() {
      try {
        grecaptcha.render('html_element', {
          'sitekey': this.sitekey,
          'callback': this.verify,
          'expired-callback': this.expired,
        });
      } catch (error) {
        this.expired();
      }
    },
    showAlert(message) {
      // Reset loading, current selected agent
      this.loginApi.showLoading = false;
      this.loginApi.message = message;
      bus.$emit('newToastMessage', this.loginApi.message);
    },
    login() {
      this.loginApi.showLoading = true;
      var credentials = {
        email: this.credentials.email,
        password: this.credentials.password,
      };
      if (this.recaptcha.required && this.recaptcha.token != null) {
        credentials['token'] = this.recaptcha.token;
      }
      this.$store
        .dispatch('login', credentials)
        .then(() => {
          this.showAlert(this.$t('LOGIN.API.SUCCESS_MESSAGE'));
        })
        .catch(response => {
          if (response && response.status === 401) {
            if (response.data.verificationRequired) {
              this.recaptcha.required = true;
              this.renderReCaptcha();
            }
            this.showAlert(this.$t('LOGIN.API.UNAUTH'));
            return;
          }
          this.showAlert(this.$t('LOGIN.API.ERROR_MESSAGE'));
        });
    },
    verify(response) {
      this.recaptcha.token = response;
      this.recaptcha.verified = true;
    },
    expired() {
      this.recaptcha.token = null;
      this.recaptcha.verified = false;
      grecaptcha.reset();
    },
  },
};
</script>
