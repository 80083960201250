export default {
  APP_BASE_URL: '/',
  get apiURL() {
    return `${this.APP_BASE_URL}/`;
  },
  GRAVATAR_URL: 'https://www.gravatar.com/avatar/',
  ASSIGNEE_TYPE_SLUG: {
    MINE: 0,
    UNASSIGNED: 1,
    OPEN: 0,
  },
  CONVERSATION_SORT: {
    ASC: 0,
    DESC: 1
  },
  PAGE_LIMIT: 30,
  INBOX_SETTINGS: {
    FILE_UPLOAD: {
      URL: 'https://messenger-fileupload.quickwork.co/upload',
      // URL: 'http://localhost:3001/upload',
      MAX_FILE_SIZE: 1048576,
      IMAGE_TYPES: ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'svg', 'ico'],
      FILE_TYPES: ["jpeg", "jpg", "png", "gif", "bmp", "svg", "ico","mp4", "avi", "mov", "flv", "mp3", "m4a", "wav", "pdf", "doc", "docx", "html", "htm", "csv", "xls", "xlsx", "txt", "ppt", "pptx" ]
    }
  },
};
