<template>
  <div>
    <div class="agent-list">
      <div>
        <QWThumbnail
            :src="agentProfilePic"
            :username="agentName"
            size="40px"
            style="margin-right: 8px;"
            :status="availability"
          />
      </div>
      <div class="agent">
        <div class="elememnt">
          <div class="title">Full Name</div>
          <div class="value">
            <span v-if="!showEditPopup">{{agentName}}</span>
            <div v-else class="form-field">
              <input
                v-model.trim="agentName"
                type="text"
                :placeholder="$t('AGENT_MGMT.EDIT.FORM.NAME.PLACEHOLDER')"
                @input="$v.agentName.$touch"
              />
            </div>

          </div>
        </div>
        <div class="elememnt">
          <div class="title"> <img src="~/assets/new_images/Role_Icon.svg" /> <span> Role </span> </div>
          <div class="value" style="text-transform: capitalize;">
            <span v-if="!showEditPopup" > {{agentType.label}} </span>
            <div v-else class="form-field">
              <select v-model="agentType.name" class="field" @change="onTabChange()">
                  <option
                    v-for="item in agentTypeList"
                    :key="item.label"
                    :value="item.name"
                  >
                    {{ item.label}}
                  </option>
                </select>
            </div>
           </div>
        </div>
        <div class="elememnt" style="display: flex; justify-content: space-between;">
          <div>
            <div class="title"> <img src="~/assets/new_images/Shield_Icon.svg" /> <span> Verification </span> </div>
            <div class="value">
              <span v-if="agentConfirmed" class="verified">Verified</span>
              <span v-else class="pending">Pending</span>
            </div>
          </div>
          <div v-if="showEditPopup">
            <div style="cursor: pointer;" @click="resetPassword"> <img src="~/assets/new_images/Password_Icon.svg" />  <span style="vertical-align: bottom; color: #3a6ff1;"> Reset Password </span> </div>
          </div>

        </div>
        <div class="elememnt">
          <div class="title"> <img src="~/assets/new_images/Email_Icon.svg" /> <span> Email </span> </div>
          <div class="value">{{agentCredentials.email}}</div>
        </div>
        <div class="elememnt">
          <div class="title"> <img src="~/assets/new_images/User_Icon.svg" /> <span> ID </span> </div>
          <div class="value">{{id}}</div>
        </div>
        <div class="elememnt">
          <!-- @click="toggleStatus" -->
          <div v-if="showEditPopup" style="display: flex; align-self: flex-end;">
            <div class="form-button blue" @click="editAgent()" >
                Save Changes
            </div> 
            <div class="form-button grey" @click="hideEditPopup()" style="margin-left: 16px;" >
                Cancel
            </div> 
          </div>
          <div v-if="showDeletePopup">
                                    <div class="delete-confirmation-title">Are you sure you want to delete this?</div>
                        <div class="display-flex-space-between">
                          <div
                            class="form-button red m-t-16"
                            @click="confirmDeletion()"
                            style="width: 118px"
                          >Delete</div>
                          <div
                            class="form-button grey m-t-16"
                            @click="closeDeletePopup()"
                            style="width: 118px"
                          >Cancel</div>
                        </div>
          </div>
          <div v-if="!showEditPopup && !showDeletePopup" style="display: flex; align-self: flex-end;">
            <div v-if="currentUserId != id" class="agent-action-options" @click="openEditPopup()" >
                <img src="~/assets/new_images/Edit_Icon.svg">
            </div> 
            <div v-if="currentUserId != id" class="agent-action-options" @click="openDeletePopup()" style="margin-left: 16px;" >
                <img src="~/assets/new_images/Delete_Icon.svg">
            </div> 
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global bus */
/* eslint no-console: 0 */
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Auth from '../../../../api/auth';
import QWThumbnail from '../../../../components/widgets/QWThumbnail';

export default {
  components: {
    WootSubmitButton,
    QWThumbnail,
  },
  props: {
    index: Number,
    id: Number,
    profilePic: String,
    confirmed: Boolean,
    name: String,
    email: String,
    type: String,
    onClose: Function,
    updateData: Function,
    availability: String,
  },
  data() {
    return {
      agentTypeList: this.$t('AGENT_MGMT.AGENT_TYPES'),
      agentProfilePic: this.profilePic,
      agentProfilePic: this.availability,
      agentName: this.name,
      agentConfirmed: this.confirmed,
      agentType: {
        name: this.type,
        label: this.type,
      },
      agentCredentials: {
        email: this.email,
      },
      show: true,
      showDeletePopup: false,
      showEditPopup: false,
      currentAgent: {}
    };
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(4),
    },
    agentType: {
      required,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('AGENT_MGMT.EDIT.TITLE')} - ${this.name}`;
    },
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
    }),
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} ${
        this.agentName
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} ${
        this.agentName
      }`;
    },
    deleteMessage() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.agentName
      } ?`;
    },
  },
  methods: {
    onTabChange() {
      this.agentType.label = this.agentType.name;
    },
    openEditPopup(){
      this.showEditPopup = true;
    },
    hideEditPopup() {
      this.showEditPopup = false;
      this.agentName = this.name;
      this.agentType = {
        name: this.type,
        label: this.type,
      }
    },
    openDeletePopup() {
      this.showDeletePopup = true;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.deleteAgent(this.id);
      this.closeDeletePopup();
    },
    setPageName({ name }) {
      this.$v.agentType.$touch();
      this.agentType = name;
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async deleteAgent(id) {
      try {
        await this.$store.dispatch('agents/delete', id);
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.ERROR_MESSAGE'));
      }
    },
    async editAgent() {
      try {
        await this.$store.dispatch('agents/update', {
          id: this.id,
          name: this.agentName,
          role: this.agentType.name.toLowerCase(),
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.updateData(this.index, this.agentName, this.agentType.name);
        this.showEditPopup = false;
      } catch (error) {
        if (error.response.data.error) {
          this.showAlert(error.response.data.error);
        } else if (error.response.data.message) {
          this.showAlert(error.response.data.message);
        } else {
          this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
        }
      }
    },
    async resetPassword() {
      try {
        await Auth.resetPassword(this.agentCredentials);
        this.showAlert(
          this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_SUCCESS_MESSAGE')
        );
        this.hideEditPopup();
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ERROR_MESSAGE'));
      }
    },
  },
};
</script>



<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';
@import '~dashboard/assets/scss/setting-form.scss';

.agent-list {
  display: flex;
  border-bottom: 1px solid #EFEEF0;
  margin-top: 24px;

  .agent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;

    .elememnt {
      flex-grow: 1;
      width: 33%;
      margin-bottom: 31px;

      .title {
        color: #697181;
        display: flex;
        align-items: flex-end;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          padding-left: 8px;
        }
      }

      .value {
        padding-top: 7px;
        font-weight: 500;
        color: #1A1C1D;

        .verified {
          color: #4CBF56;
        }

        .pending {
          color: #FF5959;
        }
      }
    }



  }

 .agent-action-options{
      padding: 7px;
      border: 1px solid #DEDDDF;
      border-radius: 4px;
      cursor: pointer;
  }

  .agent-action-options:hover {
    border-color: #3970F1;

    img {
      filter: brightness(3.5) sepia(1) hue-rotate(190deg) saturate(6);
    }
  }

  input {
    height: 32px;
    width: 75%;
    margin-bottom: 0;
  }


  select {
    width: 75%;
    border-radius: 4px;
    height: 32px;
    padding: 0 8px;
    font-size: 14px;
    background-position: right 0 center;
    margin-bottom: 0;
  }

}

</style>