<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editLabelResponse()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.name.$error }">
            {{ $t('LABEL_MGMT.EDIT.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="name"
              type="text"
              :placeholder="$t('LABEL_MGMT.EDIT.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.name.$touch"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                  $v.name.$invalid ||
                  editLabel.showLoading
              "
              :button-text="$t('LABEL_MGMT.EDIT.FORM.SUBMIT')"
              :loading="editLabel.showLoading"
            />
            <a @click="onClose">Cancel</a>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
/* global bus */
/* eslint no-console: 0 */
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../../components/Modal';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  props: {
    id: Number,
    edname: String,
    onClose: Function,
  },
  data() {
    return {
      editLabel: {
        showAlert: false,
        showLoading: false,
        message: '',
      },
      name: this.edname,
      show: true,
    };
  },
  validations: {
    name: {
      required
    }
  },
  computed: {
    pageTitle() {
      return `${this.$t('LABEL_MGMT.EDIT.TITLE')} - ${this.edname}`;
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
  },
  methods: {
    showAlert() {
      bus.$emit('newToastMessage', this.editLabel.message);
    },
    resetForm() {
      this.name = '';
      this.$v.name.$reset();
    },
    editLabelResponse() {
      // Show loading on button
      this.editLabel.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('inboxLabels/update', {
          id: this.id,
          inbox_id: this.currentInboxId,
          name: this.name,
        })
        .then(() => {
          // Reset Form, Show success message
          this.editLabel.showLoading = false;
          this.editLabel.message = this.$t(
            'LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'
          );
          this.showAlert();
          this.resetForm();
          setTimeout(() => {
            this.onClose();
          }, 10);
          this.$store.dispatch('inboxes/get');
        })
        .catch(error => {
          
          try {
            if (error.response.data.error == "Label with same name already exists!") {
              this.editLabel.showLoading = false;
              this.editLabel.message = this.$t('LABEL_MGMT.ADD.API.ALREADY_EXISTS_MESSAGE');
              this.showAlert();
            } else {
              his.editLabel.showLoading = false;
              this.editLabel.message = this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE');
              this.showAlert();
            }
          } catch (error) {
            this.editLabel.showLoading = false;
            this.editLabel.message = this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE');
            this.showAlert();
          }

        });
    },
  },
};
</script>
