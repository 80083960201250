<template>
  <div class="all_agent_list">
    <div class="settings-tabs">
      <div class="setting-tab" :class="{active: !showAddPopup}" @click="hideAddPopup">Agent List</div>
      <div class="setting-tab" :class="{active: showAddPopup}" @click="openAddPopup">Add New Agent</div>
    </div>
    <div v-if="showAddPopup" class="scrollable-div" style="height: calc(100% - 110px);">
      <add-agent :on-close="hideAddPopup"/>
    </div>
    <div v-if="!showAddPopup" class="scrollable-div">
      <woot-loading-state v-if="uiFlags.isFetching" :message="$t('AGENT_MGMT.LOADING')"/>
      <div v-else>
        <p v-if="!agentList.length">{{ $t('AGENT_MGMT.LIST.404') }}</p>
        <div v-else style="margin: 24px;">
          <div v-for="(agent, index) in agentList" :key="agent.email">
            <edit-agent
              :index="index"
              :id="agent.id"
              :name="agent.name"
              :type="agent.role"
              :availability="agent.availability"
              :update-data="updateData"
              :email="agent.email"
              :profile-pic="agent.profile_pic"
              :confirmed="agent.confirmed"
              :on-close="hideEditPopup"
            />
            <p
              v-if="index == (agentList.length - 1)"
              key="AllConversationLoaded_1"
              class="qw-all-conversation-loaded-section"
            >
              <span class="qw-all-conversation-loaded">All admins and agents loaded</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global bus */

import { mapGetters } from 'vuex';
import md5 from 'md5';
// import Vue from 'vue';
import AddAgent from './AddAgent';
import EditAgent from './EditAgent';

export default {
  components: {
    AddAgent,
    EditAgent,
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      agentAPI: {
        message: '',
      },
      currentAgent: {},
      agentType: this.$t('AGENT_MGMT.AGENT_TYPES')[1],
      agentTypeList: this.$t('AGENT_MGMT.AGENT_TYPES'),
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
    }),
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} ${
        this.currentAgent.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} ${
        this.currentAgent.name
      }`;
    },
    deleteMessage() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.currentAgent.name
      } ?`;
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    updateData(index, name, role) {
      this.agentList[index].name = name;
      this.agentList[index].role = role;
    },
    showEditAction(agent) {
      return this.currentUserId !== agent.id;
    },
    showDeleteAction(agent) {
      if (this.currentUserId === agent.id) {
        return false;
      }

      if (!agent.confirmed) {
        return true;
      }

      if (agent.role === 'administrator') {
        return this.verifiedAdministrators().length !== 1;
      }
      return true;
    },
    verifiedAdministrators() {
      return this.agentList.filter(
        agent => agent.role === 'administrator' && agent.confirmed
      );
    },
    gravatarUrl(agent) {
      if (agent.profile_pic) {
        return agent.profile_pic;
      }
      const hash = md5(agent.email);
      return `${window.WootConstants.GRAVATAR_URL}${hash}?default=404`;
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Function
    openEditPopup(agent) {
      this.showEditPopup = true;
      this.currentAgent = agent;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.currentAgent.id] = false;
      this.currentAgent = {};
      // Show message
      this.agentAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>

<style lang="scss">
.all_agent_list {
  height: calc(100% - 67px);

  .scrollable-div {
    height: calc(100% - 50px);
  }
}
</style>