<template>
  <div>
    <div class="search-main">
      <!-- <i class="icon ion-search "></i> -->

      <img class="search-icon" src="~dashboard/assets/new_images/Search_Icon.svg">
      <div style="width: 100%;">
        <input
          class="search"
          style="margin-bottom: 0 !important; border: 0;"
          type="text"
          placeholder="Search Conversations..."
          v-on:keyup.enter="onValueChange"
          v-model="searchValue"
        >
      </div>
      <div
        v-if="searchValue"
        @click="clearSearch()"
        style="align-self: center; padding: 0px 10px; cursor: pointer;"
      >
        <i class="fa fa-times" style="font-size: 15px; color: #6e6f73;"></i>
      </div>
      <select v-model="searchKey" class="search-filter" @change="onTabChange()">
        <option
          v-for="item in $t('CHAT_LIST.CHAT_SEARCH_TYPES')"
          :key="item['VALUE']"
          :value="item['VALUE']"
        >{{ item['TEXT'] }}</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeSearchKey: {
      type: String,
      default: 'text',
    },
    activeSearchValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchKey: this.activeSearchKey,
      searchValue: this.activeSearchValue,
    };
  },
  created() {
    this.$store.getters['globalVariables/setSearchObject']( this.searchKey, this.searchValue);
  },
  watch: {
    activeSearchValue(newactiveSearchValue, oldactiveSearchValue) {
      this.searchValue = newactiveSearchValue;
      this.searchKey = this.activeSearchKey;
    },
  },
  methods: {
    onValueChange() {
      if (this.searchValue == null) {
        return;
      } else {
        this.searchValue = this.searchValue.trim();
        if (this.searchValue.length > 1) {
          if (this.searchValue !== this.activeSearchValue) {
            this.$emit('searchChange', this.searchKey, this.searchValue);
          }
        } else if (this.searchValue == '') {
          this.searchValue = null;
          if (this.searchValue !== this.activeSearchValue) {
            this.$emit('searchChange', this.searchKey, this.searchValue);
          }
        }
      }
    },
    clearSearch() {
      this.searchValue = null;
      this.$emit('searchChange', this.searchKey, this.searchValue);
    },
    onTabChange() {
      if (this.searchKey != this.activeSearchKey && this.searchValue != null) {
        if (this.searchValue == '') {
          this.searchValue = null;
        }
        this.$emit('searchChange', this.searchKey, this.searchValue);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-main {
  border-radius: 4px;
  border: 1px solid #efeef0;
  display: flex;
  background: #f6f6f7;

  select {
    height: 3rem;
    padding: 0;
    margin: 0;
    padding-right: 2.4rem;
    cursor: pointer;
  }

  [type='text'] {
    height: 3rem;
  }
}

.search-icon {
  padding-left: 10px;
}

.search-filter {
  border: 0;
  width: fit-content;
  font-size: 13px;
  color: #8493a5;
  background-color: #f6f6f7;
  margin-bottom: 0;
  border-radius: inherit;
}
</style>
