<template>
  <div class="canned">
    <div class="canned-popover">
      <div>Canned Responses</div>
      <div v-if="loadCannedResponses.length">{{loadCannedResponses.length}}</div>
    </div>
    <div v-if="uiFlags.fetchingList">
      Fetching...
    </div>
    <div v-else>
      <div v-if="loadCannedResponses.length" id="canned-list" class="scrollable vertical dropdown menu" >
        <div
          v-for="(item, index) in loadCannedResponses"
          :id="`canned-${index}`"
          :key="item.short_code"
          :class="{ active: index === selectedIndex }"
          @click="onListItemSelection(index)"
          @mouseover="onHover(index)"
        >
          <div class="text-truncate">
            <div class="short-form" v-html="highlightIfRequired('/' + item.short_code)"></div>
            <div class="content" v-html="highlightIfRequired(item.content)" >{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div v-else>0 canned responses</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['onKeyenter', 'onClick', 'cannedSearchKey'],
  data() {
    return {
      selectedIndex: 0,
      cannedResponsesSize: 0,
    };
  },
  computed: {
    ...mapGetters({
      cannedMessages: 'getCannedResponses',
      uiFlags: 'getUIFlags',
    }),
    loadCannedResponses() {
      if(this.cannedSearchKey){
        var list = this.cannedMessages.filter((item)=>{
          return item.short_code.toLowerCase().includes(this.cannedSearchKey.toLowerCase()) || item.content.toLowerCase().includes(this.cannedSearchKey.toLowerCase());
        });
        this.cannedResponsesSize = list.length;
        
        return list;
      }else{
        this.cannedResponsesSize = this.cannedMessages.length;
        return this.cannedMessages;
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keyListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyListener);
  },
  watch: {
    cannedResponsesSize (oldCannedResponsesSize, newCannedResponsesSize) {
      this.selectedIndex = 0;
    }
  },
  methods: {
    highlightIfRequired(content = '') {
      if (this.cannedSearchKey) {
        return content.replace(
          new RegExp(`(${this.cannedSearchKey})`, 'ig'),
          '<span class="cannedsearchtext--highlight">$1</span>'
        );
      } else {
        return content;
      }
    },
    getTopPadding() {
      if (this.cannedMessages.length <= 4) {
        return -this.cannedMessages.length * 3.5;
      }
      return -14;
    },
    isUp(e) {
      return e.keyCode === 38 || (e.ctrlKey && e.keyCode === 80); // UP, Ctrl-P
    },
    isDown(e) {
      return e.keyCode === 40 || (e.ctrlKey && e.keyCode === 78); // DOWN, Ctrl-N
    },
    isEnter(e) {
      return e.keyCode === 13;
    },
    keyListener(e) {
      if (this.isUp(e)) {
        if (!this.selectedIndex) {
          this.selectedIndex = this.loadCannedResponses.length - 1;
        } else {
          this.selectedIndex -= 1;
        }
      }
      if (this.isDown(e)) {
        if (this.selectedIndex === this.loadCannedResponses.length - 1) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex += 1;
        }
      }
      if (this.isEnter(e)) {
        this.onKeyenter(this.loadCannedResponses[this.selectedIndex].content);
      }
      var parent = this.$el.querySelector('#canned-list');
      if (parent) {
        parent.scrollTop = 45 * this.selectedIndex; 
      }
    },
    onHover(index) {
      this.selectedIndex = index;
    },
    onListItemSelection(index) {
      this.selectedIndex = index;
      this.onClick(this.loadCannedResponses[this.selectedIndex].content);
    },
  },
};
</script>

<style lang="scss">
.canned-popover {
  padding: 2px 8px;
  font-size: 14px;
  color: #1a1c1d;
  line-height: normal;
  border-bottom: 1px solid #dedddf;
  display: flex;
  justify-content: space-between;
}
.cannedsearchtext--highlight {
  background: #fde9c8;
  color: #000;
  padding: 0px 2px;
}
</style>