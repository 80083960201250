<template>
  <div class="user-thumbnail-box" :style="{ height: size, width: size }">
    <img
      v-if="!imgError && Boolean(src)"
      id="image"
      :src="src"
      class="user-thumbnail"
      @error="onImgError()"
    >
    <QWAvatar v-else :username="username" class="user-thumbnail" :size="avatarSize"/>
    <div v-if="status != null" class="options-tick-div" :title="status">
      <div class="green-tick" :style="{background: status == 'active'  ? activeColor:awayColor, height: statusSize, width: statusSize }"></div>
    </div>
  </div>
</template>
<script>
/**
 * Thumbnail Component
 * Src - source for round image
 * Size - Size of the thumbnail
 * Badge - Chat source indication { fb / telegram }
 * Username - User name for avatar
 */
import QWAvatar from './QWAvatar';

export default {
  components: {
    QWAvatar,
  },
  props: {
    src: {
      type: String,
    },
    size: {
      type: String,
      default: '40px',
    },
    badge: {
      type: String,
      default: 'fb',
    },
    username: {
      type: String,
    },
    status: {
      type: String,
      default: null
    },
    statusSize: {
      type: String,
      default: '8px',
    }
  },
  data() {
    return {
      imgError: false,
      activeColor: "#1bb057",
      awayColor: "#fbc916",
    };
  },
  computed: {
    avatarSize() {
      return Number(this.size.replace(/\D+/g, ''));
    },
    badgeStyle() {
      const badgeSize = `${this.avatarSize / 3}px`;
      return { width: badgeSize, height: badgeSize };
    },
  },
  methods: {
    onImgError() {
      this.imgError = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.user-thumbnail-box {
  @include flex-shrink;
  position: relative;

  .user-thumbnail {
    border-radius: 4px;
    height: 100%;
    width: 100%;
    background-color: #3a6ff1;
    color: white;
  }

  .source-badge {
    bottom: -$space-micro / 2;
    height: $space-slab;
    position: absolute;
    right: $zero;
    width: $space-slab;
  }

  .options-tick-div {
  padding: 2px;
  border-radius: 10px;
  background: white;
  position: absolute;
  right: -1px;
  bottom: -1px;

  .green-tick {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    background: #1bb057;
    position: relative;
  }
}
}
</style>
