<template>
  <div class="qw-agent-dropdown-selector" style="margin-right: 8px;">
    <div style="position: relative">
      <div v-if="dropdownShow" v-on-clickaway="onToggleDropdown" class="qw-dropdown-menu-items">
        <div
          class="conversation-agent-filter-element"
          v-for="agent in agentList"
          :key="agent.user_id"
          @click="onSelect(agent)"
        >
          <QWThumbnail :src="agent.profile_pic" class="m-r-4" size="16px" :username="agent.name"/>
          <div :title="agent.name" class="title">{{agent.name}}</div>
        </div>
      </div>
    </div>
    <div v-if="selectedAgent" class="conversation-agent-filter" @click="onToggleDropdown">
      <QWThumbnail
        :src="selectedAgent.profile_pic"
        class="m-r-4"
        size="16px"
        :username="selectedAgent.name"
      />
      <div :title="selectedAgent.name || 'None'" class="title">{{selectedAgent.name || 'None'}}</div>
      <i class="fa fa-chevron-down qw-chevron" aria-hidden="true"></i>
    </div>
    <div v-else class="conversation-agent-filter" @click="onToggleDropdown">
      <QWThumbnail size="16px" class="m-r-4" :username="'None'"/>
      <div class="title">None</div>
      <i class="fa fa-chevron-down qw-chevron" aria-hidden="true"></i>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import QWThumbnail from './QWThumbnail';

export default {
  mixins: [clickaway],
  components: {
    QWThumbnail,
  },
  props: {
    selectedAgent: {
      type: Object,
      default: () => {},
    },
    agentList: {
      type: Array,
      default: [],
    },
    assignAgent: {
      type: Function,
    },
  },

  data: () => ({
    activeIndex: 0,
    dropdownShow: false,
  }),
  mounted() {},
  methods: {
    onToggleDropdown() {
      this.dropdownShow = !this.dropdownShow;
    },
    onSelect(agent) {
      this.assignAgent(agent);
      this.onToggleDropdown();
    },
  },
};
</script>

<style lang="scss">

.qw-agent-dropdown-selector {

  .qw-dropdown-menu-items {
    position: absolute;
    top: 3.5rem;
    background: white;
    border: 1px solid #d4d8dc;
    z-index: 999;
    width: max-content;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 8px #1a1c1d29;
    min-width: -webkit-fill-available;
  }

  .conversation-agent-filter {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid #dedddf;
    border-radius: 4px;
    height: 32px;
    color: #1a1c1d;

    img {
      margin-right: 4px;
    }

    .title {
      color: #1a1c1d;
      font-size: 14px;
      margin-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 122px;
      width: 100%;
      line-height: 24px;
    }
  }

  .conversation-agent-filter:hover {
    border-color: #3970f1;
    color: #3970f1;

    .title {
      color: #3970f1;
    }
  }

  .conversation-agent-filter-element {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    cursor: pointer;

    img {
      margin-right: 4px;
    }

    .title {
      color: inherit;
      font-size: 14px;
      margin-right: 8px;
      line-height: 24px;
    }
  }

  .conversation-agent-filter-element:hover {
    background: #3970f1;

    .avatar-container {
      background: white;
      color: #3970f1;
    }

    .title {
      color: white;
    }
  }
}
</style>