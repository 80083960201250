/* global axios */
import ApiClient from './ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations');
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  get(conversationID) {
    return axios.get(`${this.url}/${conversationID}`);
  }

  exporRequest(conversationID) {
    return axios.get(`${this.url}/${conversationID}/export_request`);
  }

  createLabels(params) {
    return axios.post(`${this.url}/${params.conversationId}/labels`, {
      labels: params.labels
    });
  }

  getPropertyKeys(conversationID) {
    return axios.get(`${this.url}/${conversationID}/conversation_property?inbox_level=false`);
  }

  getAllPropertyKeys(conversationID) {
    return axios.get(`${this.url}/${conversationID}/conversation_property?inbox_level=true`);
  }

  createProperty(params) {
    return axios.post(`${this.url}/${params.conversationId}/conversation_property`, {
      property: params.property
    });
  }
}

export default new ConversationApi();
